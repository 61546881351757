import dayjs from "dayjs";

import {
  buildQueryParams,
  deleteAuthorizationHeader,
  getResData,
  httpClient,
} from "utils";

import { UTILITY_BILLS_API_PATHS } from "./consts";
import {
  UpdateUtilityBillDTO,
  UtilityBillByIdDTO,
  UtilityBillsDTO,
  UtilityBillsQuery,
} from "./types";

export const getUtilityBills = (
  query: UtilityBillsQuery,
): Promise<UtilityBillsDTO> =>
  httpClient
    .get<UtilityBillsDTO>(
      `${UTILITY_BILLS_API_PATHS.UTILITY_BILLS}?${buildQueryParams(query)}`,
    )
    .then(getResData);

export const getUtilityBillById = (id: string): Promise<UtilityBillByIdDTO> =>
  httpClient
    .get<UtilityBillByIdDTO>(UTILITY_BILLS_API_PATHS.UTILITY_BILL(id))
    .then(getResData);

export const getSavedSearchFiltersList = (body: any): Promise<any> =>
  httpClient
    .post<UtilityBillByIdDTO>(UTILITY_BILLS_API_PATHS.SEARCH_FILTER, body)
    .then(getResData);

export const getSavedSearchFilters = (): Promise<any> =>
  httpClient.get<any>(UTILITY_BILLS_API_PATHS.SEARCH_FILTER).then(getResData);

export const deleteUtilityBill = (billId: string) =>
  httpClient.delete(UTILITY_BILLS_API_PATHS.UTILITY_BILL(billId));

export const recalculateUtilityBill = (body: UpdateUtilityBillDTO) =>
  httpClient
    .post(UTILITY_BILLS_API_PATHS.UTILITY_BILL_RECALCULATE, body)
    .then(getResData);

export const updateUtilityBill = (body: UpdateUtilityBillDTO) =>
  httpClient.put(UTILITY_BILLS_API_PATHS.UTILITY_BILLS, body).then(getResData);

export const downloadUtilityBills = (billIds: string[]) =>
  httpClient.get(
    `${UTILITY_BILLS_API_PATHS.EXPORT_UTILITY_BILLS}?${buildQueryParams({
      billIds,
    })}`,
    { responseType: "blob" },
  );

export const downloadUtilityBillPdf = (url: string) =>
  httpClient.get(url, {
    responseType: "blob",
    transformRequest: deleteAuthorizationHeader,
  });

export const approveUtilityBill = (utilityBillId: string) =>
  httpClient.put(UTILITY_BILLS_API_PATHS.APPROVE_UTILITY_BILL(utilityBillId));

export const declineUtilityBill = (utilityBillId: string) =>
  httpClient.put(UTILITY_BILLS_API_PATHS.DECLINE_UTILITY_BILL(utilityBillId));

export const manuallyApproveUtilityBill = (utilityBillId: string) =>
  httpClient.put(
    UTILITY_BILLS_API_PATHS.MANUAL_APPROVE_UTILITY_BILL(utilityBillId),
  );

  export const getDuplicateBillsData = () =>
    httpClient.get<any>(UTILITY_BILLS_API_PATHS.GET_DUPLICATE_DATA).then(response => response.data);


export const uploadHistoricalUtilityBills = (file: FormData) =>
  httpClient.post(
    UTILITY_BILLS_API_PATHS.UPLOAD_HISTORICAL_UTILITY_BILLS,
    file,
  );

export const getUtilityBillsYears = () =>
  httpClient
    .get<number[]>(UTILITY_BILLS_API_PATHS.UTILITY_BILLS_YEARS)
    .then(getResData)
    .then((data) => {
      const currentYear = dayjs().utc().year();

      if (data && !data.includes(currentYear)) {
        data.push(currentYear);
      }

      return data;
    });

export const setFeedback = (body: any): Promise<any> =>
      httpClient
        .post(UTILITY_BILLS_API_PATHS.UTILITY_BILLS_FEEDBACK, body);
