import { Box } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

import { Sidebar } from "./side-bar";
import { TopBar } from "./top-bar";

export const Layout: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Sidebar
        isOpen={isOpen}
        open={handleDrawerOpen}
        close={handleDrawerClose}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TopBar />

        <Box component="main" flex={1} overflow="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
