import React from "react";

import { CommentTextIcon } from "assets";

import { UTILITY_BILL_PAGE } from "../consts";

export const UTILITY_BILL_COMMENTS_VIEW = {
  ICON: <CommentTextIcon />,
  ABSOLUTE_PATH: (billId: string) =>
    `${UTILITY_BILL_PAGE.ABSOLUTE_PATH(billId)}/comments`,
  PATH: "comments",
  TITLE: (count: number) => `Comments (${count})`,
};
