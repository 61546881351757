import { useCallback } from "react";

import { CollectorDTO } from "api";

import { useAllCollectors } from "hooks";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";
import { OptionalMultiselectProps } from "./types";
import { getMultiselectOptions, getUniqArrayValues } from "./utils";

export const useAccountNumbersMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (collectors?: CollectorDTO[]) => {
      const accountNumbers = (collectors || [])
        .filter((collector) => collector.accountNumber)
        .map((collector) => collector.accountNumber);

      const uniqueAccountNumbers = sortBy({
        array: getUniqArrayValues(accountNumbers as string[]),
      });
      return getMultiselectOptions({
        array: uniqueAccountNumbers,
        withEmptyOption,
      });
    },
    [withEmptyOption],
  );

  const {
    isLoading: isAccountNumbersLoading,
    setSelectedOptions: setSelectedAccountNumbers,
    selectedOptions: selectedAccountNumbers,
    options: accountNumbersOptions,
    selectedOptionValues: accountNumbers,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useAllCollectors,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.ACCOUNT_NUMBERS,
  });

  return {
    isAccountNumbersLoading,
    selectedAccountNumbers,
    setSelectedAccountNumbers,
    accountNumbersOptions,
    accountNumbers: accountNumbers as string[],
  };
};
