import { Box } from "@mui/material";
import React, { FunctionComponent, useEffect, useRef } from "react";

import { CommentDTO, UtilityBillByIdDTO } from "api";

import {
  FLEX_CENTER_POSITIONING_STYLE,
  Label14SemiBold,
  Loading,
} from "components";

import { useCommentsByUtilityBill } from "hooks";

import { useAuthenticatedUserState } from "recoils";

import { Comment } from "./comment";
import { CommentTextField } from "./comment-text-field";

export const UtilityBillCommentsView: FunctionComponent<{
  utilityBill: UtilityBillByIdDTO;
}> = ({ utilityBill }) => {
  const { data, isError, isLoading } = useCommentsByUtilityBill(utilityBill.id);
  const user = useAuthenticatedUserState();
  const comments = data || [];
  const shouldScrollToComment = useRef<boolean>(true);

  useEffect(() => {
    if (shouldScrollToComment.current && data) {
      const commentId = window.location.href.split("#")[1];

      const element = document.getElementById(commentId);

      if (element) {
        element.scrollIntoView();
      }

      shouldScrollToComment.current = false;
    }
  }, [data, shouldScrollToComment]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "background.default",
          display: "flex",
          height: "100%",
          overflow: "hidden",
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 2,
            overflow: "auto",
            flexGrow: 1,
            gap: 2,
          }}
        >
          {isLoading && (
            <Loading
              sx={{ height: "100%", ...FLEX_CENTER_POSITIONING_STYLE }}
            />
          )}
          {isError && (
            <Label14SemiBold sx={{ m: 2 }}>
              Failed to fetch comments
            </Label14SemiBold>
          )}
          {!comments.length && (
            <Label14SemiBold sx={{ m: 2 }}>No comments</Label14SemiBold>
          )}
          {!isError &&
            comments.map((comment: CommentDTO) => (
              <Box
                key={comment.id}
                id={`${comment.id}`}
                sx={{
                  width: "66%",
                  alignSelf:
                    comment.authorEmail === user.email
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <Comment comment={comment} currentUserId={user.id} />
              </Box>
            ))}
        </Box>
      </Box>
      {!isError && (
        <CommentTextField
          utilityBillId={utilityBill.id}
          siteId={utilityBill.siteId}
        />
      )}
    </Box>
  );
};
