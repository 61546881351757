import { useCallback } from "react";

import { AllowedValuesDTO } from "api";

import { QUERY_PARAMS_KEYS } from "consts";


import { useMultiselectWithFetch } from "./multiselect";

import { useGetAllowedValues } from "../api";

export const useCountriesMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: any = {}) => {
  const getOptionsFromData = useCallback((allowedValues?: AllowedValuesDTO) => {
    const countries = (allowedValues?.countries || []).map((country) => ({
      label: country,
      value: country,
    }));

    const countriesAscOrder = countries.sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    return countriesAscOrder;
  }, []);

  const {
    isLoading: isCountriesLoading,
    setSelectedOptions: setSelectedCountry,
    selectedOptions: selectedCountry,
    options: countriesList,
    selectedOptionValues: country,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.COUNTRIES,
  });

  return {
    isCountriesLoading,
    setSelectedCountry,
    selectedCountry,
    countriesList,
    country,
  };
};
