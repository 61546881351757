import { Box, Button } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Dialog } from "./dialog";

export const SameUnitDialog: FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  title: string;
}> = ({ isOpen, onCancel, title }) => (
  <Dialog
    title={title}
    isOpen={isOpen}
    onClose={onCancel}
    actions={
      <Box>
        <Button variant="outlined" color="warning" onClick={onCancel}>
          Ok
        </Button>
      </Box>
    }
  >
    {/* <Box
        sx={{
          backgroundColor: "success.light",
          p: 2,
          borderRadius: 2,
          mb: 3,
          color: "success.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box component="span" sx={{ ...FLEX_CENTER_POSITIONING_STYLE, mr: 1 }}>
          <Comment2AlertIcon />
        </Box>
         This action cannot be undone.
      </Box> */}
    Data entry does not match the units of the selected emission factor. <br />
    Please update the data entry and re-upload it with the same units as the
    selected emission factor.
  </Dialog>
);
