import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

import { AdaptedCollectorsMissingDocuments, MONTHS_IDS } from "api";

import {
  EMPTY_CELL_VALUE,
  Tooltip,
  UtilityBillType,
  UtilityBillTypeKey,
} from "components";

import { TABLE_HEADERS } from "consts";

import { isNullOrUndefined } from "utils";

import { MonthCell } from "./month-cell";

export const NONE_PADDING_COLUMNS_IDS = new Set(MONTHS_IDS);

export const MISSING_DOCUMENTS_COLUMNS: ColumnDef<AdaptedCollectorsMissingDocuments>[] =
  [
    {
      accessorKey: "collector.estimate",
      header: "Tags",
      enableSorting: false,
      minSize: 70,
      cell: ({ row }) => {
        const showIndicator = row.original.collector.estimate;
        const showRecIndicator = row?.original?.collector?.recs;
        return (
          <Box sx={{display: "flex"}}>
          <Tooltip
            title={
              showIndicator
                ? "There are Parameter Based Estimations at this Site"
                : ""
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                background: showIndicator ? "#D3D3D3" : "transparent",
                color: showIndicator ? "black" : "transparent",
                fontWeight: "bold",
              }}
            >
              {showIndicator ? "E" : ""}
            </div>
          </Tooltip>
          {showRecIndicator && 
          <Tooltip
            title={
              showRecIndicator
                ? "This site has a REC applied to its emissions"
                : ""
            }
          >
            <div
              style={{
                display: "flex",
                marginLeft: "12px",
                justifyContent: "center",
                alignItems: "center",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                background: showRecIndicator ? "#D3D3D3" : "transparent",
                color: showRecIndicator ? "black" : "transparent",
                fontWeight: "bold",
              }}
            >
              {showRecIndicator ? "R" : ""}
            </div>
          </Tooltip>}
          </Box>
        );
      },
    },
    {
      header: "Site",
      accessorKey: "collector.siteName",
      cell: ({ getValue, row }) => {
        const value = getValue();
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Box sx={{ display: "flex" }}>
            <Tooltip title={value as string}>
              <Box
                sx={{
                  color: !isEnabled ? "#dddddd" : "",
                  mt: "3px",
                }}
              >
                {value as string}
              </Box>
            </Tooltip>
          </Box>
        );
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      header: "Source",
      accessorKey: "collector.providerName",
      cell: ({ getValue, row }) => {
        const value = getValue();
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Box sx={{ display: "flex" }}>
            <Tooltip title={value as string}>
              <Box
                sx={{
                  color: !isEnabled ? "#dddddd" : "",
                  mt: "3px",
                }}
              >
                {value as string}
              </Box>
            </Tooltip>
          </Box>
        );
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      header: TABLE_HEADERS.accountNumber,
      accessorKey: "collector.accountNumber",
      cell: ({ getValue, row }) => {
        const value = getValue();
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Tooltip title={value as string}>
            <Box sx={{ color: !isEnabled ? "#dddddd" : "" }}>
              {value as string}
            </Box>
          </Tooltip>
        );
      },
      enableSorting: false,
      minSize: 175,
    },
    {
      header: TABLE_HEADERS.meterNumber,
      accessorKey: "collector.meterNumber",
      cell: ({ getValue, row }) => {
        const value = getValue();
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Tooltip title={value as string}>
            <Box sx={{ color: !isEnabled ? "#dddddd" : "" }}>
              {value as string}
            </Box>
          </Tooltip>
        );
      },
      enableSorting: false,
      minSize: 155,
    },
    {
      header: "Type",
      accessorKey: "collector.utilityTypeName",
      cell: ({ getValue, row }) => {
        const value = getValue() as UtilityBillTypeKey;
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
          <Tooltip title={value as string}>
            <Box sx={{ color: !isEnabled ? "#dddddd" : "" }}>
              <UtilityBillType type={value} />;
            </Box>
          </Tooltip>
        );
      },
      minSize: 65,
      enableSorting: false,
    },
    {
      header: "Unit",
      accessorKey: "unit",
      cell: ({ getValue, row }) => {
        const value = getValue();
        const isEnabled = row.original.collector.enabled;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Tooltip title={value as string}>
            <Box sx={{ color: !isEnabled ? "#dddddd" : "" }}>
              {value as string}
            </Box>
          </Tooltip>
        );
      },
      enableSorting: false,
      minSize: 90,
    },
    {
      id: "01",
      header: "JAN",
      accessorKey: "months.01",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "02",
      header: "FEB",
      accessorKey: "months.02",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "03",
      header: "MAR",
      accessorKey: "months.03",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "04",
      header: "APR",
      accessorKey: "months.04",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "05",
      header: "MAY",
      accessorKey: "months.05",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "06",
      header: "JUN",
      accessorKey: "months.06",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "07",
      header: "JUL",
      accessorKey: "months.07",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "08",
      header: "AUG",
      accessorKey: "months.08",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "09",
      header: "SEP",
      accessorKey: "months.09",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "10",
      header: "OCT",
      accessorKey: "months.10",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "11",
      header: "NOV",
      accessorKey: "months.11",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
    {
      id: "12",
      header: "DEC",
      accessorKey: "months.12",
      cell: MonthCell,
      enableSorting: false,
      minSize: 100,
    },
  ];
