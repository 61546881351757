import {
  Tooltip as TooltipMui,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";

export const Tooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <TooltipMui {...props} arrow classes={{ popper: className }} placement="top">
      {children}
    </TooltipMui>
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
