import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { toUSNumber } from "utils";

import { Chip } from "../chip";
import {
  UTILITY_BILL_TYPE_BG_COLORS,
  UTILITY_BILL_TYPE_TO_MESSAGE,
  UtilityBillType,
  UtilityBillTypeKey,
} from "../utility-bill-type";

export const UtilityBillTypeChip: FunctionComponent<{
  utilityType: UtilityBillTypeKey;
  usage?: number;
  unit?: string;
}> = ({ utilityType, usage, unit }) => (
  <Chip
    bgColor={UTILITY_BILL_TYPE_BG_COLORS[utilityType]}
    label={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <UtilityBillType height="16px" width="16px" type={utilityType} />
        <Box sx={{ ml: 1 }} component="span">
          {usage !== undefined && unit
            ? `${toUSNumber(usage)} ${unit}`
            : UTILITY_BILL_TYPE_TO_MESSAGE[utilityType]}
        </Box>
      </Box>
    }
  />
);
