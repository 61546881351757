import { Box } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import React from "react";

import { EMPTY_CELL_VALUE } from "components";

import { toUSNumber } from "utils";

export const SITE_EMISSIONS_COLUMNS_DETAILS: MRT_ColumnDef<any>[] = [
  {
    header: "Suppliers",
    accessorKey: "supplier_name",
  },
  {
    header: "Product Grouping",
    accessorKey: "supplier_keywords",
    minSize:100,
  },
  {
    header: "Product Description",
    accessorKey: "description_1",
    minSize:100,
  },
  {
    header: "Item Number",
    accessorKey: "supplier_item_number",
    Cell: ({ cell }: any) => {
      const value = cell.getValue();

      if (!value) {
        return EMPTY_CELL_VALUE;
      }

      return <Box>{value as number}</Box>;
    },
  },
  {
    header: "Category",
    accessorKey: "category",
    Cell: ({ cell }: any) => {
      const value = cell.getValue();

      if (!value) {
        return EMPTY_CELL_VALUE;
      }

      return <Box>{value as number}</Box>;
    },
  },
  {
    header: "Scope 3 emissions (tonnes CO2eq.)",
    accessorKey: "calculated_co2_emission",
    Cell: ({ cell }: any) => {
      const value = cell.getValue();
      const tonnesValue = (value as number) / 1000;

      if (!value) {
        return EMPTY_CELL_VALUE;
      }

      return <Box>{toUSNumber((tonnesValue as number).toFixed(4))}</Box>;
    },
  },
];
