
import { Box, Typography } from "@mui/material";

import React, { useEffect } from "react";

import { DashboardTabs } from "pages/authenticated/dashboards/components/dashboardTabs";
import { DocumentsUploadIntensity, MarginWrapper } from "components";
import { useMutation } from "@tanstack/react-query";
import { getIntensity, getIntensitysite } from "api";
import { useNewBreadcrumb } from "recoils";
import { CompanyLevel } from "./company-level";
import { SiteLevel } from "./site-level";
import { INTENSITY_PAGE } from "../consts";


const leagalBtnStates = {
    LEGAL: "company",
};
export const IntensityPage = () => {
    const [value, setValue] = React.useState("");
    const [upoadAPI, setUpoadAPI] = React.useState(false);
    
    useNewBreadcrumb(INTENSITY_PAGE.TITLE);
    const { data: intensitycompany, mutateAsync: getIntenSity } = useMutation(getIntensity);
    const { data: intensitysite, mutateAsync: getIntenSitysite } = useMutation(getIntensitysite);
    const { LEGAL } = leagalBtnStates;

    useEffect(() => {
        getIntenSity()
        getIntenSitysite()
    },[getIntenSity, getIntenSitysite])

    useEffect(() => {
        if (upoadAPI === true) {
            getIntenSity()
            getIntenSitysite()
        }
    },[upoadAPI, getIntenSity, getIntenSitysite])

    const handleDeactivate = (e: any) => {
        if (e === true) {
            getIntenSity()
            getIntenSitysite()
        }
    }

    const tabs: any = {
        [LEGAL]: [
            {
                label: "Company Level",
                content: <CompanyLevel intensitydata={intensitycompany} isDeactivate={handleDeactivate}/>,
            },
            {
                label: "Site Level",
                content: <SiteLevel intensitydata={intensitysite} isDeactivate={handleDeactivate}/>,
            },

        ],
    };
    

    const handleTabChange = (tabName: any) => {
        if (tabName === 0) {
            setValue("Company Level")
        } else {
            setValue("Site Level")
         };
        }
         const handleApiResponse = (e: any) => {
            setUpoadAPI(e)
         }   
    return (

        <MarginWrapper>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "space-around", flexWrap: "wrap", padding: "10px" }} >
                <Typography sx={{ height: 40, width: 204, alignContent: "space-around", }}>

                    <DocumentsUploadIntensity activeTab={value === "Company Level"} apiResponse={handleApiResponse}/>
                </Typography>
            </Box>

            <DashboardTabs intensity tabs={tabs[LEGAL]} selectedBtnOption={LEGAL} onTabChange={handleTabChange} />

        </MarginWrapper>
    )
}
