/* eslint-disable */
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs as TabsMui,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { getMFAValues, setMFAValues } from "api";

import { COLORS, RoutedTabs, Switch } from "components";

import { useNewBreadcrumb } from "recoils";

import { USER_SECURITY_PAGE } from "./consts";

import { SETTINGS_ROUTER } from "../legal";

const SETTINGS_TABS = [
  {
    label: USER_SECURITY_PAGE.TITLE,
    to: USER_SECURITY_PAGE.ABSOLUTE_PATH,
  },
];
export const UserSecurityPage = () => {
  useNewBreadcrumb(SETTINGS_ROUTER.TITLE);

  useEffect(() => {
    mfaValue()
      .then((response) => {
        // This code will run after setMfaValue is successfully completed
        setIsEnable(response?.mfa === "TRUE" ? true : false);
      })
      .catch((error) => {
        // Handle any errors that might occur during setMfaValue
      });
  }, []);

  // Get MFA & SSO values
  const { data: getMFADetails, mutateAsync: mfaValue } =
    useMutation(getMFAValues);

  const SSOValue = getMFADetails && getMFADetails?.sso;

  // States
  const [isEnable, setIsEnable] = useState<any>();
  const [shouldRunEffect, setShouldRunEffect] = useState(false);
  const { data, mutateAsync: setMfaValue } = useMutation(setMFAValues);

  useEffect(() => {
    if (shouldRunEffect) {
      setMfaValue({ mfaEnabled: isEnable });
    }
  }, [isEnable, shouldRunEffect]);

  useEffect(() => {
    mfaValue();
  }, [data, mfaValue]);

  const handleMfaValue = () => {
    setShouldRunEffect(true);
    setIsEnable((prevState: any) => !prevState);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsMui variant="fullWidth">
          <Tab
            label="SECURITY"
            iconPosition="start"
            sx={{ minHeight: "48px", backgroundColor: "white" }}
          />
        </TabsMui>
      </Box>
      <Box mx={2} my={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography mb={2} variant="subtitle1">
              <b>TYPE</b>
            </Typography>
            <Typography variant="body1">
              Multi Factor Authentication (MFA)
            </Typography>
            <Typography variant="body1" mt={3}>
              Single Sign On (SSO)
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography mb={2} variant="subtitle1">
              <b>STATUS</b>
            </Typography>
            <Switch
              isChecked={isEnable}
              label={isEnable ? "Enable" : "Disable"}
              onChange={() => handleMfaValue()}
            />
            {/* <Typography variant="body1" mt={3}>Single Sign On (SSO) Details</Typography> */}
            <Box mt={2}>
              {/* <Button variant="outlined"
                              sx={{
                                  color: "black",
                                  backgroundColor: "white",
                                  width: "auto",
                                  height: "32px",
                              }}>Details</Button> */}
              <Switch
                disabled={true}
                isChecked={SSOValue === "TRUE" ? true : false}
                label={SSOValue === "TRUE" ? "Enable" : "Disable"}
                onChange={() => setIsEnable((prevState: any) => !prevState)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
