import React from "react";

import { EsgResourcesIcon, FactorsIcon } from "assets";

export const EMISSION_FACTORS_PAGE = {
  ABSOLUTE_PATH: "/references",
  PATH: "",
  TITLE: "Resources",
  ICON: <EsgResourcesIcon />,
  IS_TBD: false,
  renderMenu: true,
};

export const EMISSION_FACTORS_PAGEINDEX = {
  TITLE: "Resources",
  PATH: "/references/*",
};

export const EMISSION_FACTORS_REFERENCES = {
  ABSOLUTE_PATH: "/references",
  PATH: "",
  TITLE: "references",
  ICON: <FactorsIcon />,
  renderMenu: true,
};

export const EMISSION_FACTORS_FILES = {
  ABSOLUTE_PATH: "/references/files",
  PATH: "/files",
  TITLE: "Files",
  // ICON: <FileTextIcon />,
  renderMenu: true,
};
export const EMISSION_FACTORS_FILES_FOLDERS = {
  ABSOLUTE_PATH: "/references/folders",
  PATH: "/folders/:id",
  TITLE: "folders",
  // ICON: <FileTextIcon />,
  renderMenu: true,
};
