import React from "react";

import { EsgSitesIcon, EsgIntensityLogo } from "assets";

export const SITES_PAGE = {
  ABSOLUTE_PATH: "/sites",
  TITLE: "Site management",
  ICON: <EsgSitesIcon />,
  IS_TBD: false,
  renderMenu: false,
};

export const REC_UPLOAD = {
  ABSOLUTE_PATH: (recId: string) =>
  `rec/${recId}`,
  PATH: "rec/:recId",
  TITLE: "REC UPLOAD",
};
export const INTENSITY_PAGE = {
  ABSOLUTE_PATH: "/intensity",
  TITLE: "Intensity Data",
  ICON: <EsgIntensityLogo />,
  IS_TBD: false,
  renderMenu: false,
};

