import { useQuery } from "@tanstack/react-query";

import {
  CollectorDTO,
  getAllCollectors,
  getCollectorsBySiteId,
  getDeActivatedCollectorsBySiteId,
  getUnitCostByCollectorId,
} from "api";

import { QUERY_KEYS } from "consts";

import { MaybeNull } from "types";

export const useCollectorsBySiteId = (siteId: MaybeNull<number>) =>
  useQuery<CollectorDTO[]>(
    [QUERY_KEYS.COLLECTORS, QUERY_KEYS.SITES, siteId],
    () => getCollectorsBySiteId(siteId!),
    {
      enabled: !!siteId,
    },
  );

export const useAllCollectors = () =>
  useQuery<CollectorDTO[]>([QUERY_KEYS.COLLECTORS_ALL], getAllCollectors);

export const useDeActivatedCollectorsBySiteId = (siteId: MaybeNull<number>) =>
  useQuery<CollectorDTO[]>(
    [QUERY_KEYS.COLLECTORS, QUERY_KEYS.SITES, siteId],
    () => getDeActivatedCollectorsBySiteId(siteId!),
  );

  export const useUnitCostByCollectorId = (collectorId: MaybeNull<number>) =>
  useQuery<any>(
    [QUERY_KEYS.COLLECTORS, QUERY_KEYS.SITES, collectorId],
    () => getUnitCostByCollectorId(collectorId!),
  );

