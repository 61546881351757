import { Box, Button, Popover } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
} from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import { markAllAsReadNotifications, markAsReadNotification } from "api";

import { useNotificationsInfinite, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { MaybeNull } from "types";

import { useSaveCurrentUrl } from "recoils";

import { NotificationMessage } from "./notification-message";

import { COLORS } from "../../../consts";
import { Switch, useSwitchFilter } from "../../../switch";
import { H5Bold } from "../../../typography";

export const NotificationsPopover: FunctionComponent<{
  close: () => void;
  id?: string;
  anchorEl: MaybeNull<HTMLElement>;
  isOpen: boolean;
}> = ({ close, anchorEl, id, isOpen }) => {
  const { ref: loadMoreBtnRef, inView: isLoadMoreBtnInView } = useInView();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const saveCurrentUrl = useSaveCurrentUrl();
  const { value: isUnread, onSwitchChange: onIsUnreadChange } = useSwitchFilter(
    {
      key: "isUnread",
      withQueryParams: false,
    },
  );
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    remove,
  } = useNotificationsInfinite({ isUnread });
  const { mutateAsync: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMutation(markAllAsReadNotifications);
  const { mutateAsync: markAsRead, isLoading: isMarkingAsRead } = useMutation(
    markAsReadNotification,
  );
  const notify = useNotify();
  const onClose = useCallback(() => {
    close();
    remove();
  }, [close, remove]);

  const onMarkAllAsRead = () =>
    markAllAsRead(undefined, {
      onSuccess: () =>
        notify.success("Successfully marked all notifications as read!"),
      onError: () =>
        notify.error("Something went wrong during notifications update!"),
      onSettled: () =>
        queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATIONS]),
    });

  const onNotificationClick = useCallback(
    (
      notificationId: number,
      notificationLink: string,
      notificationReadDate: MaybeNull<string>,
    ) => {
      if (notificationReadDate === null) {
        markAsRead(notificationId, {
          onError: () =>
            notify.error("Something went wrong during notification update!"),
          onSettled: () => {
            queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATIONS]);
            saveCurrentUrl();
            navigate(notificationLink);
            onClose();
          },
        });
      } else {
        saveCurrentUrl();
        navigate(notificationLink);
        onClose();
      }
    },
    [onClose, markAsRead, navigate, saveCurrentUrl, notify, queryClient],
  );
  const isReadAllDisabled =
    !data ||
    isFetching ||
    isMarkingAllAsRead ||
    isMarkingAsRead ||
    data.pages[0].unreadCount === 0;
  const loadMoreBtnContent = isFetchingNextPage
    ? "Loading more..."
    : hasNextPage
    ? "Load More"
    : "Nothing more to load";

  useEffect(() => {
    if (isLoadMoreBtnInView) {
      fetchNextPage();
    }
  }, [isLoadMoreBtnInView, fetchNextPage]);

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{ height: "700px", width: "440px" }}
        bgcolor={COLORS.neutralGhWhite}
        display="flex"
        flexDirection="column"
      >
        <Box
          minHeight="76px"
          bgcolor={COLORS.white}
          display="flex"
          alignItems="center"
        >
          <H5Bold marginLeft={3}>Notifications</H5Bold>
        </Box>
        <Box display="flex" justifyContent="space-between" m={3}>
          <Switch
            isChecked={isUnread}
            label="Show only unread"
            onChange={(value) => {
              onIsUnreadChange(value);
              remove();
            }}
          />
          <Button disabled={isReadAllDisabled} onClick={onMarkAllAsRead}>
            Mark all as read
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          px={1}
          overflow="auto"
          flexGrow={1}
        >
          {data?.pages.map((notifications, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i}>
              {notifications.notifications.map((notification) => (
                <NotificationMessage
                  key={notification.id}
                  notification={notification}
                  onClick={onNotificationClick}
                />
              ))}
            </Fragment>
          ))}
          <Button
            ref={loadMoreBtnRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {loadMoreBtnContent}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
