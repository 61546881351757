import { useCallback } from "react";

import { AllowedValuesDTO } from "api";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useSingeSelectWithFetch } from "./singleselect";

import { useGetAllowedValues } from "../api";
import { OptionalMultiselectProps } from "../multiselect/types";

export const useCountryRegionsSelect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (allowedValues?: AllowedValuesDTO) => {
      const countryRegionsAscOrder = sortBy({
        array: (allowedValues || { countryRegions: [] }).countryRegions,
      });

      if (withEmptyOption) {
        countryRegionsAscOrder.push("");
      }
      return countryRegionsAscOrder;
    },
    [withEmptyOption],
  );

  const {
    isLoading: isCountryRegionsLoading,
    setSelectedOption: setSelectedCountryRegion,
    selectedOption: selectedCountryRegion,
    options: countryRegionsList,
    selectedOptionValues: countryRegion,
  } = useSingeSelectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.COUNTRY_REGION,
  });

  return {
    isCountryRegionsLoading,
    setSelectedCountryRegion,
    selectedCountryRegion,
    countryRegionsList,
    countryRegion,
  };
};
