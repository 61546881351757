import dayjs from "dayjs";

import { MaybeNull } from "types";

import { getResData, httpClient } from "utils";

import { MISSING_DOCUMENTS_API_PATHS } from "./consts";
import {
  AdaptedCollectorsMissingDocuments,
  CollectorsMissingDocumentsDTO,
  MissingDocumentsQuery,
  MonthUsage,
} from "./types";

export const MONTHS_IDS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const generateMonthIds = (fromDate: string, toDate: string): string[] => {
  const fromMonth = dayjs(fromDate).month() + 1; // Month is zero-based in dayjs
  const toMonth = dayjs(toDate).month() + 1; // Month is zero-based in dayjs
  const fromYear = dayjs(fromDate).year();
  const toYear = dayjs(toDate).year();

  const months: string[] = [];

  // Loop through months starting from fromMonth to toMonth
  // eslint-disable-next-line
  for (let year = fromYear; year <= toYear; year++) {
    const startMonth = year === fromYear ? fromMonth : 1;
    const endMonth = year === toYear ? toMonth : 12;
    // eslint-disable-next-line
    for (let month = startMonth; month <= endMonth; month++) {
      months.push(String(month).padStart(2, '0'));
    }
  }

  return months;
};

export const getMissingDocuments = (
  query: MissingDocumentsQuery,
): Promise<AdaptedCollectorsMissingDocuments[]> => {


  const setFixedData = (figure: string) => {
    if (figure.includes(".")) {
      const parts = figure?.toString()?.split(".");
      return `${parts[0]}.${parts[1]?.substring(0, 4)}`

    }
    return figure;

  }

  const { siteIds, fromDate, toDate, utilityTypeIds } = query;

  const fromYear = dayjs(fromDate).year();


  const toYear = dayjs(toDate).year();

  const monthIds = generateMonthIds(fromDate, toDate)

  // const siteId = siteIds?.split(":")[0].split(",")
  const ids: any = [];
  siteIds?.split(":")[0].split(",").map((item: any) =>
    ids.push(parseInt(item, 10))
  )
  const missingdata = {
    siteIds: ids,
    utilityTypeIds: utilityTypeIds?.length === 0 ? null : utilityTypeIds,
    fromDate,
    toDate

  }

  return httpClient
    .post<CollectorsMissingDocumentsDTO[]>(
      MISSING_DOCUMENTS_API_PATHS.MISSING_DOCUMENTS(), missingdata
    )
    .then(getResData)
    .then((data) => data.map((d) => ({ ...d, id: d.collector.id })))
    .then((data) =>
      data.map((d) => {
        const months: Record<string, MonthUsage> = {};
        const days: Record<string, Record<string, MaybeNull<number>>> = {};
        const daysLookup = d.days.reduce((acc, { day, usage }) => {
          if (!acc[day]) {
            acc[day] = usage;
          } else {
            acc[day] =
              acc[day] !== null
                ? usage !== null
                  ? Number((((acc[day] + usage + Number.EPSILON) * 100) / 100))
                  : acc[day]
                : usage;
          }

          return acc;
        }, {} as Record<string, number>);

        monthIds.forEach((month) => {
          let year = fromYear;
          if (parseInt(month, 10) < parseInt(monthIds[0], 10)) {
            year = toYear;
          }

          const datePrefix = `${year}-${month}`;
          const daysInMonth = dayjs(`${datePrefix}-01`).daysInMonth();

          for (let dayNumber = 1; dayNumber <= daysInMonth; dayNumber += 1) {
            const day = String(dayNumber).padStart(2, "0");
            const usage = daysLookup[`${datePrefix}-${day}`] ?? null;
            const billType = d.days[0]?.billType;
            const isNullUsage = usage === null;

            if (!months[month]) {
              months[month] = { usage, hasNullUsage: isNullUsage, billType };
            } else if (months[month].usage !== null) {
              if (!isNullUsage) {
                let num = usage === 0 ? Number(((months[month].usage! + usage) * 100 / 100)).toString() : Number(((months[month].usage! + usage + Number.EPSILON) * 100 / 100)).toString();
                const num22 = num.slice(0, (num.indexOf(".")) + 20);

                if (dayNumber === daysInMonth) {
                  num = setFixedData(num22);
                  const billTyps = d.days.find((ele) => ele.day.includes(`${datePrefix}-${day}`));
                  months[month].billType = billTyps?.billType;
                }
                months[month].usage = Number(num);
              }
            } else {
              months[month].usage = usage;
            }

            if (!months[month]) {
              months[month] = { usage, hasNullUsage: isNullUsage, billType };
            }

            if (!months[month].hasNullUsage && isNullUsage) {
              months[month].hasNullUsage = true;
            }

            if (!days[month]) {
              days[month] = {
                [day]: usage,
              };
            } else {
              days[month][day] = usage;
            }
          }
        });


        return { ...d, days, months };
      }),
    );
}