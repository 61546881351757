import { useCallback } from "react";

import { AllowedValuesDTO } from "api";

import { UTILITY_BILL_STATUS_TO_MESSAGE } from "components";

import { useGetAllowedValues } from "hooks";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";
import { OptionalMultiselectProps } from "./types";
import { getMultiselectOptions } from "./utils";

export const useUtilityBillStatusesMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback((allowedValues?: AllowedValuesDTO) => {
    const billStatusesAscendingOrder = sortBy({
      array: (allowedValues || { billStatuses: [] }).billStatuses,
    });

    return getMultiselectOptions({
      array: billStatusesAscendingOrder,
      labelMapObject: UTILITY_BILL_STATUS_TO_MESSAGE,
    });
  }, []);

  const {
    isLoading: isUtilityBillStatusesLoading,
    setSelectedOptions: setSelectedUtilityBillStatuses,
    selectedOptions: selectedUtilityBillStatuses,
    options: utilityBillStatusesOptions,
    selectedOptionValues: utilityBillStatusesValues,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.STATUSES,
  });

  return {
    isUtilityBillStatusesLoading,
    selectedUtilityBillStatuses,
    setSelectedUtilityBillStatuses,
    utilityBillStatusesOptions,
    utilityBillStatusesValues: utilityBillStatusesValues as string[],
  };
};
