import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

import { manualSearchSupplies } from "api";

interface States {
  label: string;
  value: string;
}

export const MultiSelectDropdown: React.FunctionComponent<{
  data: any;
  manualSearchTableData: any;
}> = ({ data, manualSearchTableData }) => {
  const [selected, setSelected] = useState("");
  const [geograhy, setGeograhy] = useState<States[]>([]);
  const [sector, setSector] = useState<States[]>([]);
  const [timeperiod, setTimeperiod] = useState<States[]>([]);
  const [units, setUnits] = useState<States[]>([]);

  const {
    data: manualSearchData,
    mutateAsync: manualSearch,
    isSuccess,
  } = useMutation(manualSearchSupplies);
  const geograhyPayload = geograhy.map((item) => item.value);
  const sectoryPayload = sector.map((item) => item.value);
  const timeperiodPayload = timeperiod.map((item) => item.value);
  const unitsPayload = units.map((item) => item.value);

  useEffect(() => {
    if (isSuccess) {
      setSelected("");
      setGeograhy([]);
      setSector([]);
      setTimeperiod([]);
      setUnits([]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (manualSearchData === null || manualSearchData === undefined) {
      manualSearchTableData(null);
    } else {
      manualSearchTableData(manualSearchData);
    }
  }, [manualSearchData, manualSearchTableData]);

  const Optionsscope3Geograhy = data.scope3_geograhy.map((item: any) => ({
    label: item,
    value: item,
  }));

  const Scope3Sector = data.scope3_sector.map((item: any) => ({
    label: item,
    value: item,
  }));

  const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
  }));

  const buttonStyle = {
    width: "100%",
    position: "relative",
    top: 45,
    m: 2,
  };

  const fontStyle = {
    fontWeight: "bold",
    mt: 4,
  };

  const search = () => {
    manualSearch({
      geography: geograhyPayload.length === 0 ? null : geograhyPayload,
      searchKeyWords: selected.length === 0 ? null : selected,
      sector: sectoryPayload.length === 0 ? null : sectoryPayload,
      timePeriod: timeperiodPayload.length === 0 ? null : timeperiodPayload,
      units: unitsPayload.length === 0 ? null : unitsPayload,
    });
  };

  const handleTextFieldValue = (e: any) => {
    setSelected(e.target.value);
  };

  return (
    <Box sx={{}}>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid xs={4} sm={4} md={4}>
          <Item>
            <Box sx={fontStyle}> Supplier keywords </Box>
          </Item>
          <TextField
            sx={{ width: "100%", height: "38px" }}
            size="small"
            id="outlined-basic"
            value={selected}
            variant="outlined"
            onChange={(e: any) => handleTextFieldValue(e)}
          />
        </Grid>
        <Grid xs={3} sm={3} md={3}>
          <Item>
            <div>
              <h4> Sector </h4>
              <MultiSelect
                options={Scope3Sector}
                hasSelectAll={false}
                value={sector}
                onChange={setSector}
                labelledBy="Select"
              />
            </div>
          </Item>
        </Grid>
        <Grid xs={3} sm={3} md={3}>
          <Item>
            <div>
              <h4> Geography </h4>
              <MultiSelect
                options={Optionsscope3Geograhy}
                value={geograhy}
                hasSelectAll={false}
                onChange={setGeograhy}
                labelledBy="Select"
              />
            </div>
          </Item>
        </Grid>
        <Grid xs={2} sm={2} md={2}>
          <Item>
            <Button
              variant="contained"
              onClick={search}
              disabled={selected === "" && true}
              sx={buttonStyle}
            >
              Search
            </Button>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
