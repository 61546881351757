import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  ADD_DOCUMENT_BILL_PAGE,
  AddDocumentBillPage,
} from "./add-document-bill";

export const DocumentUploadRouter = () => (
  <Routes>
    <Route
      path={ADD_DOCUMENT_BILL_PAGE.PATH}
      element={<AddDocumentBillPage />}
    />
  </Routes>
);
