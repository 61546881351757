import React from "react";

import { EsgSettingIcon, RotateCwIcon } from "assets";

export const SETTINGS_ROUTER = {
  TITLE: "Settings",
  PATH: "/settings/*",
};

export const SETTINGS_PAGE = {
  ABSOLUTE_PATH: "/settings/",
  PATH: "",
  TITLE: "Settings",
  ICON: <EsgSettingIcon />,
  IS_TBD: false,
  renderMenu: false,
};

export const REST_LEARNING_PAGE = {
  ABSOLUTE_PATH: "/rest-learning",
  PATH: "",
  TITLE: "Rest Learning",
  ICON: <RotateCwIcon />,
};
