import { UseQueryResult } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_KEYS } from "consts";

import { HookWithQueryParams } from "types";

import { getParsedQueryParams } from "utils";
import moment from "moment";

export const useYearMonthPeriodFilter = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  // useYears = useUtilityBillsYears,
  withQueryParams = true,
  initialValue,
  isYearly = false,
}: HookWithQueryParams & {
  useYears?: () => UseQueryResult<number[], unknown>;
  initialValue?: number;
} = {}) => {
  // const { data: availableYears } = useYears();
  const availableYears: any[] = useMemo(
    () => [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    [],
  );
  const [period, setPeriod] = useState<Dayjs>(dayjs().utc());
  const [searchParams, setSearchParams] = useSearchParams();

  const isInitialQueryReadRef = useRef<boolean>(true);

  useEffect(() => {
    if (availableYears && isInitialQueryReadRef.current && withQueryParams) {
      // const yearFromSearchParams = Number.parseInt(
      //   searchParams.get(QUERY_PARAMS_KEYS.YEAR) || "",
      //   10,
      // );

      // const monthFromSearchParams = Number.parseInt(
      //   searchParams.get(QUERY_PARAMS_KEYS.MONTH) || "",
      //   10,
      // );

      // if (
      //   !Number.isNaN(yearFromSearchParams) &&
      //   availableYears.includes(yearFromSearchParams)
      // ) {
      //   setPeriod(dayjs(`${yearFromSearchParams}-${monthFromSearchParams}-01`));
      // } else if (initialValue) {
      //   setPeriod(dayjs(`${initialValue}-${monthFromSearchParams}-01`));
      // }

      isInitialQueryReadRef.current = false;
    }
  }, [initialValue, availableYears, searchParams, withQueryParams]);

  const onPeriodAccept = useCallback(
    (y: Dayjs | null) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setPeriod(y || dayjs().utc());

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        if (y?.year() !== undefined && y?.month !== undefined) {
          queryParams[QUERY_PARAMS_KEYS.YEAR] = String(y.year());
          queryParams[QUERY_PARAMS_KEYS.MONTH] = isYearly
            ? "1"
            : String(y.month() + 1);
        }

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
          }),
        );
      }
    },
    [
      updateExternalStates,
      withQueryParams,
      searchParams,
      setSearchParams,
      getQueryParamsWithExternalChanges,
      isYearly,
    ],
  );

  return {
    startDate: isYearly
      ? period?.startOf("year").format("YYYY-MM-DD")
      : `${period.format("YYYY-MM")}-01`,
    endDate: isYearly
      ? period?.endOf("year").format("YYYY-MM-DD")
      : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
    period,
    periodStr: isYearly ? period.format("YYYY") : period.format("MMM-YY"),
    onPeriodChange: () => null,
    onPeriodAccept,
    availableYears,
  };
};

export const useYearMonthPeriodFilterScope3 = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  // useYears = useUtilityBillsYears,
  withQueryParams = true,
  initialValue,
}: HookWithQueryParams & {
  useYears?: () => UseQueryResult<number[], unknown>;
  initialValue?: number;
} = {}) => {
  // const { data: availableYears } = useYears();
  const availableYears: any[] = useMemo(
    () => [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    [],
  );
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [period, setPeriod] = useState<Dayjs>(dayjs("2021-05-01"));
  const [endPeriod, setEndPeriod] = useState<Dayjs>(dayjs(moment(lastDay).format("YYYY-MM-DD")));
  const [searchParams, setSearchParams] = useSearchParams();

  const isInitialQueryReadRef = useRef<boolean>(true);

  useEffect(() => {
    if (availableYears && isInitialQueryReadRef.current && withQueryParams) {
      const yearFromSearchParams = Number.parseInt(
        searchParams.get(QUERY_PARAMS_KEYS.YEAR) || "",
        10,
      );

      const monthFromSearchParams = Number.parseInt(
        searchParams.get(QUERY_PARAMS_KEYS.MONTH) || "",
        10,
      );

      if (
        !Number.isNaN(yearFromSearchParams) &&
        availableYears.includes(yearFromSearchParams)
      ) {
        setPeriod(dayjs(`${yearFromSearchParams}-${monthFromSearchParams}-01`));
        setEndPeriod(
          dayjs(`${yearFromSearchParams}-${monthFromSearchParams}-01`),
        );
      } else if (initialValue) {
        setPeriod(dayjs(`${initialValue}-${monthFromSearchParams}-01`));
        setEndPeriod(dayjs(`${initialValue}-${monthFromSearchParams}-01`));
      }

      isInitialQueryReadRef.current = false;
    }
  }, [initialValue, availableYears, searchParams, withQueryParams]);

  const onPeriodAccept = useCallback(
    (y: Dayjs | null) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setPeriod(y || dayjs());

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        if (y?.year() !== undefined && y?.month !== undefined) {
          queryParams[QUERY_PARAMS_KEYS.YEAR] = String(y.year());
          queryParams[QUERY_PARAMS_KEYS.MONTH] = String(y.month() + 1);
        }

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
          }),
        );
      }
    },
    [
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  const onEndPeriodAccept = useCallback(
    (y: Dayjs | null) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setEndPeriod(y || dayjs());

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        // if (y?.year() !== undefined && y?.month !== undefined) {
        //   queryParams[QUERY_PARAMS_KEYS.YEAR] = String(y.year());
        //   queryParams[QUERY_PARAMS_KEYS.MONTH] = String(y.month() + 1);
        // }

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
          }),
        );
      }
    },
    [
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  return {
    startDate: `${period.format("YYYY-MM")}-01`,
    endDate: `${endPeriod.format("YYYY-MM")}-${endPeriod?.daysInMonth()}`,
    period,
    endPeriod,
    periodStr: period.format("MMM-YY"),
    onPeriodChange: () => null,
    onEndPeriodChange: () => null,
    onPeriodAccept,
    onEndPeriodAccept,
    availableYears,
  };
};
