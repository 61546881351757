import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material";
import React, { FunctionComponent } from "react";

import { ReactChildren } from "types";

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          height: 100%;
        },
        body {
          height: 100%;
        },
        #root {
          height: 100%;
        },
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: 600,
          height: 34,
          padding: "8px 12px",
          lineHeight: "18px",
        },
        sizeLarge: {
          height: 40,
          padding: "11px 20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { background: "#ffffff" },
        input: {
          ":disabled": {
            background: "#F6F6F8",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Manrope",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: "#054254",
      light: "#BFDED1",
      dark: "#172822",
    },
    text: {
      primary: "#000302",
      secondary: "#6C6F6D",
      disabled: "#9DA7B8",
    },
    error: {
      main: "#E70202",
      light: "rgba(231, 2, 2, 0.1)",
    },
    success: {
      main: "#2F8B21",
      light: "rgba(5, 136, 2, 0.1)",
    },
    warning: {
      main: "#BA7B02",
      light: "rgba(232, 125, 0, 0.1)",
    },
    grey: {
      50: "#FAFAFB",
      100: "#F4F4F6",
      200: "#ECEDF0",
      300: "#DDDEE3",
      400: "#B6B9C4",
      500: "#9499A8",
      600: "#696F81",
      700: "#575C6B",
      800: "#3B3E49",
      900: "#1E1F24",
    },
    background: {
      default: "#F6F8FA",
      // default: "#FFFFFF",
    },
  },
});

export const ThemeProvider: FunctionComponent<ReactChildren> = ({
  children,
}) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
