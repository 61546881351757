import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { FunctionComponent, useMemo, useState } from "react";

import { SearchIcon } from "assets";

import { useSites } from "hooks";

const AddOtherSitesDialog: FunctionComponent<{
  handleSaveClick: () => void;
  isOpen: boolean;
  onClose: () => void;
  setNewSite: any;
}> = ({ handleSaveClick, isOpen, onClose, setNewSite }) => {
  const containsText = (text: any, searchText: any) =>
    typeof text === "string" &&
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const { data: sites } = useSites();
  const [selectedOption, setSelectedOption] = useState("");

  const isApplyDisabled = selectedOption === "";

  const [searchText, setSearchText] = useState("");
  const displayedSites = useMemo(
    () => sites && sites.filter((site) => containsText(site.name, searchText)),
    [searchText, sites],
  );

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value as string;
    const site = sites && sites.find((s) => s.name === value);
    setSelectedOption(value);
    setNewSite(site);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ top: "-300px" }}>
      <DialogTitle
        sx={{
          color: "#054254",
          fontWeight: "600",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        Find More Sites
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: "#054254",
            backgroundColor: "#F2F8FF",
            borderRadius: "5px",
            marginTop: "12px",
            padding: "12px",
            fontSize: "14px",
          }}
        >
          Looking for a way to apply this bill to another site? Search for sites
          below to apply your bill to a different site.
        </DialogContentText>
        <div style={{ marginTop: "20px", color: "#525B73" }}>
          Select a site:
        </div>
        <Box mt={1}>
          <FormControl>
            <Select
              sx={{ maxHeight: "44px", width: "460px" }}
              MenuProps={{ autoFocus: false, defaultValue: "The Hello" }}
              labelId="search-select-label"
              id="search-select"
              placeholder="Select"
              value={selectedOption}
              onChange={handleSelectChange as any}
              onClose={() => setSearchText("")}
              renderValue={() => selectedOption}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Search for a site"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedSites &&
                displayedSites.map((site: any) => (
                  <MenuItem key={site.id} value={site.name}>
                    {site.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "#6B7280", mr: "4px", mb: "10px" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: isApplyDisabled ? "#D1D5DB" : "#054254",
            color: isApplyDisabled ? "#FFFFFF" : "#EAFCFF",
            mr: "15px",
            mb: "10px",
            "&:hover": {
              backgroundColor: isApplyDisabled ? "#D1D5DB" : "#0781a6",
            },
            "&:focus": {
              outline: "none",
            },
          }}
          onClick={handleSaveClick}
          disabled={isApplyDisabled}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOtherSitesDialog;
