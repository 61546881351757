import { AllowedValuesDTO } from "api";

import { AllowedFormValues, FormCollector } from "./types";

export const mapFormCollectorsToRequestData = ({
  utilityTypes,
  siteCollectors,
  siteId,
}: {
  utilityTypes: AllowedFormValues["utilityTypes"];
  siteCollectors: FormCollector[];
  siteId: number;
}) =>
  siteCollectors.map((c) => ({
    accountNumber: c.accountNumber,
    allowedDocuments: [c.allowedDocuments],
    frequency: c.frequency,
    id: c.id,
    meterNumber: c.meterNumber,
    providerName: c.providerName,
    consumptionUnit: c.consumptionUnit,
    currency: c.currency,
    siteId,
    utilityTypeId:
      utilityTypes.find(
        (ut) => ut.name.toLowerCase() === c.utilityTypeName.name.toLowerCase(),
      )?.id || 0,
  }));

export const getAllowedValues = (
  allowedValues?: AllowedValuesDTO,
): AllowedFormValues => ({
  utilityTypes:
    allowedValues?.utilityTypes.map((ut) => ({
      name: ut.name,
      id: ut.id,
      unitDescription: ut.unitDescription.map((ud) => ({
        unit: ud.unit,
        description: ud.description,
      })),
    })) || [],
    utilitySubtypesMap: allowedValues?.utilitySubtypesMap || {},
  countries: allowedValues?.countries || [],
  frequency: allowedValues?.frequencies || [],
  regions: allowedValues?.regions || [],
  siteTypes: allowedValues?.siteTypes || [],
  statuses: allowedValues?.billStatuses || [],
  uploadTypes: allowedValues?.uploadTypes || [],
  siteStatuses: allowedValues?.siteStatuses || [],
  countryRegions: allowedValues?.countryRegions || [],
  currencies: allowedValues?.currencies || [],
  units: allowedValues?.units || [],
});
