import { Box } from "@mui/material";
import React, { FunctionComponent, memo } from "react";

import { Loading } from "components";

import { useGetAllowedValues, useGetUsers, useSiteById } from "hooks";

import { SiteEditorForm, getAllowedValues } from "./site-editor-form";

export const Site: FunctionComponent<{
  siteId: number;
  isExpanded: boolean;
  isNewSite?: boolean;
}> = memo(({ siteId, isExpanded, isNewSite }) => {
  const { data: site, isLoading: isSiteLoading } = useSiteById(
    siteId,
    isExpanded && !isNewSite,
  );
  const { data: allowedValues, isLoading: areAllowedValuesLoading } =
    useGetAllowedValues();
  const { data: users, isLoading: areUsersLoading } = useGetUsers();

  if (
    (!isNewSite && isSiteLoading) ||
    areAllowedValuesLoading ||
    areUsersLoading
  ) {
    return (
      <Box position="relative" minHeight="300px">
        <Loading />
      </Box>
    );
  }

  const allowedUsers =
    users?.filter(
      (u) =>
        u.active &&
        u.admin &&
        !u.auditor &&
        !site?.users?.map((siteUser) => siteUser.id).includes(u.id),
    ) || [];
    
  return (
    <SiteEditorForm
      allowedUsers={allowedUsers}
      allowedValues={getAllowedValues(allowedValues)}
      site={site}
    />
  );
});
