import { Box } from "@mui/material";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
import React from "react";

import {
  DocumentStatusCount,
  EMPTY_CELL_VALUE,
  MEASURE_UNIT_DISPLAY_MAP,
  Tooltip,
  UtilityBillStatusKey,
} from "components";

import { getDateFormat, isNullOrUndefined } from "utils";

export const DOCUMENT_UTILITY_BILLS_COLUMNS: MRT_ColumnDef<any>[] = [
  {
    header: "Supplier Name",
    accessorKey: "supplier_name",
    Cell: ({ cell }) => {
      const supplierName = cell.getValue();
      if (!supplierName) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Tooltip title={supplierName as string}>
          <Box sx={{ textAlign: "left" }}>{`${supplierName}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Item              number", // Space is For word wrapping In header
    accessorKey: "supplier_item_number",
    size: 120,
    Cell: ({ cell }) => {
      const value = cell.getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Tooltip title={value as string}>
          <Box sx={{ textAlign: "left" }}>{`${value}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Product      Description",
    accessorKey: "description_1",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Tooltip title={value as string}>
          <Box sx={{ textAlign: "left" }}>{`${value}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Category",
    accessorKey: "category",
    size: 100,
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Tooltip title={value as string}>
          <Box sx={{ paddingLeft: "30px" }}>{`${value}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Units",
    accessorKey: "conversion_unit_value",
    size: 100,
    Cell: ({ cell, row }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            {MEASURE_UNIT_DISPLAY_MAP[
              row.original
                .units_of_measure as keyof typeof MEASURE_UNIT_DISPLAY_MAP
            ] ||
              row.original.units_of_measure ||
              EMPTY_CELL_VALUE}
          </span>
        </Box>
      );
    },
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    size: 100,
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Box sx={{ textAlign: "left" }}>{(value as number).toFixed(2)}</Box>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 100,
    Cell: ({ cell }) => {
      const statuses = cell.getValue();

      if (!statuses) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <DocumentStatusCount
            keyName={statuses as UtilityBillStatusKey}
            value={"" as unknown as number}
          />
        </Box>
      );
    },
  },
  {
    header: "Conversion unit",
    accessorKey: "conversion_unit",
    size: 100,
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return <Box sx={{ textAlign: "left" }}>{`${value}`}</Box>;
    },
  },
  {
    header: "Converted quantity purchased",
    accessorKey: "converted_quantity_purchased",
    size: 120,
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <Tooltip title={value as string}>
          <Box sx={{ textAlign: "text" }}>{(value as Number).toFixed(2)}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Consumption period start date",
    accessorKey: "consumptionPeriodStartDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      const date = dayjs(value as string)
        .utc()
        .format(getDateFormat());

      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Tooltip title={date}>
          <Box>{date}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: "Consumption period end date",
    accessorKey: "consumptionPeriodEndDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      const date = dayjs(value as string)
        .utc()
        .format(getDateFormat());

      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Tooltip title={date}>
          <Box>{date}</Box>
        </Tooltip>
      );
    },
  },
];
