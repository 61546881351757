import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";
import { Link, matchPath, useLocation } from "react-router-dom";


import { Scope3Context } from "pages";
import {
  EMISSION_FACTORS_FILES,
  EMISSION_FACTORS_REFERENCES,
} from "pages/authenticated/emission-factors";
import { EsgArrowDown, EsgArrowRight, EsgDropLine, EsgFilesIcon, EsgReferencesIcon } from "assets";
import { Box } from "@mui/system";
import { COLORS } from "components/consts";


export const ResourcesOptionsList: FunctionComponent<{
  isOpen: boolean;
  icon: ReactNode;
  isSelected?: boolean;
  isScope3?: boolean;
}> = ({ isOpen, icon, isSelected, isScope3 = false }) => {
  const options = [
    {
      label: "References",
      to: EMISSION_FACTORS_REFERENCES.ABSOLUTE_PATH,
      shortLabel: "U",
      icon: <EsgReferencesIcon />,
      key: 0,
    },
    {
      label: "Files",
      to: EMISSION_FACTORS_FILES.ABSOLUTE_PATH,
      shortLabel: "U",
      icon: <EsgFilesIcon />,
      key: 1,
    },

  ];

  const { pathname } = useLocation();
  const selectedOption = options.filter((item) => matchPath(item.to, pathname));
  const initialTabIndex = selectedOption.length ? selectedOption[0].key : null;
  const { activeScope } = useContext(Scope3Context);
  const setDefaultOpen = () => !(isScope3 && activeScope.length > 1);
  const [open, setOpen] = React.useState(setDefaultOpen());

  const [selectedIndex, setSelectedIndex] = useState(initialTabIndex);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  if (selectedIndex !== initialTabIndex) {
    setSelectedIndex(initialTabIndex);
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const handleMouseEnter = (event: any) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({ top: rect.top, left: rect.right - 20 });
    setModalOpen(true);
  };

  const handleMouseLeave = () => {
    setModalOpen(false);
  };


  return (
    <>
      <ListItem sx={{
        display: 'grid',
        justifyContent: !isOpen ? 'center' : 'flex-start',
        py: 0,
        my: "9px",
        ...(isOpen && { pl: 0 })
      }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <ListItemButton
          selected={isSelected}
          onClick={() => setOpen(!open)}
          sx={{
            display: 'block',
            justifyContent: 'center',
            borderRadius: 2,
            marginLeft: isOpen ? '15px' : '',
            "&.Mui-selected": {
              backgroundColor: "background.default",
              width: isOpen ? '258px' : '85px', 
              "& svg": {
                color: "primary.main",
              },
              ":hover": {
                backgroundColor: "background.default",
              },
            },
            "&.Mui-focusVisible": {
              backgroundColor: "background.default",
            },
            ":hover": {
              backgroundColor: "transparent",
              color: isSelected ? null : "primary.main",
              "& svg": {
                color: "primary.main",
              },
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ListItemIcon
              sx={{
                justifyContent: "center",
                minWidth: isOpen ? '25px' : '56px'
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary="Resources"
              sx={{
                opacity: isOpen ? 1 : 0,
                marginLeft: isOpen ? '6px' : '',
                alignItems: 'center',
                justifyContent: "center",

                "& .MuiListItemText-primary": {
                  fontWeight: 400,
                  color: COLORS.Romance,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                },
                ".MuiListItemButton-root": {
                  ":hover": {
                    backgroundColor: "transparent",
                  },

                }
              }}
            />
            {isOpen && <Box sx={{ ml: '100px' }}>
              {open ? <EsgArrowDown /> : <EsgArrowRight />}
            </Box>}
          </Box>
          {!isOpen && <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            fontSize: '12px',
            mt: '-5px',
            fontWeight: 400,
            color: COLORS.Romance,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            lineHeight: '12px' }}>Resources</Box>}
        </ListItemButton>
        {!isOpen && isModalOpen && (
          <div className="sideBar-main-modal" style={{ top: modalPosition.top, left: modalPosition.left }}>
            {options.map((item, index) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }} key={item.key}>
                <ListItemButton
                  onClick={(event) => {
                    handleListItemClick(event, index)
                    handleMouseLeave()
                  }}
                  selected={item.key === selectedIndex}
                  component={Link}
                  to={item.to}
                  sx={
                    {
                      height: '42px',
                      borderRadius: 2,
                      justifyContent: !isOpen ? "center" : '',
                      "&.Mui-selected": {
                        backgroundColor: "background.default",
                        ":hover": {
                          backgroundColor: "background.default",
                        },
                      },
                      ".MuiListItemButton-root": {
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }
                    }
                  }
                  key={item.label}
                >
                  <ListItemIcon sx={!isOpen ? { minWidth: 0, mr: 1 } : {}}>
                    {item?.icon}
                  </ListItemIcon>
                  <ListItemText sx={{
                    marginLeft: isOpen ? '-10px' : '',
                    "& .MuiListItemText-primary": {
                      fontSize: '12px',
                      color: COLORS.Romance,
                      fontWeight: 400,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      lineHeight: '24px',
                    }
                  }} primary={item.label} />
                </ListItemButton>
              </Box>
            )
            )}
          </div>
        )}
      </ListItem>

      {isOpen && <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            paddingLeft: isOpen ? "20px" : "0px",
          }}
        >
          {options.map((item, index) => (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} key={item.key}>
              {isOpen && <ListItemIcon sx={{
                minWidth: "12px",
                position: 'absolute',
                left: '43px',
                mt: 0,
                zIndex: 9999,
              }}>
                <EsgDropLine />
              </ListItemIcon>}
              <ListItemButton
                onClick={(event) => handleListItemClick(event, index)}
                selected={item.key === selectedIndex}
                component={Link}
                to={item.to}
                sx={
                  {
                    pl: isOpen ? 4 : 0,
                    height: '42px',
                    ml: 1,
                    borderRadius: 2,
                    justifyContent: !isOpen ? "center" : '',
                    "&.Mui-selected": {
                      backgroundColor: "background.default",
                      ":hover": {
                        backgroundColor: "background.default",
                      },
                    },
                    ".MuiListItemButton-root": {
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }
                  }
                }
                key={item.label}
              >
                {isOpen &&
                  <ListItemIcon sx={!isOpen ? { minWidth: 0, pl: item.label === "Upload" ? 3 : 2, mt: 0 } : { minWidth: '40px' }}>
                    {item?.icon}
                  </ListItemIcon>}
                {isOpen && <ListItemText sx={{ marginLeft: isOpen ? '-10px' : '',
                  "& .MuiListItemText-primary": {
                      color: COLORS.Romance,
                      fontWeight: 400,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      lineHeight: '24px',
                    }
                 }} primary={item.label} />}
              </ListItemButton>
            </Box>
          ))}
        </List>
      </Collapse>}
    </>
  );
};
