/* eslint-disable */
import { Search, Send } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import MaterialReactTable from "material-react-table";
import React, { useState } from "react";
import * as XLSX from "xlsx";

import { SiteDTO, deleteSite, exportSites, uploadSites, exportRecsDetailBySite, enableSite } from "api";
import { getMultipleRecDocumentDetails, getRecDocumentDetails } from "api/rec";

import { EsgEditIcon, PlusIcon, SitemultiRegion, EsgRedPowerIcon, EsgGreenPowerIcon } from "assets";

import {
  COLORS,
  DeleteDialog,
  EMPTY_CELL_VALUE,
  H5BoldInter,
  Loading,
  MarginWrapper,
  TextField,
  useDialogControls,
} from "components";
import { RecUploadButton } from "components/buttons/rec-upload-button";

import { useGetAllowedValues, useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";

import { USER_ROLES, useNewBreadcrumb, useUserRole } from "recoils";

import { convertBlankStringsToNull } from "utils";

import { SiteAccordion } from "./components";
import { AddSiteModal } from "./components/site-editor-form/components/AddSiteModal";
import { SiteColumns } from "./components/site-editor-form/components/SiteColumns";

import { getAllowedValues } from "../sites/components";
import { SITES_PAGE } from "../sites/consts";

export const SitesPageV2 = () => {
  useNewBreadcrumb(SITES_PAGE.TITLE);
  const { data: sites, isLoading, refetch, isRefetching } = useSites();
  const userRole = useUserRole();
  const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
  const [EditSiteOpen, setEditSiteOpen] = useState(false);
  const [toDeactivateSite, setDeactivateSite] = useState<any | undefined>();
  const [EditSite, setEditSite] = useState<any | undefined>();
  const [clickType, setClickType] = useState<string>("");
  const [isDataLoading, setIsLoading] = useState<boolean>(false);

  const activatedSites = sites?.filter((t) => t.enabled === true);
  const deActivatedSites = sites?.filter((t) => t.enabled === false);

  const [searchValue, setSearchValue] = useState("");
  const [filterSites, setFilterSites] = useState<SiteDTO[]>(
    activatedSites || [],
  );

  const notify = useNotify();

  const { data: allowedValues } = useGetAllowedValues();
  const { mutateAsync: disableSiteRequest } = useMutation(deleteSite);
  const { mutateAsync: activeSite } = useMutation(enableSite);

  const deleteDialogControls = useDialogControls();

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
    handleSearch(value);
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setFilterSites(sites || []);
    } else {
      const filteredActivatedRecords = sites?.filter(
        (record) =>
          (record.name &&
            record.name.toLowerCase().includes(value.toLowerCase())) ||
          (record.type &&
            record.type.toLowerCase().includes(value.toLowerCase())) ||
          (record.city &&
            record.city.toLowerCase().includes(value.toLowerCase())) ||
          (record.country &&
            record.country.toLowerCase().includes(value.toLowerCase())),
      );
      setFilterSites(filteredActivatedRecords || []);
    }
  };

  const siteDetailsColumns = [
    {
      accessorKey: "name",
      header: "Site Name",
      size: 60,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                maxWidth: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type of site",
      size: 40,
    },
    {
      accessorKey: "businessUnit",
      header: "Business Unit",
      size: 40,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;
        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                maxWidth: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "city",
      header: "City",
      size: 100,
    },
    {
      accessorKey: "country",
      header: "Country",
      size: 40,
    },
    {
      accessorKey: "countryRegion",
      header: "Region",
      size: 40,
    },
    {
      accessorKey: "region",
      header: "Electricity Region Code",
      Header: <Box>Electricity Region Code</Box>,
      size: 200,
      // eslint-disable-next-line
      Cell: ({ row }: any) => {
        const { multipleRegion: regionmultiple, region } = row.original;

        let message = "";
        if (region === null) {
          message = regionmultiple
            ? "Multiple region codes found"
            : "Missing region code";
        }

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {region === null ? (
              <span style={{ color: "#D86262" }}>
                <SitemultiRegion
                  style={{
                    marginRight: "2px",
                    paddingTop: regionmultiple ? "0px" : "4px",
                  }}
                />
                {message}{" "}
              </span>
            ) : (
              <span>{region} </span>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "address",
      header: "Street Address",
      size: 40,
      // eslint-disable-next-line
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                alignItems: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "zipCode",
      header: "ZipCode",
      size: 40,
    },
  ];

  const handleEditSite = (rowData: any, type: string) => {
    setClickType(type)
    setEditSiteOpen(true);
    setEditSite(rowData);
  };

  const handleCloseSiteModal = () => {
    if (addSiteModalOpen) {
      setAddSiteModalOpen(false);
    }
    if (EditSiteOpen) {
      setEditSiteOpen(false);
      refetch();
    }
    setEditSite(undefined);
  };

  const handleDeActivateSite = (siteId: number) => {
    if (siteId) {
      disableSiteRequest(siteId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while de-activating the  site!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully De-Activated!`);
          refetch();
          // window.location.reload();
        },
      });
    }
  };

  const handleReActivateSite = (siteId: number) => {
    if (siteId) {
      activeSite(siteId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while re-activating the site!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully Re-Activated!`);
          refetch();
          // window.location.reload();
        },
      });
    }
  };

  const activatedRecords =
    (activatedSites &&
      JSON.parse(
        JSON.stringify(searchValue.length > 0 ? filterSites : activatedSites),
      )) ||
    [];

  const deActivatedRecords =
    (deActivatedSites && JSON.parse(JSON.stringify(deActivatedSites))) || [];

  const exportToExcel = async () => {
    setIsLoading(true)
    notify.success(`Data is being prepared, this might take a moment.`);
    try {
      setIsLoading(true)
      const sites: any = await exportSites();
      const recs = await exportRecsDetailBySite();
      const siteSetup = sites.map((site: any) => ({
        "Customer Site ID": site.customerSiteId,
        "Site Name": site.name,
        "Type of site": site.type,
        "Business Unit": site.businessUnit,
        City: site.city,
        Country: site.country,
        "Country Region": site.countryRegion,
        "Electricity Region": site.region,
        "Street address": site.address,
        "Zip Code": site.zipCode,
        State: site.state,
        Status: site.status,
        "Start Date": new Date(site.startDate),
        "End Date": new Date(site.endDate),
        Notes: site.notes,
      }));

      const utilityProviderInfo = sites.flatMap((site: any) =>
        site.collectors.map((collector: any) => ({
          "Site Name": site.name,
          "Utility Type": collector.utilityTypeName,
          utilitySubType: collector.utilitySubType,
          "Account Number": collector.accountNumber,
          "Meter Number": collector.meterNumber,
          "Utility Provider": collector.providerName,
          Frequency: collector.frequency,
          "Consumption Unit": collector.consumptionUnit,
          Currency: collector.currency,
          "Notes": collector.notes
        })),
      );

      const parameterBasedEstimatesInfo = sites.flatMap((site: any) =>
        site.estimates.map((estimate: any) => ({
          "Customer Site Id": site.customerSiteId,
          "Estimate Name": estimate.estimateName,
          "Estimate Type": estimate.estimateType,
          "Usage Per Day": estimate.estimateUsage,
          "Estimation Value": estimate.estimateHeadCount,
          Unit: estimate.estimateUsageUnit,
          "Start Date": estimate.estimateStartDate,
          "End Date": estimate.estimateEndDate,
          "Site Name": estimate.siteName,
          "Utility Type": estimate.estimateName,
          Notes: estimate.notes,
          Scope: estimate.scope,
        })),
      );

      const dataProvidersInfo = sites.flatMap((site: any) =>
        site.users.map((user: any) => ({
          "Site Name": site.name,
          "First Name": user.firstName,
          "Last Name": user.lastName,
          Email: user.email,
        })),
      );

      const renewableEnergyCertificatesInfo: any = [];

      const fetchRECDetails = async (recId: any) => {
        const recResponse = await getMultipleRecDocumentDetails(recId);
        return recResponse.data;
      };

      const formatCollectorString = (collectorRecsDtoList: any) =>
        collectorRecsDtoList
          .map(
            ({ siteName, meterNumber = "N/A", accountNumber = "N/A" }: { siteName: string, meterNumber: string, accountNumber: string }) =>
              `${siteName} - ${meterNumber} - ${accountNumber}`
          )
          .join("\n");

          const recCache = new Map();

          const getRecData = async(recId: string) => {
            if (recCache.has(recId)) {
              return recCache.get(recId);
            } else {
              const data = await fetchRECDetails(recId);
              recCache.set(recId, data);
              return data;
            }
          }

      const recIds = sites.flatMap((item: any) =>
        item.collectors
          .filter((collector: any) => collector.recsDetail && collector.recsDetail.recId !== undefined)
          .map((collector: any) => collector.recsDetail.recId)
      );

        const recData = await getRecData(recIds);

          
        recData.forEach((recItem: any) => {
          let matchingScope = null;
        
          // Iterate over sites to find relevant scope and other details
          for (const site of sites) {
            const relevantCollectors = site.collectors.filter((collector: any) => collector.recs);
        
            for (const { recsDetail, scope } of relevantCollectors) {
              // If there's a condition to match recItem with scope, do it here
              if (scope) {
                matchingScope = scope;
                break; // Stop the inner loop when a match is found
              }
            }
        
            if (matchingScope) {
              break; // Stop the outer loop once a match is found
            }
          }
        
          // Push the record with the found scope or default scope handling
          renewableEnergyCertificatesInfo.push({
            "Utility Provider": recItem.utilityProvider,
            "Utility Type": recItem.utilityTypeName,
            "Start Date": recItem.startDate,
            "End Date": recItem.endDate,
            Scope: matchingScope, // Add a default or null if no match found
            Collector: formatCollectorString(recItem.collectorRecsDtoList),
          });
        });

      const recDetailsBySite = recs.flatMap((recData: any) => ({
        "Site Name": recData.siteName,
        "Customer Site ID": recData.customerSiteId,
        "REC Country": recData.country,
        "REC Provider": recData.recsProvider,
        "REC Utility Type": recData.recsUtilityType,
        "REC Start Date": new Date(recData.recsStartDate).toISOString(),
        "REC End Date": new Date(recData.recsEndDate).toISOString(),
        "REC Percentage": recData.recsPercentage,
        "Collector": recData.collector?.replace("==>", "; "),
        "Scope of Collector": recData.scope,
        "Utility Type of Collector": recData.utilityTypeOfCollector
      })
      );

      const wb = XLSX.utils.book_new();

      const wsSiteSetup = XLSX.utils.json_to_sheet(siteSetup);

      const range = XLSX.utils.decode_range(wsSiteSetup['!ref'] as string);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const startDateCell = XLSX.utils.encode_cell({ r: row, c: 12 });
        if (wsSiteSetup[startDateCell]) wsSiteSetup[startDateCell].z = 'yyyy-mm-dd';

        const endDateCell = XLSX.utils.encode_cell({ r: row, c: 13 });
        if (wsSiteSetup[endDateCell]) wsSiteSetup[endDateCell].z = 'yyyy-mm-dd';
      }

      const wsUtilityProviderInfo = XLSX.utils.json_to_sheet(utilityProviderInfo);
      const wsParameterBasedEstimatesInfo = XLSX.utils.json_to_sheet(
        parameterBasedEstimatesInfo,
      );
      const wsDataProvidersInfo = XLSX.utils.json_to_sheet(dataProvidersInfo);
      const wsRenewableEnergyInfo = XLSX.utils.json_to_sheet(
        renewableEnergyCertificatesInfo,
      );

      const wsRecDetailsBySite = XLSX.utils.json_to_sheet(recDetailsBySite);

      XLSX.utils.book_append_sheet(wb, wsSiteSetup, "Site Setup");
      XLSX.utils.book_append_sheet(
        wb,
        wsUtilityProviderInfo,
        "Utility Provider Info",
      );
      XLSX.utils.book_append_sheet(
        wb,
        wsParameterBasedEstimatesInfo,
        "Parameter Estimates Setup",
      );
      XLSX.utils.book_append_sheet(
        wb,
        wsDataProvidersInfo,
        "Data Providers Info",
      );
      XLSX.utils.book_append_sheet(wb, wsRenewableEnergyInfo, "RECS Info");
      XLSX.utils.book_append_sheet(wb, wsRecDetailsBySite, "REC details by site");

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", cellDates: true });

      const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      };

      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Site_Management_Data.xlsx",
      );
    } catch (e) {
      console.log(":::e export", e)
      setIsLoading(false)
    }
    setIsLoading(false)
  };

  return (
    <MarginWrapper>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
        <RecUploadButton
          label="Upload Data"
          uploadApiCall={uploadSites}
          queryKeyToInvalidate={QUERY_KEYS.SITES}
          availableToRoles={[USER_ROLES.ADMIN]}
          extraReqParams={[["configuration", "true"]]}
        />
        <Button
          sx={{ marginTop: "10px" }}
          variant="contained"
          onClick={() => setAddSiteModalOpen(true)}
          startIcon={<PlusIcon />}
          disabled={userRole.isAuditor || userRole.isDataProvider}
        >
          Add site
        </Button>
        <Button
          sx={{ marginTop: "10px" }}
          variant="contained"
          onClick={exportToExcel}
          endIcon={<Send />}
          disabled={isDataLoading}
        >
          {isDataLoading ? "Loading..." : "Export to Excel"}
        </Button>
      </Box>

      <Box
        display="flex"
        gap="2"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
        sx={{ marginLeft: "auto", width: "fit-content" }}
      >
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomWidth: "1.8px",
            },
          }}
          placeholder="Search sites..."
          variant="standard"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
          <MaterialReactTable
            state={{ isLoading }}
            enableSorting={true} // Enable sorting
            columns={SiteColumns}
            enableGlobalFilter={false}
            data={searchValue.length > 0 ? filterSites : sites!}
            enablePagination
            muiTableContainerProps={{
              sx: {
                "& tbody tr:nth-child(even)": {
                  height: 0,
                },
                "& tbody": {
                  maxWidth: '100%',
                  "& tr:nth-child(even) > td:first-child > div: first-child > div > div": {
                    width: 'calc(100vw - 200px)',
                  },
                },
                height: "calc(100vh - 300px)",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "white",
                minWidth: '100%',
                width: '100%',
                maxWidth: '100%',
                '@media (min-width: 600px)': {
                  width: '100%',
                },
                '@media (min-width: 960px)': {
                  width: '1180px',

                },
                '@media (min-width: 1280px)': {
                  width: '1140px',

                },
                '@media (min-width: 1440px)': {
                  width: '1290px',

                },
                '@media (min-width: 1680px)': {
                  width: '1540px',

                },
                '@media (min-width: 2400px)': {
                  width: '100%',

                },
              },
            }}
            positionActionsColumn="last"
            defaultColumn={{
              minSize: 20,
              maxSize: 9001,
              size: 320,
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [100, 200, 500, 1000],
              showFirstButton: true,
              showLastButton: true,
            }}
            initialState={{ pagination: { pageSize: 100, pageIndex: 0 } }}
            renderDetailPanel={({ row }) => (
              <>
                <H5BoldInter mt={4} mb={2}>
                  Site Details
                </H5BoldInter>
                <MaterialReactTable
                  muiTableContainerProps={{
                    sx: {
                      "& thead tr:first-child th:first-child": {
                        paddingLeft: 0,
                        "& > div": {
                          justifyContent: "center",
                        },
                      },
                      "& tbody tr td:first-child": {
                        paddingLeft: 0,
                        "& > div": {
                          paddingLeft: 0,
                          paddingRight: 0,
                          justifyContent: "center",
                        },
                      },
                      border: "1px solid #D9D9D9",
                      borderRadius: "10px",
                      minWidth: "100%",
                      width: "100%",
                      maxWidth: "100%",
                      "@media (min-width: 600px)": {
                        width: "100%",
                      },
                      "@media (min-width: 960px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1280px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1440px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1680px)": {
                        width: "1300px",
                      },
                      "@media (min-width: 2400px)": {
                        width: "100%",
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      lineHeight: "30px",
                      "& .MuiBadge-root": {
                        display: "none",
                      },
                    },
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      height: "45px",
                      backgroundColor: "#F8FAFC",
                    },
                  }}
                  muiTableBodyRowProps={{
                    sx: {
                      height: "45px",
                      backgroundColor: "white",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color: row.original?.enabled ? 'black' : 'grey',
                      fontSize: '14px',
                    }
                  }}
                  columns={siteDetailsColumns}
                  data={[row.original]}
                  enablePagination={false}
                  enableDensityToggle={false}
                  initialState={{
                    density: "compact",
                  }}
                  enableRowActions
                  renderRowActions={() => (
                    <Box
                      sx={{
                        display: "flex",
                        borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', paddingRight: '5px'
                      }}
                    >
                      <Tooltip arrow placement="right" title="Edit">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "");
                          }}
                        >
                          <EsgEditIcon color={row.original.enabled ? COLORS.Romance : 'grey'} />
                        </IconButton>
                      </Tooltip>
                      {row.original.enabled === true ? <Tooltip arrow placement="right" title="Deactivate">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "Disable");
                          }}
                        >
                          <EsgRedPowerIcon />
                        </IconButton>
                      </Tooltip> : <Tooltip arrow placement="right" title="Reactivate">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "Enable");
                          }}
                        >
                          <EsgGreenPowerIcon />
                        </IconButton>
                      </Tooltip>}
                    </Box>
                  )}
                />
                {row.original.enabled &&
                  <Box sx={{ mt: 7 }}>
                    <SiteAccordion data={row.original} />
                  </Box>
                }
              </>
            )}
          />
      )}

      <AddSiteModal
        isOpen={addSiteModalOpen || EditSiteOpen}
        onClose={handleCloseSiteModal}
        allowedValues={getAllowedValues(allowedValues)}
        editSite={EditSite}
        clickType={clickType}
      />
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => handleDeActivateSite(toDeactivateSite)}
        target="Site"
        title="De-activate Site?"
      />
    </MarginWrapper>
  );
};
