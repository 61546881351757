import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Anomaly } from "api";

import { AnomalyArrowIcon, EditIcon, ManuallyApprovedIcon } from "assets";

import { EMPTY_CELL_VALUE, Tooltip } from "components";

import { MaybeNull } from "types";

import { isNullOrUndefined } from "utils";

import {
  ANOMALY_TYPE_TO_MESSAGE,
  AnomalyTypeToMessageKeys,
} from "../../consts";

export const AnomalyLogCell: FunctionComponent<{
  anomalies: Anomaly[];
  updateDate: MaybeNull<string>;
  isManuallyApproved: MaybeNull<boolean>;
}> = ({ anomalies, updateDate, isManuallyApproved }) => {
  if (isNullOrUndefined(anomalies)) {
    return <Box>{EMPTY_CELL_VALUE}</Box>;
  }

  const hasUtilityBillAnomalies = !!anomalies.length;

  if (!isManuallyApproved && !hasUtilityBillAnomalies && !updateDate) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {isManuallyApproved && (
        <Tooltip title="Manually approved">
          <ManuallyApprovedIcon />
        </Tooltip>
      )}

      {hasUtilityBillAnomalies && (
        <Tooltip
          title={anomalies
            .map(
              ({ anomalyType }) =>
                ANOMALY_TYPE_TO_MESSAGE[
                  anomalyType as AnomalyTypeToMessageKeys
                ],
            )
            .join(",\n")}
          sx={{ whiteSpace: "pre-line" }}
        >
          <AnomalyArrowIcon />
        </Tooltip>
      )}

      {updateDate && (
        <Tooltip title="Edited">
          <EditIcon />
        </Tooltip>
      )}
    </Box>
  );
};
