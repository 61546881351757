export const getInitials = (name: string | null) => {
  if (!name) {
    return null;
  }

  const initials = [];
  const nameParts = name.split(" ");

  if (nameParts.length > 1) {
    nameParts.forEach((part) => initials.push(part && part[0].toUpperCase()));
  } else {
    initials.push(nameParts && nameParts[0][0].toUpperCase());
  }

  return initials.join("");
};
