import { Box, Chip } from "@mui/material";
import React from "react";

import { COLORS } from "components/consts";
import { Label14SemiBold } from "components/typography";

export const SelectionCounter: React.FunctionComponent<{
  selectedElements: number;
  title?: string;
}> = ({ selectedElements, title = "Selection" }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Label14SemiBold
      {...(!selectedElements ? { color: COLORS.muiDisabled } : {})}
    >
      {title}
    </Label14SemiBold>
    {!!selectedElements && (
      <Chip
        sx={{
          minWidth: "20px",
          height: "20px",
          borderRadius: "8px",
          ".MuiChip-label": { paddingLeft: "4px", paddingRight: "4px" },
        }}
        label={selectedElements}
        color="primary"
      />
    )}
  </Box>
);
