import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { getAuthenticatedUser } from "api";

import {
  isUserAuthenticatedState,
  useSetDefaultUser,
  useSetUser,
} from "recoils";

import { setHttpClientAuthToken } from "utils";

export const useOktaAuthentication = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const setUser = useSetUser();
  const setDefaultUser = useSetDefaultUser();
  const isUserAuthenticated = useRecoilValue(isUserAuthenticatedState);

  useEffect(() => {
    oktaAuth.isAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        setDefaultUser();
        setIsLoading(false);
      }
    });
  }, [oktaAuth, setDefaultUser]);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setIsLoading(true);
      setHttpClientAuthToken(authState?.accessToken?.accessToken);

      getAuthenticatedUser()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
          setDefaultUser();
          oktaAuth.signOut().finally(() => {
            setHttpClientAuthToken();
            window.localStorage.clear();
            window.sessionStorage.clear();
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [authState, oktaAuth, setUser, setDefaultUser]);

  return {
    isUserAuthenticated,
    isLoading,
  };
};

export const useOktaSignOut = () => {
  const { oktaAuth } = useOktaAuth();
  const setDefaultUser = useSetDefaultUser();
  function clearStorageWithoutKey(keyToSave: string) {
    const items = { ...localStorage };
    localStorage.clear();
    localStorage.setItem(keyToSave, items[keyToSave]);
  }


  return useCallback(() => {
    oktaAuth
      .signOut()
      .catch((e) => {
        // TODO [MP]: investigate if we should call setDefaultUser and setHttpClientAuthToken on error
        console.error(`Failed to logout: ${e}`);
      })
      .finally(() => {
        setDefaultUser();
        setHttpClientAuthToken();
        // window.localStorage.clear();
        clearStorageWithoutKey('TERMLY_API_CACHE');
        window.sessionStorage.clear();
      });
  }, [setDefaultUser, oktaAuth]);
};

export const useOktaSignIn = () => {
  const { oktaAuth } = useOktaAuth();

  return useCallback(() => oktaAuth.signInWithRedirect(), [oktaAuth]);
};
