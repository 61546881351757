import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { MaybeNull } from "types";

import { UTILITY_BILL_STATUS_TO_MESSAGE, UtilityBillStatusKey } from "./consts";
import { UtilityBillStatus } from "./utility-bill-status";

import { Tooltip } from "../tooltip";

export const UtilityBillStatusWithTooltip: FunctionComponent<{
  message: MaybeNull<string>;
  status: MaybeNull<UtilityBillStatusKey>;
}> = ({ message, status }) => {
  if (!status) {
    return null;
  }
  
  return (
    <Tooltip
      title={message
        ? `${UTILITY_BILL_STATUS_TO_MESSAGE[status]}: ${message}`
        : UTILITY_BILL_STATUS_TO_MESSAGE[status]}
    >
      <Box component="span">
        <UtilityBillStatus status={status} />
      </Box>
    </Tooltip>
  );
};
