// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { Box, Button, ButtonGroup } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  createComanDocuments,
  // documentUploaded,
  comandocumentUploaded,
  uploadDocumentToS3,
} from "api";

// import { UploadButton } from "components/buttons";

import { useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";

import { updateFileCountContext } from "providers/uploadFileCount-provider";

import { useUserRole } from "recoils";

import { useDialogControls } from "../dialog";
// import { SiteSelectionDialog } from "../site-selection-dialog";

export const DocumentsUploadExcel: FunctionComponent<{
  // isScope3: boolean;
  billIdExcelId?:any;
  
}> = ({ billIdExcelId }) => {
  const notify = useNotify();
  const userRole = useUserRole();
  const queryClient = useQueryClient();
  // const { mutate: create } = useMutation(createDocuments);
  const { mutate: create } = useMutation(createComanDocuments);
  const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);
  const { mutateAsync: uploaded } = useMutation(comandocumentUploaded);
  const { data: sites } = useSites();
  const sitesDialogControls = useDialogControls();
  // const [selectedSite, setSelectedSite] = useState<null | string>(nu);
  const [uploadCount, setUploadCount] = useState<any>(0);

  const { updateUploadedCount, updateTotalCount } = useContext(
    updateFileCountContext,
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  let isOneSite = false;

  if (sites !== undefined) {
    isOneSite = sites?.length > 1;
  }
  // const onSiteSelect = (id: string) => {
  //   sitesDialogControls.close();
  //   setSelectedSite(id);
  //   fileInputRef?.current?.click();
  // };

  const onUploadButtonClick = () => {
    if (isOneSite && !userRole.isAdmin) {
      sitesDialogControls.open();
    } else if (sites?.length === 1 && !userRole.isAdmin) {
      // setSelectedSite(sites[0]?.id);
      fileInputRef?.current?.click();  
    } else {
      // setSelectedSite(billIdExcelId);
      fileInputRef?.current?.click();
    }
  };

  useEffect(() => {
    updateUploadedCount(uploadCount);
  }, [uploadCount, updateUploadedCount]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    let uploadedFilesCount = 0;
    updateTotalCount(files.length);
    create(
   
      {
        filename: files[0].name,
        billGuid: billIdExcelId ,

        
      },
      {
        onSuccess: (
          documents: {
            s3uploadUrl: string;
            document: { uuid: string; originalFileName: string };
          },
        
        ) => {
          // setUploadPdf(documents?.s3uploadUrl)
          // console.log("object uploaded successfully", documents?.document.uuid)
          // uploaded(documents?.document.uuid)
          const docA:any = [];
          docA.push(documents)
          queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]).then(() => {
            Promise.allSettled(
              docA.map((document: { s3uploadUrl: any; document: { originalFileName: string; uuid: string; }; }) =>
                uploadToS3({

                  url: document.s3uploadUrl,
                  file: files.find(
                    (file) => file.name === document.document.originalFileName,
                  ) as File,
                })
                 
                  .then(() => uploaded(documents.document.uuid))
                  .then((res) => {
                    if (res.data[0].status === "ERROR") {
                      notify.error(
                        `Failed to upload file: ${document.document.originalFileName}!`,
                      );
                    }
                    if (res.data[0].status === "PROCESSING") {
                      setUploadCount(uploadedFilesCount + 1);
                      uploadedFilesCount += 1;
                    }
                  })
                  .catch(() => {
                    // // TODO cleanup document if failed to upload
                    // notify.error(
                    //   `Failed to upload file: ${document.document.originalFileName}!`,
                    // );
                  }),
              ),
 
            ).then(() => {
              if (uploadedFilesCount === files.length) {
                notify.success(`Successfully uploaded all files!`);
                setTimeout(() => {
                  updateUploadedCount(0);
                  updateTotalCount(0);
                }, 2000);
              } else {
                notify.success(
                  `Successfully uploaded files:${files.length}`,
                );
                setTimeout(() => {
                  updateUploadedCount(0);
                  updateTotalCount(0);
                }, 2000);
              }
              queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]);
              e.target.value = "";
            });
          });
        },
        onError() {
          notify.error("Failed to upload files!");
          e.target.value = "";
        },
      },
    );
  };

  return (
    <>
      <ButtonGroup
        orientation="horizontal"
        aria-label="upload files button group"
        // variant="contained"
        sx={{
          display: "flex",
          width: "300px",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
       
          <>
            <input
              ref={fileInputRef}
              hidden
              accept="application/pdf"
              multiple
              type="file"
              onChange={onChange}
            />
            {/* <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              title="Upload pdf"
              component="label"
              onClick={onUploadButtonClick}
              disabled={!sites?.length || userRole.isAuditor}
            >
              Upload pdf
            </Button> */}
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            top: 20,
            "&:hover": {
              background: "#e0e0e0",
              cursor: "pointer",
            },
          }} 
         
         
          >
            <Button 
             onClick={onUploadButtonClick}
             style={{ all: 'unset' , display:"flex", flexDirection: "column", }}
             disabled={!sites?.length || userRole.isAuditor}
            >
              <CloudUploadIcon sx={{ marginLeft: "25px", fontSize: 60 }} color="disabled" />
            <span>Click To Upload</span>
            </Button> 
          </Box>
          
          </>
      

       
      </ButtonGroup>

      {/* {isOneSite && (
        <SiteSelectionDialog
          isDialogOpen={sitesDialogControls.isOpen}
          onDialogClose={sitesDialogControls.close}
          onSiteSelect={onSiteSelect}
        />
      )} */}
    </>
  );
};
