import MockAdapter from "axios-mock-adapter";

import { SITES_API_PATHS } from "api";

export const initSitesMock = (clientMock: MockAdapter) => {
  clientMock.onGet(new RegExp(`${SITES_API_PATHS.SITES}.*`)).reply(200, [
    {
      address: "",
      city: "",
      country: "",
      createDate: 0,
      id: 0,
      name: "Site",
      region: "",
      serviceAddress: "",
      type: "",
      updateDate: 0,
      zipCode: "",
    },
  ]);
};
