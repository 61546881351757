import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { atom, useSetRecoilState } from "recoil";

export const savedUrlState = atom({
  key: "locationPathnameAndSearch",
  default: "",
});

export const useSaveCurrentUrl = () => {
  const setLocationPathnameAndSearch = useSetRecoilState(savedUrlState);
  const { pathname, search } = useLocation();

  return useCallback(
    () => setLocationPathnameAndSearch(`${pathname}${search}`),
    [pathname, search, setLocationPathnameAndSearch],
  );
};
