import { MouseEvent, useCallback, useState } from "react";

export const usePopoverControl = (idStr: string) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [],
  );

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? idStr : undefined;

  return {
    anchorEl,
    isOpen,
    open,
    close,
    id,
  };
};
