import { useState } from "react";

export const useDialogControls = () => {
  const [isOpen, setIsDialogOpen] = useState(false);

  return {
    isOpen,
    close: () => setIsDialogOpen(false),
    open: () => setIsDialogOpen(true),
  };
};
