import { AxiosRequestConfig } from "axios";

export const extractIdPathParamFromUrl = (config: AxiosRequestConfig) =>
  config && config.url ? config.url.split("/").pop() : "";

export const getByIdFromList =
  <T extends { id: string }>(list: T[]) =>
  (config: AxiosRequestConfig) => {
    const id = extractIdPathParamFromUrl(config);
    const item = list.find((c) => c.id === id);

    return [200, item];
  };
