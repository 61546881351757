import { Chip, Stack } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Pages } from "components";

interface PdfPaginationProps {
  pageNumber: number;
  numberOfPages: number | null;
  onPageChange: (newPageNumber: number) => void;
}

export const PdfPagination: FunctionComponent<PdfPaginationProps> = ({
  pageNumber,
  numberOfPages,
  onPageChange,
}: PdfPaginationProps) => (
  <Stack direction="row" spacing={1} alignSelf="flex-start" mt={2}>
    <Chip
      sx={{
        background: "#ffffff",
        borderRadius: "8px",
        height: 40,
      }}
      label={`Page ${pageNumber} of ${numberOfPages}`}
      variant="filled"
    />
    {numberOfPages && numberOfPages > 1 && (
      <Pages
        disabledPages
        onPageChange={onPageChange}
        page={pageNumber}
        numberOfPages={numberOfPages || 0}
      />
    )}
  </Stack>
);
