import { Box } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

import { getInitials } from "utils";

import { Avatar } from "./avatar";
import { Body2SemiBold } from "./typography";

export const AvatarName: FunctionComponent<{
  name: string;
  content?: ReactNode;
  gap?: number;
  size?: number;
}> = ({ name, content, gap = 2, size }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap }}>
    <Avatar {...(size ? { sx: { height: size, width: size } } : {})}>
      {getInitials(name)}
    </Avatar>
    <Body2SemiBold>
      {name}
      {content}
    </Body2SemiBold>
  </Box>
);
