export const LEGAL_PAGE_SETTINGS = {
  ABSOLUTE_PATH: "/settings/legal",
  PATH: "/settings/legal",
  TITLE: "Legal",
  ICON: () => null,
};
export const LEGAL_SETTINGS_PRIVACY_POLICY = {
  PATH: "/settings/legal/privacy-policy",
};
export const LEGAL_SETTINGS_COOKIE_POLICY = {
  PATH: "/settings/legal/cookie-policy",
};
export const LEGAL_SETTINGS_DSAR = {
  PATH: "/settings/legal/dsar",
};
export const SETTINGS_ROUTER = {
  TITLE: "Settings",
  PATH: "/settings/*",
};
