import React from "react";

import { EsgFileIcon } from "assets";

export const DOCUMENT_UPLOAD_PAGE = {
  ABSOLUTE_PATH: "/documents",
  PATH: "",
  TITLE: "Document upload",
  ICON: <EsgFileIcon />,
  renderMenu: true,
};
