/* eslint-disable */
import { Box, Tooltip } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import React from "react";

import { SitemultiRegion } from "assets";

import { MaybeNull } from "types";
import { COLORS, EMPTY_CELL_VALUE } from "components";
import { isNullOrUndefined } from "utils";

type UserDTO = {
  active: boolean;
  admin: boolean;
  auditor: boolean;
  email: string;
  firstName: MaybeNull<string>;
  id: number;
  lastName: MaybeNull<string>;
  oktaIdentifier: string;
  sites: number[];
  enabled?: boolean | undefined;
};

export const SiteColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "tags",
    header: "Tags",
    size: 1,
    Cell: ({ row }) => {
      const showEstimateIndicator = row.original.estimateIndicator;
      const showSpendBasedIndicator = row.original.spendBasedIndicator;
      const showRecIndicator = row.original.recsIndicator;
      const {
        multipleRegion: regionmultiple,
        regionUpdated: regionupdated,
        region,
      } = row.original;
      const showMultipleRegionIndicator =
        regionmultiple === true && regionupdated === false;

      return (
        <div style={{ display: "flex", alignItems: 'center' }}>
          {(showMultipleRegionIndicator || region === null) && (
            <Tooltip
              title={
                region === null
                  ? regionmultiple
                    ? "Multiple Region Codes Found"
                    : "Missing region code"
                  : ""
              }
              placement="top"
            >
              <SitemultiRegion
                style={{
                  marginRight: "8px",
                  width: "20px",
                  height: "20px",
                  marginTop: "1px",
                }}
              />
            </Tooltip>
          )}

          {showEstimateIndicator && (
            <Tooltip
              placement="top"
              title={
                showEstimateIndicator
                  ? "There are Parameter Based Estimations at this site"
                  : ""
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  background:
                    showEstimateIndicator || showSpendBasedIndicator
                      ? COLORS.Romance
                      : "transparent",
                  color:
                    showEstimateIndicator || showSpendBasedIndicator
                      ? "black"
                      : "transparent",
                  fontWeight: "bold",
                  marginRight: "4px", // Add margin between "E" and "S" icons
                }}
              >
                {showEstimateIndicator ? <span style={{color: "white"}}>E</span> : null}
              </div>
            </Tooltip>
          )}
          {showSpendBasedIndicator && (
            <Tooltip
              title="There are Spend Based Estimations at this site"
              placement="top"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "4px", // Add margin between "E" and "S" icons
                  background:
                    showEstimateIndicator || showSpendBasedIndicator
                      ? COLORS.Romance
                      : "transparent",
                  color:
                    showEstimateIndicator || showSpendBasedIndicator
                      ? "black"
                      : "transparent",
                  fontWeight: "bold",
                }}
              >
                {showSpendBasedIndicator ? <span style={{color: "white"}}>S</span> : null}
              </div>
            </Tooltip>
          )}
          {showRecIndicator && (
            <Tooltip
              title="This site has a REC applied to its emissions"
              placement="top"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  background: showRecIndicator ? COLORS.Romance : "transparent",
                  color: showRecIndicator ? "black" : "transparent",
                  fontWeight: "bold",
                }}
              >
                {showRecIndicator ? <span style={{color: "white"}}>R</span> : null}
              </div>
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "customerSiteId",
    header: "Customer Site Id",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return (
        <div>{value as string}</div>
      )
    },
    size: 0.4,
  },
  {
    accessorKey: "name",
    header: "Site Name",
    size: 0.4,
  },
  {
    accessorKey: "type",
    header: "Type of site",
    size: 0.4,
  },
  {
    accessorKey: "businessUnit",
    header: "Business Unit",
    size: 40,
    Cell: ({ cell }: any) => {
      const value = cell.getValue() as string;
      if (!value) {
        return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
      }
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              maxWidth: '160px',
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
  },
  {
    accessorKey: "city",
    header: "City",
    size: 0.4,
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 0.4,
  },
  {
    accessorKey: "countryRegion",
    header: "Region",
    size: 0.4,
    Cell: ({ row }) => (
      <div style={{ display: "flex", padding: "none" }}>
        <p>{row.original.countryRegion}</p>
      </div>
    ),
  },
  {
    size: 0.4,
    accessorFn: (row) =>
      row.users && row.users.length > 0
        ? row.users
            .map((user: UserDTO) => user.firstName)
            .reduce((acc: any, firstName: any, index: any) => {
              if (index === 0) {
                return firstName;
              }
              if (index === row.users.length - 1) {
                return `${acc}, +${index}`;
              }
              return acc;
            }, "")
        : "",

    id: "firstName",
    header: "Data Manager",
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 0.4,
    Cell: ({ row }) => (
      <div style={{ display: "flex", padding: "none" }}>
        <p>{row.original.status}</p>
      </div>
    ),
  },
];
