import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { getParsedQueryParams } from "utils";

import { OptionalSingleselectProps } from "./types";

export const useSingeSelectWithFetch = <T>({
  getQueryParamsWithExternalChanges,
  updateExternalStates,
  withQueryParams = false,
  useGetData,
  getOptionsFromData,
  queryKey,
}: Omit<OptionalSingleselectProps, "withEmptyOption"> & {
  useGetData: () => UseQueryResult<T>;
  getOptionsFromData: (data?: T) => string[];
  queryKey: string;
}) => {
  const { data, isLoading } = useGetData();

  const [selectedOption, setSelectedOptionState] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const isInitialQueryReadRef = useRef<boolean>(true);

  const options = useMemo(
    () => getOptionsFromData(data),
    [data, getOptionsFromData],
  );

  useEffect(() => {
    if (data && isInitialQueryReadRef.current && withQueryParams) {
      const selectedOptionsFromSearchParams = searchParams.getAll(queryKey);

      if (selectedOptionsFromSearchParams.length) {
        const selectedValue = options.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
        // eslint-disable-next-line
        selectedValue && setSelectedOptionState(selectedValue);
      }

      isInitialQueryReadRef.current = false;
    }
  }, [data, searchParams, options, withQueryParams, queryKey]);

  const setSelectedOption = (newSelectedOption: string) => {
    if (withQueryParams) {
      const queryParams = getParsedQueryParams(searchParams);

      setSearchParams(
        createSearchParams({
          ...(getQueryParamsWithExternalChanges
            ? getQueryParamsWithExternalChanges(queryParams)
            : queryParams),
          [queryKey]: newSelectedOption,
        }),
      );
    }

    if (updateExternalStates) {
      updateExternalStates();
    }
    setSelectedOptionState(newSelectedOption);
  };

  return {
    isLoading,
    setSelectedOption,
    selectedOption,
    options,
    selectedOptionValues: selectedOption || "",
  };
};
