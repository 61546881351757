/* eslint-disable */
import { Box, Button, Checkbox } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import MaterialReactTable, {
  MRT_RowSelectionState,
} from "material-react-table";
import React, { useEffect } from "react";

import {
  PendingEmissionfactorPopupDTO,
  getScopePendingConponentpopupEmissionFactors,
  getScopePendingpopupEmissionFactors,
  recalculateSupplies,
} from "api";

import { Loading, MarginWrapper, SameUnitDialog } from "components";

import { useNotify } from "hooks";

import { useNewBreadcrumb } from "recoils";

import popUpColumn from "./popupColumns";

import { EMISSION_FACTORS_PAGE_SCOPE3 } from "../../consts";

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export const EmissionFactorsPopupTable: React.FunctionComponent<{
  id: string;
  rowData: any;
  closePendingModal: any;
  selectedData: any;
  searchManual: any;
  manualSearchData: any;
  closeDirectModal: any;
}> = ({
  id,
  rowData,
  closePendingModal,
  selectedData,
  searchManual,
  manualSearchData,
  closeDirectModal,
}) => {
  const [popupData, setPopupData] = React.useState<
    PendingEmissionfactorPopupDTO[]
  >([]);
  const [componentApiData, setComponentData] = React.useState<any[]>([]);
  const [pedingEmissionData, setPedingEmissionData] = React.useState<any[]>([]);
  const [arrProductName, setProductNameFilter] = React.useState<any[]>([]);
  const [arrSector, setSectorFilter] = React.useState<any[]>([]);
  const [arrGeography, setGeographyFilter] = React.useState<any[]>([]);
  const [arrUnit, setUnitFilter] = React.useState<any[]>([]);
  const [componentIsLoading, setComponentIsLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [isSessionLoading, setIsLoading] = React.useState(true);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const componentId = selectedData.id;

  const { EMISSION_FACTORS_POPUP_COLUMNS } = popUpColumn(
    arrProductName,
    arrGeography,
    arrUnit,
    arrSector,
  );

  const ComponentData = window.sessionStorage.getItem("ComponentData")
    ? JSON.parse(window.sessionStorage.getItem("ComponentData") || "")
    : [];
  const [pendingComponentData, setPendingComponentData] =
    React.useState<any>(ComponentData);

  useNewBreadcrumb(EMISSION_FACTORS_PAGE_SCOPE3.TITLE);

  const { data: recalculateData, mutateAsync: recalculate } =
    useMutation(recalculateSupplies);
  useEffect(() => {
    if (manualSearchData && manualSearchData.length === 0) {
      if (componentId) {
        setPopupData(componentApiData);
      } else {
        setPopupData(pedingEmissionData);
      }
    } else {
      setPopupData(manualSearchData);
    }
  }, [manualSearchData, pedingEmissionData, componentApiData, componentId]);

  useEffect(() => {
    const apiCalling = async () => {
      if (componentId) {
        const componentData =
          await getScopePendingConponentpopupEmissionFactors(id, componentId);
        setComponentData(componentData);
        setComponentIsLoading(false);
      } else {
        const data = await getScopePendingpopupEmissionFactors(id);
        setPedingEmissionData(data);
      }
    };

    apiCalling();
  }, [componentId, id]);

  const tableRows = popupData || [];

  const selectedRadioRows: any[] = [];
  const notify = useNotify();

  selectedRadioRows.push(...Object.keys(rowSelection));

  const searchManually = () => {
    searchManual(true);
    setPopupData([]);
  };

  const buttonStyle = {
    width: "19%",
    position: "relative",
    bottom: 0,
    mt: 2,
    m: 2,
  };

  useEffect(() => {
    window.sessionStorage.setItem(
      "ComponentData",
      JSON.stringify(pendingComponentData),
    );
  }, [pendingComponentData]);

  const apply = () => {
    const selectedRowData = tableRows.filter(
      (t) => t.id === selectedRadioRows[0],
    );
    const selectedRowUnit = selectedRowData[0].unit;
    if (rowData?.original?.conversion_unit === selectedRowUnit) {
      if (rowData.original.suppliesComponent.length !== 0) {
        const componentData = pendingComponentData.filter(
          (t: any) => t.component_id === selectedData?.id,
        );
        selectedRadioRows.map(
          (selectedId) => {
            if (componentData && componentData.length > 0) {
              return pendingComponentData.filter(
                (t: any) => t !== componentData,
              );
            }
            setPendingComponentData([
              ...pendingComponentData,
              {
                activity_uuid_product_uuid: tableRows.filter(
                  (t: any) => t.id === selectedId,
                )[0]?.id,
                // "gwp_100a": tableRows.filter((t: any) => t.id === selectedId)[0]?.gwp_100a,
                gwp_100a: 0,
                component_type: selectedData?.component_type,
                component_id: selectedData?.id,
                selected_row_id: rowData.original.id,
                component_composition_percent:
                  selectedData?.component_composition_percent,
              },
            ]);
            return "";
          },
          setTimeout(() => {
            closePendingModal(popupData);
          }, 100),
        );
      } else {
        Promise.allSettled(
          selectedRadioRows.map((selectedId) =>
            recalculate(
              {
                activity_uuid_product_uuid: selectedId,
                suppliedID: rowData.original.id,
                gwp_100a: tableRows.filter((t: any) => t.id === selectedId)[0]
                  ?.gwp_100a,
                learning: checked,
              },
              {
                onError: () =>
                  notify.error(
                    `An error has happened while calculating emissions`,
                  ),
              },
            ),
          ),
        ).finally(() => {
          closeDirectModal();
        });
      }
    } else {
      setDialogOpen(true);
    }
  };

  const handleTableCancel = () => {
    if (rowData.original.suppliesComponent.length !== 0) {
      closePendingModal();
    } else {
      closeDirectModal();
    }
  };

  useEffect(() => {
    if (recalculateData !== null && recalculateData !== undefined) {
      closePendingModal();
    }
  }, [recalculateData, closePendingModal]);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // DropDown Filter
  useEffect(() => {
    const productName: any[] = [];
    const sector: any[] = [];
    const geography: any[] = [];
    const unit: any[] = [];

    tableRows.map((i: any) => productName.push(i.productName));
    const productNameFilteredArray = productName.filter(
      (item: any, pos: any) => productName.indexOf(item) === pos,
    );
    setProductNameFilter(productNameFilteredArray);

    // Sector
    tableRows.map((i: any) => sector.push(i.sector));
    const sectorFilteredArray = sector.filter(
      (item: any, pos: any) => sector.indexOf(item) === pos,
    );
    setSectorFilter(sectorFilteredArray);

    // Geography
    tableRows.map((i: any) => geography.push(i.geography));
    const geographyFilteredArray = geography.filter(
      (item: any, pos: any) => geography.indexOf(item) === pos,
    );
    setGeographyFilter(geographyFilteredArray);

    // Unit
    tableRows.map((i: any) => unit.push(i.unit));
    const unitFilteredArray = unit.filter(
      (item: any, pos: any) => unit.indexOf(item) === pos,
    );
    setUnitFilter(unitFilteredArray);
    // eslint-disable-next-line
  }, [tableRows]);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <>
      {isSessionLoading ? (
        <Loading />
      ) : (
        <MarginWrapper>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {componentIsLoading ? (
              <Loading />
            ) : (
              <>
                {arrProductName && arrProductName.length > 0 && (
                  <MaterialReactTable
                    columns={EMISSION_FACTORS_POPUP_COLUMNS}
                    data={tableRows}
                    enableRowSelection
                    enablePagination={false}
                    getRowId={(row) => row.id}
                    enableColumnResizing
                    enableDensityToggle={false}
                    enableMultiRowSelection={false}
                    initialState={{ density: "compact" }}
                    onRowSelectionChange={setRowSelection}
                    state={{ rowSelection }}
                    enableRowVirtualization
                  />
                )}
              </>
            )}
            <Button
              variant="contained"
              onClick={apply}
              sx={buttonStyle}
              disabled={selectedRadioRows.length === 0 && true}
            >
              {rowData.original.suppliesComponent.length !== 0
                ? "Apply"
                : "Calculate Emission"}
            </Button>
            <Button
              variant="outlined"
              onClick={searchManually}
              sx={buttonStyle}
            >
              Search Manually
            </Button>
            <Button
              variant="outlined"
              onClick={handleTableCancel}
              sx={buttonStyle}
            >
              Cancel
            </Button>
            {rowData.original.suppliesComponent.length === 0 && (
              <Box>
                <Checkbox
                  color="primary"
                  defaultChecked
                  onChange={(e: any) => handleChecked(e)}
                />
                Apply matches to similar products.
              </Box>
            )}
          </Box>
          <SameUnitDialog
            isOpen={dialogOpen}
            onCancel={() => setDialogOpen(false)}
            title="Unit Not Matched"
          />
        </MarginWrapper>
      )}
    </>
  );
};

export const EmissionFactorsPopupDetails: React.FunctionComponent<{
  popupData?: any;
  componentData?: any;
}> = ({ popupData, componentData }) => (
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <Box sx={{ display: "flex" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <>
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Supplier</Box>
              <Box>{popupData?.supplier_name}</Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Item Number</Box>
              <Box>{popupData?.supplier_item_number}</Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                Description 1
              </Box>
              <Box>{popupData?.description_1}</Box>
            </Item>
          </Grid>
          {/*  */}
          {popupData.status !== "MATCHED" &&
            popupData.status !== "APPROVED" &&
            componentData.component_type === "type1" && (
              <Grid xs={2} sm={2} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Description 2
                  </Box>
                  <Box>
                    {componentData?.component_keyword}{" "}
                    {componentData?.component_composition_percent}%
                  </Box>
                </Item>
              </Grid>
            )}
          {/*  */}
          {popupData.status !== "MATCHED" &&
            popupData.status !== "APPROVED" &&
            componentData.component_type === "type2" && (
              <Grid xs={2} sm={2} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Description 3
                  </Box>
                  <Box>
                    {componentData?.component_keyword}{" "}
                    {componentData?.component_composition_percent}%
                  </Box>
                </Item>
              </Grid>
            )}

          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Unit</Box>
              <Box>{popupData?.units_of_measure}</Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Quantity</Box>
              <Box>{(popupData?.quantity as unknown as number).toFixed(4)}</Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                Converted units
              </Box>
              <Box>{popupData?.conversion_unit}</Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                Converted Quantity
              </Box>
              <Box>
                {(
                  popupData?.converted_quantity_purchased as unknown as number
                ).toFixed(4)}
              </Box>
            </Item>
          </Grid>
          {/*  */}
          <Grid xs={2} sm={2} md={2}>
            <Item>
              <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Country</Box>
              <Box>{popupData?.country}</Box>
            </Item>
          </Grid>
        </>
      </Grid>
    </Box>
  </Typography>
);
