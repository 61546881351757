/* eslint-disable */
import {
  DatePicker as DatePickerMui,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React from "react";

import { getDateFormat } from "utils";

import { TextField, TextFieldProps } from "./text-field";

export const DatePickerYearField = ({
  disabled,
  textFieldProps = {},
  format = getDateFormat(),
  ...datePickerProps
}: { disabled?: boolean; textFieldProps?: TextFieldProps } & Omit<
  DatePickerProps<Dayjs>,
  "renderInput" | "date" | "openPicker" | "rawValue"
>) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePickerMui
      disabled={disabled}
      format={format}
      {...datePickerProps}
      slots={{
        textField: (params: any) => (
          <TextField
            disabled={disabled}
            {...params}
            fullWidth
            {...textFieldProps}
          />
        ),
      }}
    />
  </LocalizationProvider>
);
