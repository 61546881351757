import React from "react";

import { FactorsIcon } from "assets";

export const EMISSION_FACTORS_PAGE_SCOPE3 = {
  ABSOLUTE_PATH: "/emission-factors-scope3",
  TITLE: "Emission factors",
  ICON: <FactorsIcon />,
  IS_TBD: false,
  renderMenu: false,
};
