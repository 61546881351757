import React from "react";

import { FileDraftIcon } from "assets";

import { TABLE_HEADERS } from "consts";

import { UTILITY_BILL_PAGE } from "../consts";

const BASE_WARNING_MESSAGE = "Failed to extract";

export const WARNING_MESSAGES = {
  endDate: `${BASE_WARNING_MESSAGE} end date`,
  startDate: `${BASE_WARNING_MESSAGE} start date`,
  provider: `${BASE_WARNING_MESSAGE} utility provider`,
  type: `${BASE_WARNING_MESSAGE} utility type`,
  measuredValue: `${BASE_WARNING_MESSAGE} usage`,
  measuredUnit: `${BASE_WARNING_MESSAGE} unit`,
  amount: `${BASE_WARNING_MESSAGE} cost`,
  currency: `${BASE_WARNING_MESSAGE} currency`,
  collector: `${BASE_WARNING_MESSAGE} utility account information`,
  subtype: `${BASE_WARNING_MESSAGE} subtype`,
  traveledMiles: `${BASE_WARNING_MESSAGE} traveled miles`,
} as const;

export const UTILITY_BILL_FORM_VIEW = {
  ICON: <FileDraftIcon />,
  ABSOLUTE_PATH: UTILITY_BILL_PAGE.ABSOLUTE_PATH,
  PATH: "",
  TITLE: "Invoice data",
};

export const COLLECTOR_LABEL = `Utility type / ${TABLE_HEADERS.accountNumber} / ${TABLE_HEADERS.meterNumber}`;
