export const MISSING_DOCUMENTS_PAGE = {
  ABSOLUTE_PATH: "/documents/missing",
  PATH: "missing",
  TITLE: "Missing documents",
};

export const MISSING_DOCUMENTS_PAGE_QUERY_PARAMS = {
  COLLECTOR_ID: "collectorId",
  MONTH: "month",
};
