/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";
import React from "react";

import { Label14SemiBold, MultiSelectControlledOption } from "components";

drilldow(Highcharts);
export const ScopeEmissionScope2PieChart = (sitesEmissionsData: any) => {
  const categories = ["CO2", "CH4", "N2O"];

  const data = [
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope2Co2OnlyEmission,
      color: "#4967B4",
      drilldown: {
        name: "Scope 1",
        categories: ["scope"],
        data: [
          sitesEmissionsData?.sitesEmissionsData?.totalScope2Co2OnlyEmission,
        ],
      },
    },
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope2Ch4OnlyEmission,
      color: "#307752",
      drilldown: {
        name: "Scope 2",
        categories: ["scope"],
        data: [
          sitesEmissionsData?.sitesEmissionsData?.totalScope2Ch4OnlyEmission,
        ],
      },
    },
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope2N2oOnlyEmission,
      color: "#837701",
      drilldown: {
        name: "Scope 2",
        categories: ["scope"],
        data: [
          sitesEmissionsData?.sitesEmissionsData?.totalScope2N2oOnlyEmission,
        ],
      },
    },
  ];

  const browserData = [];
  const versionsData = [];
  let i;
  let j;
  const dataLen = data.length;
  let drillDataLen;
  let brightness;

  // Build the data arrays
  for (i = 0; i < dataLen; i += 1) {
    // add browser data
    browserData.push({
      name: categories[i],
      y: data[i].y,
      color: data[i].color,
    });

    // add version data
    drillDataLen = data[i].drilldown.data.length;
    for (j = 0; j < drillDataLen; j += 1) {
      brightness = 0.2 - j / drillDataLen / 5;
      versionsData.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.data[j],
        color: Highcharts.color(data[i].color).brighten(brightness).get(),
      });
    }
  }

  // Create the chart
  const options = {
    chart: {
      align: "top",
      type: "pie",
      height: 180,
      width: 220,
      spacingTop: -30,
      backgroundColor: "#FAFAFA"
    },
    title: {
      align: "left",
      floating: true,
      text: `<span style="font-size:17px"><span style="font-weight: bold;"><span style="font-family: Manrope; color: #000302;">${sitesEmissionsData?.sitesEmissionsData?.totalScope2Emission} </span></span><span style="font-size: 12px ; font-family: Manrope;">tCO2eq</span></span>`,
      y: 51,
      x: 4,
    },
    subtitle: {
      text: `
      <div  style="display:flex , flex-direction:colmun , align:left" ,><br/>
      <div style="font-size: 10px; font-family: Manrope;"><span style="font-weight: bold; color: #4967B4;">CO2</span> ${
        sitesEmissionsData?.sitesEmissionsData?.totalScope2Co2OnlyEmission
      } tCO2eq</div></br>
      
      <div style="font-size: 10px; font-family: Manrope; "><span style="font-weight: bold; color: #307752;">CH4 Fossil</span> ${
        sitesEmissionsData?.sitesEmissionsData?.totalScope2Ch4FossilEmission
      } tCO2eq</div></br>
       <div style="font-size: 10px; font-family: Manrope; "><span style="font-weight: bold;  color: #307752; font-family: Manrope;">CH4 Non-Fossil</span> ${
         sitesEmissionsData?.totalScope2Ch4NonFossilEmission
           ?.totalScope1Ch4NonFossilEmission
           ? sitesEmissionsData?.totalScope2Ch4NonFossilEmission
               ?.totalScope1Ch4NonFossilEmission
           : 0
       } tCO2eq</div></br>
      
      <div style="font-size: 10px;font-family: Manrope; "><span style="font-weight: bold; color: #837701;">N2O</span> ${
        sitesEmissionsData?.sitesEmissionsData?.totalScope2N2oOnlyEmission
      } tCO2eq</div>
       </div>
      `,
      verticalAlign: "bottom",
      floating: true,
      align: "left",
      x: 4,
      y: 15,
      width: 280,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "middle",
      x: -60,
      itemStyle: { fontSize: "10px" },
    },
    series: [
      {
        name: "scope",
        data: browserData,
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          color: "#ffffff",
          distance: 0,
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 100,
          },
          chartOptions: {
            series: [
              {},
              {
                id: "versions",
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <>
      <div style={{ backgroundColor: "#FAFAFA", width: "172px" }}>
        <Label14SemiBold sx={{ pt: 2, pl: 1.8 }}>Scope 2</Label14SemiBold>
        <PieChart highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};
