import { useLayoutEffect, useRef } from "react";
import { atom, useSetRecoilState } from "recoil";

export interface Breadcrumb {
  title: string;
  absolutePath?: string;
}

const DEFAULT_BREADCRUMBS_STATE: Breadcrumb[] = [];

export const breadcrumbsState = atom<Breadcrumb[]>({
  key: "breadcrumbs",
  default: DEFAULT_BREADCRUMBS_STATE,
});

export const useNewBreadcrumb = (title: string, absolutePath?: string) => {
  const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
  const breadcrumbIndexRef = useRef(0);

  useLayoutEffect(() => {
    setBreadcrumbs((currentBreadcrumbs) => {
      breadcrumbIndexRef.current = currentBreadcrumbs.length;
      return [...currentBreadcrumbs, { title, absolutePath }];
    });

    return () => {
      setBreadcrumbs((currentBreadcrumbs) => currentBreadcrumbs.slice(0, -1));
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    setBreadcrumbs((currentBreadcrumbs) => [
      ...currentBreadcrumbs.slice(0, breadcrumbIndexRef.current),
      { title, absolutePath },
      ...currentBreadcrumbs.slice(breadcrumbIndexRef.current + 1),
    ]);
  }, [title, absolutePath, setBreadcrumbs]);
};
