import { Box } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

type MarginWrapperProps = {
  children: ReactNode;
  backgroundColor?: string;
};

export const MarginWrapper: FunctionComponent<MarginWrapperProps> = ({
  children,
  backgroundColor = "#FFFFFF",
}) => (
  <Box mx={3} my={3} sx={{ backgroundColor }}>
    {children}
  </Box>
);
