import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Box, IconButton, Tooltip } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";

import { deleteCompanyLevel } from "api";

import { EsgDeleteIcon, EsgEditIcon } from "assets";

import {
  Body1SemiBold,
  DeleteDialogIntensity,
  EMPTY_CELL_VALUE,
  useDialogControls,
} from "components";

import { useNotify } from "hooks";

import { AddIntensityModal } from "pages/authenticated/sitesv2/components/site-editor-form/components/AddIntensityModal";

import { getUTCDateFormat, isNullOrUndefined } from "utils";

interface IIntensityData {
  companyName: string;
  startDate: string;
  endDate: string;
  intensityCategory: string;
  value: number;
  unit: string;
  uploadDate: string;
  enabled: boolean;
  id: number;
}

const Accordion = styled(MuiAccordion)({
  border: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "white",
  "&:before": {
    display: "none",
  },
});

const CustomAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  "& .MuiIconButton-root": {
    transition: "transform 0.3s",
    transform: "rotate(0deg)",
  },
  "& .Mui-expanded .MuiIconButton-root": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const CompanyLevel = ({
  intensitydata,
  isDeactivate,
}: {
  intensitydata: any;
  isDeactivate: any;
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [EditCompanyOpen, setEditCompanyOpen] = React.useState(false);
  const [EditCompany, setEditCompany] = React.useState<any | undefined>();
  const [addCompanyModalOpen, setAddCompanyModalOpen] = React.useState(false);
  const [toDeleteCompany, setDeleteCompany] = React.useState<any | undefined>();
  const deleteDialogControls = useDialogControls();
  const { mutateAsync: deleteCompanyRequest } = useMutation(deleteCompanyLevel);

  const notify = useNotify();

  const monthlydata: IIntensityData[] = intensitydata?.monthly;
  const yearlydata: IIntensityData[] = intensitydata?.yearly;

  const getFormattedData = (dataList: IIntensityData[]) =>
    dataList?.map((i) => ({
      ...i,
      companyName: "Enersys",
    }));
  const monthlyIntensityData = getFormattedData(monthlydata);
  const yearlyIntensityData = getFormattedData(yearlydata);

  const compnaymonthlydata = useMemo<MRT_ColumnDef<IIntensityData>[]>(
    () => [
      {
        accessorKey: "startDate",
        header: "Start Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },
      {
        accessorKey: "endDate",
        header: "End Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },

      {
        accessorKey: "intensityCategory",
        header: "Intensity Category",
        size: 140,
      },
      {
        accessorKey: "value",
        header: "Value",
        size: 140,
      },
      {
        accessorKey: "unit",
        header: "Unit",
        size: 140,
      },
      {
        accessorKey: "uploadDate",
        header: "Update Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },
    ],
    [],
  );
  const compnayyearlydata = useMemo<MRT_ColumnDef<IIntensityData>[]>(
    () => [
      {
        accessorKey: "startDate",
        header: "Start Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },
      {
        accessorKey: "endDate",
        header: "End Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },
      {
        accessorKey: "intensityCategory",
        header: "Intensity Category",
        size: 140,
      },
      {
        accessorKey: "value",
        header: "Value",
        size: 140,
      },
      {
        accessorKey: "unit",
        header: "Unit",
        size: 140,
      },
      {
        accessorKey: "uploadDate",
        header: "Update Date",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }
          return dayjs(value as string)
            .utc()
            .format(getUTCDateFormat());
        },
      },
    ],
    [],
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleEditCompanyLevel = (rowData: any) => {
    setEditCompanyOpen(true);
    setEditCompany(rowData);
  };
  const handleCloseCompanyModal = () => {
    if (addCompanyModalOpen) {
      setAddCompanyModalOpen(false);
    }
    if (EditCompanyOpen) {
      setEditCompanyOpen(false);
    }
    setEditCompany(undefined);
    isDeactivate(true);
    setTimeout(() => {
      isDeactivate(false);
    }, 2000);
  };

  const handleCancelCompanyModal = () => {
    setAddCompanyModalOpen(false);
    setEditCompanyOpen(false);
  };

  const handleDeleteCompany = (companyId: number) => {
    if (companyId) {
      deleteCompanyRequest(companyId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while deleting the  Data!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Data successfully deleted!`);
          isDeactivate(true);
          setTimeout(() => {
            isDeactivate(false);
          }, 2000);
        },
      });
    }
  };

  return (
    <>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ my: 2, background: "white" }}
        >
          <CustomAccordionSummary
            //  onClick={toggleDetails}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <ArrowForwardIosSharpIcon
              style={{
                height: "14px",
                width: "14px",
                marginRight: "14px",
                transform:
                  expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
            <Body1SemiBold>Fiscal Year</Body1SemiBold>
          </CustomAccordionSummary>
          <AccordionDetails>
            <MaterialReactTable
              muiTableContainerProps={{
                sx: {
                  "& thead tr:first-child th:first-child > div": {
                    justifyContent: "center",
                  },
                  "& tbody tr td:first-child > div": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    justifyContent: "center",
                  },
                  border: "1px solid #D9D9D9",
                  borderRadius: "10px",
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  "@media (min-width: 600px)": {
                    width: "100%",
                  },
                  "@media (min-width: 960px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1280px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1440px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1680px)": {
                    width: "1300px",
                  },
                  "@media (min-width: 2400px)": {
                    width: "100%",
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "10px",
                  boxShadow: "none",
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  lineHeight: "30px",
                  "& .MuiBadge-root": {
                    display: "none",
                  },
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  height: "45px",
                  backgroundColor: "#F8FAFC",
                },
              }}
              muiTableBodyRowProps={{
                sx: {
                  height: "45px",
                  backgroundColor: "white",
                },
              }}
              columns={compnayyearlydata}
              data={yearlyIntensityData?.length > 0 ? yearlyIntensityData : []}
              enableRowActions
              muiTableBodyCellProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
              renderRowActions={(row) => (
                <>
                  {row?.row?.original.enabled ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "0.5",
                        marginLeft: "-5px",
                      }}
                    >
                      <Tooltip arrow placement="right" title="Edit">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditCompanyLevel(row?.row);
                          }}
                        >
                          <EsgEditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow placement="right" title="De-activated">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            deleteDialogControls.open();
                            setDeleteCompany(row?.row?.original?.id);
                          }}
                        >
                          <EsgDeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <span style={{ color: "red" }}>De-activated</span>
                  )}
                </>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ my: 2 }}
        >
          <CustomAccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <ArrowForwardIosSharpIcon
              style={{
                height: "14px",
                width: "14px",
                marginRight: "14px",
                transform:
                  expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
            <Body1SemiBold>Monthly</Body1SemiBold>
          </CustomAccordionSummary>
          <AccordionDetails>
            <MaterialReactTable
              columns={compnaymonthlydata}
              data={
                monthlyIntensityData?.length > 0 ? monthlyIntensityData : []
              }
              // enablePagination={false}
              enableRowActions
              muiTableContainerProps={{
                sx: {
                  "& thead tr:first-child th:first-child > div": {
                    justifyContent: "center",
                  },
                  "& tbody tr td:first-child > div": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    justifyContent: "center",
                  },
                  border: "1px solid #D9D9D9",
                  borderRadius: "10px",
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  "@media (min-width: 600px)": {
                    width: "100%",
                  },
                  "@media (min-width: 960px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1280px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1440px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1680px)": {
                    width: "1300px",
                  },
                  "@media (min-width: 2400px)": {
                    width: "100%",
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "10px",
                  boxShadow: "none",
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  lineHeight: "30px",
                  "& .MuiBadge-root": {
                    display: "none",
                  },
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  height: "45px",
                  backgroundColor: "#F8FAFC",
                },
              }}
              muiTableBodyRowProps={{
                sx: {
                  height: "45px",
                  backgroundColor: "white",
                },
              }}
              renderRowActions={(row) => (
                <>
                  {row?.row?.original.enabled ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "0.5",
                        marginLeft: "-5px",
                      }}
                    >
                      <Tooltip arrow placement="right" title="Edit">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditCompanyLevel(row?.row);
                          }}
                        >
                          <EsgEditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow placement="right" title="De-activated">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            deleteDialogControls.open();
                            setDeleteCompany(row?.row?.original?.id);
                          }}
                        >
                          <EsgDeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <span style={{ color: "red" }}>De-activated</span>
                  )}
                </>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <AddIntensityModal
        isOpen={addCompanyModalOpen || EditCompanyOpen}
        onClose={handleCloseCompanyModal}
        onCancel={handleCancelCompanyModal}
        editSite={EditCompany}
        Intensity="Company Level"
      />
      <DeleteDialogIntensity
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => handleDeleteCompany(toDeleteCompany)}
        title="De-activating an Intensity Metric?"
      />
    </>
  );
};
