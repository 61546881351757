import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";

import {
  CommentDTO,
  SiteByIdDTO,
  UnitDescription,
  createEstimates,
  getCommentsByEstimateId,
  getSiteById,
  updateEstimate,
} from "api";

import {
  COLORS,
  InputFieldWrapper,
  Label14SemiBold,
  TYPOGRAPHY_COMMON_STYLES,
} from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { useAuthenticatedUserState } from "recoils";

import { getDateFormatset } from "utils";

import { SiteCommentTextField } from "pages/authenticated/documents/document-summary-v2/utility-bill/utility-bill-comments-view/site-comment-text-field";

import { SiteComment } from "pages/authenticated/documents/document-summary-v2/utility-bill/utility-bill-comments-view/site-comment";

import { Controller, useForm } from "react-hook-form";
import { ESTIMATES_COMMENTS_VIEW } from "./const";

interface EstimatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  columns?: any;
  site?: SiteByIdDTO;
  editEstimates?: any;
  EstimateData?: any;
  isEditNotes?: boolean;
  onEstimatesDataUpdate?: (siteData: SiteByIdDTO) => void;
}

export const EstimatesModal: FC<EstimatesModalProps> = ({
  isOpen,
  onClose,
  columns,
  allowedValues,
  site,
  editEstimates,
  EstimateData,
  isEditNotes,
  onEstimatesDataUpdate,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [currentTab, setCurrentTab] = useState("form");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const initialFormValues =
    columns &&
    columns.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any);

  const [values, setValues] = useState<any>(initialFormValues);
  const [notesError, setNotesError] = useState(false);
  const [notes, setNotes] = useState<any>(EstimateData?.notes);
  const [commentSent, setCommentSent] = useState<any>(false);
  const [siteCommentEvent, setSiteCommentEvent] = useState<any>(false);

  const [availableUnitDescriptions, setAvailableUnitDescriptions] = useState<
    UnitDescription[]
  >([]);

  const scope = [
    {
      id: "1",
      name: "Scope 1",
    },
    {
      id: "2",
      name: "Scope 2",
    },
    {
      id: "3",
      name: "NO_SCOPE",
    },
  ];

  const handleClose = () => {
    setValues(initialFormValues);
    setCurrentTab("form");
    onClose();
  };

  useEffect(() => {
    setNotes(EstimateData?.notes);
  }, [EstimateData]);

  const { mutateAsync: createEstimateRequest } = useMutation(createEstimates);

  const { mutateAsync: updateEstimateRequest } = useMutation(updateEstimate);

  const { data, isError, mutateAsync: useCommentsByEstimateId } = useMutation(getCommentsByEstimateId);

  useEffect(() => {
    useCommentsByEstimateId(editEstimates?.id ?? 0) // eslint-disable-line
    setCommentSent(false)
  }, [editEstimates?.id, commentSent, useCommentsByEstimateId])

  useEffect(() => {
    if (siteCommentEvent) {
      useCommentsByEstimateId(editEstimates?.id ?? 0) // eslint-disable-line
    }
    setSiteCommentEvent(false)
  }, [siteCommentEvent, editEstimates?.id, useCommentsByEstimateId])

  const user = useAuthenticatedUserState();

  const comments = data || [];

  const { utilityTypes = [] } = allowedValues || {};

  const estimateTypes = ["HEAD_COUNT", "SQUARE_FOOTAGE", "SQUARE_METERS"];

  const { control } = useForm<any>({
    mode: "onChange",
    defaultValues: initialFormValues,
  });


  const [estimateHeadCountError, setEstimateHeadCountError] = useState(false);


  const sortedUtilityTypes = utilityTypes.sort((a: any, b: any) =>
    a.name.localeCompare(b.name),
  );

  useEffect(() => {
    if (editEstimates) {
      const updatedValues = columns.reduce((acc: any, column: any) => {
        acc[column.accessorKey ?? ""] = editEstimates[column.accessorKey] || "";
        return acc;
      }, {} as any);
      setValues(updatedValues);
    } else {
      setValues(initialFormValues);
    }
    // eslint-disable-next-line
  }, [columns, editEstimates]);

  useEffect(() => {
    if (values.utilityName) {
      const selectedUtilityTypeInfo = utilityTypes.find(
        (ut: any) => ut.name.toLowerCase() === values.utilityName.toLowerCase(),
      );

      if (selectedUtilityTypeInfo) {
        setAvailableUnitDescriptions(selectedUtilityTypeInfo.unitDescription);
      }
    }
  }, [values.utilityName, utilityTypes]);

  const handleEditNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 200) {
      if (!isEditNotes) {
        setValues({ ...values, notes: value });
      } else {
        setValues({ ...values, notes: value });
        setNotes(value);
      }
      setNotesError(false);
    } else {
      setNotesError(true);
    }
  };

  const formatDateToISO = (dateObject: any): string => {
    const inputDate = new Date(dateObject);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00Z`;
  };

  const handleAddEstimates = async () => {
    const createEstimatesRequestData: any = {
      estimateName: values.estimateName || null,
      estimateType: values.estimateType || null,
      days: 21 || null,
      estimateUsage: Number(values.estimateUsage) || null,
      estimateHeadCount: Number(values.estimateHeadCount) || null,
      estimateUsageUnit: values.estimateUsageUnit || null,
      frequency: "MONTHLY" || null,
      estimateStartDate: formatDateToISO(values.estimateStartDate) || null,
      estimateEndDate: formatDateToISO(values.estimateEndDate) || null,
      scope: values.scope || null,
      siteId: site?.id || 0,
      utilityTypeId:
        utilityTypes.find(
          (ut: any) =>
            ut.name.toLowerCase() === values.utilityName.toLowerCase(),
        )?.id || 0,
      notes: values.notes || null,
    };
    await createEstimateRequest(createEstimatesRequestData, {
      onError: () => {
        notify.error(`Some error has happened while adding Estimates!`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        notify.success(
          `Estimates successfully created. The estimates will be generated on the 5th of every month`,
        );
        onClose();
        if (site && onEstimatesDataUpdate) {
          getSiteById(site?.id)
            .then((siteData) => {
              onEstimatesDataUpdate(siteData);
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error("Error fetching site data:", error);
            });
        }
        setValues(initialFormValues);
      },
    });
  };

  const handleEditEstimates = async () => {
    if (!isEditNotes) {
      const editEstimatesRequestData: any = {
        estimateName: values.estimateName || null,
        estimateType: values.estimateType || null,
        days: 21,
        estimateUsage: Number(values.estimateUsage) || null,
        estimateHeadCount: Number(values.estimateHeadCount) || null,
        estimateUsageUnit: values.estimateUsageUnit || null,
        estimateStartDate: values.estimateStartDate || null,
        estimateEndDate: values.estimateEndDate || null,
        scope: values.scope || null,
        frequency: "MONTHLY" || null,
        siteId: site?.id || 0,
        utilityTypeId:
          utilityTypes.find(
            (ut: any) =>
              ut.name.toLowerCase() === values.utilityName.toLowerCase(),
          )?.id || 0,
        notes: values.notes || null,
        id: editEstimates?.id || null,
      };
      await updateEstimateRequest(editEstimatesRequestData, {
        onError: () => {
          notify.error(`Some error has happened while editing Estimates!`);
        },
        onSuccess: () => {
          notify.success(`Estimates successfully edited.`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          onClose();
          if (site && onEstimatesDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onEstimatesDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    } else {
      const notesData = {
        estimateName: EstimateData.estimateName,
        estimateType: EstimateData.estimateType,
        days: 21,
        estimateUsage: Number(EstimateData.estimateUsage),
        estimateHeadCount: Number(EstimateData.estimateHeadCount),
        estimateUsageUnit: EstimateData.estimateUsageUnit,
        estimateStartDate: EstimateData.estimateStartDate,
        estimateEndDate: EstimateData.estimateEndDate,
        scope: EstimateData.scope,
        frequency: EstimateData.frequency,
        siteId: site?.id || 0,
        utilityTypeId: EstimateData.utilityTypeId,
        notes,
        id: EstimateData?.id,
      };
      await updateEstimateRequest(notesData, {
        onError: () => {
          notify.error(`Some error has happened while updating notes!`);
        },
        onSuccess: () => {
          notify.success(`Notes successfully Updated!`);
          queryClient.invalidateQueries([
            QUERY_KEYS.ESTIMATES,
            QUERY_KEYS.SITES,
            site?.id,
          ]);
          onClose();
          if (site && onEstimatesDataUpdate) {
            getSiteById(site?.id)
              .then((siteData) => {
                onEstimatesDataUpdate(siteData);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        width: "100%",
      }}
    >
      {!editEstimates && (
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: isEditNotes ? "4px" : "10px",
          }}
        >
          {isEditNotes
            ? "Edit Notes"
            : editEstimates
              ? " Parameter Based Estimates"
              : " New Parameter Based Estimates"}
        </DialogTitle>
      )}

      {editEstimates && (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="Estimates Tabs"
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Tab
            label="Parameter Based Estimates"
            value="form"
            sx={{ minHeight: "48px", marginLeft: "18px" }}
          />
          <Tab
            icon={ESTIMATES_COMMENTS_VIEW.ICON}
            label={ESTIMATES_COMMENTS_VIEW.TITLE(comments.length)}
            value="comments"
            sx={{
              marginLeft: "160px",
              marginRight: "20px",
              minHeight: "48px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
            }}
          />
        </Tabs>
      )}
      {currentTab === "form" && (
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={3}>
              {columns &&
                (isEditNotes
                  ? columns.map((column: any) => {
                    if (column.accessorKey === "notes") {
                      return (
                        <Grid item key={column.accessorKey}>
                          <TextField
                            sx={{ minWidth: "500px" }}
                            id={column.accessorKey}
                            name={column.accessorKey}
                            onChange={handleEditNotes}
                            value={notes || ""}
                            error={notesError}
                            helperText={
                              notesError
                                ? "Notes length should not exceed 200 characters"
                                : ""
                            }
                            multiline
                            rows={3}
                          />

                        </Grid>
                      );
                    }
                    return null;
                  })
                  : columns
                    .filter((column: any) => column.accessorKey !== "notes")
                    .map((column: any) => (
                      <Grid item xs={8} sm={6} key={column.accessorKey}>
                        {column.accessorKey === "utilityName" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="type"
                              control={control}
                              render={() => (
                                <Select
                                  labelId="type-simple-select-label"
                                  id="type-simple-select"
                                  name={column.accessorKey}
                                  autoWidth
                                  fullWidth
                                  sx={{ height: 40 }}
                                  value={values[column.accessorKey] || ""}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        minWidth: 260,
                                        maxWidth: 'auto',
                                        whiteSpace: 'normal',
                                        
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(sortedUtilityTypes).map(
                                    (type: any) => (
                                      <MenuItem
                                        sx={{
                                          fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                          fontSize: 14,
                                          lineHeight: "18px",
                                          color: '#90959E',
                                          '&:hover': {
                                            backgroundColor: '#F6F8FA',
                                            borderRadius: 5,
                                            color: COLORS.Romance,
                                          },
                                          '&.Mui-selected': {
                                            backgroundColor: '#F6F8FA',
                                            borderRadius: 5,
                                            color: COLORS.Romance,
                                          },
                                        }}
                                        key={type.id} value={type.name}>
                                        {type.name}
                                      </MenuItem>
                                    ),
                                  )}
                                </Select>
                              )
                              }
                            />
                          </InputFieldWrapper>
                        )}

                        {column.accessorKey === "estimateName" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="name"
                              control={control}
                              render={({
                                fieldState: { error },
                              }) => (
                                <TextField
                                  id={column.accessorKey}
                                  helperText={error ? error.message : null}
                                  size="small"
                                  error={!!error}
                                  name={column.accessorKey}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  value={values[column.accessorKey] || ""}
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                          </InputFieldWrapper>
                        )}
                        <div>
                          {(column.accessorKey === "estimateStartDate" ||
                            column.accessorKey === "estimateEndDate") && (
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                              >
                                <InputFieldWrapper label={column.header}>
                                  <DatePicker
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        sx: {
                                          width: "100%",
                                        }
                                      },
                                    }}
                                    value={
                                      values[column.accessorKey]
                                        ? dayjs(
                                          values[column.accessorKey],
                                        ).utc()
                                        : null
                                    }
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        [column.accessorKey]: date,
                                      })
                                    }
                                    format={getDateFormatset()}
                                  />
                                </InputFieldWrapper>
                              </LocalizationProvider>

                            )}
                        </div>

                        {column.accessorKey === "estimateType" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="type"
                              control={control}
                              render={() => (
                                <Select
                                  labelId="type-simple-select-label"
                                  id="type-simple-select"
                                  name={column.accessorKey}
                                  autoWidth
                                  fullWidth
                                  sx={{ height: 40 }}
                                  value={values[column.accessorKey] || ""}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        minWidth: 260,
                                        maxWidth: 'auto',
                                        whiteSpace: 'normal',
                                        
                                      },
                                    },
                                  }}
                                >
                                  {estimateTypes.map((t) => (
                                    <MenuItem
                                      sx={{
                                        fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                        fontSize: 14,
                                        lineHeight: "18px",
                                        color: '#90959E',
                                        '&:hover': {
                                          backgroundColor: '#F6F8FA',
                                          borderRadius: 5,
                                          color: COLORS.Romance,
                                        },
                                        '&.Mui-selected': {
                                          backgroundColor: '#F6F8FA',
                                          borderRadius: 5,
                                          color: COLORS.Romance,
                                        },
                                      }} key={t} value={t}>
                                      {t}</MenuItem>
                                  ))}
                                </Select>
                              )
                              }
                            />
                          </InputFieldWrapper>
                        )}

                        {column.accessorKey === "estimateHeadCount" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="name"
                              control={control}
                              render={({
                                fieldState: { error },
                              }) => (
                                <Tooltip
                                  title="E.g. Headcount of 15"
                                  placement="top"
                                  arrow
                                >
                                  <TextField
                                    id={column.accessorKey}
                                    size="small"
                                    error={!!error}
                                    name={column.accessorKey}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        column.accessorKey === "estimateHeadCount"
                                      ) {
                                        if (
                                          Number.isNaN(Number(inputValue)) ||
                                          inputValue.trim() === ""
                                        ) {
                                          setEstimateHeadCountError(true);
                                        } else {
                                          setEstimateHeadCountError(false);
                                        }
                                      }
                                      setValues({
                                        ...values,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                    value={values[column.accessorKey] || ""}
                                    helperText={
                                      estimateHeadCountError
                                        ? "Please enter a numeric value"
                                        : ""
                                    }
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Tooltip>
                              )}
                            />
                          </InputFieldWrapper>
                        )}
                        {column.accessorKey === "estimateUsageUnit" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="type"
                              control={control}
                              render={() => (
                                <Select
                                  labelId="type-simple-select-label"
                                  id="type-simple-select"
                                  name={column.accessorKey}
                                  autoWidth
                                  fullWidth
                                  sx={{ height: 40 }}
                                  value={values[column.accessorKey] || ""}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        minWidth: 260,
                                        maxWidth: 'auto',
                                        whiteSpace: 'normal',
                                        
                                      },
                                    },
                                  }}
                                >
                                  {availableUnitDescriptions.map(
                                    (option: any) => (
                                      <MenuItem
                                        sx={{
                                          fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                          fontSize: 14,
                                          lineHeight: "18px",
                                          color: '#90959E',
                                          '&:hover': {
                                            backgroundColor: '#F6F8FA',
                                            borderRadius: 5,
                                            color: COLORS.Romance,
                                          },
                                          '&.Mui-selected': {
                                            backgroundColor: '#F6F8FA',
                                            borderRadius: 5,
                                            color: COLORS.Romance,
                                          },
                                        }}
                                        key={option} value={option.unit}>
                                        {`${option.unit} (${option.description})`}
                                      </MenuItem>
                                    ),
                                  )}
                                </Select>
                              )
                              }
                            />
                          </InputFieldWrapper>
                        )}
                        {column.accessorKey === "estimateUsage" && (
                          <InputFieldWrapper label={column.header}>
                            <Controller
                              name="name"
                              control={control}
                              render={({
                                fieldState: { error },
                              }) => (
                                <Tooltip
                                  title="E.g. Electricity usage of 12.5 kWh per headcount per day"
                                  placement="top"
                                  arrow
                                >
                                  <TextField
                                    id={column.accessorKey}
                                    size="small"
                                    error={!!error}
                                    name={column.accessorKey}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    value={values[column.accessorKey] || ""}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Tooltip>
                              )}
                            />
                          </InputFieldWrapper>
                        )}
                        {column.accessorKey === "scope" && (
                          <InputFieldWrapper label={column.accessorKey}>
                            <Controller
                              name="type"
                              control={control}
                              render={() => (<Select
                                labelId="type-simple-select-label"
                                id="type-simple-select"
                                name={column.accessorKey}
                                autoWidth
                                fullWidth
                                sx={{ height: 40 }}
                                value={values[column.accessorKey] || ""}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      minWidth: 260,
                                      maxWidth: 'auto',
                                      whiteSpace: 'normal',
                                      
                                    },
                                  },
                                }}
                              >
                                {scope.map((type: any) => (
                                  <MenuItem
                                    sx={{
                                      fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                      fontSize: 14,
                                      lineHeight: "18px",
                                      color: '#90959E',
                                      '&:hover': {
                                        backgroundColor: '#F6F8FA',
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                      '&.Mui-selected': {
                                        backgroundColor: '#F6F8FA',
                                        borderRadius: 5,
                                        color: COLORS.Romance,
                                      },
                                    }}
                                    key={type.id} value={type.name}>
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </Select>)
                              }
                            />
                          </InputFieldWrapper>
                        )}
                      </Grid>
                    )))}
            </Grid>
            {!isEditNotes && (
              <Box sx={{ mt: 4 }}>
                <InputFieldWrapper label="Notes">
                  <FormControl sx={{ minWidth: 500, marginTop: "1px" }}>
                    <TextField
                      id="notes"
                      name="notes"
                      onChange={handleEditNotes}
                      value={values.notes || ""}
                      error={notesError}
                      helperText={
                        notesError
                          ? "Notes length should not exceed 200 characters"
                          : ""
                      }
                      multiline
                      rows={3}
                    />
                  </FormControl>
                </InputFieldWrapper>
              </Box>
            )}
            {values.estimateUsage &&
              values.estimateUsageUnit &&
              values.utilityName &&
              values.estimateType && (
                <Box
                  mt={2}
                  ml={1}
                  sx={{
                    backgroundColor: "#F2F2F2",
                    display: "flex",
                    maxWidth: "600px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "20px",
                      flex: "1",
                      color: "black",
                    }}
                  >
                    <b>
                      You are estimating {values.estimateUsage}{" "}
                      {values.estimateUsageUnit} of {values.utilityName} per day
                      per {values.estimateType}.
                      <br />
                      {values.estimateType} of {values.estimateHeadCount} is
                      assumed.
                    </b>
                  </span>
                </Box>
              )}
            {!isEditNotes && (
              <Box mt={3} ml={2} sx={{ maxWidth: "400px" }}>
                <span style={{ fontSize: "14px" }}>
                  Note: 21 days per month is assumed.
                  <br />
                  New estimates will be generated on the 5th of each month
                </span>
              </Box>
            )}
          </form>
        </DialogContent>
      )}
      {currentTab === "comments" && (
        <DialogContent sx={{ minHeight: "594px" }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "background.default",
                display: "flex",
                height: "100%",
                overflow: "hidden",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: 2,
                  overflow: "auto",
                  flexGrow: 1,
                  gap: 2,
                }}
              >
                {isError && (
                  <Label14SemiBold sx={{ m: 2 }}>
                    Failed to fetch comments
                  </Label14SemiBold>
                )}
                {!comments.length && (
                  <Label14SemiBold sx={{ m: 2 }}>No comments</Label14SemiBold>
                )}
                {!isError &&
                  comments.map((comment: CommentDTO) => (
                    <Box
                      key={comment.id}
                      id={`${comment.id}`}
                      sx={{
                        width: "86%",
                        alignSelf:
                          comment.authorEmail === user.email
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <SiteComment
                        siteId={site?.id}
                        estimateId={editEstimates?.id}
                        comment={comment}
                        currentUserId={user.id}
                        onSuccessfulSiteSubmit={() => {
                          setSiteCommentEvent(true);
                        }} />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          {/* Comment Text Field */}
          <Box sx={{ mt: 1 }}>
            <SiteCommentTextField
              estimateId={editEstimates?.id}
              parentId={undefined}
              siteId={site?.id}
              onSuccessfulSubmit={() => {
                setCommentSent(true);
              }}
            />
          </Box>

        </DialogContent>
      )}
      {/* <SiteComment/> */}
      <DialogActions sx={{ marginBottom: "15px" }}>
        <Button onClick={handleClose} variant="outlined">
          {currentTab === "comments" ? "Close" : "Cancel"}
        </Button>
        {currentTab === "form" && (
          <Button
            onClick={
              editEstimates || isEditNotes
                ? handleEditEstimates
                : handleAddEstimates
            }
            variant="contained"
            color="primary"
          >
            {editEstimates || isEditNotes ? "Save" : "Add"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
