/* eslint-disable */
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { getAuthenticatedUser } from "api";

import {
  isUserAuthenticatedState,
  useSetDefaultUser,
  useSetUser,
} from "recoils";

import { setHttpClientAuthToken } from "utils";
import { time } from "console";

export const useAuth0Authentication = () => {
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const [isHookLoading, setIsHookLoading] = useState(true);
  const setUser = useSetUser();
  const setDefaultUser = useSetDefaultUser();
  const isUserAuthenticated = useRecoilValue(isUserAuthenticatedState);

  useEffect(() => {
    if (!isAuthenticated) {
      setDefaultUser();
    }
  }, [isAuthenticated, setDefaultUser]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((accessToken) => {
        // eslint-disable-next-line no-underscore-dangle
        

        if (!accessToken) {
          throw Error("Auth0: No auth token provided!");
        }

        setHttpClientAuthToken(accessToken);

        getAuthenticatedUser()
          .then((user) => {
            window.sessionStorage.setItem("columnFilters", JSON.stringify([]));
            window.sessionStorage.setItem(
              "columnFiltersDocumentUpload",
              JSON.stringify([]),
            );
            window.sessionStorage.setItem("user", JSON.stringify(user));
            window.sessionStorage.setItem(
              "date_format",
              JSON.stringify(user?.dateFormat),
            );
            if (user.enabled) {
              setUser(user);
            } else {
              setDefaultUser();
              // eslint-disable-next-line
              window.alert(
                "This user login does not exist. Please contact your system administrator.",
              );
              logout({
                returnTo: window.location.origin,
              });
            }
          })
          .catch(() => {
            setDefaultUser();
            setHttpClientAuthToken();
            window.localStorage.clear();
            window.sessionStorage.clear();
            logout({
              returnTo: window.location.origin,
            });
          });
      });
    }
  }, [isAuthenticated, getAccessTokenSilently, logout, setUser, setDefaultUser]);

  useEffect(() => {
    if (isAuthenticated && isUserAuthenticated && !isLoading) {
      setIsHookLoading(false);
    } else if (!isAuthenticated && !isAuthenticated && !isLoading) {
      setIsHookLoading(false);
    }
  }, [isAuthenticated, isUserAuthenticated, isLoading]);

  useEffect(() => {
    if (isAuthenticated && isUserAuthenticated) {
      const refreshInterval = setInterval(async () => {
        try {
          const newAccessToken = await getAccessTokenSilently();
          setHttpClientAuthToken(newAccessToken);
        } catch (error) {
         console.error(error)
        }
      }, 60000);

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [isAuthenticated, isUserAuthenticated, getAccessTokenSilently]);

  return {
    isUserAuthenticated,
    isLoading: isHookLoading,
  };
};

export const useAuth0SignOut = () => {
  const { logout } = useAuth0();
  const setDefaultUser = useSetDefaultUser();
  function clearStorageWithoutKey(keyToSave: string) {
    var items = { ...localStorage };
    localStorage.clear();
    localStorage.setItem(keyToSave, items[keyToSave]);
  }
  return useCallback(() => {
    setDefaultUser();
    setHttpClientAuthToken();
    // window.localStorage.clear();
    clearStorageWithoutKey('TERMLY_API_CACHE');
    window.sessionStorage.clear();
    logout({
      returnTo: window.location.origin,
    });
  }, [setDefaultUser, logout]);
};

export const useAuth0SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  return useCallback(() => loginWithRedirect(), [loginWithRedirect]);
};
