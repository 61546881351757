import { Button, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { deleteDocument } from "api";

import { TrashIcon } from "assets";

import { DeleteDialog, SelectionCounter, useDialogControls } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { useSetDefaultUploadedDocumentsSelection, useUserRole } from "recoils";

export const Actions: React.FunctionComponent<{
  rowSelection: any;
  setrowSelection: any;
}> = ({ rowSelection, setrowSelection }) => {
  const notify = useNotify();
  const userRole = useUserRole();
  const queryClient = useQueryClient();
  const setDefaultSelection = useSetDefaultUploadedDocumentsSelection();
  const deleteDialogControls = useDialogControls();
  const { mutateAsync: deleteUploadedDocument } = useMutation(deleteDocument);

  const selected: any[] = [];

  selected.push(...Object.keys(rowSelection));

  const onDeleteClick = () => {
    Promise.allSettled(
      selected.map((id) =>
        deleteUploadedDocument(id, {
          onError: () =>
            notify.error(
              `Some error has happen while deleting documents: ${id}!`,
            ),
          onSuccess: () => {
            notify.success("Selected documents were deleted!");
            setrowSelection();
          },
        }),
      ),
    ).finally(() => {
      deleteDialogControls.close();
      setDefaultSelection();
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
    });
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <SelectionCounter selectedElements={selected.length} />

      <Button
        startIcon={<TrashIcon />}
        variant="outlined"
        disabled={selected.length === 0 || userRole.isAuditor}
        onClick={deleteDialogControls.open}
      >
        Delete
      </Button>

      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={onDeleteClick}
        selectedCount={selected.length}
        target="document"
        title="Delete documents?"
      />
    </Stack>
  );
};
