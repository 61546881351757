import {
  RadioButtonChecked,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import {
  ColumnDef,
  ExpandedState,
  OnChangeFn,
  SortingState,
  Updater,
} from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_KEYS } from "consts";

import { ParsedQueryParams } from "types";

import { DEFAULT_SELECTED_ROWS_STATE, SelectedRows } from "recoils";

import { getParsedQueryParams } from "utils";

import {
  DEFAULT_INITIAL_SORTING,
  SELECT_COLUMN_ID,
  SORT_DIRECTIONS,
} from "./consts";
import { Data, Id, Ids } from "./types";
import {
  getInitialSortingState,
  getRowIds,
  rowIdsToExpandedState,
} from "./utils";

export const useColumnsWithSelectionColumn = <TData extends Data>({
  columns,
  getIsIntermediateSelection,
  setSelectedFromIndeterminate,
  selected,
  selectedCount,
  setSelected,
}: {
  getIsIntermediateSelection: (rowIds: Ids) => boolean;
  setSelectedFromIndeterminate: (rowIds: Ids, shouldSetToTrue: boolean) => void;
  selected: Record<string, boolean>;
  selectedCount: number;
  setSelected: (guid: Id) => void;
  columns: ColumnDef<TData>[];
}) =>
  useMemo<ColumnDef<TData>[]>(
    () => [
      {
        id: SELECT_COLUMN_ID,
        minSize: 49,
        enableGlobalFilter: true,
        header: ({ table }) => {
          const rowIds = getRowIds(table);
          const isIntermediate = getIsIntermediateSelection(rowIds);

          return (
            <Checkbox
              color="primary"
              indeterminate={isIntermediate}
              checked={selectedCount !== 0}
              onChange={() =>
                setSelectedFromIndeterminate(
                  rowIds,
                  isIntermediate || selectedCount === 0,
                )
              }
            />
          );
        },
        cell: ({ row }) => (
          <Checkbox
            color="primary"
            checked={selected[row.original.id] || false}
            // checked={console.log(row.original)}
            onChange={() => setSelected(row.original.id)}
          />
        ),
        enableSorting: false,
      },
      ...columns,
    ],
    [
      getIsIntermediateSelection,
      setSelectedFromIndeterminate,
      selected,
      selectedCount,
      setSelected,
      columns,
    ],
  );

export const useColumnsWithSelectionColumnRadio = <TData extends Data>({
  columns,
  selected,
  setSelected,
}: {
  getIsIntermediateSelection: (rowIds: Ids) => boolean;
  setSelectedFromIndeterminate: (rowIds: Ids, shouldSetToTrue: boolean) => void;
  getGwp: Record<string, boolean>;
  selected: Record<string, boolean>;
  selectedCount: number;
  setSelected: (guid: Id) => void;
  columns: ColumnDef<TData>[];
}) =>
  useMemo<ColumnDef<TData>[]>(
    () => [
      {
        id: SELECT_COLUMN_ID,
        minSize: 49,
        cell: ({ row }) => (
          <Checkbox
            color="primary"
            checked={selected[row.original.id] || false}
            icon={<RadioButtonUncheckedOutlined />}
            checkedIcon={<RadioButtonChecked />}
            // checked={console.log(row.original)}
            onChange={() => setSelected(row.original.id)}
            // onChange={() => console.log("idddd", row.original.guid)}
          />
        ),
        enableSorting: false,
      },
      ...columns,
    ],
    [selected, setSelected, columns],
  );

export const useTableExpandedRows = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const expandedRowsIds = searchParams.getAll(QUERY_PARAMS_KEYS.EXPANDED_ROWS);

  const [expanded, setExpanded] = useState<ExpandedState>(() =>
    rowIdsToExpandedState(expandedRowsIds),
  );

  const setInitialExpanded = useCallback(() => setExpanded({}), []);
  const updateQueryParamsWithInitialExpanded = useCallback(
    (queryParams: ParsedQueryParams) => {
      const newQueryParams = { ...queryParams };
      delete newQueryParams[QUERY_PARAMS_KEYS.EXPANDED_ROWS];

      return newQueryParams;
    },
    [],
  );

  const onExpandedChange = useCallback(
    (updaterFn: Updater<ExpandedState>) => {
      setExpanded((prev) => {
        const newExpanded = (
          updaterFn as (old: ExpandedState) => ExpandedState
        )(prev);

        setSearchParams(
          createSearchParams({
            ...getParsedQueryParams(searchParams),
            [QUERY_PARAMS_KEYS.EXPANDED_ROWS]: Object.keys(newExpanded),
          }),
        );

        return newExpanded;
      });
    },
    [searchParams, setSearchParams],
  );

  return {
    expanded,
    onExpandedChange,
    setInitialExpanded,
    updateQueryParamsWithInitialExpanded,
  };
};

export const useTableSorting = (
  tableColumnToSortColumnMap: Record<string, string>,
  initialSortingState: SortingState = DEFAULT_INITIAL_SORTING,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>(() =>
    getInitialSortingState(
      searchParams,
      tableColumnToSortColumnMap,
      initialSortingState,
    ),
  );

  const isSorting = !!sorting[0];
  const sortBy = isSorting
    ? tableColumnToSortColumnMap[sorting[0].id]
    : undefined;
  const sortDirection = isSorting
    ? sorting[0].desc
      ? SORT_DIRECTIONS.DESC
      : SORT_DIRECTIONS.ASC
    : undefined;

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterFn) => {
      setSorting((prev) => {
        const newSorting = (updaterFn as (old: SortingState) => SortingState)(
          prev,
        );

        const isNewSorting = newSorting[0];
        const newSortBy = isNewSorting ? newSorting[0].id : undefined;
        const newSortDirection = isNewSorting
          ? newSorting[0].desc
            ? SORT_DIRECTIONS.DESC
            : SORT_DIRECTIONS.ASC
          : undefined;

        const queryParams = getParsedQueryParams(searchParams);

        if (
          !newSortBy ||
          !newSortDirection ||
          (newSortBy === initialSortingState[0]?.id &&
            initialSortingState[0]?.desc === newSorting[0]?.desc)
        ) {
          delete queryParams[QUERY_PARAMS_KEYS.SORT_BY];
          delete queryParams[QUERY_PARAMS_KEYS.SORT_DIRECTION];
        } else {
          queryParams[QUERY_PARAMS_KEYS.SORT_BY] = newSortBy;
          queryParams[QUERY_PARAMS_KEYS.SORT_DIRECTION] = newSortDirection;
        }

        setSearchParams(createSearchParams(queryParams));

        return newSorting;
      });
    },
    [searchParams, setSearchParams, initialSortingState],
  );

  return {
    sorting,
    onSortingChange,
    sortBy,
    sortDirection,
  };
};

export const useLocalSelectedRows = () => {
  const [selectedRowsState, setSelectedRowsState] = useState<SelectedRows>(
    DEFAULT_SELECTED_ROWS_STATE,
  );

  const selectedRowsCount = Object.values(selectedRowsState).filter(
    (v) => v,
  ).length;

  const setSelectedRow = useCallback(
    (guid: Id) =>
      setSelectedRowsState((prev) => ({ ...prev, [guid]: !prev[guid] })),
    [],
  );

  const selectedRows = useMemo(
    () =>
      Object.entries(selectedRowsState)
        .filter(([, isSelected]) => isSelected)
        .map(([guid]) => guid),
    [selectedRowsState],
  );

  const setFromIntermediateSelection = useCallback(
    (guids: Ids, isIntermediate: boolean) =>
      isIntermediate
        ? setSelectedRowsState((prev) => ({
            ...prev,
            ...guids.reduce((acc, guid) => {
              acc[guid] = isIntermediate;

              return acc;
            }, {} as Record<string, boolean>),
          }))
        : setSelectedRowsState(DEFAULT_SELECTED_ROWS_STATE),
    [],
  );

  const setDefaultSelection = useCallback(
    () => setSelectedRowsState(DEFAULT_SELECTED_ROWS_STATE),
    [],
  );

  const getIsIntermediateSelection = useCallback(
    (guids: Ids) =>
      !guids.every((guid) => selectedRowsState[guid]) &&
      selectedRowsCount !== 0,
    [selectedRowsState, selectedRowsCount],
  );

  return {
    selectedRowsState,
    selectedRowsCount,
    setSelectedRow,
    selectedRows,
    setFromIntermediateSelection,
    setDefaultSelection,
    getIsIntermediateSelection,
  };
};
