import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, Button, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { FunctionComponent, useRef } from "react";
import { useParams } from "react-router-dom";



import { downloadUtilityBillPdf, uploadDocumentToS3 } from "api";
import { RecDocumentUploaded, createRecDocument, deleteRecDocument } from "api/rec";



import { COLORS } from "components/consts";
import { DeleteDialog, useDialogControls } from "components/dialog";



import { useNotify } from "hooks";



import { MaybeNull } from "types";



import { downloadFile, openPdfInNewTab } from "utils";


interface UtilityBillActionsProps {
  actionsDisabled: boolean;
  downloadUrl: string;
  fileName: MaybeNull<string>;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setScaleChange: React.Dispatch<React.SetStateAction<boolean>>;
  isFileUploaded: any;
}

const ZOOM = 0.25;

const iconWrapperStyle = {
  padding: 0,
  minWidth: "fit-content",
  color: COLORS.white,
};

const deleteIconWrapperStyle = {
  padding: 0,
  minWidth: "fit-content",
  color: COLORS.white,
  marginLeft: "22px"
};


export const UtilityBillActions: FunctionComponent<UtilityBillActionsProps> = ({
  actionsDisabled,
  downloadUrl,
  fileName,
  setScale,
  setScaleChange,
  isFileUploaded
}) => {

  const fileInputRef: any = useRef(null);
  const notify = useNotify();
  const deleteDialogControls: any = useDialogControls();
  
  const { mutateAsync: uploaded } = useMutation(RecDocumentUploaded);
  const { mutate: create } = useMutation(createRecDocument);
  const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);
  const { mutateAsync: deleteUploadedRecDocument } = useMutation(deleteRecDocument);

  const {recId} = useParams();
  
  const handleFileChange = (e: any) => {
    const files = e.target.files[0].name;

    create(
      {
        fileName: files,
        recId: parseInt(recId!, 10),  
      },
      {
        onSuccess: (details) => {
          uploadToS3({
            url: details.s3uploadUrl,
            file: e.target.files[0] as File,
          })
            .then(() => uploaded({
              recId: parseInt(recId!, 10),  
            }))
            .then((res) => {
              if (res.data[0].status === "ERROR") {
                // console.log(e)
              }
            })
            .catch(() => {
              
            }).then(() => {
              notify.success(`Successfully uploaded REC file !`);
              isFileUploaded(true)
              setTimeout(() => {
                isFileUploaded(false)
              },2000)
            })
        },
      },
    );
  };

  
  const onDeleteClick = () => {
    deleteUploadedRecDocument(recId as any, {
      onError: () =>
        notify.error(
          `Some error has happen while deleting REC documents !`,
        ),
      onSuccess: () => {
        notify.success("Selected REC document is deleted!");
        deleteDialogControls.close();
        isFileUploaded(true);
        setTimeout(() => {
          isFileUploaded(false);
        }, 2000);
      },
    })
  };


  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };
  return (
  <Stack direction="row">
    <Button
      disabled={actionsDisabled}
      onClick={() =>
        downloadFile(
          () => downloadUtilityBillPdf(downloadUrl),
          fileName || "utility-bill.pdf",
        )
      }
      sx={{ marginRight: "45px", ...iconWrapperStyle }}
      variant="text"
    >
      <CloudDownloadIcon />
    </Button>

    <Stack direction="row" spacing={4}>
      <Button
        disabled={actionsDisabled}
        onClick={() => {
          setScale((state) => state + ZOOM);
          setScaleChange(true);
        }}
        sx={iconWrapperStyle}
        variant="text"
      >
        <ZoomInIcon />
      </Button>
      <Button
        disabled={actionsDisabled}
        onClick={() => {
          setScale((state) => state - ZOOM);
          setScaleChange(true);
        }}
        sx={iconWrapperStyle}
        variant="text"
      >
        <ZoomOutIcon />
      </Button>
      <Button
        disabled={actionsDisabled}
        onClick={() => setScale(0.75)}
        sx={iconWrapperStyle}
        variant="text"
      >
        <RestoreIcon />
      </Button>
    </Stack>

    <Button
      disabled={actionsDisabled}
      onClick={() => openPdfInNewTab(() => downloadUtilityBillPdf(downloadUrl))}
      sx={{ marginLeft: "22px", ...iconWrapperStyle }}
      variant="text"
    >
      <OpenInNewIcon />
    </Button>

    <Box
      sx={{
        textAlign: "center",
        marginTop: "5px",
        marginLeft: "22px"
      }}
    >
      <UploadFileIcon
        width={84}
        height={84}
        onClick={handleIconClick}
        style={{ cursor: "pointer" }}
      />
      
      <input
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>

    <Button
        disabled={actionsDisabled}
        onClick={deleteDialogControls.open}
        sx={deleteIconWrapperStyle}
        variant="text"
      >
        <DeleteForeverIcon />
    </Button>

      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={onDeleteClick}
        target="REC"
        title="Delete REC documents?"
      />
    

  </Stack>
)};