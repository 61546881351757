import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import React from "react";

import { InfoIcon } from "assets";

type CustomSnackbarProps = {
  open: boolean;
  onClose: () => void;
  message: string;
};

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  open,
  onClose,
  message,
}) => (
  <Snackbar
    sx={{
      borderRadius: "10px",
      width: "444px",
      height: "50px",
      left: "1309px",
      top: "160px",
    }}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={open}
    autoHideDuration={15000}
    onClose={onClose}
  >
    <SnackbarContent
      sx={{ backgroundColor: "#FFFAEA", color: "#7E5F00", boxShadow: "none" }}
      message={
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoIcon
            style={{ height: "20px", width: "20px", marginRight: "10px" }}
            fontSize="small"
          />
          <span
            style={{
              maxWidth: "300px",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              fontWeight: "500",
            }}
          >
            {message}
          </span>
        </div>
      }
      action={
        <Button
          sx={{
            backgroundColor: "#F0DEBC",
            color: "#7E5F00",
            height: "62px",
          }}
          size="small"
          onClick={onClose}
        >
          OK
        </Button>
      }
    />
  </Snackbar>
);

export default CustomSnackbar;
