import { UserDTO } from "./users";

import { MaybeNull } from "../types";
import { getResData, httpClient } from "../utils";

export const COMMENTS_API_PATHS = {
  COMMENTS: "/comment",
  SITE_COMMENTS: '/sitecomment',
  // SITE_COMMENT: (commentId: number) => `/sitecomment/${commentId}`,
  SITE_COMMENT_DONE: (commentId: number) => `/sitecomment/${commentId}/done`,
  COMMENT: (commentId: number) => `/comment/${commentId}`,
  COMMENT_DONE: (commentId: number) => `/comment/${commentId}/done`,
  COMMENTS_BY_UTILITY_BILL: (utilityBillId: string) =>
    `/comment/bill/${utilityBillId}`,
  ESTIMATE_COMMENTS: (estimateId: number | undefined) =>
    `/sitecomment/estimate/${estimateId}`,
};

export interface CommentDTO {
  assignedUser: MaybeNull<UserDTO>;
  authorEmail: string;
  authorLastName: string;
  authorName: string;
  billId: string;
  createDate: number;
  done: boolean;
  id: number;
  parentId: MaybeNull<number>;
  replies: CommentDTO[];
  text: string;
  updateDate: string;
}

interface UpdateCommentDTO {
  assignedUserId: MaybeNull<number>;
  billId: string;
  parentId: MaybeNull<number>;
  text: string;
}

export const createComment = (body: UpdateCommentDTO) =>
  httpClient.post(COMMENTS_API_PATHS.COMMENTS, body);

  export const createSiteComment = (body: any) =>
  httpClient.post(COMMENTS_API_PATHS.SITE_COMMENTS, body);

export const updateComment = ({
  commentId,
  body,
}: {
  commentId: number;
  body: UpdateCommentDTO;
}) => httpClient.put(COMMENTS_API_PATHS.COMMENT(commentId), body);

export const markCommentAsDone = (commentId: number) =>
  httpClient.put(COMMENTS_API_PATHS.COMMENT_DONE(commentId));

export const markSiteCommentAsDone = (commentId: number) =>
  httpClient.put(COMMENTS_API_PATHS.SITE_COMMENT_DONE(commentId));

export const deleteComment = (commentId: number) =>
  httpClient.delete(COMMENTS_API_PATHS.COMMENT(commentId));

export const getCommentsByUtilityBill = (
  utilityBillId: string,
): Promise<CommentDTO[]> =>
  httpClient
    .get<CommentDTO[]>(
      COMMENTS_API_PATHS.COMMENTS_BY_UTILITY_BILL(utilityBillId),
    )
    .then(getResData);

export const getCommentsByEstimateId = (
  estimateId: number | undefined,
): Promise<CommentDTO[]> =>
  httpClient
    .get<CommentDTO[]>(COMMENTS_API_PATHS.ESTIMATE_COMMENTS(estimateId))
    .then(getResData);
