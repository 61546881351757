import { AUTH_PROVIDER, AUTH_PROVIDERS } from "providers";

import {
  useAuth0Authentication,
  useAuth0SignIn,
  useAuth0SignOut,
} from "./auth0";
import { useNullAuthentication, useNullSignIn, useNullSignOut } from "./null";
import { useOktaAuthentication, useOktaSignIn, useOktaSignOut } from "./okta";

const useAuthenticationMap = {
  [AUTH_PROVIDERS.AUTH0]: useAuth0Authentication,
  [AUTH_PROVIDERS.OKTA]: useOktaAuthentication,
  [AUTH_PROVIDERS.NULL]: useNullAuthentication,
};

const useSignOutMap = {
  [AUTH_PROVIDERS.AUTH0]: useAuth0SignOut,
  [AUTH_PROVIDERS.OKTA]: useOktaSignOut,
  [AUTH_PROVIDERS.NULL]: useNullSignOut,
};

const useSignInMap = {
  [AUTH_PROVIDERS.AUTH0]: useAuth0SignIn,
  [AUTH_PROVIDERS.OKTA]: useOktaSignIn,
  [AUTH_PROVIDERS.NULL]: useNullSignIn,
};

export const useAuthentication = useAuthenticationMap[AUTH_PROVIDER];

export const useSignOut = useSignOutMap[AUTH_PROVIDER];

export const useSignIn = useSignInMap[AUTH_PROVIDER];
