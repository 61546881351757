import { MaybeNull } from "types";

import { getResData, httpClient } from "utils";

export const COLLECTORS_API_PATHS = {
  BASE_COLLECTOR_PATH: "/collector",
  COLLECTOR: (collectorId: number) => `/collector/${collectorId}`,
  COLLECTORS_BY_SITE: (siteId: number) => `/collector/site/${siteId}`,
  CREATE_COLLECTOR: `/collector`,
  DELETE_COLLECTOR: (collectorId: number) => `/collector/${collectorId}`,
  UPDATE_COLLECTOR: (collectorId: number) => `/collector/${collectorId}`,
  COLLECTOR_ALL: "/collector/all",
  DEACTIVATED_COLLECTORS: (siteId: number) =>
    `/collector/site/${siteId}/de-activated`,
  UNIT_COST_BY_COLLECTOR:(collectorId: number)=>`/collector/unitcost/${collectorId}`
};

export interface Collector {
  accountNumber: string;
  allowedDocuments: string[];
  frequency: string;
  id: number;
  meterNumber?: string;
  estimate?: boolean;
  recs?: boolean;
  providerName: MaybeNull<string>;
  siteId: number;
  siteName: string;
  utilityTypeId: number;
  utilityTypeName: string;
  consumptionUnit: string;
  currency: string;
  disableDate?: string;
  enabled?: boolean;
  notes?: string;
  country?:MaybeNull<string>
}

export type CollectorDTO = Omit<Collector, "siteId" | "utilityTypeId">;
export type CreateCollectorDTO = Omit<
  Collector,
  "siteName" | "utilityTypeName" | "id"
>;
export type UpdateCollectorDTO = Omit<
  Collector,
  "siteName" | "utilityTypeName"
>;

export const createCollector = (body: CreateCollectorDTO) =>
  httpClient.post(COLLECTORS_API_PATHS.CREATE_COLLECTOR, body);

export const getCollectorById = (collectorId: number): Promise<CollectorDTO> =>
  httpClient
    .get<CollectorDTO>(COLLECTORS_API_PATHS.COLLECTOR(collectorId))
    .then(getResData);

export const getCollectorsBySiteId = (
  siteId: number,
): Promise<CollectorDTO[]> =>
  httpClient
    .get<CollectorDTO[]>(COLLECTORS_API_PATHS.COLLECTORS_BY_SITE(siteId))
    .then(getResData);

export const getAllCollectors = (): Promise<CollectorDTO[]> =>
  httpClient
    .get<CollectorDTO[]>(COLLECTORS_API_PATHS.COLLECTOR_ALL)
    .then(getResData);

export const updateCollector = (data: UpdateCollectorDTO) => {
  const { id, ...body } = data;

  return httpClient.put(COLLECTORS_API_PATHS.UPDATE_COLLECTOR(id), body);
};

export const getDeActivatedCollectorsBySiteId = (
  siteId: number,
): Promise<CollectorDTO[]> =>
  httpClient
    .get<CollectorDTO[]>(COLLECTORS_API_PATHS.DEACTIVATED_COLLECTORS(siteId))
    .then(getResData);

 export const getUnitCostByCollectorId = (collectorId: number): Promise<any> =>
 httpClient
   .get<any>(COLLECTORS_API_PATHS.UNIT_COST_BY_COLLECTOR(collectorId))
   .then(getResData);
  