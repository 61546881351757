import MockAdapter from "axios-mock-adapter";

import { DOCUMENTS_API_PATHS } from "api";

import { DOCUMENTS_RESPONSE } from "./consts";

export const initDocumentsMock = (clientMock: MockAdapter) => {
  clientMock
    .onGet(new RegExp(`${DOCUMENTS_API_PATHS.DOCUMENTS}.*`))
    .reply(200, DOCUMENTS_RESPONSE);
};
