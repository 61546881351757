import { useCallback, useEffect, useState } from "react";
import { AllowedValuesDTO, getCountryByRegion } from "api";
import { QUERY_PARAMS_KEYS } from "consts";
import { sortBy } from "utils";
import { useSingeSelectWithFetch } from "./singleselect";
import { useGetAllowedValues } from "../api"; 

export const useCountriesSelect = ({
  selectedCountryRegion, 
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: any = {}) => {
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);

  const getOptionsFromData = useCallback(
    (allowedValues?: AllowedValuesDTO) => {
      const countriesAscOrder = sortBy({
        array: (allowedValues || { countries: [] }).countries,
      });

      if (withEmptyOption) {
        countriesAscOrder.push("");
      }
      return countriesAscOrder;
    },
    [withEmptyOption],
  );

  const {
    isLoading: isCountriesLoading,
    setSelectedOption: setSelectedCountry,
    selectedOption: selectedCountry,
    options: countriesList,
    selectedOptionValues: country,
  } = useSingeSelectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.COUNTRIES,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      if (selectedCountryRegion) {
        const data = await getCountryByRegion(selectedCountryRegion);
        const sortedCountries:any = sortBy({ array: data });
        setFilteredCountries(sortedCountries);
      } else {
        setFilteredCountries(countriesList);
      }
    };

    fetchCountries();
  }, [selectedCountryRegion, countriesList]);

  return {
    isCountriesLoading,
    setSelectedCountry,
    selectedCountry,
    countriesList: selectedCountryRegion ? filteredCountries : countriesList, 
    country,
  };
};
