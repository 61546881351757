import { buildQueryParams } from "utils";

import { NotificationsQuery } from "./types";

export const NOTIFICATIONS_API_PATHS = {
  NOTIFICATIONS: (query: NotificationsQuery) =>
    `/notifications?${buildQueryParams(query)}`,
  READ_NOTIFICATION: (notificationId: number) =>
    `/notifications/${notificationId}/read`,
  READ_ALL_NOTIFICATIONS: "/notifications/readAll",
};
