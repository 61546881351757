import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import {
  useSetDefaultEmissionFactorsSelection,
  useSetDefaultUploadedDocumentsSelection,
  useSetDefaultUtilityBillsSelection,
} from "recoils";

import { UTILITY_BILLS_PAGE } from "./document-summary-v2";
import { DOCUMENT_UPLOAD_PAGE } from "./document-upload";

import { EMISSION_FACTORS_PAGE_SCOPE3 } from "../emission-factors-scope3";

export const useDefaultSelectionWatcher = () => {
  const { pathname } = useLocation();
  const prevPathname = useRef<string | null>();
  const setDefaultUploadedDocumentsSelection =
    useSetDefaultUploadedDocumentsSelection();
  const setDefaultUtilityBillsSelection = useSetDefaultUtilityBillsSelection();
  const setDefaultEmissionFactorsSelection =
    useSetDefaultEmissionFactorsSelection();

  useEffect(() => {
    if (prevPathname.current === DOCUMENT_UPLOAD_PAGE.ABSOLUTE_PATH) {
      setDefaultUploadedDocumentsSelection();
    }
    if (prevPathname.current === EMISSION_FACTORS_PAGE_SCOPE3.ABSOLUTE_PATH) {
      setDefaultEmissionFactorsSelection();
    }
    if (
      prevPathname.current?.includes(UTILITY_BILLS_PAGE.ABSOLUTE_PATH) &&
      !pathname.includes(UTILITY_BILLS_PAGE.ABSOLUTE_PATH)
    ) {
      setDefaultUtilityBillsSelection();
    }
    prevPathname.current = pathname;
  }, [
    pathname,
    setDefaultUtilityBillsSelection,
    setDefaultUploadedDocumentsSelection,
    setDefaultEmissionFactorsSelection,
  ]);
};
