import { Box, Popover } from "@mui/material";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

import { UserDTO } from "api";

import { CloseButton, Label14SemiBold, Tabs } from "components";

import { usePopoverControl, useUsersBySiteId } from "hooks";

import { MaybeNull } from "types";

import { User } from "./user";
import { Users } from "./users";

export const UserSelectPopover: FunctionComponent<{
  siteId: number;
  onUserSelect: (id: MaybeNull<number>) => void;
}> = ({ onUserSelect, siteId }) => {
  const { close, id, anchorEl, open, isOpen } = usePopoverControl(
    "comment-user-select",
  );
  const [selectedUser, setSelectedUser] = useState<MaybeNull<UserDTO>>(null);

  const { data: users } = useUsersBySiteId(siteId);
  
  const admins = useMemo(() => users?.filter((user) => user.admin), [users]);

  const auditors = useMemo(
    () => users?.filter((user) => user.auditor),
    [users],
  );

  const dataProviders = useMemo(
    () => users?.filter((user) => !user.auditor && !user.admin),
    [users],
  );

  useEffect(() => {
    onUserSelect(selectedUser?.id || null);
    close();
  }, [close, selectedUser, onUserSelect]);

  return (
    <Box>
      <Box onClick={open} aria-describedby={id}>
        {selectedUser ? (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <User user={selectedUser} />
            <CloseButton onClick={() => setSelectedUser(null)} />
          </Box>
        ) : (
          <Label14SemiBold sx={{ cursor: "pointer" }}>Assign</Label14SemiBold>
        )}
      </Box>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ height: "300px", width: "600px" }}>
          <Tabs
            tabs={[
              {
                label: "Data providers",
                content: (
                  <Box sx={{ px: 2, mb: 2 }}>
                    <Users
                      users={dataProviders}
                      onUserSelect={setSelectedUser}
                    />
                  </Box>
                ),
              },
              {
                label: `Sustainability managers`,
                content: (
                  <Box sx={{ px: 2, mb: 2 }}>
                    <Users users={admins} onUserSelect={setSelectedUser} />
                  </Box>
                ),
              },
              {
                label: `Auditors`,
                content: (
                  <Box sx={{ px: 2, mb: 2 }}>
                    <Users users={auditors} onUserSelect={setSelectedUser} />
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Popover>
    </Box>
  );
};
