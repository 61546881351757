import { useQuery } from "@tanstack/react-query";

import { ConfigParameterDTO, getConfigParameter } from "api";

import { QUERY_KEYS } from "consts";

export const useGetConfigParameter = () =>
  useQuery<ConfigParameterDTO[]>(
    [QUERY_KEYS.CONFIG_PARAMETER],
    getConfigParameter,
  );
