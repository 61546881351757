import { Box, Tab, Tabs as TabsMui } from "@mui/material";
import React from "react";

import { DashboardTabs } from "pages/authenticated/dashboards/components/dashboardTabs";

import { useNewBreadcrumb } from "recoils";

import { SETTINGS_ROUTER } from "./const";
import CookiesPolicy from "./cookiesPolicy";
import { DASRForms } from "./dasrForms";
import { PrivacysPolicy } from "./privacysPolicy";

const leagalBtnStates = {
  LEGAL: "LEGAL",
};
export const LegalPage = () => {
  useNewBreadcrumb(SETTINGS_ROUTER.TITLE);
  const { LEGAL } = leagalBtnStates;
  const tabs = {
    [LEGAL]: [
      {
        label: "Privacy Policy",
        content: <PrivacysPolicy />,
      },
      {
        label: "Cookie Policy",
        content: <CookiesPolicy />,
      },
      {
        label: "DSAR",
        content: <DASRForms />,
      },
    ],
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsMui variant="fullWidth">
          <Tab
            label="LEGAL"
            iconPosition="start"
            sx={{ minHeight: "48px", backgroundColor: "white" }}
          />
        </TabsMui>
      </Box>
      <DashboardTabs tabs={tabs[LEGAL]} selectedBtnOption={LEGAL} />
    </>
  );
};
