import { Box, Tab, Tabs as TabsMui } from "@mui/material";
import React, { FunctionComponent, ReactElement, ReactNode } from "react";

export const Tabs: FunctionComponent<{
  tabs: {
    icon?: ReactElement;
    label: ReactNode;
    content: ReactNode;
    activeTab?: number;
  }[];
  activeTab?: number;
}> = ({ tabs, activeTab = 0 }) => {
  const [value, setValue] = React.useState(activeTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    /* eslint-disable react/no-array-index-key */
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsMui value={value} onChange={handleChange} variant="fullWidth">
          {tabs.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              icon={tab.icon}
              label={tab.label}
              iconPosition="start"
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
              sx={{ minHeight: "48px" }}
            />
          ))}
        </TabsMui>
      </Box>
      {tabs.map((tab, index) => (
        <Box
          sx={{ mt: 3, flexGrow: 1, overflow: "auto" }}
          key={`tabpanel-${index}`}
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && tab.content}
        </Box>
      ))}
    </Box>
    /* eslint-enable react/no-array-index-key */
  );
};
