import { Box, TextFieldProps as TextFieldMuiProps } from "@mui/material";
import React, { FunctionComponent } from "react";

import { ColorType, ReactChildren } from "types";

import { Label12SemiBold } from "../typography";

export const InputFieldWrapper: FunctionComponent<
  ReactChildren & {
    label: TextFieldMuiProps["label"];
    message?: string;
    color?: ColorType;
  }
> = ({ label, message, color, children }) => (
  <Box
    sx={{
      width: "100%",
    }}
  >
    {label && <Label12SemiBold sx={{ mb: "4px" }}>{label}</Label12SemiBold>}
    {children}
    {message && (
      <Label12SemiBold sx={{ mt: "4px" }} color={color}>
        {message}
      </Label12SemiBold>
    )}
  </Box>
);
