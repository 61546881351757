import { Badge, IconButton } from "@mui/material";
import React from "react";

import { EsgNotifications } from "assets";

import {
  NOTIFICATIONS_REFETCH_INTERVAL,
  useNotificationsInfinite,
  usePopoverControl,
} from "hooks";

import { NotificationsPopover } from "./notifications-popover";

export const Notifications = () => {
  const { close, id, anchorEl, open, isOpen } = usePopoverControl(
    "notifications-popover",
  );
  const { data } = useNotificationsInfinite({
    refetchInterval: NOTIFICATIONS_REFETCH_INTERVAL,
  });

  return (
    <>
      <IconButton
        onClick={open}
        aria-describedby={id}
        sx={{ 
          borderRadius: 1,
          marginRight: 2,
          padding: 0,
          "& > span:first-child > svg": {
            height: "24px",
            width: "24px"
          },
        }}
      >
        <Badge
          sx={{ ".MuiBadge-badge": { top: "3px", right: "6px" } }}
          color="error"
          variant="dot"
          invisible={!data || data.pages[0].unreadCount === 0}
        >
          <EsgNotifications />
        </Badge>
      </IconButton>

      {isOpen && (
        <NotificationsPopover
          id={id}
          anchorEl={anchorEl}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};
