import { SelectedRows, createSelectedRowsRecoil } from "./selected-rows";

export const {
  selectedRowsState: selectedUploadedDocumentsState,
  selectedRowsCount: selectedUploadedDocumentsCount,
  useSetSelectedRow: useSetSelectedUploadedDocument,
  useSelectedRows: useSelectedUploadedDocuments,
  useSetFromIntermediateSelection:
    useSetFromIntermediateSelectionUploadedDocuments,
  useSetDefaultSelection: useSetDefaultUploadedDocumentsSelection,
  useGetIsIntermediateSelection: useGetIsIntermediateSelectionUploadedDocument,
} = createSelectedRowsRecoil("UploadedDocuments");

export type SelectedUploadedDocuments = SelectedRows;

export const {
  selectedRowsState: selectedMatchedDocumentsState,
  selectedRowsCount: selectedMatchedDocumentsCount,
  useSetSelectedRow: useSetSelectedMatchedDocument,
  useSelectedRows: useSelectedMatchedDocuments,
  useSetFromIntermediateSelection:
    useSetFromIntermediateSelectionMatchedDocuments,
  useSetDefaultSelection: useSetDefaultMatchedDocumentsSelection,
  useGetIsIntermediateSelection: useGetIsIntermediateSelectionMatchedDocument,
} = createSelectedRowsRecoil("MatchedDocuments");

export type SelectedMatchedDocuments = SelectedRows;
