/* eslint-disable */
import {
  DatePicker as DatePickerMui,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { getDateFormat } from "utils";
import { TextField, TextFieldProps } from "./text-field";
import { InputAdornment } from '@mui/material'; // Import InputAdornment
import { COLORS } from "components/consts";
import { EsgCalender } from "assets";

export const EsgDatePickerField = ({
  placeholdervalue,
  disabled,
  clearDate,
  emission,
  filterAll,
  format = getDateFormat(),
  textFieldProps = {},
  ...datePickerProps
}: {
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  clearDate: boolean;
  placeholdervalue?: string;
  emission?: boolean;
  filterAll?: boolean;
} & Omit<
  DatePickerProps<Dayjs>,
  "renderInput" | "date" | "openPicker" | "rawValue"
>) => {

  const [value, setValue] = React.useState<any>();

  React.useEffect(() => {
    if (clearDate === true) {
      setValue(null);
    }
  }, [clearDate]);

  React.useEffect(() => {
    setValue(datePickerProps.value);
  }, []);

  const handleClear = () => {
    setValue(null);
  }

  React.useEffect(() => {
    if (disabled) {
      handleClear();
    }
  }, [disabled]);

  const calenderIcon = () => {
    return (<InputAdornment position="end"> {/* Changed to 'end' to ensure correct placement */}
      <EsgCalender />
    </InputAdornment>)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        disabled={disabled}
        format={format}
        {...datePickerProps}
        value={value}
        slotProps={{
          inputAdornment: {
            position: 'start',
          },
        }}
        slots={{
          openPickerIcon:calenderIcon,
          textField: (params: any) => (
            <TextField
              disabled={disabled}
              {...params}
              fullWidth
              value={disabled ? emission ? params.value : "" : filterAll ? "" : params.value}
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: '8px',
                  height: '36px',
                  width: '242px'
                }
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: COLORS.Romance,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'yourCustomHoverColor',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'yourCustomFocusedColor',
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
              placeholder={placeholdervalue || "Month"}
              {...textFieldProps}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};
