import { Box, Paper } from "@mui/material";
import dayjs from "dayjs";
import React, { FunctionComponent } from "react";

import { Notification } from "api";

import { ArrowRightIcon } from "assets";

import { getDateFormat } from "utils";

import { AvatarName } from "../../../avatar-name";
import { COLORS } from "../../../consts";
import { Body2Regular, Label12SemiBold } from "../../../typography";

import { MaybeNull } from "../../../../types";

export const NotificationMessage: FunctionComponent<{
  notification: Notification;
  onClick: (id: number, link: string, readDate: MaybeNull<string>) => void;
}> = ({ notification, onClick }) => (
  <Paper
    elevation={0}
    key={notification.id}
    onClick={() =>
      onClick(notification.id, notification.link, notification.readDate)
    }
    sx={{
      p: 2,
      cursor: "pointer",
      opacity: notification.readDate ? 0.65 : 1,
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <AvatarName
        name={
          notification?.assignedBy === null
            ? "System"
            : `${notification?.assignedBy?.firstName} ${notification?.assignedBy?.lastName}`
        }
        content={
          <>
            {" "}
            assigned you a task{" "}
            <Label12SemiBold component="span" color={COLORS.neutralDarkGray}>
              {dayjs(notification.createDate).utc().format(getDateFormat())}
            </Label12SemiBold>
          </>
        }
        gap={1}
        size={32}
      />
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Body2Regular>{notification.content}</Body2Regular>
      <ArrowRightIcon />
    </Box>
  </Paper>
);
