import { AppBar, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";

import { Loading } from "components";

import { useAuthentication } from "hooks";

import { updateFileCountContext } from "providers/uploadFileCount-provider";

import { AuthenticatedRouter } from "./authenticated";
import { UnauthenticatedRouter } from "./unauthenticated";

export const Root = () => {
  const { isUserAuthenticated, isLoading } = useAuthentication();
  const { uploadedCount, totalCount } = useContext(updateFileCountContext);

  useEffect(() => {
    window.sessionStorage.setItem(
      "navigator_language",
      JSON.stringify(navigator.language),
    );
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (isUserAuthenticated) {
    return (
      <>
        {totalCount !== undefined && totalCount !== 0 && (
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#43A047",
              height: "44px",
              top: "auto",
              bottom: 0,
              textAlign: "center",
            }}
          >
            <Toolbar
              variant="dense"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography align="center">
                File uploading {uploadedCount} of {totalCount}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        <AuthenticatedRouter />
      </>
    );
  }

  return (
    <>
      {totalCount !== undefined && totalCount !== 0 && (
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#43A047",
            height: "44px",
            top: "auto",
            bottom: 0,
            textAlign: "center",
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography align="center">
              File uploading {uploadedCount} of {totalCount}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <UnauthenticatedRouter />
    </>
  );
};
