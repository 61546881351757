// import { buildQueryParams } from "utils";

// import { MissingDocumentsQuery } from "./types";

// export const MISSING_DOCUMENTS_API_PATHS = {
//   MISSING_DOCUMENTS: (query: MissingDocumentsQuery) =>
//     `/aggregations/missing?${buildQueryParams(query)}`,
// };

export const MISSING_DOCUMENTS_API_PATHS = {
  MISSING_DOCUMENTS: () =>
    `/aggregations/missing`,
};

