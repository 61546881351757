import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { type MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useState } from "react";

import { PendingEmissionfactor, supplieRecalculateSupplies } from "api";

import { EMPTY_CELL_VALUE, Tooltip } from "components";

import {
  useNotify,
  useScopePendingManualDropdownDataEmissionFactors,
} from "hooks";

import { isNullOrUndefined } from "utils";

import { MultiSelectDropdown } from "../selectPending-sidebar/multiSelectDropdown";
import { SelectPendingSideBar } from "../selectPending-sidebar/selectPending-sidebar";
import {
  EmissionFactorsPopupDetails,
  EmissionFactorsPopupTable,
} from "../selectPending-sidebar/table";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "75%",
  transform: "translate(-68%, -50%)",
  width: "80%",
  height: "100%",
  bgcolor: "background.paper",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  width: "19%",
  position: "relative",
  bottom: 0,
  mt: 2,
  m: 2,
};

const EmissionbuttonStyle = {
  width: "30%",
  position: "relative",
  bottom: 0,
  mt: 2,
  m: 1,
};

const emissionFactorMatchedColumn = (
  arrMatchedSupplierName: any,
  arrMatchedItemNumber: any,
  arrMatchedGeography: any,
) => {
  const EMISSION_FACTORS_MATCHED_COLUMNS: MRT_ColumnDef<PendingEmissionfactor>[] =
    [
      {
        header: "Supplier Name",
        accessorKey: "supplier_name",
        size: 200,
        filterVariant: "multi-select",
        filterSelectOptions: arrMatchedSupplierName,
      },
      {
        header: "Item Number",
        accessorKey: "supplier_item_number",
        size: 200,
        filterVariant: "multi-select",
        filterSelectOptions: arrMatchedItemNumber,
      },
      {
        header: "Product Description",
        accessorKey: "description_1",
      },
      {
        header: "Category",
        accessorKey: "category",
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ paddingLeft: "30px" }}>{`${value}`}</Box>
            </Tooltip>
          );
        },
      },
      {
        header: "Activity Name",
        accessorKey: "scope3EcoInvent.emission_factor_activity_name",
        size: 350,
        Cell: ({ cell }: any) => {
          const value: any = cell.getValue();

          if (!value) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Tooltip title={value! as string}>
              <Box
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                className=""
              >
                {" "}
                {value}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        header: "Geography",
        accessorKey: "scope3EcoInvent.emission_factor_geography",
        size: 100,
        filterVariant: "multi-select",
        filterSelectOptions: arrMatchedGeography,
        Cell: function Cell({ cell }: any) {
          const value: any = cell.getValue();
          if (!value) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value! as string}>
              <Box sx={{ textAlign: "center", cursor: "pointer" }}>{value}</Box>
            </Tooltip>
          );
        },
      },
      {
        header: "Product Information",
        accessorKey: "scope3EcoInvent.emission_factor_product_information",
        enableColumnFilter: false,
        Cell: function Cell({ cell }: any) {
          const value: any = cell.getValue();
          if (!value) {
            return EMPTY_CELL_VALUE;
          }
          return (
            <Tooltip title={value! as string}>
              <Box
                sx={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {value}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        header: "Emission Factor",
        enableColumnFilter: false,
        size: 100,
        Cell: function Cell({ row }) {
          const [open, setOpen] = React.useState(false);
          const [showSelectPending, setShowSelectPending] =
            React.useState(false);
          const [selectedData, setSelectedData] = React.useState([]);
          const [id, setId] = useState("");
          const [displayData, setDisplayData] =
            useState<PendingEmissionfactor>();
          const [manualSearch, setManualSearch] = React.useState(false);
          const {
            data: emissionCalculateData,
            mutateAsync: recalculateComponent,
          } = useMutation(supplieRecalculateSupplies);
          const { data: dropDownData } =
            useScopePendingManualDropdownDataEmissionFactors();
          const [manualSearchData, setManualSearchData] = useState([]);
          const componentData = window.sessionStorage.getItem("ComponentData")
            ? JSON.parse(window.sessionStorage.getItem("ComponentData") || "")
            : [];
          const selectedComponent =
            componentData.filter(
              (selectedItem: any) =>
                selectedItem.selected_row_id === row?.original.id,
            ) || [];

          const recalculateArr: any[] = [];
          const detailsData = (row?.original?.suppliesComponent || []) as any[];
          const arrUuids = selectedComponent.map((t: any) => t.component_id);
          if (detailsData?.length > 0) {
            const allDataWithUuid = detailsData.filter(
              (t: any) =>
                t.activity_uuid_product_uuid !== null &&
                !arrUuids.includes(t.id),
            );
            allDataWithUuid.map((item: any) =>
              recalculateArr.push({
                activity_uuid_product_uuid: item.activity_uuid_product_uuid,
                component_composition_percent:
                  item.component_composition_percent,
                component_id: item.id,
                component_type: item.component_type,
                selected_row_id: row?.original.id,
              }),
            );
          }

          const finalArray = [...selectedComponent, ...recalculateArr];

          const notify = useNotify();
          const handleOpen = (e: any) => {
            setManualSearch(false);
            setDisplayData(row.original);
            setId(e.target.id);
            setOpen(true);
          };

          const handleClose = () => {
            setOpen(false);
            setShowSelectPending(false);
          };

          const handleCalculateEmission = async () => {
            if (
              finalArray.length === row?.original?.suppliesComponent?.length
            ) {
              const payload = finalArray.map((t: any) => ({
                component_id: t?.component_id,
                activity_uuid_product_uuid: t.activity_uuid_product_uuid,
                gwp_100a: t.gwp_100a,
                component_type: t.component_type,
                component_composition_percent: t?.component_composition_percent,
              }));
              recalculateComponent(
                {
                  component: payload,
                  supplierId: id,
                },
                {
                  onError: () =>
                    notify.error(
                      `An error has happened while calculating emissions`,
                    ),
                },
              );
            } else {
              notify.error(`recalculating length is not same`);
            }
          };

          const handleopenPendingModal = async (details: any) => {
            setShowSelectPending(true);
            setSelectedData(details);
          };

          const handleClosePendingModal = () => {
            setShowSelectPending(false);
          };

          const handleCloseDirectModal = () => {
            setOpen(false);
          };

          const handlesearchManually = (data: boolean) => {
            setManualSearch(data);
          };

          const handleManualSearchData = (data: any) => {
            setManualSearchData(data);
          };

          useEffect(() => {
            if (
              emissionCalculateData === null &&
              emissionCalculateData === undefined
            ) {
              setOpen(true);
            } else {
              window.sessionStorage.setItem("ComponentData", "");
              setOpen(false);
            }
          }, [emissionCalculateData]);

          return (
            <>
              <Box sx={{ textAlign: "center", cursor: "pointer" }}>
                <Button id={row.original.id as string} onClick={(e: any) => handleOpen(e)}>
                  <div id={row.original.id as string}>Modify</div>
                </Button>
              </Box>
              {/* Modal */}
              {row?.original?.suppliesComponent?.length !== 0 &&
              !showSelectPending ? (
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        Item Number : {row.original.supplier_item_number}
                      </div>
                      <Button onClick={handleClose}>
                        <CloseIcon sx={{ cursor: "pointer" }} />
                      </Button>
                    </Typography>
                    <SelectPendingSideBar
                      data={row.original}
                      openPendingModal={handleopenPendingModal}
                    />

                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      sx={buttonStyle}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleCalculateEmission}
                      disabled={
                        finalArray.length !==
                          row?.original?.suppliesComponent?.length && true
                      }
                      sx={EmissionbuttonStyle}
                    >
                      Calculate Emission
                    </Button>
                  </Box>
                </Modal>
              ) : (
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    {/* Buttons */}
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Button onClick={handleClosePendingModal}>
                        <ArrowBackIcon sx={{ cursor: "pointer" }} />
                        Back
                      </Button>
                      <Button onClick={handleClose}>
                        <CloseIcon sx={{ cursor: "pointer" }} />
                      </Button>
                    </Typography>
                    {/* Headre */}
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div />
                    </Typography>
                    {/* Component Details */}
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Box>
                        <EmissionFactorsPopupDetails popupData={displayData} />
                      </Box>
                    </Typography>
                    {/* Filter emission Details */}
                    {manualSearch && (
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <div>Filter Emission Factor</div>

                        <MultiSelectDropdown
                          data={dropDownData}
                          manualSearchTableData={handleManualSearchData}
                        />
                      </Typography>
                    )}
                    {/* Tabel Component */}
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Select an emission factor that best suits the component
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <EmissionFactorsPopupTable
                          id={id}
                          rowData={row}
                          searchManual={handlesearchManually}
                          selectedData={selectedData}
                          closePendingModal={handleClosePendingModal}
                          manualSearchData={manualSearchData}
                          closeDirectModal={handleCloseDirectModal}
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Modal>
              )}
            </>
          );
        },
      },
    ];
  return { EMISSION_FACTORS_MATCHED_COLUMNS };
};

export default emissionFactorMatchedColumn;
