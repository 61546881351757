import React from "react";

export const COLORS = {
  base: "#22272F",
  black: "#020003",
  disabled: "#F6F6F8",
  green: "#BCDBCF",
  greenDarker: "#0F6F00",
  Mainblue: "#054254", // Romance: "#054254",
  iconFill: "#22272F",
  label: "#000302",
  lightGray: "#CDD1CE",
  lighterGray: "#EEEEEE",
  lightYellow: "#edcd3b59",
  darkGray: "#676767",
  neutralDarkGray: "#939795",
  extraLightGray: "#0000001f",
  menuItemColor: "#020003",
  menuPaperBoxShadow: "rgba(0, 0, 0, 0.04)",
  red: "#E70202",
  white: "#fff",
  faGray: "#fafafa",
  muiDisabled: "#00000042", 
  mintCream: "#F4F6F5",
  lightgreen:"#86E950",
  neutralGhWhite:"#F6F8FA",
  lightRed:"#C55050",
  lightGreen:"#44853C",
  lightgray:"#000302",  
  Romance: "#054254",
  White_Lilac: "#F6F8FA",
  Fuzzy_Brown: "#C55050",
  Hippie_Green: "#44853C",
  Twilight_Blue: "#EAFCFF",
};

export const DASHBOARDCOLORS = {
  electricity: "#7D90B3",
  water: "#2389DA",
  sewer: "#6C9568",
  naturalgas: "#946523",
  lpgas: "#65320F",
  refrigerants: "#67A49E",
  estimates: "#428ADC",
  bill: "#729566",
};

export const YEARLYDASHBOARDCOLORS = {
  2018: "#67A49E",
  2019: "#65320F",
  2020: "#6C9568",
  2021: "#2389DA",
  2022: "#946523",
  2023: "#7D90B3",
};

export const SCOPE_COLORS = {
  scope1: '#428ADC', 
  scope2: '#67A49E',
 
};

export const GRADIENTS = {
  LOGIN: "linear-gradient(206.31deg, #127403 0%, #1F460F 100%)",
};

export const FLEX_CENTER_POSITIONING_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const MEASURE_UNIT_DISPLAY_MAP = {
  "m^3": (
    <span>
      m<sup>3</sup>
    </span>
  ),
  co2: (
    <span>
      CO<sub>2</sub>
    </span>
  ),
} as const;

export const FLEX_ALIGN_CENTER_GAP_2 = {
  display: "flex",
  alignItems: "center",
  gap: 2,
  mx: 2,
};

export const FLEX_ALIGN_CENTER_GAP_2_SWITCH = {
  // position: "fixed",
  pt: "10px",
  display: "flex",
  alignItems: "flex-start",
  gap: 1,
  mx: 1,
};

export const SCOP_POPUP_FLEX_ALIGN_CENTER_GAP_2 = {
  display: "flex",
  alignItems: "center",
  gap: 2,
};

export const FILTERS_AND_ACTIONS_WRAPPER = {
  display: "flex",
  margin: "0 0 20px 0",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 3,
  flexWrap: "wrap",
};
