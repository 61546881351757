import { Box, Button, Modal } from "@mui/material";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
import React, { useState } from "react";

import { DocumentScopeDTO } from "api";

import {
  Avatar,
  DocumentStatusCount,
  EMPTY_CELL_VALUE,
  FLEX_CENTER_POSITIONING_STYLE,
  Tooltip,
  UtilityBillStatusKey,
} from "components";

import { TABLE_HEADERS } from "consts";

import {
  getDateFormat,
  getInitials,
  isNullOrUndefined,
  toUSNumber,
} from "utils";

import { DocumentExpandedRow } from "../scope-document-expanded-row";

export const SCOPE_DOCUMENTS_COLUMNS: MRT_ColumnDef<any>[] = [
  {
    header: "File name",
    accessorKey: "fileName",
    minSize: 250,
  },
  {
    header: TABLE_HEADERS.uploader,
    accessorKey: "uploader",
    Cell: ({ cell }) => {
      const value = cell.getValue() as DocumentScopeDTO["uploader"];

      if (!value) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Tooltip title={value.email}>
          <Box>
            <Avatar
              sx={{
                width: "32px",
                height: "32px",
                padding: 0,
                margin: "0 auto",
              }}
            >
              {getInitials(`${value.lastName} ${value.firstName}`)}
            </Avatar>
          </Box>
        </Tooltip>
      );
    },
    minSize: 60,
  },
  {
    header: "Upload date",
    accessorKey: "uploadDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return dayjs(value as string)
        .utc()
        .format(getDateFormat());
    },
  },
  {
    header: "Data entries",
    accessorKey: "supplies",
    Cell: ({ cell }) => {
      const value = cell.getValue() as DocumentScopeDTO["supplies"];

      if (!value) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Box sx={FLEX_CENTER_POSITIONING_STYLE}>{toUSNumber(value.length)}</Box>
      );
    },
    minSize: 60,
  },
  {
    header: "Data entries status",
    accessorKey: "statuses",
    Cell: ({ cell }) => {
      const statuses = cell.getValue() as DocumentScopeDTO["statuses"];

      const arrStatues = [];
      if (statuses!.DECLARED) {
        arrStatues.push({ key: "DECLARED", value: statuses!.DECLARED });
      }
      if (statuses!.MATCHED) {
        arrStatues.push({ key: "MATCHED", value: statuses!.MATCHED });
      }
      if (statuses!.APPROVED) {
        arrStatues.push({ key: "APPROVED", value: statuses!.APPROVED });
      }

      if (!statuses || !Object.entries(statuses).length) {
        return EMPTY_CELL_VALUE;
      }

      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {arrStatues.map((t) => (
            <DocumentStatusCount
              key={`${t.key}_${t.value}`}
              keyName={t.key as UtilityBillStatusKey}
              value={t.value}
            />
          ))}
        </Box>
      );
    },
    minSize: 70,
  },
  {
    header: "",
    accessorKey: "co2Emission",
    Cell: function Cell({ row }) {
      const [open, setOpen] = useState(false);
      const [id, setId] = useState("");
      const handleClose = () => setOpen(false);

      const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        // overflow: "auto",
        p: 7,
      };

      const handleViewClick = async (e: any) => {
        setId(e.target.id);
        setOpen(true);
      };

      return (
        <>
          <Box sx={{ textAlign: "right", cursor: "pointer" }}>
            <Button id={row.original.id as string} onClick={(e: any) => handleViewClick(e)}>
              <div id={row.original.id as string}>View</div>
            </Button>
          </Box>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <DocumentExpandedRow id={id} />
              </Box>
            </Modal>
          </div>
        </>
      );
    },
    minSize: 30,
  },
];
