import React, { HTMLAttributes, useEffect, useRef } from "react";

interface CustomDivProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  "data-id": string;
}

const DivElement: React.FC<CustomDivProps> = (props) => <div {...props} />;

const CookiesPolicy = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initialized = useRef(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      addScript();
    }
  }, []);

  const addScript = () => {
    const myobj = document.getElementsByClassName("addScript");
    for (let i = 0; i < myobj.length; i += 1) {
      myobj[i].remove();
    }
    if (document.createElement("script")) {
      const script = document.createElement("script");
      script.setAttribute("class", "addScript");
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  };

  return (
    <DivElement
      name="termly-embed"
      data-id="e4bef87e-a5d9-4926-992f-dadf7e3e7232"
    />
  );
};

export default CookiesPolicy;
