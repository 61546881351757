import { useCallback, useMemo } from "react";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import type { Id, Ids } from "components";

export type SelectedRows = Record<string, boolean>;
export const DEFAULT_SELECTED_ROWS_STATE: SelectedRows = {};

export const createSelectedRowsRecoil = (key: string) => {
  const selectedRowsState = atom<SelectedRows>({
    key: `selected${key}`,
    default: DEFAULT_SELECTED_ROWS_STATE,
  });

  const selectedRowsCount = selector<number>({
    key: `selected${key}Count`,
    get: ({ get }) =>
      Object.values(get(selectedRowsState)).filter((v) => v).length,
  });

  const useSetSelectedRow = () => {
    const [selected, setSelected] = useRecoilState(selectedRowsState);

    return useCallback(
      (id: Id) => setSelected({ ...selected, [id]: !selected[id] }),
      [selected, setSelected],
    );
  };

  const useSelectedRows = () => {
    const selected = useRecoilValue(selectedRowsState);
    return useMemo(
      () =>
        Object.entries(selected)
          .filter(([, isSelected]) => isSelected)
          .map(([id]) => id),
      [selected],
    );
  };

  const useSetFromIntermediateSelection = () => {
    const [selected, setSelected] = useRecoilState(selectedRowsState);

    return useCallback(
      (ids: Ids, isIntermediate: boolean) =>
        isIntermediate
          ? setSelected({
              ...selected,
              ...ids.reduce((acc, id) => {
                acc[id] = isIntermediate;

                return acc;
              }, {} as Record<string, boolean>),
            })
          : setSelected(DEFAULT_SELECTED_ROWS_STATE),
      [selected, setSelected],
    );
  };

  const useSetDefaultSelection = () => {
    const setUserState = useSetRecoilState(selectedRowsState);

    return useCallback(
      () => setUserState(DEFAULT_SELECTED_ROWS_STATE),
      [setUserState],
    );
  };

  const useGetIsIntermediateSelection = () => {
    const selected = useRecoilValue(selectedRowsState);
    const selectedCount = useRecoilValue(selectedRowsCount);

    return useCallback(
      (ids: Ids) => !ids.every((id) => selected[id]) && selectedCount !== 0,
      [selected, selectedCount],
    );
  };

  return {
    selectedRowsState,
    selectedRowsCount,
    useSetSelectedRow,
    useSelectedRows,
    useSetFromIntermediateSelection,
    useSetDefaultSelection,
    useGetIsIntermediateSelection,
  };
};
