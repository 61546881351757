import { Drawer } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

interface DrawerViewProps {
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const DrawerView: FunctionComponent<DrawerViewProps> = ({
  children,
  isOpen,
  onClose,
}) => (
  <Drawer
    sx={{
      "& .MuiDrawer-paper": {
        width: "100%",
      },
      "&.MuiDrawer-modal": {
        zIndex: 1300,
      },
    }}
    anchor="right"
    open={isOpen}
    onClose={onClose}
  >
    {children}
  </Drawer>
);
