import { CollectorDTO, CreateBillInDocumentDTO } from "api";

import { isNullOrUndefined } from "utils";

// eslint-disable-next-line
import {
  UtilityBillFormData,
  compareCollector,
} from "../../../document-summary-v2";

function extractValue(inputString: string) {
  const trimmedString = inputString.trim();
  const firstPart = trimmedString.split(" ")[0];
  return firstPart;
}

export const getCreateBillInDocumentDTO = (
  formData: UtilityBillFormData,
  collectors?: CollectorDTO[],
): CreateBillInDocumentDTO => ({
  collectorId:
    collectors?.find((c) => compareCollector(formData.collector, c))?.id ||
    null,
  endDate: formData.endDate!.format("YYYY-MM-DDT00:00:00[Z]"),
  startDate: formData.startDate!.format("YYYY-MM-DDT00:00:00[Z]"),
  measuredUnit: extractValue(formData.measuredUnit),
  measuredValue: Number(formData.measuredValue.replaceAll(",", "")),
  amount:
    formData.amount === "" ? 0 : Number(formData.amount.replaceAll(",", "")),
  currency: formData.currency,
  subtype:
    isNullOrUndefined(formData.subtype) || formData.subtype === ""
      ? null
      : formData.subtype,
  milesTraveled:
    isNullOrUndefined(formData.traveledMiles) || formData.traveledMiles === ""
      ? null
      : Number(formData.traveledMiles.replaceAll(",", "")),
});
