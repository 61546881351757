export const USER_PREFERENCE_PAGE = {
  ABSOLUTE_PATH: "/settingsv2",
  PATH: "",
  TITLE: "Preferences",
  ICON: () => null,
};

export const USER_CONSENT_MANAGAMENT_PAGE = {
  ABSOLUTE_PATH: "/settings/consentmanagament",
  PATH: "/settings/user-preference/consentmanagament",
  TITLE: "Consent Managament",
  ICON: () => null,
};
export const USER_PREFERENCES_PAGE = {
  ABSOLUTE_PATH: "/settings/",
  PATH: "",
  TITLE: "User Preference",
};
