import { Box } from "@mui/material";
import type { CellContext } from "@tanstack/table-core";
import dayjs from "dayjs";
import React, { FunctionComponent } from "react";

import { Tooltip } from "components";

import { ReactChildren } from "types";

import { getDateFormat, isNullOrUndefined, toUSNumber } from "utils";

import { Label14SemiBold } from "../typography";

export const CellValueWrapper: FunctionComponent<ReactChildren> = ({
  children,
}) => (
  <Label14SemiBold noWrap component="div">
    {children}
  </Label14SemiBold>
);

export const EMPTY_CELL_VALUE = "-";

export const DefaultCell = <TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) => {
  const value = getValue();

  if (isNullOrUndefined(value)) {
    return EMPTY_CELL_VALUE;
  }

  return (
    <Tooltip title={value as string}>
      <Box>{value as string}</Box>
    </Tooltip>
  );
};

export const ESGDefaultCell = ({ cell }: any) => {
  if (isNullOrUndefined(cell)) {
    return EMPTY_CELL_VALUE;
  }

  return (
    <Tooltip title={cell as string}>
      <Box>{cell as string}</Box>
    </Tooltip>
  );
};

export const USNumberCell = <TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) => {
  const value = getValue();

  if (isNullOrUndefined(value)) {
    return EMPTY_CELL_VALUE;
  }

  return toUSNumber(value as string);
};

export const DateCell = <TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) => {
  const value = getValue();

  if (isNullOrUndefined(value) || value === 0) {
    return EMPTY_CELL_VALUE;
  }

  return dayjs(value as string)
    .utc()
    .format(getDateFormat());
};
