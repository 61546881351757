import { Row } from "@tanstack/react-table";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { DocumentDTO } from "api";

import { getFromLocalStorage } from "utils";

// eslint-disable-next-line
import { UtilityBillsPage } from "./page";
// import {UtilityBillsPages} from "./page";
import {
  UTILITY_BILL_COMMENTS_VIEW,
  UTILITY_BILL_FORM_VIEW,
  UTILITY_BILL_PAGE,
  UtilityBill,
  UtilityBillCommentsView,
  UtilityBillFormView,
  UtilityBillLoader,
} from "./utility-bill";
import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "./utility-bill/utility-bill-allDataEntries-view/consts";

// eslint-disable-next-line
import { DocumentExpandedRow } from "../document-upload/components";

export const UtilityBillsRouter = () => (
  <Routes>
    <Route index element={<UtilityBillsPage />} />
    {/* <Route path="document/summary" element={<UtilityBillsPages />}/> */}
    <Route path={UTILITY_BILL_PAGE.PATH} element={<UtilityBill />}>
      <Route
        path={UTILITY_BILL_FORM_VIEW.PATH}
        element={<UtilityBillLoader Component={UtilityBillFormView} />}
      />
      <Route
        path={UTILITY_BILL_COMMENTS_VIEW.PATH}
        element={<UtilityBillLoader Component={UtilityBillCommentsView} />}
      />
      <Route
        path={UTILITY_BILL_ALL_DATAENTRIES_VIEW.PATH}
        element={
          <UtilityBillLoader
            // eslint-disable-next-line
            Component={DocumentComponent}
          />
        }
      />
    </Route>
  </Routes>
);
const DocumentComponent = () => {
  const rowData: Row<DocumentDTO> = getFromLocalStorage("dataEntries")
    ? JSON.parse(getFromLocalStorage("dataEntries") || "")
    : [];
  return <DocumentExpandedRow row={rowData} />;
};
