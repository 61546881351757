import { Box, CircularProgress } from "@mui/material";
import React, { FunctionComponent } from "react";

import { MuiSx, ReactChildren } from "types";

type LoadingProps = ReactChildren & MuiSx;

export const Loading: FunctionComponent<LoadingProps> = ({
  children,
  sx = {
    minWidth: 50,
    minHeight: 50,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}) => <Box sx={sx}>{children || <CircularProgress />}</Box>;
