import React, { FunctionComponent } from "react";

import { ReactChildren } from "types";

import {
  AUTH0_AUTH_ROUTES,
  AUTH0_SIGN_IN_BUTTON_TITLE,
  AUTH0_SIGN_IN_SUBTITLE,
  Auth0Provider,
} from "./auth0-provider";
import {
  OKTA_AUTH_ROUTES,
  OKTA_SIGN_IN_BUTTON_TITLE,
  OKTA_SIGN_IN_SUBTITLE,
  OktaProvider,
} from "./okta-provider";

export const AUTH_PROVIDERS = {
  OKTA: "okta",
  AUTH0: "auth0",
  NULL: "null",
};

export const AUTH_PROVIDER =
  process.env.REACT_APP_AUTH_PROVIDER || AUTH_PROVIDERS.NULL;

export const AuthProvider: FunctionComponent<ReactChildren> = ({
  children,
}) => {
  if (AUTH_PROVIDER === AUTH_PROVIDERS.OKTA) {
    return <OktaProvider>{children}</OktaProvider>;
  }

  if (AUTH_PROVIDER === AUTH_PROVIDERS.AUTH0) {
    return <Auth0Provider>{children}</Auth0Provider>;
  }

  return <>{children}</>;
};

const authRoutesMap = {
  [AUTH_PROVIDERS.AUTH0]: AUTH0_AUTH_ROUTES,
  [AUTH_PROVIDERS.OKTA]: OKTA_AUTH_ROUTES,
  [AUTH_PROVIDERS.NULL]: [],
};

const signInButtonMap = {
  [AUTH_PROVIDERS.AUTH0]: AUTH0_SIGN_IN_BUTTON_TITLE,
  [AUTH_PROVIDERS.OKTA]: OKTA_SIGN_IN_BUTTON_TITLE,
  [AUTH_PROVIDERS.NULL]: "Sign in",
};

const signInSubtitleMap = {
  [AUTH_PROVIDERS.AUTH0]: AUTH0_SIGN_IN_SUBTITLE,
  [AUTH_PROVIDERS.OKTA]: OKTA_SIGN_IN_SUBTITLE,
  [AUTH_PROVIDERS.NULL]: "No sign in provider",
};

export const AUTH_ROUTES = authRoutesMap[AUTH_PROVIDER];

export const SIGN_IN_BUTTON_TITLE = signInButtonMap[AUTH_PROVIDER];

export const SIGN_IN_SUBTITLE = signInSubtitleMap[AUTH_PROVIDER];
