import React, { FunctionComponent } from "react";

import { UTILITY_BILL_STATUS_TO_ICON, UtilityBillStatusKey } from "./consts";

export const UtilityBillStatus: FunctionComponent<{
  status?: UtilityBillStatusKey | null;
}> = ({ status }) => {
  if (!status) {
    return null;
  }

  if (!UTILITY_BILL_STATUS_TO_ICON[status]) {
    return <>{status}</>;
  }

  const Icon = UTILITY_BILL_STATUS_TO_ICON[status];

  return <Icon />;
};
