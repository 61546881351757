import { useCallback, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_KEYS } from "consts";

import { HookWithQueryParams } from "types";

import { getParsedQueryParams } from "utils";

const getInitialSearchValue = (
  withQueryParams: boolean,
  searchParams: URLSearchParams,
) => (withQueryParams ? searchParams.get(QUERY_PARAMS_KEYS.SEARCH) || "" : "");

export const useSearch = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: HookWithQueryParams = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>(() =>
    getInitialSearchValue(withQueryParams, searchParams),
  );

  const onSearchChange = useCallback(
    (value: string) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setSearch(value);

      if (withQueryParams) {
        const currentSearchParams = withQueryParams
          ? getParsedQueryParams(searchParams)
          : {};

        if (!value) {
          delete currentSearchParams[QUERY_PARAMS_KEYS.SEARCH];
        } else {
          currentSearchParams[QUERY_PARAMS_KEYS.SEARCH] = value;
        }

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(currentSearchParams)
              : currentSearchParams),
          }),
        );
      }
    },
    [
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  const onSearchClear = useCallback(() => {
    if (updateExternalStates) {
      updateExternalStates();
    }

    setSearch("");

    if (withQueryParams) {
      const currentSearchParams = withQueryParams
        ? getParsedQueryParams(searchParams)
        : {};

      delete currentSearchParams[QUERY_PARAMS_KEYS.SEARCH];

      setSearchParams(
        createSearchParams({
          ...(getQueryParamsWithExternalChanges
            ? getQueryParamsWithExternalChanges(currentSearchParams)
            : currentSearchParams),
        }),
      );
    }
  }, [
    searchParams,
    setSearchParams,
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
  ]);

  return {
    search,
    onSearchChange,
    onSearchClear,
  };
};
