import { Box, Button, Grid } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, memo, useEffect, useState } from "react";

import {
  UserDTO,
  deleteUser,
  getAuthenticatedUser,
  reActivateUser,
  updateUserRole,
} from "api";

import { TrashIcon } from "assets";

import {
  AutocompleteField,
  Avatar,
  Label14SemiBold,
  MultiSelectControlledOption,
  MultiselectControlledField,
} from "components";

import { useNotify, useSitesMultiselect } from "hooks";

import { QUERY_KEYS } from "consts";

import {
  USER_ROLES_NAMES,
  USER_ROLES_NAME_TO_VALUE,
  USER_ROLE_COLOR,
  UserRole,
  getUserRole,
  getUserRoleObject,
} from "recoils";

import { getInitials } from "utils";

import { ROLE_SELECT_OPTIONS } from "./consts";

export const User: FunctionComponent<{
  userData: UserDTO;
  getFilter: UserRole | null;
}> = memo(({ userData, getFilter }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteUsers } = useMutation(deleteUser);
  const { mutateAsync: reactivateUsers } = useMutation(reActivateUser);
  const { mutateAsync: updateRole } = useMutation(updateUserRole);
  const notify = useNotify();
  const [roleSelectValue, setRoleSelectValue] = useState<null | string>(null);
  const [loggedUser, setLoggedUser] = useState<UserDTO>();
  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useSitesMultiselect({ withQueryParams: false });

  const onDeleteUser = (user: UserDTO) => {
    deleteUsers(user.id, {
      onError: () =>
        notify.error(
          `Some error has happen while deleting user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully deleted ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  const onReActivateUser = (user: UserDTO) => {
    reactivateUsers(user.id, {
      onError: () =>
        notify.error(`Some error has happen while re-activating the user!`),
      onSuccess: () => {
        notify.success(`User successfully Enabled!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  useEffect(() => {
    getAuthenticatedUser().then((user) => {
      setLoggedUser(user);
    });
  }, []);

  const disableDelete = () => {
    if (loggedUser !== undefined) {
      if (userData.id === loggedUser.id) return true;
    }
    return false;
  };

  const onUpdateUser = (user: UserDTO) => {
    const body = {
      admin: user.admin,
      auditor: user.auditor,
      id: user.id,
      siteIds: user.sites,
    };

    updateRole(body, {
      onError: () =>
        notify.error(
          `Some error has happen while updating user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully updated ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  return (
    <>
      <Grid item xs={!(getFilter === "enabled") ? 4 : 8}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Grid xs={6}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {" "}
              {userData.firstName} {userData.lastName}
            </span>
          </Grid>
          <Grid xs={2}>
            <Avatar
              sx={{
                bgcolor:
                  USER_ROLE_COLOR[
                    getUserRole(userData.admin, userData.auditor)
                  ],
                alignItems: "center",
              }}
            >
              {getInitials(`${userData.firstName} ${userData.lastName}`)}
            </Avatar>
          </Grid>
          <Grid xs={4}>
            <Label14SemiBold>{userData.email}</Label14SemiBold>
          </Grid>
        </Box>
      </Grid>
      {userData.enabled && (
        <>
          <Grid item xs={4} style={{ width: "65%", paddingLeft: "180px" }}>
            <AutocompleteField
              textFieldProps={{}}
              autocompleteProps={{
                value:
                  roleSelectValue ||
                  USER_ROLES_NAMES[
                    getUserRole(userData.admin, userData.auditor)
                  ],
                onChange: (_, role) => {
                  if (role) {
                    onUpdateUser({
                      ...userData,
                      ...getUserRoleObject(
                        USER_ROLES_NAME_TO_VALUE[role as string] as UserRole,
                      ),
                    });
                  }

                  setRoleSelectValue(role as string);
                },
                options: ROLE_SELECT_OPTIONS,
              }}
            />
          </Grid>

          <Grid item xs={4} style={{ width: "65%", display: "flex" }}>
            <MultiselectControlledField
              label=""
              disabled={isSitesLoading}
              selectedValues={
                selectedSites.length > 0
                  ? selectedSites
                  : sitesOptions.filter((option) =>
                      userData?.sites?.includes(option.value as number),
                    )
              }
              setSelectedValues={(data) => {
                if (data) {
                  onUpdateUser({
                    ...userData,
                    sites: (data as MultiSelectControlledOption[]).map(
                      (option) => option.value,
                    ) as number[],
                  });
                }
                setSelectedSites(data);
              }}
              options={sitesOptions}
            />
            <Button
              sx={{ padding: 0 }}
              onClick={() => onDeleteUser(userData)}
              disabled={disableDelete()}
            >
              <TrashIcon />
            </Button>
          </Grid>
        </>
      )}
      {!userData.enabled && (
        <Box>
          <Button
            sx={{
              marginLeft: "6px",
              marginTop: "20px",
              backgroundColor: "lightgray",
            }}
            onClick={() => onReActivateUser(userData)}
          >
            Re-Activate
          </Button>
        </Box>
      )}
    </>
  );
});
