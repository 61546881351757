/* eslint-disable */
import React, { createContext, useState } from "react";

export const updateFileCountContext = createContext({
  uploadedCount: 0 as any,
  totalCount: 0 as any,
  updateUploadedCount: (value: any) => {},
  updateTotalCount: (value: any) => {},
});

function UpdateFileCountContext({ children }: any) {
  const [uploadedCount, setUploadedCount] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();

  const updateUploadedCount = (value: any) => {
    setUploadedCount(value);
  };

  const updateTotalCount = (value: any) => {
    setTotalCount(value);
  };

  return (
    <updateFileCountContext.Provider
      value={{
        uploadedCount,
        totalCount,
        updateUploadedCount,
        updateTotalCount,
      }}
    >
      {children}
    </updateFileCountContext.Provider>
  );
}

export default UpdateFileCountContext;
