import { Stomp } from "@stomp/stompjs";
import { useCallback, useEffect, useMemo } from "react";
import SockJS from "sockjs-client";

import { httpClient } from "../utils";

export const useWebSocketClient = (url?: string) => {
  const accessToken = httpClient.defaults.headers.common.Authorization;
  if (!accessToken) {
    throw Error("Access token should be defined. Cannot connect to websocket!");
  }
  const localUrl = `${
    url || `${httpClient.defaults.baseURL}/web-socket?`
  }access_token=${String(accessToken).replace("Bearer ", "")}`;

  const socketFactory = useCallback(() => new SockJS(localUrl), [localUrl]);
  const wsClient = useMemo(() => {
    const client = Stomp.over(socketFactory);
    client.debug = () => null;

    return client;
  }, [socketFactory]);

  useEffect(() => {
    wsClient.connect({}, () => {});
    return () => {
      wsClient.disconnect();
    };
  }, [wsClient]);

  return wsClient;
};
