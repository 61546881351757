import { TablePagination } from "@mui/material";
import React, { FunctionComponent } from "react";

import { ChevronDownIcon } from "assets";

import { COLORS } from "components/consts";

import { Pages } from "./pages";
import { PaginationProps } from "./types";

import { ROWS_PER_PAGE_OPTIONS } from "../react-table";

export const Pagination: FunctionComponent<PaginationProps> = ({
  page,
  rowsCount,
  rowsPerPage,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  onPageChange,
  onRowsPerPageChange,
  numberOfPages = Math.floor((rowsCount ? rowsCount - 1 : 1) / rowsPerPage) +
    1 || 0,
}) => {
  const pages = () => (
    <Pages
      onPageChange={onPageChange}
      page={page}
      numberOfPages={numberOfPages}
    />
  );

  return (
    <TablePagination
      sx={{
        ".MuiTablePagination-spacer": { display: "none" },
        ".MuiTablePagination-toolbar": { padding: 0 },
      }}
      component="div"
      SelectProps={{
        variant: "outlined",
        IconComponent: ChevronDownIcon,
        size: "small",
        disabled: !rowsCount,
        sx: {
          width: "77px",
          height: "40px",
          borderRadius: "8px",
          ".MuiSelect-icon": {
            top: "50%",
            transform: "translateY(-50%)",
            right: "16px",
            opacity: rowsCount ? 1 : 0.4,
            path: {
              fill: COLORS.iconFill,
            },
          },
          ".MuiSelect-iconOpen": {
            transform: "translateY(-50%) rotate(180deg)",
          },
          ".MuiTablePagination-select": {
            textAlign: "left",
            textAlignLast: "left",
          },
        },
      }}
      count={rowsCount}
      onPageChange={() => {}}
      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onRowsPerPageChange(parseInt(event.target.value, 10));
      }}
      page={page - 1} // must start from 0, initial page is 1
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      ActionsComponent={pages}
    />
  );
};
