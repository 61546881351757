import MockAdapter from "axios-mock-adapter";

import { USERS_API_PATHS } from "api";

export const initUsersMock = (clientMock: MockAdapter) => {
  clientMock.onGet(USERS_API_PATHS.AUTHENTICATED_USER).reply(200, {
    active: true,
    email: "test@gmail.com",
    firstName: "Test",
    id: 0,
    lastName: "Test",
    oktaIdentifier: "test@gmail.com",
    admin: true,
    auditor: false,
  });
};
