/* eslint-disable */
import { Auth0Provider as AuthProvider } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";

import { ReactChildren } from "types";

export const AUTH0_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN || "",
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "",
  audience: process.env.REACT_APP_BASE_URL || "",
  redirectUri: window.location.origin,
};

export const Auth0Provider: FunctionComponent<ReactChildren> = ({
  children,
}) => {
  // Get the current URL's query string parameters
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  // Parse the query string and extract individual parameter-value pairs
  const invitation = urlParams.get("invitation");
  // const orgId = urlParams.get("organization");

  return (
    <AuthProvider
      domain={AUTH0_CONFIG.domain}
      clientId={AUTH0_CONFIG.clientId}
      redirectUri={AUTH0_CONFIG.redirectUri}
      invitation={invitation || undefined}
      organization={process.env.REACT_APP_AUTH_ORG_ID}
      scope="offline_access"
      audience={AUTH0_CONFIG.audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </AuthProvider>
  );
};

export const AUTH0_AUTH_ROUTES = [];

export const AUTH0_SIGN_IN_SUBTITLE =
  "Application allows to sign in with Auth0 account";

export const AUTH0_SIGN_IN_BUTTON_TITLE = "Sign in with Auth0 SSO";
