import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { MultiSelectControlledOption } from "components";

import { getParsedQueryParams } from "utils";

import { OptionalMultiselectProps } from "./types";

export const useMultiselectWithFetch = <T>({
  getQueryParamsWithExternalChanges,
  updateExternalStates,
  withQueryParams = true,
  useGetData,
  getOptionsFromData,
  queryKey,
}: Omit<OptionalMultiselectProps, "withEmptyOption"> & {
  useGetData: () => UseQueryResult<T>;
  getOptionsFromData: (data?: T) => MultiSelectControlledOption[];
  queryKey: string;
}) => {
  const { data, isLoading } = useGetData();

  const [selectedOptions, setSelectedOptionsState] = useState<
    MultiSelectControlledOption[]
  >([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const isInitialQueryReadRef = useRef<boolean>(true);

  const options = useMemo(
    () => getOptionsFromData(data),
    [data, getOptionsFromData],
  );

  useEffect(() => {
    if (data && isInitialQueryReadRef.current && withQueryParams) {
      const selectedOptionsFromSearchParams = searchParams.getAll(queryKey);

      if (selectedOptionsFromSearchParams.length) {
        setSelectedOptionsState(
          options.filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          ),
        );
      }

      isInitialQueryReadRef.current = false;
    }
  }, [data, searchParams, options, withQueryParams, queryKey]);

  const setSelectedOptions = (
    newSelectedOptions: MultiSelectControlledOption[],
  ) => {
    if (withQueryParams) {
      const queryParams = getParsedQueryParams(searchParams);

      setSearchParams(
        createSearchParams({
          ...(getQueryParamsWithExternalChanges
            ? getQueryParamsWithExternalChanges(queryParams)
            : queryParams),
          [queryKey]: newSelectedOptions.map(({ value }) => `${value}`),
        }),
      );
    }

    if (updateExternalStates) {
      updateExternalStates();
    }

    setSelectedOptionsState(newSelectedOptions);
  };

  return {
    isLoading,
    setSelectedOptions,
    selectedOptions,
    options,
    selectedOptionValues:
      selectedOptions.length === options.length
        ? []
        : selectedOptions.map((option) => option.value),
  };
};
