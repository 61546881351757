/* eslint-disable */
import { Box, SxProps, Tabs as TabsMui, Theme } from "@mui/material";
import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import {  Outlet } from "react-router-dom";

import { H5Bold } from "components/typography";
import { useRouteMatch } from "./hooks";
import { COLORS } from "components/consts";

export const SettingTabs: FunctionComponent<{
  tabs: {
    icon?: ReactElement;
    label: ReactNode;
    to: string;
    pattern?: string;
    disable?: boolean;
  }[];
  sx?: SxProps<Theme>;
}> = ({ tabs, sx }) => {
  const routeMatch = useRouteMatch(tabs.map((t) => t.pattern || t.to));
  const currentTab:any = routeMatch?.pathnameBase;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: COLORS.Romance}}>
        <TabsMui value={currentTab} variant="fullWidth">
          {tabs
            .filter((tab) => !tab.disable)
            .map((tab) => (
              <H5Bold sx={{ml: 6}}>
                {tab.label}
              </H5Bold>
            ))}
        </TabsMui>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

