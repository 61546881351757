import { useCallback, useEffect, useState } from "react";

import { SiteDTO, getSitesByCountry } from "api";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";

import { useSites } from "../api";

export const useFilteredSitesMultiselect = ({
  selectedCountry,
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: any = {}) => {
  const [filteredSiteOptions, setFilteredSiteOptions] = useState<any[]>([]);

  const getOptionsFromData = useCallback((sitesData?: SiteDTO[]) => {
    const sitesAscendingOrder = sortBy({
      array: sitesData || [],
      key: "name",
    });

    return sitesAscendingOrder.map((site) => ({
      label: site.name ? site.name : `Site: ${site.id}`,
      value: site.id,
    }));
  }, []);

  const {
    isLoading: isSitesLoading,
    setSelectedOptions: setSelectedSites,
    selectedOptions: selectedSites,
    options: sitesOptions,
    selectedOptionValues: siteIds,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useSites,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.SITE_IDS,
  });

  useEffect(() => {
    const fetchSites = async () => {
      if (selectedCountry.length > 0) {
        let data: string[] = [];

        if (Array.isArray(selectedCountry)) {
          const sitePromises = selectedCountry.map((country) =>
            getSitesByCountry(country.value),
          );
          const results = await Promise.all(sitePromises);
          data = results.flat();
        } else {
          data = await getSitesByCountry(selectedCountry);
        }

        const filtered = sitesOptions.filter((siteOption) =>
          data.includes(siteOption.label),
        );

        setFilteredSiteOptions(filtered);
      } else {
        setFilteredSiteOptions(sitesOptions);
      }
    };

    fetchSites();
  }, [selectedCountry, sitesOptions]);

  return {
    isSitesLoading,
    setSelectedSites,
    sitesOptions: selectedCountry ? filteredSiteOptions : sitesOptions,
    selectedSites,
    siteIds: siteIds as number[],
  };
};
