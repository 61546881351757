import MockAdapter from "axios-mock-adapter";

import {
  UTILITY_BILLS_API_PATHS,
  UtilityBillByIdDTO,
  UtilityBillDTO,
} from "api";

import { getByIdFromList } from "./utils";

const randomUuid = [
  "efd0de8e-c824-43cf-80ff-49c45c751aca",
  "023133eb-8529-4c07-80f0-983f36ba925f",
  "f2420f22-3c11-4179-b7da-de576c494ce9",
  "64aacac9-2c8d-487d-a02d-045e96537f53",
  "5b05c4c7-1aa4-4f95-96d2-c11b59539822",
  "5af4d15a-af64-4d55-b419-31adffdb1c95",
  "af19b8f5-b2fe-42be-82f8-f9dc94bbc5d6",
  "cc67c8c8-2ef9-47bc-bdd8-42c880c1a646",
  "868a886e-d5de-485c-8126-b01f2af05c07",
  "8e41fc9c-315b-41fb-8a61-00e626b4c952",
  "9670fc15-6ca1-4ba3-9da0-cbee2344d629",
  "b6fdc718-623f-4dfa-90c3-2c5a5972729f",
  "5207d0c6-70aa-45b4-a258-0b252f7bb216",
  "8f048d88-cf20-4bc3-a14e-a8ed05f1b488",
  "dcfb3b80-00ce-40ee-a933-0cda9fe3215e",
];

const UTILITY_BILLS_PARTIAL = [
  {
    id: "c5fd202f-09ac-469d-a82d-7c74f9a565a9",
    filename: "NGas",
    addedBy: null,
    provider: "UGI Utilities, Inc.",

    amount: 304.36,
    status: "PARSED",
    type: "Gas",
  },
  {
    id: randomUuid[1],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 64871,
    status: "PARSED",
  },
  {
    id: randomUuid[2],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 7430,
    status: "PARSED",
  },
  {
    id: randomUuid[3],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 38185,
    status: "PARSED",
  },
  {
    id: randomUuid[4],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 47224,
    status: "PARSED",
  },
  {
    id: randomUuid[5],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 94168,
    status: "PARSED",
  },
  {
    id: randomUuid[6],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 41568,
    status: "PARSED",
  },
  {
    id: randomUuid[7],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 25080,
    status: "PARSED",
  },
  {
    id: randomUuid[8],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 95831,
    status: "PARSED",
  },
  {
    id: randomUuid[9],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 30340,
    status: "PARSED",
  },
  {
    id: randomUuid[10],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 89326,
    status: "PARSED",
  },
  {
    id: randomUuid[11],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 62754,
    status: "PARSED",
  },
  {
    id: randomUuid[12],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 35976,
    status: "PARSED",
  },
  {
    id: randomUuid[13],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 6449,
    status: "PARSED",
  },
  {
    id: randomUuid[14],
    filename: "2-00670997.pdf",
    addedBy: "AC",
    provider: "GAS Company",

    type: "Gas",
    amount: 82972,
    status: "PARSED",
  },
];

const UTILITY_BILLS: UtilityBillDTO[] = UTILITY_BILLS_PARTIAL.map((bill) => ({
  ...bill,
  co2Emission: 5678,
  explanation: null,
  accountNumber: "5380",
  currency: "USD",
  measuredUnit: "kWh",
  measuredValue: 34567,
  siteName: "site 1",
  createDate: "2022-11-23T12:17:06+00:00",
  endDate: "2022-11-23T12:17:06+00:00",
  updateDate: "2022-11-23T12:17:06+00:00",
  period: null,
  startDate: null,
  errorMessage: null,
  numberOfComments: 0,
  manuallyApproved: false,
  anomalies: [],
  subtype: null,
  milesTraveled: null,
  bboxes: null,
  dimensions: null,
  opts:{bboxes: null}
}));

export const initUtilityBillsMock = (clientMock: MockAdapter) => {
  clientMock
    .onGet(new RegExp(`${UTILITY_BILLS_API_PATHS.UTILITY_BILLS}\\?.*`))
    .reply(200, {
      bills: UTILITY_BILLS,
      count: UTILITY_BILLS.length,
    });

  clientMock
    .onGet(new RegExp(`${UTILITY_BILLS_API_PATHS.UTILITY_BILLS}\\/.*`))
    .reply(
      getByIdFromList<UtilityBillByIdDTO>(
        UTILITY_BILLS.map((b) => ({
          ...b,
          downloadUrl: "https://pdf.com",
          invoiceNumber: "123456",
          issueDate: "2022-11-23T12:17:06+00:00",
          siteId: 1,
          usageDifferenceToPreviousBill: null,
          collectorId: 6,
          partialDocumentBill: false,
        })),
      ),
    );
};
