import { Box, Button } from "@mui/material";
import React, { FunctionComponent } from "react";

import { StatusApprovedEmissionIcon } from "assets";

import { Dialog } from "./dialog";

export const ApproveDialog: FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  onAppoveClick: () => void;
  title: string;
  selectedCount?: number;
}> = ({ isOpen, onCancel, onAppoveClick, selectedCount, title }) => (
  <Dialog
    title={title}
    isOpen={isOpen}
    onClose={onCancel}
    actions={
      <Box>
        <Button variant="outlined" color="warning" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          color="success"
          startIcon={<StatusApprovedEmissionIcon />}
          onClick={onAppoveClick}
        >
          Approve
        </Button>
      </Box>
    }
  >
    {/* <Box
        sx={{
          backgroundColor: "success.light",
          p: 2,
          borderRadius: 2,
          mb: 3,
          color: "success.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box component="span" sx={{ ...FLEX_CENTER_POSITIONING_STYLE, mr: 1 }}>
          <Comment2AlertIcon />
        </Box>
         This action cannot be undone.
      </Box> */}
    {selectedCount} emission factor entries will be approved.
    <br /> Are you sure you want to appove them?
  </Dialog>
);
