import {
  TextField as TextFieldMui,
  TextFieldProps as TextFieldMuiProps,
} from "@mui/material";
import React, { FunctionComponent, forwardRef } from "react";

import { ColorType } from "types";

import { InputFieldWrapper } from "./input-field-wrapper";

import { COLORS } from "../consts";

export type TextFieldProps = TextFieldMuiProps & { message?: any };

export interface TextFieldColorState {
  color?: ColorType;
  message?: any;
}

export const TextField: FunctionComponent<TextFieldProps> = forwardRef(
  ({ label, message, color, style, ...props }, ref) => (
    <InputFieldWrapper label={label} color={color} message={message}>
      <TextFieldMui
        hiddenLabel
        size="small"
        sx={{
          ".MuiInputBase-root.Mui-disabled": {
            backgroundColor: COLORS.disabled,
          },
          ".MuiInputBase-input": {
            ...style,
          },
        }}
        color={color}
        focused={!!color}
        {...props}
        ref={ref}
      />
    </InputFieldWrapper>
  ),
);
