import { AxiosResponse } from "axios";

export const downloadFile = (
  downloadFn: () => Promise<AxiosResponse>,
  fileName: string,
) =>
  downloadFn()
    .then((response) => response.data)
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const id = "download";

      link.id = id;
      link.href = fileURL;
      link.download = fileName;

      link.click();

      const linkElement = document.getElementById(id);
      linkElement?.remove();
    });

export const openPdfInNewTab = (downloadFn: () => Promise<AxiosResponse>) => {
  downloadFn()
    .then((response) => response.data)
    .then((blob) => {
      const blobPdf = new Blob([blob], { type: "application/pdf" });
      const link = document.createElement("a");
      const id = "go-to-new-tab";

      link.id = id;
      link.href = window.URL.createObjectURL(blobPdf);
      link.target = "_blank";

      link.click();

      const linkElement = document.getElementById(id);
      linkElement?.remove();
    });
};

export const downloadFileRepo = (
  downloadFn: () => Promise<AxiosResponse>,
  fileName: string,
) =>
  downloadFn()
    .then((response) => response.data)
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const id = "download";
      link.id = id;
      link.href = fileURL;
      link.download = fileName;

      link.click();

      const linkElement = document.getElementById(id);
      linkElement?.remove();
    });