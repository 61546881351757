import { AppBar, Button, Divider, Stack, Toolbar } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router-dom";

import { EsgLogout } from "assets";

import { useSignOut } from "hooks";

import { useUserRole } from "recoils";

import { Notifications } from "./components";

import { ManageUsersDrawer } from "../manage-users-drawer";
import { EsgLocationTitle, H5BoldInter } from "../typography";

export const TopBar: FunctionComponent = () => {
  const onSignOut = useSignOut();
  const { isAdmin } = useUserRole();
  const [isMangeUsersDrawerOpen, setIsMangeUsersDrawerOpen] = useState(false);
  const { pathname } = useLocation();

  const handlerFeedback = () => {
    const subject = encodeURIComponent("Submit Feedback");
    const body = encodeURIComponent(`Please provide your feedback here:`);
    const mailtoLink = `mailto:support@esgflo.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  const currentLocation = pathname.split("/").slice(1);

  const locations: any = {
    sites: "Site Management",
    intensity: "Intensity Data",
    documents: "Documents",
    summary: "Bill Entries",
    missing: "Check Missing",
    "sites-emissions": "Emissions",
    dashboard: "Dashboard",
    files: "Files",
    "audit-page": "Audit Logs",
    "scope-upload-scope3": "Upload",
    "emission-factors-scope3": "Emission Factors",
    "sites-emissions-scope3": "Emissions",
    folders: "Folders",
  };

  const title: any = {
    sites: "Site Management",
    intensity: "Intensity Data",
    documents: "Documents - Upload",
    summary: "Documents - Bill Entries",
    missing: "Documents - Check Missing",
    "sites-emissions": "Emissions",
    dashboard: "Dashboard",
    references: "Resources - References",
    files: "Resources - Files",
    "audit-page": "Audit Logs",
    "scope-upload-scope3": "Documents - Upload",
    "emission-factors-scope3": "Documents - Emission Factors",
    "sites-emissions-scope3": "Emissions",
    folders: "Folders",
  }

  return (
    <>
      <AppBar elevation={0} color="inherit" position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <EsgLocationTitle>
              {pathname.includes("scope3") ? "Scope 3" : "Scopes 1 & 2"}
            </EsgLocationTitle>
            {currentLocation[0] === "references" && currentLocation.length === 1 &&
              <>
                <EsgLocationTitle>/</EsgLocationTitle>
                <EsgLocationTitle>Resources</EsgLocationTitle>
                <EsgLocationTitle>/</EsgLocationTitle>
                <EsgLocationTitle>References</EsgLocationTitle>
              </>
            }
            {currentLocation[0] === "references" && currentLocation.length > 1 &&
              <>
                <EsgLocationTitle>/</EsgLocationTitle>
                <EsgLocationTitle>Resources</EsgLocationTitle>
              </>
            }
            {currentLocation[0] === "emission-factors-scope3" &&
              <>
                <EsgLocationTitle>/</EsgLocationTitle>
                <EsgLocationTitle>Documents</EsgLocationTitle>
              </>
            }
            {currentLocation.filter(item => item !== "references").map((url) => (
              <>
                <EsgLocationTitle key={url}>/</EsgLocationTitle>
                <EsgLocationTitle>{locations[`${url}`]}</EsgLocationTitle>
              </>
            ))}
            {currentLocation[0] === "documents" &&
              currentLocation.length === 1 && (
                <>
                  <EsgLocationTitle>/</EsgLocationTitle>
                  <EsgLocationTitle>Upload</EsgLocationTitle>
                </>
              )
            }
          </Stack>

          <Stack alignItems="center" direction="row" spacing={2}>
            <Notifications />
            <Button sx={{ px: 6 }} onClick={handlerFeedback} variant="outlined">
              Help
            </Button>
            <Button
              sx={{ px: 5 }}
              onClick={() => setIsMangeUsersDrawerOpen(true)}
              variant="outlined"
              disabled={!isAdmin}
            >
              Manage users
            </Button>

            <Button
              sx={{ px: 4 }}
              startIcon={<EsgLogout width={20} height={20} />}
              onClick={onSignOut}
              variant="contained"
            >
              Sign out
            </Button>
          </Stack>
        </Toolbar>
        <Stack sx={{ px: 3, mt: 3 }}>
          <H5BoldInter>{title[`${currentLocation.pop() as string}`]}</H5BoldInter>
        </Stack>
        <Divider sx={{ my: 1, borderColor: "#d9d9d9" }} />
      </AppBar>
      {isAdmin && (
        <ManageUsersDrawer
          isOpen={isMangeUsersDrawerOpen}
          onClose={() => setIsMangeUsersDrawerOpen(false)}
        />
      )}
    </>
  );
};
