import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { InputFieldWrapper } from 'components';
import React, { useState } from 'react';

interface CreateFolderDialogProps {
  onCreate: (folderName: string) => void;
  isDialogOpen: boolean;
  setIsDialogCancle: (value: boolean) => void;
  
}

const CreateFolderDialog: React.FC<CreateFolderDialogProps> = ({ onCreate, isDialogOpen, setIsDialogCancle }) => {
  const [folderName, setFolderName] = useState('');

  const handleSave = () => {
    if (folderName.trim() !== '') {
      onCreate(folderName);
    }
  };
  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle>Create New Folder</DialogTitle>
      <DialogContent>
        <Box marginTop='5px' >
          <InputFieldWrapper label="Folder Name">
            <TextField
              size="small"
              onChange={(e) => setFolderName(e.target.value)}
              value={folderName}
              fullWidth
              variant="outlined"
            />
          </InputFieldWrapper>
        </Box>
        <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
          <Button color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Button color="primary" variant="outlined" onClick={() => setIsDialogCancle(false)}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderDialog;
