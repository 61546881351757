import { Box, Divider, List } from "@mui/material";
import React, { FunctionComponent, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./sideBar.css"
import {
  EsgBlackSmall,
} from "assets";

import {
  DOCUMENT_UPLOAD_PAGE,
  INTENSITY_PAGE,
  SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3, // EMISSION_FACTORS_PAGE,
  SETTINGS_PAGE,
  SITES_EMISSIONS_PAGE_SCOPE3,
  SITES_PAGE,
  Scope3Context,
} from "pages";
import { DASHBOARD_PAGE } from "pages/authenticated/dashboards/consts";
import { EMISSION_FACTORS_PAGE } from "pages/authenticated/emission-factors";
import { SITES_EMISSIONS_PAGE } from "pages/authenticated/sites-emissions";
import { AUDIT_LOGS_PAGE } from "pages/authenticated/audit-logs";

import { checkIsScope3 } from "utils";

import { Esg12Label, Label18SemiBold, PrimaryLabel } from "components/typography";
import { USER_ROLES_NAMES, getUserRole } from "recoils";

import { Drawer, DrawerHeader, SidebarItem } from "./components";
import { COLORS } from "../consts";

interface SidebarProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const Sidebar: FunctionComponent<SidebarProps> = ({
  isOpen,
  open,
  close,
}) => {
  // ---- Hook ----
  const { pathname } = useLocation();
  const { activeScope } = useContext(Scope3Context);

  // ---- Const ----
  const absolutePathPrefix = `/${pathname.split("/")[1]}`;
  const isScope3 = checkIsScope3(activeScope);
  const userData = window.sessionStorage.getItem("user");
  const loggedInUser = JSON.parse(userData!);

  // ---- Menu's ----
  const menuItems = {
    "scope3": [
      SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3,
      SITES_EMISSIONS_PAGE_SCOPE3,
    ],
    "scope1&2": [
      SITES_PAGE,
      INTENSITY_PAGE,
      DOCUMENT_UPLOAD_PAGE,
      SITES_EMISSIONS_PAGE,
      DASHBOARD_PAGE,
      EMISSION_FACTORS_PAGE,
      AUDIT_LOGS_PAGE
    ],
  };

  return (
      <Drawer
        variant="permanent"
        open={isOpen}
        sx={{
          "& .MuiDrawer-paper": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
          },
        }}>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            scrollbarWidth: 'none'
          }}
        >
          <Box sx={{ display: `${isOpen && 'flex'}` }}>
            <Box
              sx={{
                minHeight: 64,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pl: `${isOpen && '28px'}`,
                svg: {
                  position: "relative",
                  left: "2px",
                },
              }}
            >
              {isOpen ? <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <EsgBlackSmall fill={COLORS.Romance} />
                <Box>
                  <Label18SemiBold sx={{color: COLORS.Romance}}>{loggedInUser?.firstName} {loggedInUser?.lastName}</Label18SemiBold>
                  <PrimaryLabel>{USER_ROLES_NAMES[
                    getUserRole(loggedInUser?.admin, loggedInUser?.auditor)
                  ]}</PrimaryLabel>
                </Box>
              </Box> : <EsgBlackSmall />}
            </Box>
          </Box>
          {isOpen && <Divider />}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(activeScope.includes("scope1") ||
              activeScope.includes("scope2")) && (
                <>
                  <Box
                    sx={{
                      pl: isOpen ? 4 : 0,
                      pt: isOpen ? 2 : 0,
                      justifyContent: isOpen ? "left" : "center",
                      textAlign: !isOpen ? 'center' : ''
                    }}
                  >
                     <Esg12Label>Scope 1 & 2</Esg12Label>
                  </Box>
                  <List>
                    {menuItems["scope1&2"].map((page) => (
                      <SidebarItem
                        key={page.ABSOLUTE_PATH}
                        isOpen={isOpen}
                        icon={page.ICON}
                        title={page.TITLE}
                        path={page.ABSOLUTE_PATH}
                        isSelected={page.ABSOLUTE_PATH === absolutePathPrefix}
                        renderMenu={page.renderMenu}
                        isScope3={false}
                      />
                    ))}
                  </List>
                </>
              )}
            {isScope3 && (
              <>
                <Divider sx={{mx:2, my:1}}/>
                  <Box
                    sx={{
                      pl: isOpen ? 4 : 0,
                      pt: isOpen ? 2 : 0,
                      justifyContent: isOpen ? "left" : "center",
                      textAlign: !isOpen ? 'center' : ''
                    }}
                  >
                     <Esg12Label>Scope 3</Esg12Label>
                  </Box>
                <List>
                  {menuItems.scope3.map((page) => (
                    <SidebarItem
                      key={page.ABSOLUTE_PATH}
                      isOpen={isOpen}
                      icon={page.ICON}
                      title={page.TITLE}
                      path={page.ABSOLUTE_PATH}
                      isSelected={page.ABSOLUTE_PATH === absolutePathPrefix}
                      renderMenu={page.renderMenu}
                      isScope3={isScope3}
                    />
                  ))}
                </List>
              </>
            )}
          </Box>
          <Divider sx={{mx:2, my:1}}/>
          <List>
            {(activeScope.includes("scope1") || activeScope.includes("scope2")) &&
              [SETTINGS_PAGE].map((page) => (
                <SidebarItem
                  key={page.ABSOLUTE_PATH}
                  isOpen={isOpen}
                  icon={page.ICON}
                  title={page.TITLE}
                  path={page.ABSOLUTE_PATH}
                  isSelected={page.ABSOLUTE_PATH === absolutePathPrefix}
                  isScope3={isScope3}
                  renderMenu={page.renderMenu}
                />
              ))}
          </List>
        </Box>
        <OpenClose isOpen={isOpen} close={close} open={open} />
      </Drawer>
  );
};

const OpenClose: FunctionComponent<SidebarProps> = ({
  isOpen,
  close,
  open,
}) => (
  <DrawerHeader>
     {/* eslint-disable jsx-a11y/click-events-have-key-events  */}
     {/* eslint-disable jsx-a11y/no-static-element-interactions  */}
    <div className={isOpen ? "wrapper" : "closeWrapper"} onClick={isOpen ? close : open}>
      <div className={!isOpen ? "arrows" : "close_arrows"}>
        <div className={!isOpen ? "arrow" : "close_arrow"} />
        <div className={!isOpen ? "arrow_2" : "arrow_2_close"} />
      </div>
    </div>
  </DrawerHeader>
);
