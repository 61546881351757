import { useInfiniteQuery } from "@tanstack/react-query";

import { getNotifications } from "api";

import { QUERY_KEYS } from "consts";

export const NOTIFICATIONS_REFETCH_INTERVAL = 30 * 1000; // 30 sec
export const NOTIFICATIONS_DEFAULT_PAGE_SIZE = 10;

export const useNotificationsInfinite = ({
  size = NOTIFICATIONS_DEFAULT_PAGE_SIZE,
  isUnread = false,
  refetchInterval,
}: {
  size?: number;
  isUnread?: boolean;
  refetchInterval?: number;
}) =>
  useInfiniteQuery({
    queryKey: [QUERY_KEYS.NOTIFICATIONS, { size, isUnread }],
    queryFn: ({ pageParam = 0 }) =>
      getNotifications({ page: pageParam, size, isUnread }),
    getNextPageParam: (lastPage, pages) => {
      const count = isUnread ? lastPage.unreadCount : lastPage.totalCount;

      return (Math.floor((count ? count - 1 : 1) / size) + 1 || 0) >
        pages.length
        ? pages.length
        : undefined;
    },
    refetchInterval,
  });
