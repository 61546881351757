/* eslint-disable */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { updatedFiles } from "api";

import { InputFieldWrapper } from "components";
import { RecDatePickerFormField } from "components/inputs/rec-date-picker-form-field";

import { useGetAllowedValues, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { getTextFieldColorStateProps } from "pages";

import { definedValueOrDefault, getDateFormat } from "utils";
import moment from "moment";

interface AddFilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  editFile: any;
  FilesData: string;
  success: () => void;

}

export const AddFilesRepoModal: React.FC<AddFilesModalProps> = ({
  isOpen,
  onClose,
  onCancel,
  editFile,
  FilesData,
  success,

}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [unit, setUnit] = useState<any>("");
  const [intensityCategory, setIntensityCategory] = useState<any>("");
  const [unitOption, setUnitOption] = useState<any>([]);

  const { mutateAsync: updateFilesRequest } = useMutation(updatedFiles);
  const { data: allowedValues } = useGetAllowedValues();

  const defaultValues: any = useMemo(() => {
    return {
      id: editFile?.original?.id,
      documentFileName: editFile?.original?.documentFileName,
      uploader: editFile?.original?.uploader,
      uploadDate: moment(editFile?.original?.uploadDate, "YYYY-MM-DD"),
      fiscalYear: editFile?.original?.fiscalYear,
      internalNotes: editFile?.original?.internalNotes
    };
  }, [editFile]);

  const { control, reset, getValues } = useForm<any>({
    defaultValues,
    mode: "onChange",
  });

  const handleEditFiles = async () => {
    const formattedStartDate = dayjs(getValues("uploadDate")).format(
      "YYYY-MM-DD",
    );

    let updatedFilesData: any;
    if (FilesData === "files") {
      updatedFilesData = {
        id: getValues("id"),
        uploadDate: formattedStartDate,
        documentFileName: getValues("name"),
        uploader: getValues("uploader"),
        fiscalYear: getValues("fiscalYear"),
        internalNotes: getValues("internalNotes"),
      };
    } else {
      updatedFilesData = {};
    }
    await updateFilesRequest(updatedFilesData, {
      onError: () => notify.error(`Failed to edit !`),
      onSuccess: () => {
        notify.success(`Successfully edited !`);
        success();
      },
    });
    onClose();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues, editFile]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Document Info
      </DialogTitle>
      <DialogContent>
        <Box component="section" mb={3} mt={2}>
          <Stack direction="row" mb={2} spacing={3}>
            <InputFieldWrapper label={"Document Name"}>
              <Controller
                name={"documentFileName"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    disabled
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
            <InputFieldWrapper label="Uploader">
              <Controller
                name="uploader"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    disabled
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
          </Stack>

          <Stack direction="row" mb={2} spacing={3}>
            <InputFieldWrapper label="Upload Date">
              <Controller
                name="uploadDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    disabled
                    // value={value}
                    value={dayjs(value as string)
                      .utc()
                      .format(getDateFormat())}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />

            </InputFieldWrapper>
            <InputFieldWrapper label="Fiscal Year">
              <Controller
                name="fiscalYear"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
          </Stack>

          <Stack direction="row" mb={2} spacing={3}>
            <InputFieldWrapper label="Internal Notes">
              <Controller
                name="internalNotes"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="medium"
                    multiline
                    maxRows={4}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => handleEditFiles()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
