import { Box, IconButton, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

import { CrossIcon } from "assets";

import { Drawer } from "components";

import UnitCostData from "./UnitCostData";

interface UnitCostInfoDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  historicData: any;
  unitCostData: any;
}

const UnitCostInfoDrawer: FunctionComponent<UnitCostInfoDrawerProps> = ({
  isOpen,
  onClose,
  historicData,
  unitCostData,
}) => {
  function percentile(arr: any, p: any) {
    if (!arr || arr.length === 0) {
      return null;
    }

    const sorted = arr.sort((a: any, b: any) => a - b);
    const index = (p / 100) * (sorted.length - 1);
    if (Number.isInteger(index)) {
      return sorted[index];
    }
    const lower = Math.floor(index);
    const upper = Math.ceil(index);
    return (sorted[lower] + sorted[upper]) / 2;
  }

  const chartData =
    historicData &&
    historicData.map((dataPoint: any) => ({
      name: dataPoint.providerName,
      median: percentile(dataPoint.boxPlotValue, 50),
    }));

  const medians =
    chartData && chartData.map((dataPoint: any) => dataPoint.median);

  let medianOfMedians = 0;
  let roundedMedianOfMedians = "";

  if (medians && medians.length > 0) {
    medianOfMedians = percentile(medians, 50);

    roundedMedianOfMedians = medianOfMedians.toFixed(2);
  }

  const providerNameExists =
    chartData &&
    chartData.some((data: any) => data.name === unitCostData.providerName);

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: "410px",
        },
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      variant="permanent"
    >
      <div style={{ width: "320px", paddingLeft: "30px" }}>
        <div
          style={{
            display: "flex",
            paddingTop: "20px",
          }}
        >
          <Typography
            style={{
              fontSize: "26px",
              whiteSpace: "normal",
              flex: 1,
            }}
          >
            Defining Unit Cost Estimation
          </Typography>
          <IconButton
            aria-label="Cancel"
            size="small"
            sx={{
              maxHeight: "30px",
              maxWidth: "30px",
              marginLeft: "25px",
            }}
            onClick={onClose}
          >
            <CrossIcon />
          </IconButton>
        </div>
        <p style={{ whiteSpace: "normal", marginTop: "5px" }}>
          {historicData && historicData.length !== 0 ? (
            providerNameExists ? (
              <>
                Based on your country, the {historicData[0].country}, the
                average unit cost per {unitCostData.consumptionUnit} for{" "}
                <strong>{unitCostData.providerName}</strong> is{" "}
                <span style={{ color: "#008B8B" }}>
                  <b>
                    {unitCostData.unitCost} {unitCostData.currency}
                  </b>
                </span>
              </>
            ) : (
              <>
                Unfortunately, we couldn’t find any Unit Cost historical data
                for your provider, <strong>{unitCostData.providerName}</strong>{" "}
                . Based on your country, {historicData[0].country}, the median
                unit cost per {unitCostData.consumptionUnit} for available
                providers in your country is defined as{" "}
                <span style={{ color: "#008B8B" }}>
                  <b>
                    {roundedMedianOfMedians} {unitCostData.currency}
                  </b>
                </span>
              </>
            )
          ) : (
            <>
              Unfortunately, we couldn’t find any Unit Cost historical data for
              any providers in your country.
            </>
          )}
        </p>

        <Box
          sx={{
            marginTop: "40px",
            width: "350px",
            height:
              historicData && historicData?.length > 5 ? "450px" : "310px",
            border:
              historicData && historicData.length !== 0
                ? "2px solid grey"
                : "1px grey",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {historicData && historicData.length !== 0 ? (
            <UnitCostData
              historicData={historicData}
              unitCostData={unitCostData}
            />
          ) : (
            <>
              <div style={{ backgroundColor: "#F5F5F5", fontSize: "15px" }}>
                <span
                  style={{
                    opacity: "0.5",
                    marginLeft: "20px",
                    marginTop: "40px",
                    display: "block",
                  }}
                >
                  Unit Cost Data by Country
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  fontSize: "14px",
                  width: "345px",
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "#717273", fontSize: "16px" }}>
                  No Historical Data Found
                </span>
              </div>
            </>
          )}
        </Box>
        <p
          style={{
            whiteSpace: "normal",
            lineHeight: "22px",
            width: "350px",
            fontSize: "12px",
          }}
        >
          We derived the unit cost data for your country and provider through
          historical data. This data is presented for informational purposes
          only.
        </p>
      </div>
    </Drawer>
  );
};

export default UnitCostInfoDrawer;
