import { useQuery } from "@tanstack/react-query";

import {
  EstimateDTO,
  SiteByIdDTO,
  SiteDTO,
  getDeActivatedEstimatesBySiteId,
  getSiteById,
  getSites,
  IntensityDTO,
  getIntensity,
  getIntensitysite,
} from "api";

import { QUERY_KEYS } from "consts";

import { MaybeNull } from "types";

export const useSites = () => useQuery<SiteDTO[]>([QUERY_KEYS.SITES], getSites);
export const useIntensitycompany = () => useQuery<IntensityDTO[]>([QUERY_KEYS.INTENSITY], getIntensity);
export const useIntensitysite = () => useQuery<IntensityDTO[]>([QUERY_KEYS.INTENSITYSITE], getIntensitysite);

export const useSiteById = (id: number, enabled: boolean = true) =>
  useQuery<SiteByIdDTO>([QUERY_KEYS.SITES, id], () => getSiteById(id), {
    enabled,
  });

export const useDeActivatedEstimatesBySiteId = (siteId: MaybeNull<number>) =>
  useQuery<EstimateDTO[]>(
    [QUERY_KEYS.ESTIMATES, QUERY_KEYS.SITES, siteId],
    () => getDeActivatedEstimatesBySiteId(siteId!),
  );
