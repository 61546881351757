import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import React from "react";

import { Bill } from "api";

import {
  EMPTY_CELL_VALUE,
  Tooltip,
  UtilityBillStatusKey,
  UtilityBillStatusWithTooltip,
} from "components";

import { TABLE_HEADERS } from "consts";

import { theme } from "providers";

import {
  getFromLocalStorage,
  getUTCDateFormat,
  isNullOrUndefined,
  toUSNumber,
} from "utils";

// eslint-disable-next-line
import { PreviewUtilityBill } from "./preview-utility-bill";

export const DOCUMENT_UTILITY_BILLS_COLUMNS: ColumnDef<Bill>[] = [
  {
    header: () => <Tooltip title="Utility type"><Box>Utility type</Box></Tooltip>,
    accessorKey: "type",
    cell: ({ getValue }) => {
      const type = getValue() as string | null;
      if (!type) {
        return EMPTY_CELL_VALUE;
      }
      return <Tooltip title={type}>
      <Box>{type}</Box>
      </Tooltip>;
    },
    minSize: 70,
  },
  {
    header: () => <Tooltip title="Start Date"><Box>Start Date</Box></Tooltip>,
    accessorKey: "consumptionPeriodFrom",
    cell: ({ getValue, row }) => {
      const date: string = getValue() as string;
      const value = date && dayjs(date).utc().format(getUTCDateFormat());
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }

      return <Tooltip title={value}>
      <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 90,
  },
  {
    header: () => <Tooltip title="End Date"><Box>End Date</Box></Tooltip>,
    accessorKey: "consumptionPeriodTo",
    cell: ({ getValue, row }) => {
      const date: string = getValue() as string;
      const value = date && dayjs(date).utc().format(getUTCDateFormat());
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
      <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 90,
  },
  {
    header: () => <Tooltip title="Provider"><Box>Provider</Box></Tooltip>,
    accessorKey: "provider",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
      <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 80,
  },
  {
    header: () => <Tooltip title={TABLE_HEADERS.accountNumber}><Box>{TABLE_HEADERS.accountNumber}</Box></Tooltip>,
    accessorKey: "accountNumber",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
      <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 70,
  },
  {
    header: () => <Tooltip title={TABLE_HEADERS.meterNumber}><Box>{TABLE_HEADERS.meterNumber}</Box></Tooltip>,
    accessorKey: "meterNumber",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
      <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 85,
  },
  {
    header: () => <Tooltip title="Usage"><Box>Usage</Box></Tooltip>,
    accessorKey: "measuredUsageValue",
    minSize: 55,
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return (
        <Tooltip title={`${toUSNumber(value as number)}${row.original?.measuredUsageUnit}`}>
        <Box sx={{ textAlign: "center", ...style }}>{`${toUSNumber(
          value as number,
        )} ${row.original?.measuredUsageUnit}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: () => <Tooltip title="Cost"><Box>Cost</Box></Tooltip>,
    accessorKey: "amount",
    minSize: 50,
    cell: ({ getValue, row }) => {
      const value = getValue() as number;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return (
        <Tooltip title={`${toUSNumber(value)} ${row.original?.currency}`}>
        <Box sx={{ textAlign: "right", ...style }}>{`${toUSNumber(value)} ${
          row.original?.currency
        } `}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: () => <Tooltip title="Status"><Box>Status</Box></Tooltip>,
    accessorKey: "status",
    cell: ({ getValue, row }) => (
      <UtilityBillStatusWithTooltip
        message={row.original.errorMessage}
        status={getValue() as UtilityBillStatusKey}
      />
    ),
    minSize: 50,
  },
  {
    id: "preview",
    header: "",
    accessorKey: "id",
    cell: ({ getValue, row }) => (
      <PreviewUtilityBill
        id={getValue() as string}
        rowData={JSON.stringify(row?.original)}
      />
    ),
    minSize: 60,
  },
];
