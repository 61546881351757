import { SendOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";

import { createComment as createCommentApi, createSiteComment as createSiteCommentApi } from "api"; 


import { TextField } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { MaybeNull } from "types";

import { UserSelectPopover } from "../components";

export const CommentTextField: FunctionComponent<{
  utilityBillId: string;
  siteId?: number;
  parentId?: number;
  page?: string
  onSuccessfulSubmit?: () => void;
}> = ({ utilityBillId, parentId = null, onSuccessfulSubmit, siteId, page }) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [isTask, setIsTask] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const { mutateAsync: createComment, isLoading: isCreating } =
    useMutation(createCommentApi);

  const { mutateAsync: createSiteComment } = useMutation(createSiteCommentApi);
  const userIdRef = useRef<MaybeNull<number>>(null);

 

  // useEffect(() => {
  //   useCommentsByEstimateId(parseInt(utilityBillId)) // eslint-disable-line
  // },[data, siteCommentData, utilityBillId, useCommentsByEstimateId])
  
  const onUserSelect = useCallback((userId: MaybeNull<number>) => {
    userIdRef.current = userId;
    setIsTask(!!userId);
  }, []);

  const handleComments = () => {
    if (page === 'siteComment') {
      createSiteComment(
        {
          assignedUserId: isTask && userIdRef.current ? userIdRef.current : null,
          estimateId: utilityBillId,
          parentId,
          siteId,
          text: value
        },
        {
          onSuccess: () => {
            notify.success("Comment was successfully added!");
            if (onSuccessfulSubmit) {
              onSuccessfulSubmit();
            }
          },
          onError: () => notify.error("Failed to add a comment!"),
        }
      )
      setValue("");
    } else {
      createComment(
        {
          text: value,
          billId: utilityBillId,
          assignedUserId:
            isTask && userIdRef.current ? userIdRef.current : null,
          parentId,
        },
        {
          onSuccess: () => {
            notify.success("Comment was successfully added!");
            if (onSuccessfulSubmit) {
              onSuccessfulSubmit();
            }
          },
          onError: () => notify.error("Failed to add a comment!"),
          onSettled: () => {
            queryClient.invalidateQueries([
              QUERY_KEYS.COMMENTS,
              utilityBillId,
            ]);
            // TODO [MP]: get this data (updated comments count) from the websocket
            queryClient.invalidateQueries([
              QUERY_KEYS.UTILITY_BILLS,
              utilityBillId,
            ]);
          },
        },
      )
      setValue("")
    }
    
  }

  return (
    <>
      {!parentId && siteId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            pt: 1,
          }}
        >
          <FormControlLabel
            sx={{ ml: 1 }}
            control={
              <Checkbox
                checked={isTask}
                onChange={() => setIsTask((v) => !v)}
              />
            }
            label="Mark as task"
          />
          <UserSelectPopover onUserSelect={onUserSelect} siteId={siteId} />
        </Box>
      )}
      <TextField
        fullWidth
        disabled={isCreating}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!value || isCreating}
                aria-label="send"
                onClick={() => handleComments()}
                edge="end"
              >
                <SendOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
