import React from "react";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types";
import { IMask, IMaskMixin } from "react-imask";

import { TextField, TextFieldProps } from "./text-field";

export const IMaskTextField = IMaskMixin(
  ({ inputRef, ...props }: TextFieldProps) => (
    <TextField {...props} inputRef={inputRef} />
  ),
);

export const IMaskFormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  textFieldProps,
}: {
  textFieldProps: TextFieldProps;
  control: ControllerProps<TFieldValues, TName>["control"];
  name: ControllerProps<TFieldValues, TName>["name"];
  rules?: ControllerProps<TFieldValues, TName>["rules"];
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) => (
      // @ts-ignore
      <IMaskTextField
        {...field}
        {...textFieldProps}
        onAccept={(val) => field.onChange(val)}
      />
    )}
  />
);

export const USCurrencyMask = IMask.createMask({
  mask: "num{.}`cents",
  blocks: {
    num: {
      mask: Number,
      signed: true,
      scale: 0,
      overwrite: true,
      thousandsSeparator: ",",
      radix: ".",
    },
    cents: {
      mask: "`0`0",
      normalizeZeros: true,
      padFractionalZeros: true,
      overwrite: true,
    },
  },
});

export const USNumberMask = IMask.createMask({
  mask: "num{.}`fraction",
  blocks: {
    num: {
      mask: Number,
      signed: true,
      scale: 0,
      overwrite: true,
      // thousandsSeparator: ",",
      radix: ".",
    },
    fraction: {
      mask: /^[0-9]*$/,
    },
  },
});

// EACH different text field requires its own instance of an IMask
export const USNumberMask2 = IMask.createMask({
  mask: "num{.}`fraction",
  blocks: {
    num: {
      mask: Number,
      signed: true,
      scale: 0,
      overwrite: true,
      thousandsSeparator: ",",
      radix: ".",
    },
    fraction: {
      mask: /^[0-9]*$/,
    },
  },
});
