import dayjs, { Dayjs } from "dayjs";
import React, { FunctionComponent, useCallback } from "react";

import { DatePickerField, InputFieldWrapper } from "components";

import { MaybeNull } from "types";

export const StartYearMonthPeriodFilter: FunctionComponent<{
  availableYears?: number[];
  period: MaybeNull<Dayjs>;
  onPeriodChange: (y: Dayjs | null) => void;
  onPeriodAccept: (y: Dayjs | null) => void;
  label?: string;
}> = ({
  availableYears,
  period,
  onPeriodAccept,
  onPeriodChange,
  label = "Period",
}) => {
  const shouldDisableYear = useCallback(
    (d: dayjs.Dayjs): boolean =>
      availableYears ? !availableYears.includes(d.year()) : true,
    [availableYears],
  );
  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-06-01`,
  ).utc();
  const maxDate = dayjs(
    `${Math.max(...(availableYears || [dayjs().utc().year()]))}-06-01`,
  ).utc();

  return (
    <InputFieldWrapper label={label}>
      <DatePickerField
        shouldDisableYear={shouldDisableYear}
        views={["year", "month"]}
        openTo="year"
        minDate={minDate}
        maxDate={maxDate}
        format="MMM, YYYY"
        disableHighlightToday
        disableFuture
        onChange={onPeriodChange}
        value={period}
        onAccept={onPeriodAccept}
        clearDate={false}
      />
    </InputFieldWrapper>
  );
};

export const EndYearMonthPeriodFilter: FunctionComponent<{
  availableYears?: number[];
  period: MaybeNull<Dayjs>;
  onEndPeriodChange: (y: Dayjs | null) => void;
  onEndPeriodAccept: (y: Dayjs | null) => void;
  label?: string;
}> = ({
  availableYears,
  period,
  onEndPeriodAccept,
  onEndPeriodChange,
  label = "Period",
}) => {
  const shouldDisableYear = useCallback(
    (d: dayjs.Dayjs): boolean =>
      availableYears ? !availableYears.includes(d.year()) : true,
    [availableYears],
  );
  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-06-01`,
  ).utc();
  const maxDate = dayjs(
    `${Math.max(...(availableYears || [dayjs().utc().year()]))}-06-01`,
  ).utc();

  return (
    <InputFieldWrapper label={label}>
      <DatePickerField
        shouldDisableYear={shouldDisableYear}
        views={["year", "month"]}
        openTo="year"
        minDate={minDate}
        maxDate={maxDate}
        format="MMM, YYYY"
        disableHighlightToday
        disableFuture
        onChange={onEndPeriodChange}
        value={period}
        onAccept={onEndPeriodAccept}
        clearDate={false}
      />
    </InputFieldWrapper>
  );
};
