import { useQuery } from "@tanstack/react-query";

import {
  AllowedValuesDTO,
  getAllowedValues,
  getMFAValues,
  setMFAValues,
} from "api";

import { QUERY_KEYS } from "consts";

export const useGetAllowedValues = () =>
  useQuery<AllowedValuesDTO>([QUERY_KEYS.ALLOWED_VALUES], getAllowedValues);

export const useGetMFAValues = () =>
  useQuery<any>([QUERY_KEYS.GET_MFA_VALUES], getMFAValues);

export const useSetMFAValues = ({ retry = false, ...query }: any) =>
  useQuery<any>([QUERY_KEYS.SET_MFA_VALUES, query], () => setMFAValues(query), {
    retry,
  });
