import {
  Box,
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import React from "react";

import { SortNumberIcon, SortNumberReverseIcon } from "assets";

import { CellValueWrapper } from "./cell";
import { HEAD_ICON_SIZE, SELECT_COLUMN_ID } from "./consts";
import { BaseTableProps } from "./types";
import { getTableCellBorders } from "./utils";

import { COLORS } from "../consts";

const tableHeadStyles = {
  position: "sticky",
  top: 0,
  backgroundColor: "white", 
  zIndex: 1, 
};

export const TableHead = <TData,>({ table }: BaseTableProps<TData>) => (
  <MuiTableHead sx={tableHeadStyles}>
    {table.getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id}>
        {headerGroup.headers.map((header, cellIndex, cells) => {
          const isLastCell = cells.length - 1 === cellIndex;

          const cellRender = flexRender(
            header.column.columnDef.header,
            header.getContext(),
          );

          const cellValue = header.column.getCanSort() ? (
            <Box
              sx={{
                cursor: "pointer",
                userSelect: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={header.column.getToggleSortingHandler()}
            >
              {cellRender}
              {{
                asc: <SortNumberIcon {...HEAD_ICON_SIZE} />,
                desc: <SortNumberReverseIcon {...HEAD_ICON_SIZE} />,
              }[header.column.getIsSorted() as string] ?? null}
              {!header.column.getIsSorted() ? (
                <SortNumberIcon {...HEAD_ICON_SIZE} fill={COLORS.muiDisabled} />
              ) : null}
            </Box>
          ) : (
            cellRender
          );

          return (
            <TableCell
              key={header.id}
              padding={header.id === SELECT_COLUMN_ID ? "checkbox" : undefined}
              sx={(theme) => ({
                ...getTableCellBorders({
                  isLastRow: false,
                  isLastColumn: isLastCell,
                  color: theme.palette.background.default,
                }),
                // 20 is a default minSize set by react-table
                width:
                  header.column.columnDef.minSize === 20
                    ? "100%"
                    : header.column.columnDef.minSize,
                // width: header.column.columnDef.minSize,
              })}
            >
              <CellValueWrapper>
                {header.isPlaceholder ? null : cellValue}
              </CellValueWrapper>
            </TableCell>
          );
        })}
      </TableRow>
    ))}
  </MuiTableHead>
);
