import {
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
  styled,
} from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

import { ReactChildren } from "types";

import { DialogTitle } from "./components";

const DialogBody = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: "0 24px 24px 24px",
  },
  "& .MuiDialog-paper": {
    minWidth: 528,
    maxWidth: "75vw",
    borderRadius: "8px",
  },
}));

export const Dialog: FunctionComponent<
  ReactChildren & {
    actions?: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    dividers?: boolean;
  }
> = ({ actions, children, isOpen, onClose, title, dividers }) => (
  <DialogBody
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
  >
    {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}

    <DialogContent
      dividers={dividers}
      sx={{ borderBottom: "none", borderTopColor: "#DCE1ED" }}
    >
      {children}
    </DialogContent>

    {actions && <DialogActions>{actions}</DialogActions>}
  </DialogBody>
);
