import { matchPath, useLocation } from "react-router-dom";

export const useRouteMatch = (patterns: readonly string[]) => {
  const { pathname } = useLocation();

  // eslint-disable-next-line no-restricted-syntax
  for (const pattern of patterns) {
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};
