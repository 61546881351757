import React, { useState, useEffect, useRef } from 'react';
import { MarginWrapper } from "components";
import { useNewBreadcrumb } from "recoils";
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createfolder, getFiles, updatedFolder } from 'api'
import { useNotify } from 'hooks';
import { FileRepoFolderIcon } from 'assets';
import { useFolders } from 'hooks/api/filesrepo';
import { QUERY_KEYS } from 'consts';
import { EMISSION_FACTORS_FILES } from "./consts";
import CreateFolderDialog from "./createFolderDialog";

const useOutsideClick = (ref: any) => {
  const [outsieClick, setOutsideClick] = useState<any>();

  useEffect(() => {
    const handleClickOutside = (e: { target: any; }) => {
      if (!ref?.current?.contains(e.target) || ref?.current === null) {
        setOutsideClick(true);
      } else {
        setOutsideClick(false);
      }
      // setOutsideClick(null);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return outsieClick;
};

export const FilesPage: React.FC = () => {
  const navigate = useNavigate()
  useNewBreadcrumb(EMISSION_FACTORS_FILES.TITLE);
  const [folders, setFolders] = useState<{ name: string; icon: React.ReactNode; id: number; creator: string; create_date: string }[]>([]);
  const [editName, setEditName] = useState<any>()
  const [foldersName, setFolderName] = useState<any>([])
  const [renamesName, setRenameName] = useState<any>([])
  const [name, setName] = useState<any>()

  const { mutateAsync: createFolderRequest } = useMutation(createfolder);
  const { mutateAsync: updateFolderRequest } = useMutation(updatedFolder);
  const { data } = useFolders();

  const editRef = useRef(null);
  const ClickedOutside = useOutsideClick(editRef);

  useEffect(() => {
    if (ClickedOutside) {
     
      handleEditfolder()
    } else {
     
      // setSearchOutsideClick(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClickedOutside]);

  const notify = useNotify();
  const queryClient = useQueryClient();


  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data !== undefined) {
      const initialFolders: any = data?.map((item: any) => ({
        name: item?.folderName,
        icon: <FileRepoFolderIcon />,
        id: item.id,
        creator: item?.creator,
        create_date: item.create_date,
      }));
      // eslint-disable-next-line array-callback-return
      const folderData: any = [];
      
      // eslint-disable-next-line array-callback-return
      data.map((item: any) => {
        const dataObj = { id: item?.id, name: item?.folderName }
        folderData.push(dataObj)
      })
      setFolderName(folderData);
      setFolders(initialFolders);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const folderNames = data
      ?.map((item: any) => {
        if (item.id === editName) {
          return item.folderName;
        }
        return null;
      })
      .filter((folderName: any) => folderName !== null);
    const array = folderNames
    const value = array?.[0];
    setRenameName(value)
  }, [data, editName])

  const handleEditfolder = () => {
    if (name !== undefined && name !== null && Object.keys(name).length > 0 && renamesName !== name.name) {
      handleUpdateFolder();
    } else {
      setIsDialogOpen(false)
    }
  };

  const handleUpdateFolder = () => {
    let updatedFolderData: any;

    if (Object.keys(name).length > 0) {
      updatedFolderData = {
        id: name?.id,
        folderName: name?.name,

      };
      updateFolderRequest(updatedFolderData, {
        onError: () => {
          setIsDialogOpen(false);
          notify.error(`Unable to rename folder. Please ensure that it's not in use and try again.`);
        },
        onSuccess: () => {
          setIsDialogOpen(false);
          setEditName("")
          // notify.success(`Folder Update successfully !`);
          if (name?.id !== undefined) {
            getFiles(name?.id);
            queryClient.invalidateQueries([QUERY_KEYS.FILESREPO])
          }
        },
      });

    }
  }

  const handleCreateFolder = (folderName: string) => {
    if (folderName) {
      let uniqueFolderName = folderName;
      let counter = 1;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      while (folders.find((folder) => folder.name === uniqueFolderName)) {
        uniqueFolderName = `${folderName} (${counter})`;
        // eslint-disable-next-line no-plusplus
        counter++;
      }
      createFolderRequest(uniqueFolderName, {
        onError: () => {
          setIsDialogOpen(false);
          notify.error(`Folder Creation Failed. Please try again!`);
        },
        onSuccess: () => {
          setIsDialogOpen(false);
          // notify.success(`Folder created successfully !`);
          queryClient.invalidateQueries([QUERY_KEYS.FILESREPO])
        },
      });
    }
  };

  const handleCancleDialog = (value: boolean | ((prevState: boolean) => boolean)) => {
    setIsDialogOpen(value);
  };
  const createFolder = () => {
    setIsDialogOpen(true)
  };
  const hanldeFolderNameChange = (e: any) => {
    // eslint-disable-next-line eqeqeq
    const names = foldersName?.filter((item: any) => item?.id?.toString() === e.target.id.toString())
    if (names.length > 0) {
      const a = foldersName?.map((item: any) => {
        if (item.id?.toString() === e.target.id.toString()) {

          // eslint-disable-next-line no-param-reassign
          item.name = e.target.value;
        }
        return item;
      })
      setFolderName(a)
    }
    setName({ id: e.target.id, name: e.target.value })
  }

  return (
    <MarginWrapper>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ mt: -1.5, fontFamily: "Manrope", fontSize: "16px", height: "22px", width: "172", color: "#054254", fontWeight: "500" }}> Documents Repository</Typography>
          <Box sx={{ height: "8px 24px" }}>
            <Button variant="outlined" onClick={createFolder}>
              Create New Folder
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", paddingLeft: "10px", flexWrap: "wrap", mt: 4 }}>
          {folders.map((folder, index) => (
            <div key={folder.id}>
              <Box sx={{
                height: "137px",
                width: "132px",
                backgroundColor: "#054254",
                padding: "21px 25px",
                borderRadius: "4px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: 'column',
              }}
              >
                <Typography sx={{
                  fontSize: "14px",
                  fontFamily: "Manrope",
                  color: "#FFFFFF",
                  textAlign: "center",

                  "&:hover": {
                    // background: "#e0e0e0",
                    cursor: "pointer",
                    transform: 'scale(1.1, 1.1)',
                  },
                }}
                  onClick={() => navigate(`/references/folders/${folder.id}`)}
                >
                  {folder.icon} 
                  </Typography>


                {editName === folder?.id ? (
                  <div ref={editRef}>
                    <TextField
                      sx={{
                        border:"none",
                        '& input': {
                          backgroundColor: '#054254',
                          color: '#FFFFFF',
                          border: "none"
                        },
                      }}
                      id={`${folder?.id}`}
                      variant="outlined"
                      value={foldersName[index]?.name}
                      onChange={(e) => hanldeFolderNameChange(e)}
                      fullWidth />
                  </div>

                ) :
                  <Typography sx={{
                    fontSize: "14px",
                    fontFamily: " Manrope",
                    color: "#FFFFFF",
                    textAlign: "center",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    marginTop: '17px',

                  }}
                    onClick={() => { setEditName(folder.id) }}>
                    {folder.name}
                  </Typography>

                }

              </Box>
            </div>
          ))}
        </Box>
        {isDialogOpen && <CreateFolderDialog onCreate={handleCreateFolder} isDialogOpen={isDialogOpen} setIsDialogCancle={handleCancleDialog} />}
      </Box>
    </MarginWrapper>
  );
};

