import { Box, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { createUser } from "api";

import {
  AutocompleteField,
  MultiSelectControlledOption,
  MultiselectFormField,
  TextField,
} from "components";

import { useNotify, useSitesMultiselect } from "hooks";

import {
  PROMISE_STATE,
  QUERY_KEYS,
  REQUIRED_VALIDATION_MESSAGE,
  STATUS_CODES,
} from "consts";

import {
  USER_ROLES_NAMES,
  USER_ROLES_NAME_TO_VALUE,
  UserRole,
  getUserRoleObject,
} from "recoils";

import {
  COMMA_WHITE_SPACE_SEPARATED_EMAILS_REGEX,
  ROLE_SELECT_OPTIONS,
} from "./consts";

interface InviteUsersFormValues {
  emails: string;
  role: string;
  sites: MultiSelectControlledOption[];
  firstName?: string;
  lastName?: string;
}

const DEFAULT_VALUES = {
  emails: "",
  role: ROLE_SELECT_OPTIONS[0],
  sites: [],
  firstName: "",
  lastName: "",
};

export const InviteUsersForm: FunctionComponent = () => {
  const {
    register,
    unregister,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InviteUsersFormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const queryClient = useQueryClient();
  const { mutateAsync: createUsersRequest } = useMutation(createUser);
  const notify = useNotify();
  const watchRole = watch("role");
  const isDataProvider = watchRole === USER_ROLES_NAMES.site_manager;
  const { isSitesLoading, sitesOptions } = useSitesMultiselect({
    withQueryParams: false,
  });

  useEffect(() => {
    register("sites");

    return () => {
      unregister("sites");
    };
  }, [register, unregister, isDataProvider]);

  const onSubmit = (data: InviteUsersFormValues) => {
    const inviteUsersRequests = data.emails.split(",").map((email) => {
      const trimmedEmail = email.trim();

      return createUsersRequest({
        email: trimmedEmail,
        firstName: data.firstName ? data.firstName : null,
        lastName: data.lastName ? data.lastName : null,
        oktaIdentifier: trimmedEmail,
        siteIds: isDataProvider
          ? (data.sites.map((o) => o.value) as number[])
          : [],
        ...getUserRoleObject(USER_ROLES_NAME_TO_VALUE[data.role] as UserRole),
      });
    });

    Promise.allSettled(inviteUsersRequests)
      .then((results) => {
        results.forEach((result) => {
          if (result.status === PROMISE_STATE.rejected) {
            if (
              result.reason.response.request.status === STATUS_CODES.CONFLICT
            ) {
              notify.error(
                `User already invited ${
                  JSON.parse(result.reason.config.data)?.email
                }!`,
              );
            } else {
              notify.error(
                `Some error has happen while sending invitation ${
                  JSON.parse(result.reason.config.data)?.email
                }!`,
              );
            }
          } else {
            notify.success(
              `Invitation successfully sent ${result.value.data?.email}!`,
            );
          }
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <TextField
          sx={{ paddingRight: 0, width: "87%" }}
          placeholder="FirstName"
          {...register("firstName", {
            required: REQUIRED_VALIDATION_MESSAGE,
          })}
          message={errors?.firstName?.message}
          color={errors?.firstName?.message ? "error" : undefined}
        />
      </Box>
      <Box>
        <TextField
          sx={{ paddingRight: 0, width: "87%" }}
          fullWidth
          placeholder="LastName"
          {...register("lastName", {
            required: REQUIRED_VALIDATION_MESSAGE,
          })}
          message={errors?.lastName?.message}
          color={errors?.lastName?.message ? "error" : undefined}
        />
      </Box>
      <Box flex={2}>
        <TextField
          sx={{ paddingRight: 0 }}
          fullWidth
          placeholder="Email(s), separated by comma"
          {...register("emails", {
            required: REQUIRED_VALIDATION_MESSAGE,
            pattern: {
              value: COMMA_WHITE_SPACE_SEPARATED_EMAILS_REGEX,
              message: "Invalid email address",
            },
          })}
          message={errors?.emails?.message}
          color={errors?.emails?.message ? "error" : undefined}
        />
      </Box>

      <Box flex={1} sx={{ paddingLeft: 1, width: "100%" }}>
        <Controller
          name="role"
          control={control}
          rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
          render={({ field }) => (
            <AutocompleteField
              textFieldProps={{
                message: errors?.role?.message,
                color: errors?.role?.message ? "error" : undefined,
              }}
              autocompleteProps={{
                ...field,
                onChange: (e, data) => {
                  field.onChange(data);
                },
                options: ROLE_SELECT_OPTIONS,
              }}
            />
          )}
        />
      </Box>

      {isDataProvider && (
        <Box>
          <MultiselectFormField
            control={control}
            disabled={isSitesLoading}
            name="sites"
            options={sitesOptions}
            rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
          />
        </Box>
      )}

      <Button
        sx={{ height: "100%", marginLeft: "15px" }}
        type="submit"
        variant="contained"
      >
        Send invite
      </Button>
    </Box>
  );
};
