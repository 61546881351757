import { SortingState, Table } from "@tanstack/react-table";

import { SORT_DIRECTIONS, borderValue, borderless } from "./consts";
import { Data, GetTableCellBorders } from "./types";

import { QUERY_PARAMS_KEYS } from "../../consts";

export const getRowIds = <T extends Data>(table: Table<T>) =>
  table.getRowModel().rows.map((r) => r.original.id);

// export const getRowGuIds = <T extends Data>(table: Table<T>) =>
//   table.getRowModel().rows.map((r) => r.original.guid);

export const tableCellBorders = (color?: string) => ({
  right: {
    borderRight: `${borderValue} ${color}`,
  },
  bottom: {
    borderBottom: `${borderValue} ${color}`,
  },
  bottomNone: {
    borderBottom: borderless,
  },
  rightNone: {
    borderRight: borderless,
  },
});

export const getTableCellBorders = ({
  isLastRow,
  isLastColumn,
  color,
}: GetTableCellBorders) => ({
  ...tableCellBorders(color).right,
  ...tableCellBorders(color).bottom,
  ...(isLastRow && { ...tableCellBorders().bottomNone }),
  ...(isLastColumn && { ...tableCellBorders().rightNone }),
});

export const rowIdsToExpandedState = (expandedRowsIds: string[]) =>
  expandedRowsIds.reduce<Record<string, true>>((acc, row) => {
    acc[row] = true;

    return acc;
  }, {});

export const getInitialSortingState = (
  searchParams: URLSearchParams,
  tableColumnToSortColumnMap: Record<string, string>,
  initialSortingState: SortingState,
) => {
  const sortBy = searchParams.get(QUERY_PARAMS_KEYS.SORT_BY);
  const sortDirection = searchParams.get(QUERY_PARAMS_KEYS.SORT_DIRECTION);

  if (
    !sortBy ||
    !sortDirection ||
    !tableColumnToSortColumnMap[sortBy] ||
    !SORT_DIRECTIONS[sortDirection as "DESC" | "ASC"]
  ) {
    return initialSortingState;
  }

  return [{ id: sortBy, desc: sortDirection === SORT_DIRECTIONS.DESC }];
};
