import { SortingState } from "@tanstack/react-table";

export const SELECT_COLUMN_ID = "select";
export const ROWS_PER_PAGE_OPTIONS = [100, 200, 500];
export const ROWS_PER_PAGE_OPTIONS_EMISSION_FACTOR = [500, 800, 1000];
export const borderValue = "1px solid";
export const borderless = "none";

export const DEFAULT_INITIAL_SORTING: SortingState = [];
export const SORT_DIRECTIONS = { DESC: "DESC", ASC: "ASC" };
export const HEAD_ICON_SIZE = { height: 16, width: 16 };
