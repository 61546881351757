import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  styled,
} from "@mui/material";

import { ColorType } from "../types";

interface ChipProps extends MuiChipProps {
  bgColor?: string;
}

export const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<ChipProps>(({ theme, bgColor }) => ({
  backgroundColor: theme.palette[bgColor as ColorType]
    ? theme.palette[bgColor as ColorType].main
    : (bgColor as string),
  borderRadius: 8,
  height: "28px",
  ".MuiChip-label": {
    paddingRight: "8px",
    paddingLeft: "8px",
  },
}));
