import {
  FormControlLabel,
  Switch as MuiSwitch,
} from "@mui/material";
import { Label12SemiBold } from "components/typography";
import React, { FunctionComponent } from "react";

export const Switch: FunctionComponent<{
  isChecked: boolean;
  disabled?: boolean;
  onChange: (v: boolean) => void;
  label?: string;
}> = ({ onChange, isChecked, label, disabled }) => (
  <FormControlLabel
    control={<MuiSwitch checked={isChecked} onChange={(_, v) => onChange(v)} />}
    label={
      <Label12SemiBold>
        {label}
      </Label12SemiBold>
    }
    disabled={disabled}
  />
);
