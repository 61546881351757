import { useQuery } from "@tanstack/react-query";

import {
  CommentDTO,
  getCommentsByEstimateId,
  getCommentsByUtilityBill,
} from "api";

import { QUERY_KEYS } from "consts";

export const useCommentsByUtilityBill = (utilityBillId: string) =>
  useQuery<CommentDTO[]>([QUERY_KEYS.COMMENTS, utilityBillId], () =>
    getCommentsByUtilityBill(utilityBillId),
  );

export const useCommentsByEstimateId = (estimateId: number | undefined) =>
  useQuery<CommentDTO[]>([QUERY_KEYS.ESTIMATE_COMMENTS, estimateId], () =>
    getCommentsByEstimateId(estimateId),
  );
