import { DialogTitle as MuiDialogTitle } from "@mui/material";
import React, { FunctionComponent } from "react";

import { CloseButton } from "components/buttons";

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle: FunctionComponent<DialogTitleProps> = ({
  children,
  onClose,
}) => (
  <MuiDialogTitle
    sx={(theme) => ({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      m: 0,
      pt: 2,
      px: 3,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    })}
  >
    {children}
    <CloseButton onClick={onClose} />
  </MuiDialogTitle>
);
