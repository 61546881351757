/* eslint-disable react-hooks/exhaustive-deps */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
import { SINGLE_BAR_CHART, UTILITY_NAMES } from "../consts";
import {
  getRequiredYearDetails,
  parseSiteSpecificConsumptionData,
} from "../utils";

type Props = {
  siteSpecificConsumptionData: unknown;
  siteElecYearsList: string[];
  siteElecYear: string;
  setSiteElecYear: (data: string) => void;
  siteNGYearsList: string[];
  siteNGYear: string;
  setSiteNGYear: (data: string) => void;
};
export const SiteSpecificConsumption = ({
  siteSpecificConsumptionData,
  siteElecYearsList,
  siteElecYear,
  setSiteElecYear,
  siteNGYearsList,
  siteNGYear,
  setSiteNGYear,
}: Props) => {
  const [
    accountConsumptionEleHighChartsData,
    setAccountConsumptionEleHighChartsData,
  ] = useState(SINGLE_BAR_CHART);

  const [
    accountConsumptionNGHighChartsData,
    setAccountConsumptionNGHighChartsData,
  ] = useState(SINGLE_BAR_CHART);

  useEffect(() => {
    if (siteSpecificConsumptionData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(siteSpecificConsumptionData),
        siteNGYear.replace("FY", ""),
      );
      const parsedData = parseSiteSpecificConsumptionData(
        siteSpecificConsumptionData,
        reqYear,
        UTILITY_NAMES.NATURAL_GAS,
      );
      const updatedChartsData = JSON.parse(JSON.stringify(SINGLE_BAR_CHART));
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "therms";
      updatedChartsData.plotOptions.series.dataLabels.format = "{point.y:.2f}";
      setAccountConsumptionNGHighChartsData(updatedChartsData);
      if (updateYear) {
        setSiteNGYear(`FY${reqYear}`);
      }
    }
  }, [siteSpecificConsumptionData, siteNGYear]);

  useEffect(() => {
    if (siteSpecificConsumptionData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(siteSpecificConsumptionData),
        siteElecYear.replace("FY", ""),
      );
      const parsedData = parseSiteSpecificConsumptionData(
        siteSpecificConsumptionData,
        reqYear,
        UTILITY_NAMES.ELECTRICITY,
      );
      const updatedChartsData = JSON.parse(JSON.stringify(SINGLE_BAR_CHART));
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "kWh";
      updatedChartsData.plotOptions.series.dataLabels.format = "{point.y:.2f}";
      setAccountConsumptionEleHighChartsData(updatedChartsData);
      if (updateYear) {
        setSiteElecYear(`FY${reqYear}`);
      }
    }
  }, [siteSpecificConsumptionData, siteElecYear]);

  return (
    <>
      <ChartWrapper
        label="Consumption by Electricity"
        yearOptions={siteElecYearsList}
        selectedYear={siteElecYear}
        setSelectedYear={setSiteElecYear}
        renderYearDropdown
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={accountConsumptionEleHighChartsData}
        />
      </ChartWrapper>
      <ChartWrapper
        label="Consumption by Natural Gas"
        yearOptions={siteNGYearsList}
        selectedYear={siteNGYear}
        setSelectedYear={setSiteNGYear}
        renderYearDropdown
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={accountConsumptionNGHighChartsData}
        />
      </ChartWrapper>
    </>
  );
};
