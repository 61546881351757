export const getFromLocalStorage = (key: string) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error:any) {
    console.error(`Error getting from localStorage: ${error.message}`);
    return null;
  }
};

export const setToLocalStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error:any) {
    console.error(`Error setting to localStorage: ${error.message}`);
  }
};
