import { getResData, httpClient } from "utils";

import { NOTIFICATIONS_API_PATHS } from "./consts";
import { NotificationsDTO, NotificationsQuery } from "./types";

export const getNotifications = (
  query: NotificationsQuery,
): Promise<NotificationsDTO> =>
  httpClient
    .get<NotificationsDTO>(NOTIFICATIONS_API_PATHS.NOTIFICATIONS(query))
    .then(getResData);

export const markAsReadNotification = (notificationId: number) =>
  httpClient.put(NOTIFICATIONS_API_PATHS.READ_NOTIFICATION(notificationId));

export const markAllAsReadNotifications = () =>
  httpClient.put(NOTIFICATIONS_API_PATHS.READ_ALL_NOTIFICATIONS);
