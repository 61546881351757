/* eslint-disable */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { updateIntensity, updateIntensitysite } from "api";

import { InputFieldWrapper } from "components";
import { RecDatePickerFormField } from "components/inputs/rec-date-picker-form-field";

import { useGetAllowedValues, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { getTextFieldColorStateProps } from "pages";

import { definedValueOrDefault } from "utils";
import moment from "moment";

interface AddIntensityModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  Intensity: string;
  editSite: any;
}

export const AddIntensityModal: FC<AddIntensityModalProps> = ({
  isOpen,
  onClose,
  onCancel,
  Intensity,
  editSite,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [unit, setUnit] = useState<any>("");
  const [intensityCategory, setIntensityCategory] = useState<any>("");
  const [unitOption, setUnitOption] = useState<any>([]);

  const { mutateAsync: updateIntensityRequest } = useMutation(
    Intensity === "Company Level" ? updateIntensity : updateIntensitysite,
  );
  const { data: allowedValues } = useGetAllowedValues();


  useEffect(() => {
    if (editSite?.original !== undefined) {
      setUnit(editSite?.original?.unit);
      setIntensityCategory(editSite?.original?.intensityCategory);
    }
  }, [editSite]);

  useEffect(() => {
    if (intensityCategory === "AREA") {
      setUnitOption(allowedValues?.intensityAreaUnits)
    } 
    if (intensityCategory === "REVENUE") {
      setUnitOption(allowedValues?.currencies)
    }
  },[intensityCategory, allowedValues])


  const defaultValues: any = useMemo(() => {
    return {
      id: editSite?.original?.id,
      companyName: editSite?.original?.companyName,
      startDate: moment(editSite?.original?.startDate, "YYYY-MM-DD"),
      endDate: moment(editSite?.original?.endDate, "YYYY-MM-DD"),
      intensityCategory,
      value: editSite?.original?.value,
      unit: editSite?.original?.unit,
      siteName: editSite?.original?.siteName
    };
  }, [editSite, intensityCategory, unit]);

  const { control, formState, reset, getValues } = useForm<any>({
    defaultValues,
    mode: "onChange",
  });

  const handleEditIntensity = async () => {
    const formattedEndDate = dayjs(getValues("endDate")).format("YYYY-MM-DD");
    const formattedStartDate = dayjs(getValues("startDate")).format(
      "YYYY-MM-DD",
    );

    let updatedIntensity: any;
    if (Intensity === "Company Level") {
      updatedIntensity = {
        id: getValues("id"),
        endDate: formattedEndDate,
        startDate: formattedStartDate,
        intensityCategory: getValues("intensityCategory"),
        companyName: getValues("companyName"),
        unit: getValues("unit"),
        value: getValues("value"),
      };
    } else {
      updatedIntensity = {
        id: getValues("id"),
        endDate: formattedEndDate,
        startDate: formattedStartDate,
        intensityCategory: getValues("intensityCategory"),
        companyName: getValues("companyName"),
        unit: getValues("unit"),
        value: getValues("value"),
      };
    }
    await updateIntensityRequest(updatedIntensity, {
      onError: () => notify.error(`Failed to edit !`),
      onSuccess: () => {
        notify.success(`Successfully edited !`);
        queryClient.invalidateQueries(
          Intensity === "Company Level"
            ? [QUERY_KEYS.INTENSITY]
            : [QUERY_KEYS.INTENSITYSITE],
        );
      },
    });
    onClose();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues, editSite]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {Intensity === "Company Level"
          ? "Edit Company Level"
          : "Edit Site Level"}{" "}
      </DialogTitle>
      <DialogContent>
        <Box component="section" mb={3} mt={2}>
          <Stack direction="row" mb={2} spacing={3}>
            <InputFieldWrapper label={Intensity === "Company Level" ? "Company Name" : "Site Name"}>
              <Controller
                name={Intensity === "Company Level" ? "companyName" : "siteName"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    disabled
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
            <RecDatePickerFormField
              name="startDate"
              label="Start Date"
              control={control}
              disabled={false}
              rules={{
                required: editSite?.original?.startDate,
              }}
              {...getTextFieldColorStateProps("startDate", formState.errors)}
            />
          </Stack>

          <Stack direction="row" mb={2} spacing={3}>
            <RecDatePickerFormField
              name="endDate"
              label="End Date"
              control={control}
              disabled={false}
              rules={{
                required: editSite?.original?.endDate,
              }}
              {...getTextFieldColorStateProps("startDate", formState.errors)}
            />
            <InputFieldWrapper label="Intensity Category">
              <Controller
                name="intensityCategory"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      labelId="intensityCategory-simple-select-label"
                      id="intensityCategory-simple-select"
                      autoWidth
                      fullWidth
                      sx={{ height: 40 }}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setIntensityCategory(e.target.value)
                      }}
                      >
                      {allowedValues?.intensityCategories.map((t) => {
                        return <MenuItem value={t}>{t}</MenuItem>;
                      })}
                    </Select>
                  )
                }
                }
              />
            </InputFieldWrapper>
          </Stack>

          <Stack direction="row" mb={2} spacing={3}>
            <InputFieldWrapper label="Value">
              <Controller
                name="value"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>
            {intensityCategory !== "PRODUCT_UNIT" ? (<InputFieldWrapper label="Unit">
              <Controller
                name="unit"
                control={control}
                render={({ field }) =>  (
                  <Select
                    labelId="unit-simple-select-label"
                    id="unit-simple-select"
                    autoWidth
                    fullWidth
                    sx={{ height: 40 }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)
                    }
                  >
                    {unitOption?.map((t: any) => {
                      return <MenuItem value={t}>{t}</MenuItem>;
                    })}
                  </Select>
                )}
              />
            </InputFieldWrapper>) : (
              <InputFieldWrapper label="Unit">
              <Controller
                name="unit"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </InputFieldWrapper>)}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => handleEditIntensity()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
