import React, { useEffect, useRef } from "react";

export const ConsentManagament = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      addScript();
    }
  }, []);

  const addScript = () => {
    const myobj = document.getElementsByClassName("addScript");
    for (let i = 0; i < myobj.length; i += 1) {
      myobj[i].remove();
    }
    if (document.createElement("script")) {
      const script = document.createElement("script");
      script.setAttribute("class", "addScript");
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  };

  return <div data-name="termly-embed" id="termly-consent-preferences" />;
};
