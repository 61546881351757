import { Button, ButtonGroup } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { ChangeEvent, FunctionComponent, useRef, useState } from "react";

import {
  createDocumentsFolder,
  documentUploadedFiles,
  uploadDocumentToS3,
} from "api";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

export const DocumentsUploadFileRepo: FunctionComponent<{
  apiResponse: any;
  folderId?: number;
  getApiCall: any;
  getmenuclose: any;
  allfilename: any;
}> = ({ apiResponse, folderId, getApiCall, getmenuclose, allfilename }) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { mutate: create } = useMutation(createDocumentsFolder);
  const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);
  const { mutateAsync: uploaded } = useMutation(documentUploadedFiles);
  const [selectedFolderId, setSelectedSite] = useState<null | number>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const buttonStyle = {
    border: "none",
    background: "none",
    fontSize: "16px",
    width: "100%",

    color: "none !important",
    fontweight: 400,
    "&:hover": {
      backgroundcolor: "#000",
      opacity: 0.4,
    },
  };

  const hoverStyle = {
    "&:hover": {
      backgroundcolor: "#000",
      opacity: 0.4,
    },
  };

  const onUploadButtonClick = () => {
    setSelectedSite(folderId ?? null);
    fileInputRef?.current?.click();
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);

    const duplicateFiles = files.filter((file) =>
      allfilename.some(
        (existingFile: any) => existingFile.documentFileName === file.name,
      ),
    );

    if (duplicateFiles.length > 0) {
      notify.error(
        `Duplicate files: ${duplicateFiles
          .map((file) => file.name)
          .join(
            ",  ",
          )} File upload failed. You’ve uploaded a duplicate file, please check your file and try again.”`,
      );
    }
    const nonDuplicateFiles = files.filter(
      (file) =>
        !allfilename.some(
          (existingFile: any) => existingFile.documentFileName === file.name,
        ),
    );
    const expectedSizeInBytes = 10 * 1024 * 1024; // 3MB in bytes
    // const expectedSizeInBytes = 10 * 1024 // 10kb in bytes
    const isFileSizeValid = files.every(
      (file) => file.size <= expectedSizeInBytes,
    );
    if (!isFileSizeValid) {
      notify.error(
        "File upload failed. You’ve exceeded the file size limit of 10MB, please check your file size and try again.",
      );
      e.target.value = "";
      return;
    }
    const validExtensions = [
      ".xls",
      ".xlsx",
      ".pdf",
      ".xlsm",
      ".xlsb",
      ".doc",
      ".docx",
      ".jpeg",
      ".jpg",
      ".png",
      ".txt",
      ".csv",
      ".ppt",
      ".zip"
    ];
    const isValidFile = files.every((file) =>
      validExtensions.some((ext) => file.name.toLowerCase().endsWith(ext)),
    );

    if (!isValidFile) {
      notify.error(
        "File upload failed. Please verify that your file format and try again!",
      );
      e.target.value = "";
      return;
    }

    if (nonDuplicateFiles.length === 0) {
      return;
    }
    let uploadedFilesCount = 0;
    create(
      {
        fileNames: nonDuplicateFiles.map((file) => file.name),
        // fileNames: files.map((file) => file.name),
        // fileNames: files[0]?.name,
        folderId: selectedFolderId ?? 1,
      },
      {
        onSuccess: (
          documents: {
            fileName: string;
            s3uploadUrl: string;
            id: number;
          }[],
        ) => {
          queryClient.invalidateQueries([QUERY_KEYS.FILESREPO]).then(() => {
            Promise.allSettled(
              documents.map((document) =>
                uploadToS3({
                  url: document.s3uploadUrl,
                  file: files.find(
                    (file) => file.name === document.fileName,
                  ) as File,
                })
                  .then(() => uploaded(document?.id))
                  .then((res) => {
                    if (res.data[0].status === "ERROR") {
                      notify.error(`Failed to upload file!`);
                    }
                    if (res.data[0].status === "PROCESSING") {
                      uploadedFilesCount += 1;
                    }
                    getmenuclose();
                  })
                  .catch(() => {
                    notify.error(`Failed to upload file!`);
                    getmenuclose();
                  }),
              ),
            ).then(() => {
              if (uploadedFilesCount === files.length) {
                notify.success(`File has been successfully uploaded`);
                apiResponse(true);
                setTimeout(() => {
                  apiResponse(false);
                }, 2000);
              } else {
                notify.success(`Successfully uploaded files`);
              }
              getApiCall(true);
              queryClient.invalidateQueries([QUERY_KEYS.FILESREPO]);
              e.target.value = "";
              getmenuclose();
            });
          });
        },
        onError() {
          notify.error(
            "File upload failed. Please check your internet connection and try again.",
          );
          e.target.value = "";
          getmenuclose();
        },
      },
    );
  };

  return (
    <ButtonGroup sx={{ width: "100%" }}>
      <input
        ref={fileInputRef}
        hidden
        // accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        accept="*/*"
        multiple
        type="file"
        onChange={onChange}
      />
      <Button
        component="label"
        onClick={onUploadButtonClick}
        sx={{
          fontWeight: 400,
          justifyContent: "flex-start",
          paddingLeft: "16px",
          ":hover": {
            backgroundColor: "#0000000a !important",
          },
        }}
        style={{ ...buttonStyle, ...hoverStyle }}
      >
        Upload New File
      </Button>
    </ButtonGroup>
  );
};
