import { buildQueryParams } from "utils";

import { DocumentsQuery } from "./types";

export const DOCUMENTS_API_PATHS = {
  USER_SETTING: "/users/settings",
  DOCUMENTS: (query?: DocumentsQuery) => {
    const baseUrl = "/documents";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },
  COMANDOCUMENTS: (query?: DocumentsQuery) => {
    const baseUrl = "/commondocuments";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },
  DOCUMENTS_UPLOADED: "/documents/file-uploaded",
  COMANDOCUMENTS_UPLOADED: "/commondocuments/file-uploaded",
  DOCUMENT: (documentId: string) => `/documents/${documentId}`,
  SUPPLIES: (documentId: string) => `/supplies/${documentId}`,
  APPROVED: (documentId: string) => `/supplies/${documentId}/approved`,

  EXPORT_DOCUMENTS: "/export/documents",
  GET_BILLS: (documentId: string) => `/documents/${documentId}/bills`,
  CREATE_BILL: (documentId: string) => `/documents/${documentId}/bill`,
  DOCUMENTS_SUPPLIES: (documentId: string) =>
    `/documents/${documentId}/supplies`,
};
