import { SelectedRows, createSelectedRowsRecoil } from "./selected-rows";

export const {
  selectedRowsState: selectedUploadDocumentEntriesState,
  selectedRowsCount: selectedUploadDocumentEntriesCount,
  useSetSelectedRow: useSetSelectedUploadDocumentEntries,
  useSelectedRows: useSelectedUploadDocumentEntries,
  useSetFromIntermediateSelection:
    useSetFromIntermediateSelectionUploadDocumentEntries,
  useSetDefaultSelection: useSetDefaultUploadDocumentEntriesSelection,
  useGetIsIntermediateSelection:
    useGetIsIntermediateSelectionUploadDocumentEntries,
} = createSelectedRowsRecoil("UploadDocumentEntries");

export type SelectedUploadDocumentEntries = SelectedRows;
