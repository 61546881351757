import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FunctionComponent, useState } from "react";

import { CommentDTO, markSiteCommentAsDone } from "api";

import {
  Body2Regular,
  COLORS,
  Label12Regular,
  Label14SemiBold,
} from "components";

import { QUERY_KEYS } from "consts";

import { CommentTextField } from "./comment-text-field";

import { User } from "../components";

export const SiteComment: FunctionComponent<{
  comment: CommentDTO;
  isReply?: boolean;
  currentUserId: number;
  siteId?: any;
  estimateId?: any;
  onSuccessfulSiteSubmit?: any;
}> = ({ comment, isReply = false, currentUserId, siteId, estimateId, onSuccessfulSiteSubmit }) => {
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [shouldShowReplies, setShouldShowReplies] = useState<boolean>(false);
  const { mutateAsync, isLoading } = useMutation(markSiteCommentAsDone);
  const queryClient = useQueryClient();
  // TODO [MP]: discuss with the backend team implementation of a better way to check if the user is a system user.
  const isSystemComment =
    comment.authorName === "System" && comment.authorLastName === "comment";
  const isTask = !!comment.assignedUser;
  const isAssignedUser = currentUserId === comment.assignedUser?.id;

  // eslint-disable-next-line
  console.log("***", comment.text);

  const replyControls = (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Label12Regular
        sx={{
          color: COLORS.black,
          cursor: "pointer",
        }}
        onClick={() => setIsReplying((prev) => !prev)}
      >
        Reply
      </Label12Regular>

      {comment.replies.length !== 0 && (
        <Label12Regular
          sx={{
            color: COLORS.black,
            cursor: "pointer",
          }}
          onClick={() => setShouldShowReplies((prev) => !prev)}
        >
          {shouldShowReplies ? "Hide" : "Show"} replies (
          {comment.replies.length})
        </Label12Regular>
      )}
    </Box>
  );

  return (
    <>
      <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Label14SemiBold
              sx={{
                color: COLORS.black,
              }}
            >
              {comment.authorName} {comment.authorLastName}
            </Label14SemiBold>
            <Body2Regular>
              {dayjs(comment.createDate)
                .utc()
                .local()
                .format("MMM D [at] hh:mm a")}
            </Body2Regular>
          </Box>
          <Body2Regular>{comment.authorEmail}</Body2Regular>
        </Box>
        <Label12Regular
          sx={{
            mt: 2,
            color: COLORS.black,
          }}
        >
          <div
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: `<div>${comment.text.replace(/\\n/g, "<br>")}</div>`,
            }}
            style={{ whiteSpace: "pre-line" }}
          />
        </Label12Regular>
        {!isSystemComment && !isReply && !isTask && replyControls}
        {isTask && (
          <>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <User user={comment.assignedUser!} />
              </Box>
              {!isAssignedUser && (comment.done ? "Resolved" : "Not resolved")}
              {isAssignedUser && (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={comment.done}
                      disabled={comment.done || isLoading}
                      onChange={() =>
                        mutateAsync(comment.id, {
                          onSuccess: () => {
                            onSuccessfulSiteSubmit();
                            queryClient.invalidateQueries([
                              QUERY_KEYS.COMMENTS,
                            ])
                          }
                        })
                      }
                    />
                  }
                  label="Resolve"
                />
              )}
              {!isSystemComment && !isReply && replyControls}
            </Box>
          </>
        )}
        {isReplying && !isSystemComment && !isReply && (
          <Box sx={{ mt: 1 }}>
            <CommentTextField
              utilityBillId={estimateId}
              parentId={comment.id}
              page="siteComment"
              siteId={siteId}
              onSuccessfulSubmit={() => {
                setIsReplying(false);
                setShouldShowReplies(true);
                onSuccessfulSiteSubmit();
              }}
            />
          </Box>
        )}
      </Box>

      {shouldShowReplies &&
        !isSystemComment &&
        !isReply &&
        comment.replies.map((reply) => (
          <Box sx={{ mt: 2, ml: 8 }} key={reply.id}>
            <SiteComment comment={reply} isReply currentUserId={currentUserId} />
          </Box>
        ))}
    </>
  );
};
