import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { HookWithQueryParams } from "types";

import { getParsedQueryParams } from "utils";

const getIsEditedInitialValue = (searchParams: URLSearchParams, key: string) =>
  searchParams.get(key) === "true";

export const useSwitchFilter = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  key,
  withQueryParams = true,
}: HookWithQueryParams & {
  key: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<boolean>(() =>
    getIsEditedInitialValue(searchParams, key),
  );

  const onSwitchChange = useCallback(
    (v: boolean) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setValue(v);

      if (withQueryParams) {
        const currentSearchParams = getParsedQueryParams(searchParams);

        if (v) {
          currentSearchParams[key] = "true";
        } else {
          delete currentSearchParams[key];
        }

        setSearchParams({
          ...(getQueryParamsWithExternalChanges
            ? getQueryParamsWithExternalChanges(currentSearchParams)
            : currentSearchParams),
        });
      }
    },
    [
      key,
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  return {
    value,
    onSwitchChange,
  };
};
