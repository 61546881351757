import { Box } from "@mui/material";
import React, { FunctionComponent, memo } from "react";

import { UserDTO } from "api";

import { User } from "./user";

export const Users: FunctionComponent<{
  users?: UserDTO[];
  onUserSelect: (user: UserDTO) => void;
}> = memo(({ users, onUserSelect }) => (
  <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
    {users?.map((user) => (
      <Box key={user.id} sx={{ cursor: "pointer" }}>
        <User user={user} onUserSelect={onUserSelect} />
      </Box>
    ))}
  </Box>
));
