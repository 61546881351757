import { useQuery } from "@tanstack/react-query";
import { FilesRepoDTO, getFolder } from "api";
import { QUERY_KEYS } from "consts";



export const useFolders = () =>
  useQuery<FilesRepoDTO[]>(
    [QUERY_KEYS.FILESREPO],
    getFolder,
  );