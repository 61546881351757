import {
  SnackbarAction,
  SnackbarProvider as SnackbarProviderNotistack,
  useSnackbar,
} from "notistack";
import React, { FunctionComponent } from "react";

import { ReactChildren } from "types";

import { CloseButton } from "../components";

const CloseSnackBarAction: SnackbarAction = (snackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <CloseButton color="white" onClick={() => closeSnackbar(snackbarKey)} />
  );
};

export const SnackbarProvider: FunctionComponent<ReactChildren> = ({
  children,
}) => (
  <SnackbarProviderNotistack
    maxSnack={5}
    autoHideDuration={5000}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    action={CloseSnackBarAction}
  >
    {children}
  </SnackbarProviderNotistack>
);
