import { httpClient } from "utils";

const USER_SETTINGS_PATH = "/users/settings";


export type DateSetting = {
  dateFormat: string;
};

export const USER_SETTINGS_API_PATHS = {
  CREATE_USER_SETTING: USER_SETTINGS_PATH,
  GET_USER_SETTING: USER_SETTINGS_PATH,
  CLEAR_ENVIRONMENT: '/data/deleteAll',
  ABBYY_SKILL: '/abbyy-skill',
  UPDATE_ABBYY_SKILL: (value: any) => `/abbyy-skill/${value}`,
};

export const createUserSetting = (body: DateSetting) =>
  httpClient.post(USER_SETTINGS_API_PATHS.CREATE_USER_SETTING, body);
  
export const clearEnvironment = () => 
  httpClient.delete<any>(USER_SETTINGS_API_PATHS.CLEAR_ENVIRONMENT);

  export const getAbbyySkill = () =>
  httpClient.get(USER_SETTINGS_API_PATHS.ABBYY_SKILL);

  export const setAbbySkill = (body: string) =>
  httpClient.put(USER_SETTINGS_API_PATHS.UPDATE_ABBYY_SKILL(body));

  
