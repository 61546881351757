import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

import { SitesEmissionsDTO } from "api";

import { InfoIcon } from "assets";

import {
  COLORS,
  Chip,
  Label12Regular, // Label12Regular,
  Label12SemiBold,
  Label14SemiBold,
  Label16Medium,
  Label16SemiBold,
  Label18SemiBold,
  UtilityBillTypeChip,
  UtilityBillTypeKey,
} from "components";

import { ScopeEmissionPieChart } from "./emission-summary-pieChart";
import { ScopeEmissionScope2PieChart } from "./emission-summary-scope2-pieChart";
import { ScopeEmissionScopePieChart } from "./emission-summary-scope-pieChart";

export const ConsumptionSummary: FunctionComponent<{
  sitesEmissions: Omit<SitesEmissionsDTO, "siteAggregations">;
}> = ({ sitesEmissions }) => {
  const [showChart, setShowChart] = React.useState<boolean>(false);
  const [showChartScope2, setShowChartScope2] = React.useState<boolean>(false);

  return (
    <>
      <Box>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "350px",
            p: 2,
          }}
          elevation={0}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Label18SemiBold>Summary </Label18SemiBold>
            {/* <Label12SemiBold sx={{ ml: 2 }}>{period}</Label12SemiBold> */}
          </Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            {/* Sites and Other Chips */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ScopeEmissionPieChart sitesEmissionsData={sitesEmissions} />
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  marginTop: "0px",
                  marginLeft: "16px",
                }}
              >
                <div
                  style={
                    showChart
                      ? {
                          width: "220px",
                          height: "100px",
                          paddingBottom: "10px",
                          display: "flex",
                          backgroundColor: "#FAFAFA",
                          marginLeft: "10px",
                          marginRight: "8px",
                          borderRadius: "10px",
                        }
                      : {
                          width: "220px",
                          height: "100px",
                          paddingBottom: "10px",
                          display: "flex",
                          backgroundColor: "#FAFAFA",
                          marginLeft: "10px",
                          marginRight: "8px",
                          borderRadius: "10px",
                        }
                  }
                >
                  <div
                    style={
                      showChart
                        ? {
                            position: "relative",
                            height: "250px",
                            width: "220px",
                            borderRadius: "10px",
                            backgroundColor: "#FAFAFA",
                          }
                        : {
                            position: "relative",
                            height: "100px",
                            width: "220px",
                            borderRadius: "10px",
                            backgroundColor: "#FAFAFA",
                          }
                    }
                  >
                    <div
                      style={
                        showChart
                          ? { top: 20, position: "absolute" }
                          : { top: 20, position: "absolute" }
                      }
                    >
                      {showChart ? null : (
                        <Box sx={{ paddingLeft: 1.8 }}>
                          <Label14SemiBold>Scope 1</Label14SemiBold>
                          <Typography sx={{ fontSize: "small" }}>
                            <span
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              {sitesEmissions?.totalScope1Emission}
                            </span>{" "}
                            tCO2eq
                          </Typography>
                        </Box>
                      )}
                      {showChart && (
                        <ScopeEmissionScopePieChart
                          sitesEmissionsData={sitesEmissions}
                        />
                      )}
                    </div>
                    <Button
                      className="collapseBtn"
                      sx={{
                        height: "32px",
                        width: "220px",
                        position: "absolute",
                        bottom: 0,
                        boxShadow: "0px 4px 4px 0px #0000000D",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 14px",
                        background: "#FFF",
                      }}
                      onClick={() => setShowChart(!showChart)}
                    >
                      <span style={{ color: "#36855B" }}>
                        {showChart ? "Collapse" : "View Breakdown"}
                      </span>
                      <ExpandMoreIcon
                        style={{
                          color: "#36855B",
                          transform: `${showChart ? "rotate(180deg)" : ""}`,
                        }}
                      />
                    </Button>
                  </div>
                </div>
                <div
                  style={
                    showChartScope2
                      ? {
                          width: "220px",
                          height: "100px",
                          paddingBottom: "10px",
                          display: "flex",
                          backgroundColor: "#FAFAFA",
                          marginLeft: "8px",
                          borderRadius: "10px 10px 10px 10px",
                        }
                      : {
                          width: "220px",
                          height: "100px",
                          paddingBottom: "10px",
                          display: "flex",
                          backgroundColor: "#FAFAFA",
                          marginLeft: "8px",
                          borderRadius: "10px 10px 10px 10px",
                        }
                  }
                >
                  <div
                    style={
                      showChartScope2
                        ? {
                            position: "relative",
                            height: "250px",
                            width: "220px",
                            borderRadius: "10px",
                            backgroundColor: "#FAFAFA",
                          }
                        : {
                            position: "relative",
                            height: "100px",
                            width: "220px",
                            borderRadius: "10px",
                            backgroundColor: "#FAFAFA",
                          }
                    }
                  >
                    <div
                      style={
                        showChartScope2
                          ? { top: 0, position: "absolute" }
                          : { top: 16, position: "absolute" }
                      }
                    >
                      {showChartScope2 ? null : (
                        <Box sx={{ paddingLeft: 1.8 }}>
                          <Label14SemiBold>Scope 2</Label14SemiBold>
                          <Typography sx={{ fontSize: "small" }}>
                            <span
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              {sitesEmissions?.totalScope2Emission}
                            </span>{" "}
                            tCO2eq
                          </Typography>
                        </Box>
                      )}
                      {showChartScope2 && (
                        <ScopeEmissionScope2PieChart
                          sitesEmissionsData={sitesEmissions}
                        />
                      )}
                    </div>
                    <Button
                      className="collapseBtn"
                      sx={{
                        height: "32px",
                        width: "220px",
                        position: "absolute",
                        bottom: 0,
                        boxShadow: "0px 4px 4px 0px #0000000D",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 14px",
                        background: "#FFF",
                      }}
                      onClick={() => setShowChartScope2(!showChartScope2)}
                    >
                      <span style={{ color: "#36855B" }}>
                        {showChartScope2 ? "Collapse" : "View Breakdown"}
                      </span>
                      <ExpandMoreIcon
                        style={{
                          color: "#36855B",
                          transform: `${
                            showChartScope2 ? "rotate(180deg)" : ""
                          }`,
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: "200px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", ml: 4, mr: 4 }}
              >
                <Label12SemiBold>Sites</Label12SemiBold>
                <Box>
                  <Chip
                    label={`${sitesEmissions.selectedSites} of ${sitesEmissions.totalSites}`}
                    bgColor={COLORS.faGray}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Label12SemiBold>Consumption</Label12SemiBold>
                <Grid container gap={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {sitesEmissions.utilityTypesUsage.map((utilityType) => (
                    
                    <UtilityBillTypeChip
                      key={utilityType.utilityType}
                      utilityType={
                        utilityType.utilityType as UtilityBillTypeKey
                      }
                      usage={utilityType.usage}
                      unit={utilityType.unit}
                    />
                  ))}
                  </Grid>
              </Box>
            </div>
          </Box>
        </Paper>
        {/* intensity Metrics */}
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "260px",
            p: 2,
          }}
          elevation={0}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Label18SemiBold
              sx={{ fontSize: 18, fontWeight: "bold",  }}
            >
              Company Intensity Metrics
            </Label18SemiBold>

            <Tooltip
              title="For site-level intensity metrics, please group the table below by the Site column"
              placement="top-end"
            >
              <InfoIcon style={{ marginLeft: "6px", color: "#18acb6",marginTop:"2px" }} />
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {/* First */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #F0F3F7",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#F0F3F7", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Production Unit</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityProductionCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityProductionCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityProductionUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityProductionCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {
                          sitesEmissions?.totalMarketIntensityProductionCo2Emission
                        }
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityProductionUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
            {/* Second */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #EEF5EF",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#EEF5EF", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Company Revenue</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityRevenueCo2Emission !== null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityRevenueCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityRevenueUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityRevenueCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalMarketIntensityRevenueCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityRevenueUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
            {/* Third */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #FFF1E0",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#FFF1E0", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Area</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityAreaCo2Emission !== null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityAreaCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityAreaUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityAreaCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalMarketIntensityAreaCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityAreaUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
