import { Button, Chip, Stack } from "@mui/material";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

import { EyeIcon } from "assets";

import { Body2Regular, COLORS, Label12SemiBold } from "components";
import SendIcon from "@mui/icons-material/Send";
import { USER_ROLES, USER_ROLES_NAMES, USER_ROLE_COLOR, UserRole, getUserRole } from "recoils";
import { CSVLink } from "react-csv";
import { useSites } from "hooks";

const CHIP_STYLES = {
  border: "none",
  borderRadius: 2,
  padding: "4px 12px",
};

export const UsersFilters: FunctionComponent<{
  onFilterChange: Dispatch<SetStateAction<UserRole | null>>;
  isDisabled: boolean;
  usersData: any
}> = ({ onFilterChange, isDisabled, usersData }) => {

  // Get all sites
  const { data: sites } = useSites();

  const getSiteNameById = (ids: number[]) => {
    const siteMap = sites!?.reduce((acc: any, site: any) => {
      acc[site?.id] = site?.name;
      return acc;
    }, {});

    return ids?.map(id => siteMap[id])?.filter(Boolean);
  }

  const CsvEmissionSummary = usersData?.map((item: any) => ({
    "First Name": item?.firstName,
    "Last Name": item?.lastName,
    "User Email": item?.email,
    "Is User Active": item?.enabled === true ? "Yes" : "No",
    "User Role": USER_ROLES_NAMES[getUserRole(item?.admin, item?.auditor)],
    "Data Provider Site": getSiteNameById(item?.sites),
  }));

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <Body2Regular>View:</Body2Regular>
      <Chip
        sx={{ bgcolor: COLORS.mintCream, ...CHIP_STYLES }}
        icon={<EyeIcon />}
        label={<Label12SemiBold>All</Label12SemiBold>}
        variant="outlined"
        onClick={() => onFilterChange(null)}
        disabled={isDisabled}
      />
      <Chip
        sx={{ bgcolor: USER_ROLE_COLOR.auditor, ...CHIP_STYLES }}
        icon={<EyeIcon />}
        label={<Label12SemiBold>Auditor</Label12SemiBold>}
        variant="outlined"
        onClick={() => onFilterChange(USER_ROLES.AUDITOR)}
        disabled={isDisabled}
      />
      <Chip
        sx={{ bgcolor: USER_ROLE_COLOR.admin, ...CHIP_STYLES }}
        icon={<EyeIcon />}
        label={<Label12SemiBold>Sustainability manager</Label12SemiBold>}
        variant="outlined"
        onClick={() => onFilterChange(USER_ROLES.ADMIN)}
        disabled={isDisabled}
      />
      <Chip
        sx={{ bgcolor: USER_ROLE_COLOR.site_manager, ...CHIP_STYLES }}
        icon={<EyeIcon />}
        label={<Label12SemiBold>Data provider</Label12SemiBold>}
        variant="outlined"
        onClick={() => onFilterChange(USER_ROLES.SITE_MANAGER)}
        disabled={isDisabled}
      />
      <Chip
        sx={{ bgcolor: USER_ROLE_COLOR.enabled, ...CHIP_STYLES }}
        icon={<EyeIcon />}
        label={<Label12SemiBold>De-activated Users</Label12SemiBold>}
        variant="outlined"
        onClick={() => onFilterChange(USER_ROLES.ENABLED)}
        disabled={isDisabled}
      />

      <CSVLink
        style={{ textDecoration: "none" }}
        data={CsvEmissionSummary}
        filename="User-List-Data.csv"
      >
        <Button
          sx={{ height: "100%", marginLeft: "15px" }}
          type="submit"
          endIcon={<SendIcon />}
          variant="contained"
        >
          Export User List
        </Button>
      </CSVLink>
    </Stack>
  )
};
