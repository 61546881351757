import { Box } from "@mui/material";
import React, { FunctionComponent, memo } from "react";

import { UserDTO } from "api";

import { Avatar, Label10SemiBold, Label12SemiBold } from "components";

import { USER_ROLES_NAMES, getUserRole } from "recoils";

import { getInitials } from "utils";

export const User: FunctionComponent<{
  user: UserDTO;
  onUserSelect?: (user: UserDTO) => void;
}> = memo(({ user, onUserSelect }) => {
  const name =
  user && user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user && user.email;

  return (
    <Box
      sx={{ display: "flex", gap: 2, alignItems: "center", width: "100%" }}
      onClick={
        onUserSelect
          ? (e) => {
              e.stopPropagation();
              onUserSelect(user);
            }
          : undefined
      }
    >
      <Avatar>{getInitials(name)}</Avatar>
      <Box>
        <Label12SemiBold>{name}</Label12SemiBold>
        <Label10SemiBold>
          {USER_ROLES_NAMES[getUserRole(user && user.admin, user && user.auditor)]}
        </Label10SemiBold>
      </Box>
    </Box>
  );
});
