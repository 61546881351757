import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { getAuthenticatedUser } from "api";

import {
  isUserAuthenticatedState,
  useSetDefaultUser,
  useSetUser,
} from "recoils";

export const useNullAuthentication = () => {
  const [isInternalLoading, setIsInternalLoading] = useState(false);
  const setUser = useSetUser();
  const setDefaultUser = useSetDefaultUser();
  const isUserAuthenticated = useRecoilValue(isUserAuthenticatedState);

  useEffect(() => {
    setIsInternalLoading(true);
    getAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch(() => {
        setDefaultUser();
      })
      .finally(() => {
        setIsInternalLoading(false);
      });
  }, [setDefaultUser, setUser]);

  return {
    isUserAuthenticated,
    isLoading: isInternalLoading,
  };
};

export const useNullSignOut = useSetDefaultUser;

export const useNullSignIn = () => {
  const setUser = useSetUser();
  const setDefaultUser = useSetDefaultUser();

  return useCallback(
    () =>
      getAuthenticatedUser()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
          setDefaultUser();
        }),
    [setUser, setDefaultUser],
  );
};
