import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";

import { initDocumentsMock } from "./documents";
import { initSitesMock } from "./sites";
import { initUsersMock } from "./users";
import { initUtilityBillsMock } from "./utility-bills";

export const initializeHttpClientMock = (client: AxiosInstance) => {
  const clientMock = new MockAdapter(client);

  setTimeout(() => {
    initSitesMock(clientMock);
    initUsersMock(clientMock);
    initUtilityBillsMock(clientMock);
    initDocumentsMock(clientMock);
  });
};
