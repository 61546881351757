import { useCallback } from "react";

import { CollectorDTO } from "api";

import { useAllCollectors } from "hooks";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";
import { OptionalMultiselectProps } from "./types";
import { getMultiselectOptions, getUniqArrayValues } from "./utils";

export const useMeterNumbersMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (collectors?: CollectorDTO[]) => {
      const meterNumbers = (collectors || [])
        .filter((collector) => collector.meterNumber)
        .map((collector) => collector.meterNumber);

      const uniqueMeterNumbers = sortBy({
        array: getUniqArrayValues(meterNumbers as string[]),
      });

      return getMultiselectOptions({
        array: uniqueMeterNumbers,
        withEmptyOption,
      });
    },
    [withEmptyOption],
  );

  const {
    isLoading: isMeterNumbersLoading,
    setSelectedOptions: setSelectedMeterNumbers,
    selectedOptions: selectedMeterNumbers,
    options: meterNumbersOptions,
    selectedOptionValues: meterNumbers,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useAllCollectors,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.METER_NUMBERS,
  });

  return {
    isMeterNumbersLoading,
    selectedMeterNumbers,
    setSelectedMeterNumbers,
    meterNumbersOptions,
    meterNumbers: meterNumbers as string[],
  };
};
