import { SelectedRows, createSelectedRowsRecoil } from "./selected-rows-guid";
import { createSelectedRadioRowsRecoil } from "./selected-rows-guid-radio";

export const {
  selectedRowsState: selectedEmissionFactorsState,
  selectedRowsCount: selectedEmissionFactorsCount,
  useSetSelectedRow: useSetSelectedEmissionFactors,
  useSelectedRows: useSelectedEmissionFactors,
  useSetFromIntermediateSelection:
    useSetFromIntermediateSelectionEmissionFactors,
  useSetDefaultSelection: useSetDefaultEmissionFactorsSelection,
  useGetIsIntermediateSelection: useGetIsIntermediateSelectionEmissionFactors,
} = createSelectedRowsRecoil("EmissionFactors");

export type SelectedEmissionFactors = SelectedRows;

export const {
  selectedRadioRowsState: selectedEmissionFactorsRadioState,
  selectedRadioRowsCount: selectedEmissionFactorsRadioCount,
  selectedRadioRowsGWPState: useSetSelectedRadioGWPRow,
  useSetSelectedRadioRow: useSetSelectedEmissionFactorsRadio,
  useSelectedRadioRows: useSelectedEmissionFactorsRadio,
  useSetFromIntermediateSelection:
    useSetFromIntermediateSelectionRadioEmissionFactors,
  useSetDefaultSelection: useSetDefaultRadioEmissionFactorsSelection,
  useGetIsIntermediateSelection:
    useGetIsIntermediateSelectionEmissionFactorsRadio,
} = createSelectedRadioRowsRecoil("EmissionFactorsRadio");

export type SelectedRadioEmissionFactors = SelectedRows;
