import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_KEYS } from "consts";

import { HookWithQueryParams, MaybeNull } from "types";

import { getParsedQueryParams } from "utils";

export const useMonthPeriodFilter = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: HookWithQueryParams) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialQueryReadRef = useRef<boolean>(true);
  const [month, setMonth] = useState<MaybeNull<Dayjs>>(null);

  useEffect(() => {
    if (isInitialQueryReadRef.current && withQueryParams) {
      const monthFromSearchParams = Number.parseInt(
        searchParams.get(QUERY_PARAMS_KEYS.MONTH) || "",
        10,
      );

      if (
        !Number.isNaN(monthFromSearchParams) &&
        monthFromSearchParams > 0 &&
        monthFromSearchParams < 13
      ) {
        setMonth(dayjs(`${dayjs().year()}-${monthFromSearchParams}-01`));
      }
      isInitialQueryReadRef.current = false;
    }
  }, [searchParams, withQueryParams]);

  const onMonthChange = useCallback(
    (m: Dayjs | null) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setMonth(m || null);

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        if (!m || Number.isNaN(m?.month())) {
          delete queryParams[QUERY_PARAMS_KEYS.MONTH];
        } else {
          queryParams[QUERY_PARAMS_KEYS.MONTH] = String(m.month() + 1);
        }

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
          }),
        );
      }
    },
    [
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  return {
    month,
    monthValue: month ? month.month() + 1 : undefined,
    onMonthChange,
  };
};
