import {
  TypeElectricity,
  TypeLiquefiedPetroleumGas,
  TypeNaturalGas,
  TypeOtherFuels,
  TypeRefrigerants,
  TypeSewer,
  TypeVehicleFuel,
  TypeWater,
} from "assets";

export const UTILITY_BILL_TYPES = {
  ELECTRICITY: "ELECTRICITY",
  "NATURAL GAS": "NATURAL GAS",
  "LP GAS": "LP GAS",
  GASOLINE: "GASOLINE",
  REFRIGERANTS: "REFRIGERANTS",
  WATER: "WATER",
  SEWER: "SEWER",
  "OTHER FUELS": "OTHER FUELS",
  "VEHICLE FUEL": "VEHICLE FUEL",
} as const;

export type UtilityBillTypeKey = keyof typeof UTILITY_BILL_TYPES;

export const UTILITY_BILL_TYPE_TO_ICON = {
  [UTILITY_BILL_TYPES.ELECTRICITY]: TypeElectricity,
  [UTILITY_BILL_TYPES["NATURAL GAS"]]: TypeNaturalGas,
  [UTILITY_BILL_TYPES["LP GAS"]]: TypeLiquefiedPetroleumGas,
  [UTILITY_BILL_TYPES.GASOLINE]: TypeVehicleFuel,
  [UTILITY_BILL_TYPES.REFRIGERANTS]: TypeRefrigerants,
  [UTILITY_BILL_TYPES.WATER]: TypeWater,
  [UTILITY_BILL_TYPES.SEWER]: TypeSewer,
  [UTILITY_BILL_TYPES["OTHER FUELS"]]: TypeOtherFuels,
  [UTILITY_BILL_TYPES["VEHICLE FUEL"]]: TypeVehicleFuel,
} as const;

export const UTILITY_BILL_TYPE_TO_MESSAGE = {
  [UTILITY_BILL_TYPES.ELECTRICITY]: "Electricity",
  [UTILITY_BILL_TYPES["NATURAL GAS"]]: "Natural gas",
  [UTILITY_BILL_TYPES["LP GAS"]]: "LP gas",
  [UTILITY_BILL_TYPES.GASOLINE]: "Gasoline",
  [UTILITY_BILL_TYPES.REFRIGERANTS]: "Refrigerants",
  [UTILITY_BILL_TYPES.WATER]: "Water",
  [UTILITY_BILL_TYPES.SEWER]: "Sewer",
  [UTILITY_BILL_TYPES["OTHER FUELS"]]: "Other fuels",
  [UTILITY_BILL_TYPES["VEHICLE FUEL"]]: "Vehicle fuel",
} as const;

export const UTILITY_BILL_TYPE_BG_COLORS = {
  [UTILITY_BILL_TYPES.ELECTRICITY]: "#E6E8EC",
  [UTILITY_BILL_TYPES["NATURAL GAS"]]: "#E9E3DB",
  [UTILITY_BILL_TYPES["LP GAS"]]: "#E9E3DB",
  [UTILITY_BILL_TYPES.GASOLINE]: "#E9E3DB",
  [UTILITY_BILL_TYPES.REFRIGERANTS]: "#E3EBEA",
  [UTILITY_BILL_TYPES.WATER]: "#DBE7F1",
  [UTILITY_BILL_TYPES.SEWER]: "#E4E9E3",
  [UTILITY_BILL_TYPES["OTHER FUELS"]]: "#E6E8EC",
  [UTILITY_BILL_TYPES["VEHICLE FUEL"]]: "#E9E3DB",
} as const;
