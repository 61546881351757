import { useCallback } from "react";

import { AllowedValuesDTO } from "api";

import { UTILITY_BILL_TYPE_TO_MESSAGE } from "components";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";
import { OptionalMultiselectProps } from "./types";
import { getMultiselectOptions } from "./utils";

import { useGetAllowedValues } from "../api";

export const useUtilityTypesMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (allowedValues?: AllowedValuesDTO) => {
      const utilityTypesAscendingOrder = sortBy({
        array: (allowedValues || { utilityTypes: [] }).utilityTypes,
        key: "name",
      });

      return getMultiselectOptions({
        array: utilityTypesAscendingOrder,
        labelKey: "name",
        labelMapObject: UTILITY_BILL_TYPE_TO_MESSAGE,
        valueKey: "id",
        withEmptyOption,
        dropdownType: "Utility",
      });
    },
    [withEmptyOption],
  );

  const {
    isLoading: isUtilityTypesLoading,
    setSelectedOptions: setSelectedUtilityTypes,
    selectedOptions: selectedUtilityTypes,
    options: utilityTypesOptions,
    selectedOptionValues: utilityTypeIds,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.UTILITY_TYPE_IDS,
  });

  return {
    isUtilityTypesLoading,
    setSelectedUtilityTypes,
    selectedUtilityTypes,
    utilityTypesOptions,
    utilityTypeIds: utilityTypeIds as number[],
  };
};
