import { Box } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { ResizableBox } from "react-resizable";

import { MaybeNull } from "types";

import { PdfDocument, PdfEmptyData, PdfPagination } from "./components";
import {
  PDF_DOCUMENT_DEFAULT_HEIGHT,
  PDF_DOCUMENT_DEFAULT_WIDTH,
} from "./consts";
import "./css/style.css";

interface PdfPreviewProps {
  downloadUrl: MaybeNull<string>;
  scale: number;
  isScaleChange: boolean;
  billId?:any;
}

export const PdfPreview: FunctionComponent<PdfPreviewProps> = ({
  downloadUrl,
  scale,
  isScaleChange,
  billId,
}) => {
  const [numberOfPages, setNumberOfPages] = useState<null | number>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [resizeWidth, setResizeWidth] = useState(PDF_DOCUMENT_DEFAULT_WIDTH);
  const [isLoading, setIsLoading] = useState(true);

  const file = downloadUrl;


  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setPageNumber(1);
    setIsLoading(false);
  };

  const onPageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };
  const onResize = (_: any, { size }: any) => {
    setResizeWidth(size.width);
  };

  return (
    <ResizableBox
      height={PDF_DOCUMENT_DEFAULT_HEIGHT}
      width={resizeWidth}
      onResize={onResize}
      minConstraints={[PDF_DOCUMENT_DEFAULT_WIDTH, PDF_DOCUMENT_DEFAULT_HEIGHT]}
      maxConstraints={[900, PDF_DOCUMENT_DEFAULT_HEIGHT]}
      resizeHandles={["e"]}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "background.default",
          borderRadius: 2,
          p: 3,
          pr: 5,
          minWidth: PDF_DOCUMENT_DEFAULT_WIDTH,
          position: "relative",
        }}
      >
        {!file ? (
          <PdfEmptyData />
        ) : (
          <>
            <PdfDocument
              width={resizeWidth}
              scale={scale}
              file={file}
              onLoadSuccess={onLoadSuccess}
              pageNumber={pageNumber}
              isScaleChange={isScaleChange}
              billIdExcel={billId}
            />

            {!isLoading && (
              <PdfPagination
                pageNumber={pageNumber}
                numberOfPages={numberOfPages}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </Box>
    </ResizableBox>
  );
};
