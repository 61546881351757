import {
  StatusApprovedIcon, // MatchedIcon,
  StatusCleanedIcon,
  StatusDeclinedIcon,
  StatusErrorIcon,
  StatusMissingIcon,
  StatusParsedIcon,
  StatusProcessingIcon,
  statusFlagIcon,
} from "assets";

export const UTILITY_BILL_STATUSES = {
  APPROVED: "APPROVED",
  DECLARED: "DECLARED",
  MATCHED: "MATCHED",
  CLEANED: "CLEANED",
  DECLINED: "DECLINED",
  ERROR: "ERROR",
  INCOMPLETE_DATA: "INCOMPLETE_DATA",
  PARSED: "PARSED",
  PROCESSING: "PROCESSING",
  VERIFICATION_FAILED: "VERIFICATION_FAILED",
  PENDING: "PENDING",
} as const;

export type UtilityBillStatusKey = keyof typeof UTILITY_BILL_STATUSES;

export const UTILITY_BILL_STATUS_TO_ICON = {
  [UTILITY_BILL_STATUSES.APPROVED]: StatusApprovedIcon,
  [UTILITY_BILL_STATUSES.DECLARED]: StatusDeclinedIcon,
  [UTILITY_BILL_STATUSES.MATCHED]: StatusErrorIcon,
  [UTILITY_BILL_STATUSES.CLEANED]: StatusCleanedIcon,
  [UTILITY_BILL_STATUSES.DECLINED]: StatusDeclinedIcon,
  [UTILITY_BILL_STATUSES.ERROR]: StatusErrorIcon,
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: StatusMissingIcon,
  [UTILITY_BILL_STATUSES.PARSED]: StatusParsedIcon,
  [UTILITY_BILL_STATUSES.PROCESSING]: StatusProcessingIcon,
  [UTILITY_BILL_STATUSES.PENDING]: StatusProcessingIcon,
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: statusFlagIcon,
} as const;

export const UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR = {
  [UTILITY_BILL_STATUSES.APPROVED]: "rgba(47, 139, 33, 0.12)",
  [UTILITY_BILL_STATUSES.DECLARED]: " rgba(255, 0, 0, 0.12)",
  [UTILITY_BILL_STATUSES.MATCHED]: "rgba(255, 121, 0, 0.12)",
  [UTILITY_BILL_STATUSES.CLEANED]: "rgba(47, 139, 33, 0.12)",
  [UTILITY_BILL_STATUSES.DECLINED]: "rgba(231, 2, 2, 0.12)",
  [UTILITY_BILL_STATUSES.ERROR]: "rgba(255, 121, 0, 0.12)",
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: " #ffe6e6",
  [UTILITY_BILL_STATUSES.PARSED]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.PROCESSING]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.PENDING]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: "#ede5ca",
} as const;

export const UTILITY_BILL_STATUS_TO_MESSAGE = {
  [UTILITY_BILL_STATUSES.APPROVED]: "Approved",
  [UTILITY_BILL_STATUSES.DECLARED]: "Declared",
  [UTILITY_BILL_STATUSES.MATCHED]: "Matched",
  [UTILITY_BILL_STATUSES.CLEANED]: "Cleaned",
  [UTILITY_BILL_STATUSES.DECLINED]: "Decline",
  [UTILITY_BILL_STATUSES.ERROR]: "Error",
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: "Incomplete Data",
  [UTILITY_BILL_STATUSES.PARSED]: "Parsed",
  [UTILITY_BILL_STATUSES.PROCESSING]: "Processing",
  [UTILITY_BILL_STATUSES.PENDING]: "Pending",
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: "Verification Failed",
} as const;
