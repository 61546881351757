import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

import { Box } from "@mui/system";
import { COLORS } from "components/consts";
import { DocumentOptionsList } from "../documentOptionsMenu-bar";
import { SettingsOptionsList } from "../settingsOptionMenu-bar";

import { LinkStyleless } from "../../link";
import { ResourcesOptionsList } from "../resourcesOptionMenu-bar";

interface SidebarItemProps {
  isOpen: boolean;
  icon: ReactNode;
  title: string;
  path?: string;
  isSelected?: boolean;
  renderMenu?: boolean;
  isScope3?: boolean;
}

export const SidebarItem: FunctionComponent<SidebarItemProps> = ({
  isOpen,
  icon,
  title,
  path,
  isSelected,
  renderMenu,
  isScope3 = false,
}) => {
  const sideBarItem = getSideBarItem({ isOpen, isSelected, icon, title });

  if (renderMenu) {
    return title === "Settings" ? (
      <SettingsOptionsList
        isOpen={isOpen}
        icon={icon}
        isSelected={isSelected}
        path={path || ""}
        isScope3={isScope3}
      />
    ) : title === "Resources" ?
      <ResourcesOptionsList
        isOpen={isOpen}
        icon={icon}
        isSelected={isSelected}
        isScope3={isScope3}
      />
      : (
        <DocumentOptionsList
          isOpen={isOpen}
          icon={icon}
          isSelected={isSelected}
          isScope3={isScope3}
        />
      );
  }

  if (path) {
    return <LinkStyleless to={path}>{sideBarItem}</LinkStyleless>;
  }

  return sideBarItem;
};

const getSideBarItem = ({
  isOpen,
  isSelected,
  icon,
  title,
}: Omit<SidebarItemProps, "path">) => {
  const modifiedTitles = () => {
    if (title.includes("Site management")) return <Box sx={{
      fontSize: '12px',
      color: COLORS.Romance,
      fontWeight: 400,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '16px',
    }}>{title.replace("Site management", "Site Mgmt")}
    </Box>
    if (title.includes("Emissions Overview")) return <Box sx={{
      fontSize: '12px',
      color: COLORS.Romance,
      fontWeight: 400,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '16px',
    }}>Emissions
    </Box>
    return <Box sx={{
      fontSize: '12px',
      color: COLORS.Romance,
      fontWeight: 400,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '16px',
    }}>{title}</Box>
  };
  return (
    <ListItem sx={{
      display: 'grid',
      justifyContent: !isOpen ? 'center' : 'flex-start',
      py: 0,
      my: "8px",
      ...(isOpen && { pl: 0 })
    }}>
      <ListItemButton
        sx={{
          display: 'block',
          justifyContent: 'center',
          borderRadius: 2,
          marginLeft: isOpen ? '15px' : '',
          alignItems: 'center',
          "&.Mui-selected": {
            width: isOpen ? '258px' : '85px',
            backgroundColor: "background.default",
            "& svg": {
              color: "primary.main",
            },
            ":hover": {
              backgroundColor: "background.default",
            },
          },
          "&.Mui-focusVisible": {
            backgroundColor: "background.default",
          },
          ":hover": {
            backgroundColor: "transparent",
            color: isSelected ? null : "primary.main",
            "& svg": {
              color: "primary.main",
            },
          },
        }}
        selected={isSelected}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ListItemIcon
            sx={{
              justifyContent: "center",
              minWidth: isOpen ? '25px' : '56px'
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            sx={{
              opacity: isOpen ? 1 : 0,
              fontSize: '16px',
              color: COLORS.Romance,
              marginLeft: isOpen ? '6px' : '',
              "& .MuiListItemText-primary": {
                fontWeight: 400,
                color: COLORS.Romance,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                lineHeight: '24px'
              },
            }}
          />
        </Box>

        {!isOpen && <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '11px', mt: '-5px' }}>{modifiedTitles()}</Box>}
      </ListItemButton>
    </ListItem>
  )
};