
import { MaybeNull } from "types";

import { buildQueryParams, getResData, httpClient } from "utils";
import { DocumentsQuery } from "./documents";

export interface FilesRepoDTO {
  documentFileName: MaybeNull<string>;
  uploader: MaybeNull<string>;
  uploadDate: MaybeNull<string>;
  fiscalYear: MaybeNull<string>;
  id: number;
  internalNotes: MaybeNull<string>;
}
export interface FoldersRepoDTO {
  name: MaybeNull<string>;
  id: number;

}

type UpdateFiles = Omit<FilesRepoDTO, "createDate" | "updateDate" | "users"> & {
  dataProviders: number[];
};

type UpdateFolders = Omit<FoldersRepoDTO, "createDate" | "updateDate" | "users"> & {
  dataProviders: number[];
};

export const FILESREPO_API_PATHS = {
  FOLDERREPO: "/folders",
  FILESREPO: "/documentupload",
  FILESREPOS: (filesId: number) => `/documentupload/${filesId}`,
  GETFILES: (filesId: number) => `/folder/${filesId}`,
  DELETE_FILES: (filesId: number) => `/documentupload/${filesId}`,
  UNDO_FILES: "/undodelete",
  UNDO_FILE: "/undodeletefile",
  DELETE_FOLDER: (filesId: number) => `/folder/${filesId}`,
  DOCUMENTS_UPLOADED: "/documentupload/file-uploaded",
  EXPORT_DOCUMENTSFILE: "filedownload",
  FOLDERUPDATE: "/folder",
  DOCUMENTS: (query?: DocumentsQuery) => {
    const baseUrl = "/documentupload";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },

};

export const getFolder = (): Promise<FilesRepoDTO[]> =>
  httpClient.get<FilesRepoDTO[]>(FILESREPO_API_PATHS.FOLDERREPO).then(getResData);

export const createfolder = (foldername: string) =>
  httpClient.post(FILESREPO_API_PATHS.FOLDERREPO, { folderName: foldername });

export const deleteFiles = (id: number) =>
  httpClient.delete(FILESREPO_API_PATHS.DELETE_FILES(id));

export const undoFiles = (idvalue: number) =>
  httpClient.put(`${FILESREPO_API_PATHS.UNDO_FILES}`, {}, { params: { id: idvalue } });

  export const undoFile = (idvalue: number) =>
  httpClient.put(`${FILESREPO_API_PATHS.UNDO_FILE}`, {}, { params: { id: idvalue } });

export const deleteFolder = (id: number) =>
  httpClient.delete(FILESREPO_API_PATHS.DELETE_FOLDER(id));

export const getFiles = (id: number): Promise<FilesRepoDTO[]> =>
  httpClient.get<FilesRepoDTO[]>(FILESREPO_API_PATHS.GETFILES(id)).then(getResData);

export const updatedFiles = (data: UpdateFiles) =>
  httpClient.put(FILESREPO_API_PATHS.FILESREPO, data);

export const updatedFolder = (data: UpdateFolders) =>
  httpClient.put(FILESREPO_API_PATHS.FOLDERUPDATE, data);


export const createDocumentsFolder = ({
  fileNames,
  folderId,
}: {
  fileNames: string[];
  folderId: number;
}) =>
  httpClient
    .post(
      FILESREPO_API_PATHS.DOCUMENTS(),
      fileNames.map((fileName) => ({ fileName, folderId })),
    )
    .then(getResData);


export const documentUploadedFiles = (id: number) =>
  httpClient.post(FILESREPO_API_PATHS.DOCUMENTS_UPLOADED, [{ id }]);

export const downloadFiles = (ids: string[]) =>
  httpClient.get(
    `${FILESREPO_API_PATHS.EXPORT_DOCUMENTSFILE}?${buildQueryParams({
      ids,
    })}`,
    { responseType: "blob" },
  );