import { Box, Button, InputAdornment, Stack } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { CollectorDTO } from "api";

import { InfoIcon, MinusIcon } from "assets";

import {
  AutocompleteField,
  COLORS,
  DeleteDialog,
  H5Bold,
  TextField,
  Tooltip,
  useDialogControls,
} from "components";

import { REQUIRED_VALIDATION_MESSAGE } from "consts";

import { useUserRole } from "recoils";

import {
  AllowedFormValues,
  FormCollector,
  SiteEditorFormFields,
} from "../types";

const TOOLTIP_TITLE =
  "Leave empty if utility type is billed directly to account";

const ERROR_STYLE = {
  border: `2px solid ${COLORS.red}`,
  p: 2,
};

const BORDER_BOTTOM = {
  borderBottom: `1px solid ${COLORS.lightGray}`,
};

export const Collectors: FunctionComponent<{
  allowedValues: AllowedFormValues;
  isDisabled: boolean;
  onDisableClick: (id: number | undefined) => void;
  DeActivatedCollectors: CollectorDTO[] | null;
  rejected: { updated: number[]; created: string[] };
}> = ({
  allowedValues,
  isDisabled,
  rejected,
  onDisableClick,
  DeActivatedCollectors,
}) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<{
    collectors: SiteEditorFormFields["collectors"];
  }>();
  const {
    fields: collectorsFields,
    append: addCollector,
    remove: removeCollector,
  } = useFieldArray({
    control,
    name: "collectors",
  });

  const { isAdmin } = useUserRole();
  const disabled = !isAdmin || !isDisabled;
  const { uploadTypes, utilityTypes, frequency, units, currencies } =
    allowedValues;
  const deleteDialogControls = useDialogControls();
  const [deleteCollectorId, setDeleteCollectorId] = useState(0);
  const [deActivatedCollectors, setDeActivatedCollectors] = useState<
    CollectorDTO[]
  >([]);

  const setCollectorToDelete = (id: number | undefined) => {
    deleteDialogControls.open();
    if (id) setDeleteCollectorId(id);
  };

  const deleteCollector = (id: number | undefined) => {
    onDisableClick(id);
    deleteDialogControls.close();
  };

  useEffect(() => {
    if (DeActivatedCollectors) {
      setDeActivatedCollectors(DeActivatedCollectors);
    }
  }, [DeActivatedCollectors]);

  return (
    <Box component="section" mb={3}>
      <H5Bold mb={3}>Utility Providers</H5Bold>

      {collectorsFields.map((collector, index, formCollectors) => {
        const {
          id: formId,
          isNewCollector,
          collectorId,
          accountNumber,
        } = collector;

        const isLastCollector = index === collectorsFields.length - 1;
        const isUpdateError =
          collectorId && rejected.updated.includes(collectorId);
        const isCreateError = rejected.created.includes(accountNumber);

        return (
          <Box
            key={formId}
            sx={{
              ...(!isLastCollector && BORDER_BOTTOM),
              ...((isUpdateError || isCreateError) && ERROR_STYLE),
            }}
            mb={2}
          >
            <Stack direction="row" mb={2} spacing={3}>
              <Box flex={1}>
                <Controller
                  name={`collectors.${index}.utilityTypeName`}
                  control={control}
                  rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
                  render={({ field }) => (
                    <AutocompleteField
                      textFieldProps={{
                        label: "Utility type",
                        message:
                          errors?.collectors?.[index]?.utilityTypeName?.message,
                        color: errors?.collectors?.[index]?.utilityTypeName
                          ?.message
                          ? "error"
                          : undefined,
                        disabled,
                      }}
                      autocompleteProps={{
                        ...field,
                        isOptionEqualToValue: (option, value) =>
                          (option as FormCollector["utilityTypeName"]).name ===
                          (value as FormCollector["utilityTypeName"]).name,
                        getOptionLabel: (option) =>
                          (option as FormCollector["utilityTypeName"]).name,
                        onChange: (e, data) => field.onChange(data),
                        options: utilityTypes,
                        disabled,
                      }}
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <TextField
                  label="Account number"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  fullWidth
                  {...register(`collectors.${index}.accountNumber`)}
                  message={errors?.collectors?.[index]?.accountNumber?.message}
                  color={
                    errors?.collectors?.[index]?.accountNumber?.message
                      ? "error"
                      : undefined
                  }
                  disabled={disabled}
                />
              </Box>

              <Box flex={1}>
                <TextField
                  label="Meter number"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  fullWidth
                  {...register(`collectors.${index}.meterNumber`)}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          sx={{ display: disabled ? "none" : "block" }}
                          title={TOOLTIP_TITLE}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>

            <Stack direction="row" mb={3} spacing={3}>
              <Box width="50%">
                <TextField
                  label="Utility provider"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  fullWidth
                  {...register(`collectors.${index}.providerName`)}
                  disabled={disabled}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name={`collectors.${index}.allowedDocuments`}
                  control={control}
                  rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
                  render={({ field }) => (
                    <AutocompleteField
                      textFieldProps={{
                        label: "Document type",
                        message:
                          errors?.collectors?.[index]?.allowedDocuments
                            ?.message,
                        color: errors?.collectors?.[index]?.allowedDocuments
                          ?.message
                          ? "error"
                          : undefined,
                        disabled,
                      }}
                      autocompleteProps={{
                        ...field,
                        onChange: (e, data) => field.onChange(data),
                        options: uploadTypes,
                        disabled,
                      }}
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name={`collectors.${index}.frequency`}
                  control={control}
                  rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
                  render={({ field }) => (
                    <AutocompleteField
                      textFieldProps={{
                        label: "Frequency",
                        message:
                          errors?.collectors?.[index]?.frequency?.message,
                        color: errors?.collectors?.[index]?.frequency?.message
                          ? "error"
                          : undefined,
                        disabled,
                      }}
                      autocompleteProps={{
                        ...field,
                        onChange: (e, data) => field.onChange(data),
                        options: frequency,
                        disabled,
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack direction="row" spacing={3} mt={3} mb={3}>
              <Box flex={1}>
                <Controller
                  name={`collectors.${index}.consumptionUnit`}
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      textFieldProps={{
                        label: "Consumption Unit",
                        message: errors?.collectors?.[index]?.consumptionUnit
                          ?.message as string,
                        color: errors?.collectors?.[index]?.consumptionUnit
                          ? "error"
                          : undefined,
                        disabled,
                      }}
                      autocompleteProps={{
                        ...field,
                        onChange: (e, data) => field.onChange(data),
                        options: units,
                        disabled,
                      }}
                    />
                  )}
                />
              </Box>

              <Box flex={1}>
                <Controller
                  name={`collectors.${index}.currency`}
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      textFieldProps={{
                        label: "Currency",
                        message: errors?.collectors?.[index]?.currency
                          ?.message as string,
                        color: errors?.collectors?.[index]?.currency
                          ? "error"
                          : undefined,
                        disabled,
                      }}
                      autocompleteProps={{
                        ...field,
                        onChange: (e, data) => field.onChange(data),
                        options: currencies,
                        disabled,
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>

            <Box>
              <Button
                sx={{ marginBottom: "24px", backgroundColor: "#dddddd" }}
                variant="outlined"
                onClick={() => setCollectorToDelete(collectorId)}
                disabled={disabled || isNewCollector}
              >
                De-activate
              </Button>
            </Box>

            {isNewCollector && (
              <Button
                sx={{ marginBottom: "24px" }}
                color="error"
                startIcon={<MinusIcon />}
                variant="outlined"
                onClick={() => {
                  removeCollector(index);
                }}
                disabled={disabled || formCollectors.length === 1}
              >
                Delete collector
              </Button>
            )}
          </Box>
        );
      })}
      <Button
        variant="outlined"
        onClick={() =>
          addCollector({
            allowedDocuments: "",
            utilityTypeName: utilityTypes[0],
            frequency: "",
            accountNumber: "",
            providerName: "",
            siteName: "",
            isNewCollector: true,
            id: 0,
            consumptionUnit: "",
            currency: "",
          })
        }
        disabled={disabled}
      >
        Add/delete Utility Provider
      </Button>
      <Box mt={3}>
        <H5Bold mb={3}>De-activated Utility Providers</H5Bold>
        {deActivatedCollectors.map((item, index) => {
          const isLastCollector = index === deActivatedCollectors.length - 1;
          return (
            <Box sx={{ ...(!isLastCollector && BORDER_BOTTOM) }} mb={3}>
              <Stack direction="row" mb={2} spacing={3}>
                <Box flex={1}>
                  <TextField
                    label="Utility type"
                    fullWidth
                    value={item.utilityTypeName}
                    disabled
                  />
                </Box>

                <Box flex={1}>
                  <TextField
                    label="Account number"
                    fullWidth
                    value={item.accountNumber}
                    message={
                      errors?.collectors?.[index]?.accountNumber?.message
                    }
                    color={
                      errors?.collectors?.[index]?.accountNumber?.message
                        ? "error"
                        : undefined
                    }
                    disabled
                  />
                </Box>

                <Box flex={1}>
                  <TextField
                    label="Meter number"
                    fullWidth
                    value={item.meterNumber}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            sx={{ display: disabled ? "none" : "block" }}
                            title={TOOLTIP_TITLE}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" mb={3} spacing={3}>
                <Box width="32%">
                  <TextField
                    label="Utility provider"
                    fullWidth
                    value={item.providerName}
                    disabled
                  />
                </Box>

                <Box flex={1}>
                  <TextField
                    label="Document type"
                    fullWidth
                    value={item.allowedDocuments}
                    disabled
                  />
                </Box>

                <Box flex={1}>
                  <TextField
                    label="Frequency"
                    fullWidth
                    value={item.frequency}
                    disabled
                  />
                </Box>
              </Stack>
              <Stack direction="row" mb={3} spacing={3}>
                <Box width="32%">
                  <TextField
                    label="Consumption Unit"
                    fullWidth
                    value={item.consumptionUnit}
                    disabled
                  />
                </Box>

                <Box flex={1}>
                  <TextField
                    label="Currency"
                    fullWidth
                    value={item.currency}
                    disabled
                  />
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => deleteCollector(deleteCollectorId)}
        target="Collector"
        title="De-activate Utility Provider?"
      />
    </Box>
  );
};
