import { Box } from "@mui/material";
import React, { FunctionComponent, memo } from "react";

import { InfoIcon } from "assets";

import { Tooltip } from "components";

import { MaybeNull } from "types";

export const EmissionCellTooltip: FunctionComponent<{
  explanation?: MaybeNull<string>;
}> = memo(({ explanation }) => {
  if (!explanation) {
    return null;
  }
  // Check if "Emissions Factors Applied:" is present in the explanation
  const indexOfEmissions = explanation.indexOf("Emissions Factors Applied:");
  // If present, split the explanation into two parts and apply styles
  const formattedExplanation =
    indexOfEmissions !== -1 ? (
      <pre>
        {[
          <span
            key="bold"
            style={{
              fontWeight: "1000",
              fontFamily: "Manrope",
              fontSize: "13px",
            }}
          >
            {explanation.substring(
              0,
              indexOfEmissions + "Emissions Factors Applied:".length,
            )}
          </span>,
          explanation.substring(
            indexOfEmissions + "Emissions Factors Applied:".length,
          ),
        ]}
      </pre>
    ) : (
      <pre>{explanation}</pre>
    );
  return (
    <Tooltip title={formattedExplanation}>
      <Box sx={{ mx: 1, mt: 1 }}>
        <InfoIcon height="12" width="12" />
      </Box>
    </Tooltip>
  );
});
