import { useCallback, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_KEYS } from "consts";

import { HookWithQueryParams, ParsedQueryParams } from "types";

import {
  getFromLocalStorage,
  getParsedQueryParams,
  setToLocalStorage,
} from "utils";

import { ROWS_PER_PAGE_OPTIONS } from "../react-table";

const getInitialPageSize = ({
  withQueryParams,
  searchParams,
  pageSizeLocalStorageKey,
}: {
  withQueryParams: boolean;
  searchParams: URLSearchParams;
  pageSizeLocalStorageKey: string;
}) => {
  const pageSizeFromSearchParam = Number.parseInt(
    searchParams.get(QUERY_PARAMS_KEYS.PAGE_SIZE) || "",
    10,
  );
  const pageSizeFromLocalStorage = Number.parseInt(
    getFromLocalStorage(pageSizeLocalStorageKey) || "",
    10,
  );

  return withQueryParams && !Number.isNaN(pageSizeFromSearchParam)
    ? pageSizeFromSearchParam
    : !Number.isNaN(pageSizeFromLocalStorage)
    ? pageSizeFromLocalStorage
    : ROWS_PER_PAGE_OPTIONS[2];
};

const getInitialPageNumber = (
  withQueryParams: boolean,
  searchParams: URLSearchParams,
) => {
  const pageNumberFromSearchParam = Number.parseInt(
    searchParams.get(QUERY_PARAMS_KEYS.PAGE_NUMBER) || "",
    10,
  );

  return withQueryParams && !Number.isNaN(pageNumberFromSearchParam)
    ? pageNumberFromSearchParam - 1
    : 0;
};

export const usePagination = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  pageSizeLocalStorageKey,
  withQueryParams = true,
}: HookWithQueryParams & {
  pageSizeLocalStorageKey: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageNumber, setPageNumber] = useState<number>(() =>
    getInitialPageNumber(withQueryParams, searchParams),
  );
  const [pageSize, setPageSize] = useState<number>(() =>
    getInitialPageSize({
      withQueryParams,
      searchParams,
      pageSizeLocalStorageKey,
    }),
  );

  const onPageChange = useCallback(
    (newPageNumber: number) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setPageNumber(newPageNumber - 1);

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
            [QUERY_PARAMS_KEYS.PAGE_SIZE]: String(pageSize),
            [QUERY_PARAMS_KEYS.PAGE_NUMBER]: String(newPageNumber),
          }),
        );
      }
    },
    [
      pageSize,
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setPageNumber(0);
      setPageSize(newPageSize);
      setToLocalStorage(pageSizeLocalStorageKey, `${newPageSize}`);

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        setSearchParams(
          createSearchParams({
            ...(getQueryParamsWithExternalChanges
              ? getQueryParamsWithExternalChanges(queryParams)
              : queryParams),
            [QUERY_PARAMS_KEYS.PAGE_SIZE]: String(newPageSize),
            [QUERY_PARAMS_KEYS.PAGE_NUMBER]: "1",
          }),
        );
      }
    },
    [
      pageSizeLocalStorageKey,
      searchParams,
      setSearchParams,
      updateExternalStates,
      getQueryParamsWithExternalChanges,
      withQueryParams,
    ],
  );

  const setInitialPage = useCallback(() => setPageNumber(0), []);
  const updateQueryParamsWithInitialPage = useCallback(
    (queryParams: ParsedQueryParams) => ({
      ...queryParams,
      [QUERY_PARAMS_KEYS.PAGE_NUMBER]: "1",
    }),
    [],
  );

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    onPageChange,
    onPageSizeChange,
    setInitialPage,
    updateQueryParamsWithInitialPage,
  };
};
