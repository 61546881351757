export const DASHBOARD_API_PATHS = {
  EMISSIONS_ON_MONTH: "/emissions/monthly",
  ESTIMATION_ON_MONTH: "/emissions/estimates/monthly",
  ESTIMATION_BY_SITE: "/emissions/estimates/sites",
  EMISSIONS_BY_SITE: "/emissions/sites",
  EMISSIONS_BY_UTILITY_TYPE: "/emissions/utilities",
  EMISSIONS_BY_FACILITY_TYPE: "/emissions/facilitytype",
  EMISSIONS_YEAR_ON_YEAR: "/emissions/yearly",
  EMISSIONS_BY_REGION: "/emissions/countryregions",
  EMISSIONS_BY_US_VS_ROW: "/emissions/countries",
  EMISSIONS_BY_SCOPE: "/emissions/scope",
  FINANCIALS_ON_MONTH: "/financial/monthly",
  FINANCIALS_BY_SITE: "/financial/sites",
  FINANCIALS_BY_UTILITY_TYPE: "/financial/utilities",
  FINANCIALS_BY_FACILITY_TYPE: "/financial/facilitytype",
  FINANCIALS_YEAR_ON_YEAR: "/financial/yearly",
  FINANCIALS_BY_REGION: "/financial/countryregions",
  FINANCIALS_BY_US_VS_ROW: "/financial/countries",
  FINANCIALS_BY_SCOPE: "/financial/scope",
  FINANCIALS_UNIT_LEVEL_COST: "/financial/unitlevelcost",
  FINANCIALS_EM_SPEND: "/financial/emissionperspend",
  SITE_SPECIFIC_EMISSIONS: "/sitespecific/emissions/account",
  SITE_SPECIFIC_CONSUMPTION: "/sitespecific/consumptions/account",
  SITE_SPECIFIC_SPEND_ACC: "/sitespecific/spendperaccount",
  CONSUMPTIONS_ON_MONTH: "/consumptions/monthly",
  CONSUMPTIONS_BY_SITE: "/consumptions/sites",
  CONSUMPTIONS_BY_FACILITY_TYPE: "/consumptions/facilitytype",
  CONSUMPTIONS_YEAR_ON_YEAR: "/consumptions/yearly",
  CONSUMPTIONS_BY_REGION: "/consumptions/countryregions",
  CONSUMPTIONS_BY_US_VS_ROW: "/consumptions/countries",
  EMISSIONS_BY_SCOPE_1:"/emissions/scope/monthly",
};
