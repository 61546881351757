import { useCallback } from "react";

import { ConfigParameterDTO } from "api";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useSingeSelectWithFetch } from "./singleselect";

import { useGetConfigParameter } from "../api";
import { OptionalMultiselectProps } from "../multiselect/types";

export const useScopeSelect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (allowedValues?: ConfigParameterDTO[]) => {
      const scopeValues = (allowedValues || [])
        .filter((value) => value.configValue === "true")
        .map((value) => value.name);
      const sortedValues = sortBy({
        array: scopeValues,
      });

      if (withEmptyOption) {
        sortedValues.push("");
      }
      return sortedValues;
    },
    [withEmptyOption],
  );

  const {
    isLoading: isScopesLoading,
    setSelectedOption: setSelectedScope,
    selectedOption: selectedScope,
    options: scopesList,
    selectedOptionValues: scopeValue,
  } = useSingeSelectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetConfigParameter,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.SCOPE,
  });

  return {
    isScopesLoading,
    setSelectedScope,
    selectedScope,
    scopesList,
    scopeValue,
  };
};
