/* eslint-disable react-hooks/exhaustive-deps */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
import {
  SSPEND_CS_ACC_HIGHCHARTS_DATA,
  SS_EM_ACC_HIGHCHARTS_DATA,
} from "../consts";
import {
  getRequiredYearDetails,
  parseSiteSpecificDataBasedOnAcct,
} from "../utils";

type Props = {
  siteSpecificAccData: unknown;
  siteSpendAccData: unknown;
  siteEMYearsList: string[];
  siteEmYear: string;
  setSiteEMyear: (data: string) => void;
  siteCostYearsList: string[];
  siteCostYear: string;
  setSiteCostYear: (data: string) => void;
};
export const SiteSpecificEmissions = ({
  siteSpecificAccData,
  siteSpendAccData,
  siteEMYearsList,
  siteEmYear,
  setSiteEMyear,
  siteCostYearsList,
  siteCostYear,
  setSiteCostYear,
}: Props) => {
  const [accountEmissionsHighChartsData, setAccountEmissionsHighChartsData] =
    useState(SS_EM_ACC_HIGHCHARTS_DATA);

  const [siteSpendAccHighChartsData, setSiteSpendAccHighChartsData] = useState(
    SSPEND_CS_ACC_HIGHCHARTS_DATA,
  );

  useEffect(() => {
    if (siteSpecificAccData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(siteSpecificAccData),
        siteEmYear.replace("FY", ""),
      );
      const { series, categories } = parseSiteSpecificDataBasedOnAcct(
        siteSpecificAccData,
        "co2Emission",
        reqYear,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(SS_EM_ACC_HIGHCHARTS_DATA),
      );
      updatedChartsData.series = series;
      updatedChartsData.xAxis.categories = categories;
      updatedChartsData.legend.enabled = false;
      setAccountEmissionsHighChartsData(updatedChartsData);
      if (updateYear) {
        setSiteEMyear(`FY${reqYear}`);
      }
    }
  }, [siteSpecificAccData, siteEmYear]);

  useEffect(() => {
    if (siteSpendAccData) {
      const { reqYear, updateYear } = getRequiredYearDetails(
        Object.keys(siteSpendAccData),
        siteCostYear.replace("FY", ""),
      );
      const { series, categories } = parseSiteSpecificDataBasedOnAcct(
        siteSpendAccData,
        "amount",
        reqYear,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(SSPEND_CS_ACC_HIGHCHARTS_DATA),
      );
      updatedChartsData.series = series;
      updatedChartsData.xAxis.categories = categories;
      updatedChartsData.legend.enabled = false;
      setSiteSpendAccHighChartsData(updatedChartsData);
      if (updateYear) {
        setSiteCostYear(`FY${reqYear}`);
      }
    }
  }, [siteSpendAccData, siteCostYear]);

  return (
    <>
      <ChartWrapper
        label="Emissions"
        yearOptions={siteEMYearsList}
        selectedYear={siteEmYear}
        setSelectedYear={setSiteEMyear}
        renderYearDropdown
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={accountEmissionsHighChartsData}
        />
      </ChartWrapper>
      <ChartWrapper
        label="Cost"
        yearOptions={siteCostYearsList}
        selectedYear={siteCostYear}
        setSelectedYear={setSiteCostYear}
        renderYearDropdown
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={siteSpendAccHighChartsData}
        />
      </ChartWrapper>
    </>
  );
};
