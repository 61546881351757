import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AUTH_ROUTES } from "providers";

import { SIGN_IN_PAGE, SignInPage } from "./sign-in";

export const UnauthenticatedRouter = () => (
  <Routes>
    <Route path={SIGN_IN_PAGE.ABSOLUTE_PATH} element={<SignInPage />} />
    <Route path={SIGN_IN_PAGE.FORM_PATH} element={<SignInPage isForm />} />
    {AUTH_ROUTES}
    <Route path="*" element={<Navigate to={SIGN_IN_PAGE.ABSOLUTE_PATH} />} />
  </Routes>
);
