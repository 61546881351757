import { SelectedRows, createSelectedRowsRecoil } from "./selected-rows";

export const {
  selectedRowsState: selectedUtilityBillsState,
  selectedRowsCount: selectedUtilityBillsCount,
  useSetSelectedRow: useSetSelectedUtilityBill,
  useSelectedRows: useSelectedUtilityBills,
  useSetFromIntermediateSelection: useSetFromIntermediateSelectionUtilityBills,
  useSetDefaultSelection: useSetDefaultUtilityBillsSelection,
  useGetIsIntermediateSelection: useGetIsIntermediateSelectionUtilityBill,
} = createSelectedRowsRecoil("UtilityBills");

export type SelectedUtilityBills = SelectedRows;
