import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import {
  H3Bold,
  Label12Regular,
  Label12SemiBold,
  Label16SemiBold,
  Label18SemiBold,
} from "components";

import { toUSNumber } from "utils";

export const ConsumptionSummary: FunctionComponent<{
  sitesEmissions: Omit<any, "siteAggregations">;
}> = ({ sitesEmissions }) => {
  const totalEmission = sitesEmissions.total_emission / 1000;
  const category1 = sitesEmissions.category1_total_emission / 1000;
  const category2 = sitesEmissions.category2_total_emission / 1000;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        border: `${
          sitesEmissions.declaredCount === 0 &&
          sitesEmissions.matchedCount === 0
            ? "1px solid green"
            : "1px solid orange"
        }`,
        borderRadius: "10px",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 1,
          flex: 1,
        }}
      >
        {sitesEmissions.declaredCount === 0 &&
        sitesEmissions.matchedCount === 0 ? (
          <Label18SemiBold sx={{ ml: 2 }}>
            Total Scope 3, Category 1 emissions
          </Label18SemiBold>
        ) : (
          <>
            <Label18SemiBold sx={{ ml: 2 }}>Inaccurate Data</Label18SemiBold>
            <Label12Regular sx={{ ml: 2 }}>
              Please ensure all data has been matched to an emission factor.
            </Label12Regular>
            {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Label16SemiBold sx={{ mb: "6px" }}>
                Category 1 :-
              </Label16SemiBold>
              <H3Bold>{toUSNumber(category1)}</H3Bold>
              <Label16SemiBold sx={{ mb: "6px" }}>tonnes C02eq</Label16SemiBold>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Label16SemiBold sx={{ mb: "6px" }}>
                Category 2 :-
              </Label16SemiBold>
              <H3Bold>{toUSNumber(category2)}</H3Bold>
              <Label16SemiBold sx={{ mb: "6px" }}>tonnes C02eq</Label16SemiBold>
            </Box> */}
          </>
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 2,
            flex: 1,
          }}
        />
        <Box sx={{ mr: 2 }}>
          <Box sx={{ display: "flex", alignItems: "flex-end", my: 1 }}>
            <Label12SemiBold sx={{ mb: "8px" }}>Category 1 :-</Label12SemiBold>
            <Label16SemiBold sx={{ ml: 1, mb: "8px" }}>
              {toUSNumber(category1)}
            </Label16SemiBold>
            <Label12SemiBold sx={{ ml: 1, mb: "6px" }}>
              tonnes C02eq
            </Label12SemiBold>
          </Box>

          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Label12SemiBold sx={{ mb: "8px" }}>Category 2 :-</Label12SemiBold>
            <Label16SemiBold sx={{ ml: 1, mb: "8px" }}>
              {toUSNumber(category2)}
            </Label16SemiBold>
            <Label12SemiBold sx={{ ml: 1, mb: "6px" }}>
              tonnes C02eq
            </Label12SemiBold>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", ml: 2, mr: 6, mt: 1 }}
        >
          <Label12SemiBold>Total Scope 3 Emissions</Label12SemiBold>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <H3Bold>{toUSNumber(totalEmission)}</H3Bold>
            <Label16SemiBold sx={{ ml: 1, mb: "6px" }}>
              tonnes C02eq
            </Label16SemiBold>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
