import { useQuery } from "@tanstack/react-query";

import {
  DetailSitesEmissionsQuery,
  SitesEmissionsDTO,
  SitesEmissionsDetailsDTO,
  SitesEmissionsQuery,
  SitesEmissionsQueryview,
  SitesScope3EmissionsQuery,
  getAuditLogsData,
  getDetailSitesEmissions,
  getScop3SitesEmissions,
  getSitesEmissions,
  getSitesEmissionsview,
} from "api";

import { QUERY_KEYS } from "consts";

export const useSitesEmissions = (query: SitesEmissionsQuery) =>
  useQuery<SitesEmissionsDTO>(
    [QUERY_KEYS.SITES_EMISSIONS, query],
    () => getSitesEmissions(query),
    {
      cacheTime: 0,
    },
  );

  export const useAuditLogs = (query: any) =>
    useQuery<any>(
      [QUERY_KEYS.AUDIT_LOGS, query],
      () => getAuditLogsData(query),
      {
        cacheTime: 0,
      },
    );

  export const updateAuditLogs = async (variables: SitesEmissionsQueryview) => {
    try {
      const data = await getAuditLogsData(variables);
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch data: ${error}`);
    }
   
  };

  export const updateSitesEmissionsViewAsOfDateMutation = async (variables: SitesEmissionsQueryview) => {
    try {
      const data = await getSitesEmissionsview(variables);
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch data: ${error}`);
    }
   
  };



export const useScop3SitesEmissions = (query: SitesScope3EmissionsQuery) =>
  useQuery<any>(
    [QUERY_KEYS.SITES_EMISSIONS, query],
    () => getScop3SitesEmissions(query),
    {
      cacheTime: 0,
    },
  );

export const useDetailSitesEmissions = (query: DetailSitesEmissionsQuery) =>
  useQuery<SitesEmissionsDetailsDTO>(
    [QUERY_KEYS.SITE_EMISSION_DETAIL, query],
    () => getDetailSitesEmissions(query),
    {
      cacheTime: 0,
    },
  );
