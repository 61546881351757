import { SendOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { FunctionComponent, useCallback, useRef, useState } from "react";

import { createSiteComment as createSiteCommentApi } from "api";

import { TextField } from "components";

import { useNotify } from "hooks";

import { MaybeNull } from "types";

import { UserSelectPopover } from "../components";

export const SiteCommentTextField: FunctionComponent<{
  estimateId: number;
  siteId?: number;
  parentId?: number;
  onSuccessfulSubmit?: () => void;
}> = ({ estimateId, parentId = null, onSuccessfulSubmit, siteId }) => {
  const notify = useNotify();
  const [isTask, setIsTask] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const { mutateAsync: createSiteComment, isLoading: isCreating } =
    useMutation(createSiteCommentApi);

  const userIdRef = useRef<MaybeNull<number>>(null);
  
  const onUserSelect = useCallback((userId: MaybeNull<number>) => {
    userIdRef.current = userId;
    setIsTask(!!userId);
  }, []);

  return (
    <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            pt: 1,
          }}
        >
          <FormControlLabel
            sx={{ ml: 1 }}
            control={
              <Checkbox
                checked={isTask}
                onChange={() => setIsTask((v) => !v)}
              />
            }
            label="Mark as task"
          />
          <UserSelectPopover onUserSelect={onUserSelect} siteId={siteId!} />
        </Box>
      <TextField
        fullWidth
        disabled={isCreating}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!value || isCreating}
                aria-label="send"
                onClick={() => {
                  createSiteComment(
                    {
                      assignedUserId: isTask && userIdRef.current ? userIdRef.current : null,
                      estimateId,
                      parentId,
                      siteId,
                      text: value
                    },
                    {
                      onSuccess: () => {
                        notify.success("Comment was successfully added!");
                        if (onSuccessfulSubmit) {
                          onSuccessfulSubmit();
                        }
                      },
                      onError: () => notify.error("Failed to add a comment!"),
                     
                    },
                  );
                  setValue("");
                }}
                edge="end"
              >
                <SendOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
