
import { ButtonGroup } from "@mui/material";
import React, {
  FunctionComponent, useState,

} from "react";

import {
  uploadintensity
} from "api";

import { UploadButton } from "components/buttons";

import { QUERY_KEYS } from "consts";


import { USER_ROLES } from "recoils";


export const DocumentsUploadIntensity: FunctionComponent<{ activeTab: boolean, apiResponse: any }> = ({ activeTab, apiResponse }) =>{
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [uploaddiseble, setUploadsdiseble] = useState(false);

  const handleApiResponse = (e: any) => {
    apiResponse(e)
  }

  return (
    <ButtonGroup
      orientation="horizontal"
      aria-label="upload files button group"
      sx={{
        display: "flex",
        width: "300px",
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
  
      <UploadButton
        variant="contained"
        label="Upload Spreadsheet"
        uploadApiCall={uploadintensity}
        queryKeyToInvalidate={activeTab ? QUERY_KEYS.INTENSITY : QUERY_KEYS.INTENSITYSITE}
        availableToRoles={[USER_ROLES.ADMIN, USER_ROLES.SITE_MANAGER]}
        extraReqParams={[["configuration", "true"]]}
        isScope3={activeTab}
        marginTop="0px"
        apiResponse={handleApiResponse}
        setUploadsdiseble={setUploadsdiseble}
      />
    </ButtonGroup>
  )
};
