/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// import { Box } from "@mui/system";
import PieChart from "highcharts-react-official";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";

import { Label14SemiBold } from "components";


drilldow(Highcharts);
export const ScopeEmissionPieChart = (sitesEmissionsData: any) => {
  const categories = ["Scope 1", "Scope 2"];
  const data = [
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope1Emission,
      color: "#ADD7FF",
      drilldown: {
        name: "Scope 1",
        categories: ["scope"],
        data: [sitesEmissionsData?.sitesEmissionsData?.totalScope1Emission],
      },
    },
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope2Emission,
      color: "#C9DFC5",
      drilldown: {
        name: "Scope 2",
        categories: ["scope"],
        data: [sitesEmissionsData?.sitesEmissionsData?.totalScope2Emission],
      },
    },
  ];

  const browserData = [];
  const versionsData = [];
  let i;
  let j;
  const dataLen = data.length;
  let drillDataLen;
  let brightness;

  // Build the data arrays
  for (i = 0; i < dataLen; i += 1) {
    // add browser data
    browserData.push({
      name: categories[i],
      y: data[i].y,
      color: data[i].color,
    });

    // add version data
    drillDataLen = data[i].drilldown.data.length;
    for (j = 0; j < drillDataLen; j += 1) {
      brightness = 0.2 - j / drillDataLen / 5;
      versionsData.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.data[j],
        color: Highcharts.color(data[i].color).brighten(brightness).get(),
      });
    }
  }

  // Create the chart
  const options: any = {
    chart: {
      type: "pie",
      height: 153,
      backgroundColor: "#fafafa",
      
    },
    title: {
      y: 59,
      x: 51,
      floating: true,
      text: `<span style="font-size: 14px"><span style="font-weight: bold;">&nbsp;&nbsp;${sitesEmissionsData.sitesEmissionsData.totalCo2Emission}</span></span>
          <br>
          <span style="font-size: 14px">tCO2eq</span>`,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
      },
    },
 
    tooltip: {
      valueSuffix: " tC02",
    },

    credits: {
      enabled: false,
    },
    legend: {
      align: "left",
      layout: "vertical",
      verticalAlign: "middle",
      itemMarginBottom: 15,
      lineHeight: 10,
      x: 5,
      itemStyle:{fontSize: "14px"},
      
     
    },
   
    series: [
      {
        name: "scope",
        data: browserData,
        size: "100%",
        innerSize: "90%",
        dataLabels: {
          enabled: false,
          color: "#FAFAFA",
          distance: 0,
        },
      },
    ],
   

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 100,
          },
          chartOptions: {
            series: [
              {},
              {
                id: "versions",
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#FAFAFA", width: "323px", height: "157px" , marginLeft:"10px" }}
      >
        <Label14SemiBold sx={{ mt: 2, ml: 3  , fontWeight:"bold"}}>Net Emissions</Label14SemiBold>
        <PieChart highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};