import { Box, Paper } from "@mui/material";
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { FunctionComponent, memo, useMemo } from "react";

import { AdaptedCollectorsMissingDocuments } from "api";

import {
  AvatarName,
  CloseButton,
  Data,
  H5Bold,
  Table,
  UtilityBillTypeChip,
  UtilityBillTypeKey,
} from "components";

import { DayCellMissingMonth } from "./table/month-cell";

const getColumn = (day: string) => ({
  header: day,
  accessorKey: day,
  cell: DayCellMissingMonth,
});

export const CollectorMonthView: FunctionComponent<{
  year: number;
  month: string;
  collectorMissingDocuments: AdaptedCollectorsMissingDocuments;
  onClose: () => void;
}> = memo(({ collectorMissingDocuments, year, month, onClose }) => {
  const monthValue = month.split("-")[1].padStart(2, '0');
  const monthNumber = parseInt(monthValue, 10);

  const columnKeys = useMemo(() => {
    if (collectorMissingDocuments?.days && month) {
      const daysForMonth = collectorMissingDocuments.days[monthValue];
      if (daysForMonth) {
        return Object.keys(daysForMonth).sort();
      }
    }
    return [];
  }, [collectorMissingDocuments, month, monthValue]);

  const columns1 = useMemo(
    () => columnKeys.slice(0, 15).map(getColumn),
    [columnKeys],
  );

  const columns2 = useMemo(
    () => columnKeys.slice(15).map(getColumn),
    [columnKeys],
  );

  const data: Data[] = useMemo(() => {
    if (
      collectorMissingDocuments &&
      collectorMissingDocuments.days &&
      monthValue
    ) {
      return [{ id: "days", ...collectorMissingDocuments.days[monthValue] }];
    }
    return [];
  }, [collectorMissingDocuments, monthValue]);

  const nonePaddingIndexes = useMemo(() => new Set(columnKeys), [columnKeys]);

  const table1 = useReactTable({
    data,
    columns: columns1 as unknown as ColumnDef<Data>[],
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const table2 = useReactTable({
    data,
    columns: columns2 as unknown as ColumnDef<Data>[],
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <H5Bold>
            {dayjs(`${year}-${monthNumber}-01`).format("MMMM YYYY")}
          </H5Bold>
          <AvatarName
            name={collectorMissingDocuments?.collector?.siteName || ""}
          />
          <UtilityBillTypeChip
            utilityType={
              collectorMissingDocuments?.collector
                ?.utilityTypeName as UtilityBillTypeKey
            }
          />
        </Box>
        <CloseButton onClick={onClose} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Table table={table1} nonePaddingColumnIds={nonePaddingIndexes} />
      </Box>
      <Table table={table2} nonePaddingColumnIds={nonePaddingIndexes} />
    </Paper>
  );
});
