import { useState } from "react";

export const useCustomYearsDropdownHook = ({
  yearsOptions = [],
  initialYear = "",
}: {
  yearsOptions?: string[];
  initialYear?: string;
}) => {
  const yearsList = [
    "FY2018",
    "FY2019",
    "FY2020",
    "FY2021",
    "FY2022",
    "FY2023",
    "FY2024",
  ];
  const initialYState = initialYear || yearsList[yearsList.length - 1];
  const [selectedYear, setSelectedYear] = useState(initialYState);

  return {
    selectedYear,
    setSelectedYear,
    yearsList: yearsOptions.length ? yearsOptions : yearsList,
  };
};
