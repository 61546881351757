import { AllowedValuesDTO, IntensityDTO } from "api";

import { AllowedFormValues, AllowedFormValuesCompany, FormCollector } from "./types";


export const mapFormCollectorsToRequestData = ({
  utilityTypes,
  siteCollectors,
  siteId,
}: {
  utilityTypes: AllowedFormValues["utilityTypes"];
  siteCollectors: FormCollector[];
  siteId: number;
}) =>
  siteCollectors.map((c) => ({
    accountNumber: c.accountNumber,
    allowedDocuments: [c.allowedDocuments],
    frequency: c.frequency,
    id: c.id,
    meterNumber: c.meterNumber,
    providerName: c.providerName,
    consumptionUnit: c.consumptionUnit,
    currency: c.currency,
    siteId,
    utilityTypeId:
      utilityTypes.find(
        (ut) => ut.name.toLowerCase() === c.utilityTypeName.name.toLowerCase(),
      )?.id || 0,
  }));

export const getAllowedValues = (
  allowedValues?: AllowedValuesDTO,
): AllowedFormValues => ({
  utilityTypes:
    allowedValues?.utilityTypes.map((ut) => ({
      name: ut.name,
      id: ut.id,
      unitDescription: ut.unitDescription.map((ud) => ({
        unit: ud.unit,
        description: ud.description,
      })),
    })) || [],
  countries: allowedValues?.countries || [],
  utilitySubtypesMap: allowedValues?.utilitySubtypesMap || {},
  frequency: allowedValues?.frequencies || [],
  regions: allowedValues?.regions || [],
  siteTypes: allowedValues?.siteTypes || [],
  statuses: allowedValues?.billStatuses || [],
  uploadTypes: allowedValues?.uploadTypes || [],
  siteStatuses: allowedValues?.siteStatuses || [],
  countryRegions: allowedValues?.countryRegions || [],
  currencies: allowedValues?.currencies || [],
  units: allowedValues?.units || [],
});

export const getAllowedValuesCompany = (
  allowedValues?: IntensityDTO,
): AllowedFormValuesCompany => ({
 
  companyName:allowedValues?.companyName && typeof allowedValues.companyName === 'string'
  ? allowedValues.companyName
  : null,
  siteName: allowedValues?.siteName && typeof allowedValues.siteName === 'string'
  ? allowedValues.companyName
  : null,
  intensityCategory:allowedValues?.intensityCategory && typeof allowedValues.intensityCategory === 'string'
  ? allowedValues.intensityCategory
  : null,
  value:allowedValues?.value && typeof allowedValues. value === 'string'
  ? allowedValues.value
  : null,
  unit:allowedValues?.unit && typeof allowedValues.unit === 'string'
  ? allowedValues.unit
  : null,
  uploadDate:allowedValues?.uploadDate && typeof allowedValues.uploadDate === 'string'
  ? allowedValues.uploadDate
  : null,
  startDate:allowedValues?.startDate && typeof allowedValues.startDate === 'string'
  ? allowedValues.startDate
  : null,
  endDate:allowedValues?.endDate && typeof allowedValues.endDate === 'string'
  ? allowedValues.endDate
  : null,

});
