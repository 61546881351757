import React, { FunctionComponent } from "react";

import { Site } from "./site";

import { SiteType } from "../types";

export const SiteAccordion: FunctionComponent<{
  data: any | SiteType;
}> = ({ data }) => (
  <Site isExpanded isNewSite={data.isNewSite} siteId={data.id} />
);
