import { Pagination, PaginationItem } from "@mui/material";
import React, { FunctionComponent } from "react";

import { ChevronLeftIcon, ChevronRightIcon } from "assets";

import { PaginationProps } from "./types";

export const Pages: FunctionComponent<
  Pick<PaginationProps, "onPageChange" | "page" | "numberOfPages"> & {
    disabledPages?: boolean;
  }
> = ({ onPageChange, page, numberOfPages, disabledPages = false }) => (
  <Pagination
    sx={{
      ".MuiPaginationItem-root": {
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
      ".MuiPagination-ul": {
        marginLeft: "16px",
        ".Mui-selected": {
          backgroundColor: "#dddddd",
        },
        ...(disabledPages && {
          ".MuiPaginationItem-page": {
            display: "none",
          },
          ".MuiPaginationItem-ellipsis": {
            display: "none",
          },
          ".MuiPaginationItem-previousNext": {
            ":first-of-type": {
              marginRight: "8px",
            },
          },
        }),
      },
      ".MuiPaginationItem-previousNext": {
        background: "#FFFFFF",
        "&:hover": { backgroundColor: "#FFFFFF" },
      },
    }}
    renderItem={(item) => (
      <PaginationItem
        sx={{
          width: 40,
          height: 40,
          borderRadius: "8px",
        }}
        components={{ previous: ChevronLeftIcon, next: ChevronRightIcon }}
        {...item}
      />
    )}
    shape="rounded"
    count={numberOfPages}
    page={page} // must start from 1
    onChange={(event: React.ChangeEvent<unknown>, value: number) => {
      onPageChange(value);
    }}
  />
);
