import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3, Scope3Context } from "pages";

import { useNewBreadcrumb } from "recoils";

import { checkIsScope3 } from "utils";

import { DOCUMENTS_ROUTER } from "./consts";
import { UTILITY_BILLS_PAGE, UtilityBillsRouter } from "./document-summary-v2";
import { DOCUMENT_UPLOAD_PAGE, DocumentUploadPage } from "./document-upload";
import { DocumentUploadRouter } from "./document-upload/router";
import { useDefaultSelectionWatcher } from "./hooks";
import {
  MISSING_DOCUMENTS_PAGE,
  MissingDocumentsPage,
} from "./missing-documents";

export const DocumentsRouter = () => {
  const { activeScope } = useContext(Scope3Context);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isScope3 = checkIsScope3(activeScope) && activeScope.length === 1;

  useEffect(() => {
    if (pathname === DOCUMENT_UPLOAD_PAGE.ABSOLUTE_PATH && isScope3)
      navigate(SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3.ABSOLUTE_PATH);
  }, [isScope3, pathname, navigate]);
  useNewBreadcrumb(DOCUMENTS_ROUTER.TITLE);
  useDefaultSelectionWatcher();

  return (
    <Routes>
      <Route path="/*" element={<DocumentUploadRouter />} />
      {/* <Route
        path="/"
        element={
          <RoutedTabs sx={{ bgcolor: COLORS.white }} tabs={DOCUMENTS_TABS} />
        }
      > */}
      <Route
        path={DOCUMENT_UPLOAD_PAGE.PATH}
        element={<DocumentUploadPage />}
      />
      <Route path={UTILITY_BILLS_PAGE.PATH} element={<UtilityBillsRouter />} />
      <Route
        path={MISSING_DOCUMENTS_PAGE.PATH}
        element={<MissingDocumentsPage />}
      />
      {/* </Route> */}
    </Routes>
  );
};
