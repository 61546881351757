import { Button, Stack } from "@mui/material";
import React, { FunctionComponent, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ChevronLeftIcon, ChevronRightIcon } from "assets";

import { COLORS, Label14SemiBold } from "components";

import { MaybeNull } from "types";

import { UTILITY_BILL_PAGE } from "pages";

import { useSelectedUtilityBills } from "recoils";

interface BillsPaginationProps {
  fileName: MaybeNull<string>;
}

const getPaginationButtonsStyle = ({ disabled }: { disabled: boolean }) => ({
  padding: 0,
  opacity: disabled ? "0.25" : "1",
  svg: { width: 24, height: 24, path: { fill: "#ffffff" } },
});

export const UtilityBillsPagination: FunctionComponent<
  BillsPaginationProps
> = ({ fileName }) => {
  const selectedUtilityBills = useSelectedUtilityBills();

  const { billId } = useParams();
  const navigate = useNavigate();

  const utilityBillsSelected = selectedUtilityBills.length
    ? selectedUtilityBills
    : [billId];

  const currentUtilityBillIndex = utilityBillsSelected.findIndex(
    (id) => id === billId,
  );

  const isFirstBill = currentUtilityBillIndex === 0;
  const isLastBill =
    selectedUtilityBills.length !== 0
      ? currentUtilityBillIndex === selectedUtilityBills.length - 1
      : true;

  const onPreviousClick = useCallback(() => {
    navigate(
      `${UTILITY_BILL_PAGE.ABSOLUTE_PATH(
        selectedUtilityBills[currentUtilityBillIndex - 1],
      )}`,
    );
  }, [currentUtilityBillIndex, navigate, selectedUtilityBills]);

  const onNextClick = useCallback(
    () =>
      navigate(
        `${UTILITY_BILL_PAGE.ABSOLUTE_PATH(
          selectedUtilityBills[currentUtilityBillIndex + 1],
        )}`,
      ),
    [currentUtilityBillIndex, navigate, selectedUtilityBills],
  );

  return (
    <Stack direction="row" alignItems="center">
      {fileName && <Label14SemiBold>{fileName}</Label14SemiBold>}
      <Button
        sx={getPaginationButtonsStyle({ disabled: isFirstBill })}
        disabled={isFirstBill}
        variant="text"
        onClick={onPreviousClick}
      >
        <ChevronLeftIcon />
      </Button>

      <Stack direction="row" alignItems="center">
        <Label14SemiBold
          sx={{
            minWidth: 50,
            background: "#ffffff",
            marginRight: 1,
            padding: "10px 16px",
            color: COLORS.menuItemColor,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          {currentUtilityBillIndex + 1}
        </Label14SemiBold>

        <Label14SemiBold>/</Label14SemiBold>

        <Label14SemiBold>{utilityBillsSelected.length}</Label14SemiBold>
      </Stack>

      <Button
        sx={getPaginationButtonsStyle({ disabled: isLastBill })}
        disabled={isLastBill}
        variant="text"
        onClick={onNextClick}
      >
        <ChevronRightIcon />
      </Button>
    </Stack>
  );
};
