import SendIcon from "@mui/icons-material/Send";
import { Box, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { CSVLink } from "react-csv";

import {
  COLORS,
  DatePickerField,
  Loading,
  MarginWrapper,
  MultiselectControlledField,
  Switch,
  usePagination,
  useTableExpandedRows,
  useYearMonthPeriodFilter,
  useYearPeriodFilter,
} from "components";
import { YearlyDateRangePicker } from "components/period-filters/year-period-filter/YearlyDateRangePicker";

import {
  updateSitesEmissionsViewAsOfDateMutation,
  useGetAllowedValues,
  useSitesEmissions,
  useSitesMultiselect,
  useUtilityTypesMultiselect,
} from "hooks";

import { LOCAL_STORAGE_KEYS } from "consts";

import { ParsedQueryParams } from "types";

import { useNewBreadcrumb } from "recoils";

import { getDateFormatmonthset } from "utils";

import { ConsumptionSummary } from "./components";
import { SITE_EMISSIONS_COLUMNS_MAIN } from "./components/site-emissions-table/columnsMain";
import { SITES_EMISSIONS_PAGE } from "./consts";

import AddDateModel from "../documents/missing-documents/components/AddDateModel";

const currentDate = new Date();
const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const lastDay = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0,
);

const initialState = {
  isYearly: false,
  startDate: moment(firstDay).format("YYYY-MM-DD"),
  endDate: moment(lastDay).format("YYYY-MM-DD"),
  fromDate: null,
  toDate: null,
  view: "monthly",
};

function reducer(state: any, action: any) {
  let isYearly;
  let period;

  switch (action.type) {
    case "SET_YEARLY":
      ({ isYearly, period } = action.payload);
      return {
        ...state,
        isYearly,
        view: isYearly ? "yearly" : "monthly",
        startDate: isYearly
          ? period?.startOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-01`,
        endDate: isYearly
          ? period?.endOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
      };
    case "SET_FROM_DATE":
      return {
        ...state,
        fromDate: action.payload.value,
        view: "daterange",
        startDate: action.payload.value,
      };
    case "SET_TO_DATE":
      return {
        ...state,
        toDate: action.payload.value,
        view: "daterange",
        endDate: action.payload.value,
      };
    case "SET_START_AND_END_DATES":
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case "CLEAR_DATES":
      return {
        ...state,
        fromDate: null,
        toDate: null,
        startDate: moment(firstDay).format("YYYY-MM-DD"),
        endDate: moment(lastDay).format("YYYY-MM-DD"),
        isYearly: false,
        view: "monthly",
      };
    default:
      return state;
  }
}

export const SitesEmissionsPage = () => {
  useNewBreadcrumb(SITES_EMISSIONS_PAGE.TITLE);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [clearDate, setClearDate] = useState(false);
  const { isYearly, startDate, endDate, fromDate, view } = state;

  const [dateError, setDateError] = useState(false);
  const [dateDisable, setDateDisable] = useState(false);

  useNewBreadcrumb(SITES_EMISSIONS_PAGE.TITLE);
  const { setInitialExpanded, updateQueryParamsWithInitialExpanded } =
    useTableExpandedRows();

  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.EMISSION_SUMMARY_SIZE,
    updateExternalStates: setInitialExpanded,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialExpanded,
  });
  const updateExternalStates = useCallback(() => {
    setInitialPage();
    setInitialExpanded();
  }, [setInitialPage, setInitialExpanded]);

  const getQueryParamsWithExternalChanges = useCallback(
    (queryParams: ParsedQueryParams) =>
      updateQueryParamsWithInitialExpanded(
        updateQueryParamsWithInitialPage(queryParams),
      ),
    [updateQueryParamsWithInitialExpanded, updateQueryParamsWithInitialPage],
  );
  const { period, availableYears } = useYearMonthPeriodFilter({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    isYearly,
  });

  const { year, onYearChange } = useYearPeriodFilter({
    updateExternalStates: undefined,
    withQueryParams: undefined,
    useYears: undefined,
    initialValue: dayjs().utc().year(),
  });

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>([]);

  const {
    siteIds,
    isSitesLoading,
    selectedSites,
    setSelectedSites,
    sitesOptions,
  } = useSitesMultiselect({ withQueryParams: false });

  const {
    utilityTypeIds,
    isUtilityTypesLoading,
    selectedUtilityTypes,
    setSelectedUtilityTypes,
    utilityTypesOptions,
  } = useUtilityTypesMultiselect({
    withQueryParams: false,
  });

  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-01-01`,
  ).utc();
  const maxDate = dayjs().utc();
  const [dateFrom, setDateFrom] = useState("");
  const [dateFromToDate, setDateFromToDate] = useState<any>({});
  const handleFromDateChange = (date: any) => {
    setDateFrom(date);
    const firstDate = new Date(date);
    const firstDateOfMonth = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    const fromDateValue = moment(formattedFirstDate).format("YYYY-MM-DD");
    if (fromDateValue && dateFromToDate?.toDateValue < fromDateValue) {
      setDateError(true);
    } else {
      setDateError(false);
      setDateFromToDate({
        fromDateValue,
        toDateValue: dateFromToDate?.toDateValue,
      });
    }
  };

  const handleToDateChange = (date: any) => {
    const firstDate = new Date(dateFrom);
    const firstDateOfMonth = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    const fromDateValue = moment(formattedFirstDate).format("YYYY-MM-DD");

    const lastDate = new Date(date);
    const lastDateOfMonth = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth() + 1,
      0,
    );
    const formattedLastDate = lastDateOfMonth;
    const toDateValue = moment(formattedLastDate).format("YYYY-MM-DD");

    // for get from date value hear code if user not select from date
    let formattedFirstDateOfYear;
    if (toDateValue) {
      const [Year] = toDateValue.split("-").map(Number);
      const firstDateOfYear = new Date(Year, 0, 1);
      formattedFirstDateOfYear = `${firstDateOfYear.getFullYear()}-${String(
        firstDateOfYear.getMonth() + 1,
      ).padStart(2, "0")}-${String(firstDateOfYear.getDate()).padStart(
        2,
        "0",
      )}`;
    }
    // end code
    if (fromDateValue && toDateValue < fromDateValue) {
      if (toDateValue && fromDateValue === "Invalid date") {
        setDateFromToDate({
          fromDateValue: formattedFirstDateOfYear,
          toDateValue,
        });
      } else {
        setDateError(true);
      }
    } else if (fromDate === null) {
      setDateFromToDate({ fromDateValue, toDateValue });

      setDateError(false);
    } else {
      setDateFromToDate({ fromDateValue, toDateValue });
      setDateError(false);
    }
  };

  const handleSearchDates = () => {
    if (
      fromDate === null &&
      dateFromToDate?.fromDateValue !== undefined &&
      dateFromToDate?.toDateValue !== undefined
    ) {
      dispatch({
        type: "SET_FROM_DATE",
        payload: { value: dateFromToDate?.fromDateValue, period },
      });
      dispatch({
        type: "SET_TO_DATE",
        payload: { value: dateFromToDate?.toDateValue, period },
      });
      setDateDisable(true);
    } else if (
      dateFromToDate?.fromDateValue !== undefined &&
      dateFromToDate?.toDateValue !== undefined
    ) {
      dispatch({
        type: "SET_FROM_DATE",
        payload: { value: dateFromToDate?.fromDateValue, period },
      });
      dispatch({
        type: "SET_TO_DATE",
        payload: { value: dateFromToDate?.toDateValue, period },
      });
      setDateDisable(true);
    } else {
      const firstDate = new Date(dateFrom);
      const firstDateOfMonth = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        1,
      );
      const formattedFirstDate = firstDateOfMonth;

      dispatch({
        type: "SET_FROM_DATE",
        payload: {
          value: moment(formattedFirstDate).format("YYYY-MM-DD"),
          period,
        },
      });
    }
  };

  const handleClearDates = () => {
    dispatch({ type: "CLEAR_DATES", payload: null });
    setDateError(false);
    setClearDate(true);
    setTimeout(() => {
      setClearDate(false);
    }, 100);
    window.location.reload();
  };
  const clearDates = () => {
    dispatch({ type: "CLEAR_DATES", payload: null });
    setDateError(false);
    setClearDate(true);
    setDateFrom("");
    setTimeout(() => {
      setClearDate(false);
    }, 100);
    window.location.reload();
  };
  const handleSwitchChange = () => {
    dispatch({ type: "SET_YEARLY", payload: { isYearly: !isYearly, period } });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isYearly ? clearDates() : null;
  };

  useEffect(() => {
    dispatch({
      type: "SET_START_AND_END_DATES",
      payload: {
        startDate: isYearly
          ? year?.startOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-01`,
        endDate: isYearly
          ? year?.endOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
      },
    });
    // eslint-disable-next-line
  }, [period, year]);
  const [siteFilter, setSiteFilter] = useState("");

  const [siteView, setSiteView] = useState(false);
  const [viewAsOfData, setViewAsOfData] = useState<any>([]);
  const { data: sitesEmissions, isLoading } = useSitesEmissions({
    startDate,
    endDate,
    siteIds,
    utilityTypeIds,
    businessUnits: selectedBusinessUnit.map((item: any) => item.value).join(","),
    viewType: view,
  });

  // Update Mutation
  const updateMutation = useMutation(updateSitesEmissionsViewAsOfDateMutation);
  const { data: allowedValues } = useGetAllowedValues();

  const businessUnits = allowedValues?.businessUnits?.map((t) => ({
    value: t,
    label: t,
  }));

  useEffect(() => {
    const fetchData = async () => {
      updateMutation
        .mutateAsync({
          startDate,
          endDate,
          viewType: view,
          siteIds,
          utilityTypeIds,
          viewAsOfDate: moment(siteFilter).format("YYYY-MM-DD"),
        })
        .then((data: any) => {
          setViewAsOfData(data);
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
        });
    };
    if (siteFilter !== "") {
      setSiteView(true);
      fetchData();
      setTimeout(() => {
        setSiteView(false);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteFilter, isYearly]);

  const viewAsDate = (dateValue: any) => {
    setSiteFilter(dateValue);
  };

  const tableData: any = siteView
    ? viewAsOfData && viewAsOfData !== null
      ? viewAsOfData
      : []
    : sitesEmissions?.siteAggregations || [];

  const CsvEmissionSummary = tableData?.map((item: any) => ({
    "Created Date": moment(item.createDate).format("YYYY-MM-DD"),
    "Period Start Date": moment(startDate).format("YYYY-MM-DD"),
    "Period End Date": moment(endDate).format("YYYY-MM-DD"),
    Country: item.country,
    "Type of Site": item.sitetype,
    Site: item.sitename,
    "Utility Type": item.utilityName,
    Scope: item.scope,
    Source: item.source,
    Usage: item.usage,
    "Usage Unit": item.unit,
    "Renewable Energy Usage": item.recsUsage,
    "Renewable Energy Usage Unit": item.unit,
    "Standardized Usage": item.standardizedUsageValue,
    "Standardized Usage Unit": item.standardizedUsageUnit,
    Cost: item.cost,
    "Estimation Methodology": item?.estimationMethodology,
    Currency: item.currency,
    Emissions: item.co2Emission,
    "Emissions Unit": "tCO2eq",
    CO2: item.co2OnlyEmission,
    "CO2 Unit": "tCO2eq",
    "CH4-Fossil": item.ch4OnlyEmission,
    "CH4-Fossil Unit": "tCO2eq",

    "CH4 Non-Fossil": item.ch4NonFossilEmission,
    "CH4 Non_Fossil Unit": "tCO2eq",

    N2O: item.n2oOnlyEmission,
    "N2O Unit": "tCO2eq",

    "Emissions - Market": item.marketCo2Emission && `${item.marketCo2Emission}`,
    "Emissions - Market Unit": "tCO2eq",

    "CO2 - Market":
      item.marketCo2OnlyEmission && `${item.marketCo2OnlyEmission}`,
    "CO2 - Market Unit": "tCO2eq",

    "CH4 - Market":
      item.marketCh4OnlyEmission && `${item.marketCh4OnlyEmission}`,
    "CH4 - Market Unit": "tCO2eq",

    "CH4 Non-Fossil - Market":
      item.marketCh4NonFossilEmission && `${item.marketCh4NonFossilEmission}`,
    "CH4 Non-Fossil - Market Unit": "tCO2eq",

    "N2O - Market":
      item.marketN2oOnlyEmission && `${item.marketN2oOnlyEmission}`,
    "N2O - Market Unit": "tCO2eq",

    Area: item?.intensityArea && `${item.intensityArea}`,
    "Area Unit": item?.intensityArea && `${item.intensityAreaUnit}`,

    Production: item?.intensityProductUnit && `${item?.intensityProductUnit}`,
    "Production Unit":
      item?.intensityArea && `${item.unitsForIntensityProduct}`,

    Revenue: item?.intensityRevenue && `${item.intensityRevenue}`,
    "Revenue Currency": item?.intensityArea && `${item.intensityRevenueUnit}`,

    "Emissions Intensity by Area":
      item.emissionByIntensityArea && `${item.emissionByIntensityArea}`,
    "Emissions Intensity by Area Unit":
      item.emissionByIntensityArea && ` tCO2eq/${item.intensityAreaUnit}`,

    "Emissions Intensity by Production":
      item.emissionByIntensityProductUnit &&
      `${item.emissionByIntensityProductUnit}`,
    "Emissions Intensity by Production Unit":
      item.emissionByIntensityProductUnit &&
      ` tCO2eq/${item.unitsForIntensityProduct}`,

    "Emissions Intensity by Revenue":
      item.emissionByIntensityRevenue && `${item.emissionByIntensityRevenue}`,
    "Emissions Intensity by Revenue Unit":
      item.emissionByIntensityRevenue && ` tCO2eq/${item.intensityRevenueUnit}`,

    "Emissions Intensity by Area - Market":
      item.marketEmissionByIntensityArea &&
      `${item.marketEmissionByIntensityArea}`,
    "Emissions Intensity by Area - Market Unit":
      item.marketEmissionByIntensityArea && `tCO2eq/${item.intensityAreaUnit}`,

    "Emissions Intensity by Production - Market":
      item.marketEmissionByIntensityProductUnit &&
      `${item.marketEmissionByIntensityProductUnit}`,
    "Emissions Intensity by Production - Market Unit":
      item.marketEmissionByIntensityProductUnit &&
      `tCO2eq/${item.unitsForIntensityProduct}`,

    "Emissions Intensity by Revenue - Market":
      item.marketEmissionByIntensityRevenue &&
      `${item.marketEmissionByIntensityRevenue}`,
    "Emissions Intensity by Revenue - Market Unit":
      item.marketEmissionByIntensityRevenue &&
      `tCO2eq/${item.intensityRevenueUnit}`,
  }));

  return (
    <MarginWrapper>
      <Box
        sx={{
          "@media (max-width: 990px)": {
            left: "345px",
          },
          "@media (max-width: 1350px)": {
            right: "490px",
          },
          "@media (min-width: 1350px) and (max-width: 1450px)": {
            left: "710px",
          },
          "@media (min-width: 1450px)": {
            right: "590px",
          },
          width: "150px",
          "& span:first-child > button": {
            width: "150px",
          },
          position: "absolute",
          top: "-5px",
          right: isLoading ? "500px" : "420px",
          cursor: "pointer",
        }}
      >
        <AddDateModel
          viewAsDate={viewAsDate}
          disabled={!isYearly}
          year={year}
        />
      </Box>
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <Box sx={{ mt: isYearly ? 3 : 3 }}>
          <Switch
            isChecked={isYearly}
            label={isYearly ? "Yearly" : "Monthly"}
            onChange={handleSwitchChange}
          />
        </Box>
        {isYearly && (
          <Box sx={{ width: "160px" }}>
            <YearlyDateRangePicker
              label="Year"
              year={year}
              onYearChange={onYearChange}
              availableYears={availableYears}
            />
          </Box>
        )}

        <Box sx={{ minWidth: "180px", width: "fit-content" }}>
          <MultiselectControlledField
            disabled={isUtilityTypesLoading}
            label="Utility type"
            selectedValues={selectedUtilityTypes}
            setSelectedValues={setSelectedUtilityTypes}
            options={utilityTypesOptions}
            isAllSelectedOnEmptySelection
          />
        </Box>
        <Box sx={{ minWidth: "180px", width: "fit-content" }}>
          <MultiselectControlledField
            label="Sites"
            disabled={isSitesLoading}
            selectedValues={selectedSites}
            setSelectedValues={setSelectedSites}
            options={sitesOptions}
            isAllSelectedOnEmptySelection
          />
        </Box>
        {businessUnits && (
          <Box sx={{ minWidth: "180px", width: "fit-content" }}>
            <MultiselectControlledField
              label="Business Unit"
              disabled={isSitesLoading}
              selectedValues={selectedBusinessUnit}
              setSelectedValues={setSelectedBusinessUnit}
              options={businessUnits as any}
              isAllSelectedOnEmptySelection
            />
          </Box>
        )}
        {!isYearly && (
          <Stack
            direction="row"
            mb={2}
            spacing={2}
            sx={{ width: dateError ? "30%" : "22%" }}
          >
            <DatePickerField
              label="From Month"
              views={["year", "month"]}
              openTo="month"
              minDate={minDate}
              maxDate={maxDate}
              format={getDateFormatmonthset()}
              disableHighlightToday
              disableFuture
              onChange={() => {}}
              onAccept={handleFromDateChange}
              value={fromDate}
              clearDate={clearDate}
              placeholdervalue="MM, YYYY"
              disabled={dateDisable}
              emission
            />
            <DatePickerField
              label="To Month"
              views={["year", "month"]}
              openTo="year"
              minDate={minDate}
              maxDate={maxDate}
              format={getDateFormatmonthset()}
              disableHighlightToday
              disableFuture
              onChange={() => {}}
              onAccept={handleToDateChange}
              value={fromDate}
              clearDate={clearDate}
              placeholdervalue="MM, YYYY"
              disabled={dateDisable}
              emission
            />
            {dateError && (
              <Box sx={{ color: COLORS.Fuzzy_Brown, fontSize: "0.75rem" }}>
                Invalid date selection. Please choose a date after the From
                Date.
              </Box>
            )}
          </Stack>
        )}
        {!isYearly && (
          <Button
            sx={{
              width: "100px",
              paddingTop: "10px",
              border: "1px solid #000",
              marginTop: "22px",
            }}
            onClick={handleSearchDates}
            disabled={dateError}
          >
            Search
          </Button>
        )}

        <Button
          sx={{
            width: "120px",
            paddingTop: "10px",
            border: "1px solid #000",
            marginTop: "22px",
          }}
          onClick={handleClearDates}
        >
          Clear Dates
        </Button>
      </Box>

      {sitesEmissions && !isLoading && (
        <Box sx={{ mb: 2 }}>
          <ConsumptionSummary sitesEmissions={sitesEmissions} />
        </Box>
      )}

      {isLoading && <Loading />}
      <Stack sx={{ mb: 2 }}>
        <CSVLink
          style={{ textDecoration: "none" }}
          data={CsvEmissionSummary}
          filename="Emission_Summary_Table.csv"
        >
          <Button variant="contained" endIcon={<SendIcon />}>
            Export CSV
          </Button>
        </CSVLink>
      </Stack>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <MaterialReactTable
            muiTableContainerProps={{
              sx: {
                "& thead tr:nth-child(2)": {
                  height: "auto",
                  "& th:first-child": {
                    paddingLeft: "20px",
                  },
                },
                "& tbody tr td:first-child": {
                  paddingLeft: "20px",
                },
                "& tbody tr:first-child > td > p": {
                  maxWidth: "100%",
                },
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "45px",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "white",
              },
            }}
            columns={SITE_EMISSIONS_COLUMNS_MAIN as any}
            data={tableData}
            enableColumnResizing
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            enablePagination={false}
            enableRowVirtualization
            enableGrouping
          />
        )}
      </div>
    </MarginWrapper>
  );
};

export default SitesEmissionsPage;
