import { Avatar as MuiAvatar } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Label10SemiBold } from "components/typography";

import { MuiSx, ReactChildren } from "types";
import { COLORS } from "components/consts";

export const Avatar: FunctionComponent<ReactChildren & MuiSx> = ({
  children,
  sx,
}) => (
  <MuiAvatar sx={{ bgcolor: COLORS.Twilight_Blue, padding: 1, ...sx, border: '1px solid', borderColor: '#D9D9D9' }}>
    <Label10SemiBold
      sx={{
        color: COLORS.Romance,
      }}
      noWrap
    >
      {children}
    </Label10SemiBold>
  </MuiAvatar>
);
