import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";

export const SelectPendingSideBar: React.FunctionComponent<{
  data: any;
  openPendingModal: any;
}> = ({ data, openPendingModal }) => {
  const DATA = data?.suppliesComponent;
  const [selectedCoponentId, setselectedCoponentId] = useState();
  const [showSelect, setShowSelect] = useState(false);

  const boxStyle = {
    border: "1px solid #054254",
    p: 2,
    borderRadius: "10px",
  };

  const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
  }));

  const handleSelectPending = (details: any) => {
    openPendingModal(details);
    setselectedCoponentId(details.id);
  };

  useEffect(() => {
    const componentData = window.sessionStorage.getItem("ComponentData")
      ? JSON.parse(window.sessionStorage.getItem("ComponentData") || "")
      : [];
    const selectedComponentData = componentData.filter(
      (t: any) => t.component_id === selectedCoponentId,
    );
    setShowSelect(!selectedComponentData);
  }, [selectedCoponentId]);

  return (
    <>
      {DATA.map((details: any, index: number) => (
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box sx={boxStyle}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid gray",
                pb: 1,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontWeight: "bold", color: "#000" }}>
                Component {(index + 1).toString()}{" "}
              </Box>
              {showSelect && (
                <Box sx={{ fontSize: "0.75rem", color: "#000" }}>
                  (selected){" "}
                </Box>
              )}
              <Button
                sx={{ fontWeight: 600, color: "#054254" }}
                onClick={() => handleSelectPending(details)}
              >
                {details.activity_uuid_product_uuid === null
                  ? "Select Pending"
                  : "Edit Matches"}
                {/* Select Pending */}
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <>
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Supplier
                      </Box>
                      <Box>{data?.supplier_name}</Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Item Number
                      </Box>
                      <Box>{data?.supplier_item_number}</Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Description 1
                      </Box>
                      <Box>{data?.description_1}</Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  {details.component_type === "type1" && (
                    <Grid xs={2} sm={2} md={2}>
                      <Item>
                        <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                          Description 2
                        </Box>
                        <Box>
                          {details?.component_keyword}{" "}
                          {details?.component_composition_percent}%
                        </Box>
                      </Item>
                    </Grid>
                  )}
                  {/*  */}
                  {details.component_type === "type2" && (
                    <Grid xs={2} sm={2} md={2}>
                      <Item>
                        <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                          Description 3
                        </Box>
                        <Box>
                          {details?.component_keyword}{" "}
                          {details?.component_composition_percent}%
                        </Box>
                      </Item>
                    </Grid>
                  )}

                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>Unit</Box>
                      <Box>{data?.units_of_measure}</Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Quantity
                      </Box>
                      <Box>
                        {(data?.quantity as unknown as number).toFixed(4)}
                      </Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Converted units
                      </Box>
                      <Box>{data?.conversion_unit}</Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Converted Quantity
                      </Box>
                      <Box>
                        {(
                          data?.converted_quantity_purchased as unknown as number
                        ).toFixed(4)}
                      </Box>
                    </Item>
                  </Grid>
                  {/*  */}
                  <Grid xs={2} sm={2} md={2}>
                    <Item>
                      <Box sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Country
                      </Box>
                      <Box>{data?.country}</Box>
                    </Item>
                  </Grid>
                </>
              </Grid>
            </Box>
          </Box>
        </Typography>
      ))}
    </>
  );
};
