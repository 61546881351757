import { Box, Button } from "@mui/material";
import React, { FunctionComponent } from "react";

import { Comment2AlertIcon, TrashIcon } from "assets";

import { Dialog } from "./dialog";

import { FLEX_CENTER_POSITIONING_STYLE } from "../consts";

export const DeleteDialogIntensity: FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  onDeleteClick: () => void;
  title: string;
  filesrepo?: string;
  // foldersrepo?:string;
}> = ({ isOpen, onCancel, onDeleteClick, title, filesrepo }) => (
  <Dialog
    title={title}
    isOpen={isOpen}
    onClose={onCancel}
    actions={
      <Box>
        <Button variant="text" style={{ color: "#000302" }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          color="error"
          startIcon={<TrashIcon />}
          onClick={onDeleteClick}
        >
          {filesrepo ? <span>Delete</span> : <span>De-activate</span>}

        </Button>
      </Box>
    }
  >
    <Box
      sx={{
        backgroundColor: "error.light",
        p: 2,
        borderRadius: 2,
        mb: 3,
        color: "error.main",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box component="span" sx={{ ...FLEX_CENTER_POSITIONING_STYLE, mr: 1 }}>
        <Comment2AlertIcon />
      </Box>
      Warning! This action cannot be undone.
    </Box>
    <Box>
      {filesrepo ? <Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
        1 document will be permanently deleted from the application.
        The file and all associated data entries will be deleted. Are you sure
        you want to delete these document?
      </Box> :
        <Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
          This intensity metric will be de-activated. All intensity ratio calculations will also be removed from the platform.
        </Box>

      }
    </Box>

  </Dialog>
);
