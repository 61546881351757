/* eslint-disable */
import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { AutocompleteField, COLORS, TYPOGRAPHY_COMMON_STYLES } from "components";

import { ColorType } from "types";

export const SelectControlledField: FunctionComponent<{
  label?: string;
  disabled?: boolean;
  color?: ColorType;
  message?: string;
  selectedValues: string;
  options: string[];
  setSelectedValues: (data: string) => void;
}> = ({
  label,
  selectedValues,
  options,
  setSelectedValues,
  disabled = false,
  color,
  message,
}) => (
  <AutocompleteField
    textFieldProps={{
      label,
      disabled,
      placeholder: selectedValues || "-Select-",
      message,
      color,
    }}
    autocompleteProps={{
      disabled,
      value: selectedValues,
      disableClearable: true,
      blurOnSelect: false,
      disableCloseOnSelect: true,
      onChange: (e, data) => {
        setSelectedValues(data as string);
      },
      options,
      isOptionEqualToValue: (opt, val) => opt === val,
      renderTags: (value) => (
        <Box sx={{ ml: 1 }} component="span">
          <>{value}</>
        </Box>
      ),
      renderOption: (props, option: any) => {
        const isSelected = selectedValues.includes(option);
        return (
        <li
          {...props}
          style={{
            color: COLORS.Romance,
            fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
            fontSize: 14,
            lineHeight: "18px",
            background: isSelected ? '#F6F8FA' : 'white',
          }}
          // eslint-disable-next-line no-param-reassign
          onMouseEnter={(event) => {
            event.currentTarget.style.backgroundColor = '#F6F8FA';
            event.currentTarget.style.color = COLORS.Romance;
          }}
          // eslint-disable-next-line no-param-reassign
          onMouseLeave={(event) => {
            event.currentTarget.style.backgroundColor = isSelected ? '#F6F8FA' : 'white';
            event.currentTarget.style.color = COLORS.Romance;
          }}
        >
          {option}
        </li>
      )},
    }}
  />
);
