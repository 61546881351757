import { Stack } from "@mui/material";
import React from "react";

import { H6, Label14Medium } from "components/typography";

const HEADING = "Sorry, No result found.";
const BODY = "Please try another way.";

export const NoResultFound = () => (
  <Stack
    sx={{
      padding: "16px 18px",
      borderRadius: "8px",
      background: "rgba(4, 92, 195, 0.05)",
      alignItems: "center",
    }}
    spacing={1}
  >
    <H6>{HEADING}</H6>
    <Label14Medium mt={1}>{BODY}</Label14Medium>
  </Stack>
);

export default NoResultFound;
