import { InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import React, { FunctionComponent } from "react";

import { SearchIcon } from "assets";

import { CloseButton } from "../buttons";

interface SearchBarProps {
  disabled?: boolean;
  fullWidth?: boolean;
  onClear: () => void;
  onChange: (searchQuery: string) => void;
  placeholder?: string;
  search: string;
  sx?: SxProps<Theme>;
}

const defaultSx = { minWidth: 310 };

const SearchBar: FunctionComponent<SearchBarProps> = ({
  disabled,
  fullWidth,
  onClear,
  onChange,
  placeholder,
  search,
  sx,
}) => (
  <TextField
    sx={{ ...defaultSx, ...sx }}
    fullWidth={fullWidth}
    size="small"
    variant="outlined"
    onChange={(e) => onChange(e.target.value)}
    value={search}
    placeholder={placeholder || undefined}
    disabled={disabled}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment
          position="end"
          style={{ display: search === "" ? "none" : "flex" }}
          onClick={onClear}
        >
          <CloseButton />
        </InputAdornment>
      ),
    }}
  />
);

export { SearchBar };
