import React from "react";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types";

import {
  MultiSelectControlledOption,
  MultiselectControlledField,
} from "components";

export const MultiselectFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  options,
  disabled,
  isAllSelectedOnEmptySelection = false,
  control,
  name,
  rules,
}: {
  label?: string;
  disabled?: boolean;
  options: MultiSelectControlledOption[];
  isAllSelectedOnEmptySelection?: boolean;
  control: ControllerProps<TFieldValues, TName>["control"];
  name: ControllerProps<TFieldValues, TName>["name"];
  rules?: ControllerProps<TFieldValues, TName>["rules"];
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <MultiselectControlledField
        isAllSelectedOnEmptySelection={isAllSelectedOnEmptySelection}
        disabled={disabled}
        label={label}
        color={error?.message ? "error" : undefined}
        message={error?.message}
        selectedValues={field.value || []}
        options={options}
        setSelectedValues={(data) => field.onChange(data)}
      />
    )}
  />
);
