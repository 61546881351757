import { getResData, httpClient } from "utils";

export const CONFIG_PARAMETER_API_PATHS = {
  CONFIG_PARAMETER: "/config-parameter",
  UPDATE_FISCAL_YEAR: "/config-parameter/multiple",
  FISCAL_YEAR_SETTINGS: "/fiscalyearsettings"
};

export interface ConfigParameterDTO {
  configValue: string;
  id: number;
  name: string;
}

export interface AddConfigParameterDTO {
  id: number;
  value: string;
}

interface UpdateConfigParameterDTO extends Pick<ConfigParameterDTO, "id"> {
  value: string;
}

export const getConfigParameter = (): Promise<ConfigParameterDTO[]> =>
  httpClient
    .get<ConfigParameterDTO[]>(CONFIG_PARAMETER_API_PATHS.CONFIG_PARAMETER)
    .then(getResData);

export const updateConfigParameter = (body: UpdateConfigParameterDTO) =>
  httpClient.put(CONFIG_PARAMETER_API_PATHS.CONFIG_PARAMETER, body);


export const getFiscalYearSettings = (): Promise<ConfigParameterDTO[]> =>
  httpClient
    .get<ConfigParameterDTO[]>(CONFIG_PARAMETER_API_PATHS.FISCAL_YEAR_SETTINGS)
    .then(getResData);

export const updateFiscalYear = (body: UpdateConfigParameterDTO[]) =>
      httpClient.put(CONFIG_PARAMETER_API_PATHS.UPDATE_FISCAL_YEAR, body);