import { MultiSelectControlledOption } from "components";

import { isNullOrUndefined } from "utils";

import { EMPTY_OPTION } from "./consts";

export const getUniqArrayValues = (array: (string | number)[]) =>
  array.reduce<(string | number)[]>((acc, value) => {
    if (acc.indexOf(value) < 0) {
      acc.push(value);
    }

    return acc;
  }, []);

export const getMultiselectOptions = <T>({
  array,
  withEmptyOption,
  labelKey,
  valueKey,
  labelMapObject,
  dropdownType,
}: {
  array: any[];
  labelKey?: keyof T;
  labelMapObject?: Record<string, string | number>;
  valueKey?: keyof T;
  withEmptyOption?: boolean;
  dropdownType?: string;
}) => {
  const multiselectOptions: MultiSelectControlledOption[] = [];

  if (array.length) {
    if (typeof array[0] !== "object") {
      array.forEach((option) => {
        multiselectOptions.push({
          label: labelMapObject
            ? (labelMapObject[option] as string) || (option as string)
            : (option as string),
          value: option as string,
        });
      });
    } else if (labelMapObject && labelKey && valueKey) {
      array.forEach((option) => {
        multiselectOptions.push({
          label:
            (labelMapObject[option[labelKey]] as string) ||
            (option[labelKey] as string),
          value: option[valueKey] as string | number,
          ...(dropdownType === "Utility" && {
            consumptions: option?.consumptions,
            emissions: option?.emissions,
            financials: option?.financials,
          }),
        });
      });
    }

    if (withEmptyOption) {
      multiselectOptions.push(EMPTY_OPTION);
    }
  }

  return multiselectOptions.filter(
    (option) => !isNullOrUndefined(option.label),
  );
};
