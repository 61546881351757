import { Button, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { CSVLink } from "react-csv";
import { useRecoilValue } from "recoil";

import { deleteDocument } from "api";

import { FileDownloadIcon } from "assets";

import { DeleteDialog, useDialogControls } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import {
  selectedUploadedDocumentsCount,
  useSelectedUploadedDocuments,
  useSetDefaultUploadedDocumentsSelection,
} from "recoils";

import { getUTCDateFormat } from "utils";

export const EmissionActions: React.FunctionComponent<{ csvData: any }> = ({
  csvData,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const setDefaultSelection = useSetDefaultUploadedDocumentsSelection();
  const deleteDialogControls = useDialogControls();
  const { mutateAsync: deleteUploadedDocument } = useMutation(deleteDocument);
  const selectedCount = useRecoilValue(selectedUploadedDocumentsCount);
  const selected = useSelectedUploadedDocuments();
  const onDeleteClick = () => {
    Promise.allSettled(
      selected.map((id) =>
        deleteUploadedDocument(id, {
          onError: () =>
            notify.error(
              `Some error has happen while deleting documents: ${id}!`,
            ),
          onSuccess: () => notify.success("Selected documents were deleted!"),
        }),
      ),
    ).finally(() => {
      deleteDialogControls.close();
      setDefaultSelection();
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
    });
  };

  const EmissionSummaryCsvData = csvData.map((item: any) => ({
    "Supplier name": item.supplier_name,
    Category: item.category,
    "Item number": item.supplier_item_number,
    "Description 1": item.description_1,
    "Description 2": item.description_2,
    "Description 3": item.description_3,
    Country: item.country,
    "Amount of recycled content": item.amount_of_recycled_content,
    Comments: item.comments,
    "Unit of measure  ": item.units_of_measure,
    Quantity: item.quantity,
    "Conversion unit": item.conversion_unit,
    "Conversion unit value": item.conversion_unit_value,
    "Converted quantity purchased": item.converted_quantity_purchased,
    "Consumption period start date": dayjs(item?.consumptionPeriodStartDate)?.utc()?.format(getUTCDateFormat()),
    "Consumption period end date": dayjs(item?.consumptionPeriodEndDate)?.utc()?.format(getUTCDateFormat()),
    "ISIC Section": item.isic_section,
    "ISIC Classification": item.isic_classification,
    "CPC Classification": item.cpc_classification,
    "Ecoinvent product name": item.product_name,
    "Ecoinvent activity name": item.activity_name,
    Geography: item.geography,
    "Scope 3 emissions(tonnes CO2eq.)": (
      item.calculated_co2_emission / 1000
    ).toFixed(3),
  }));

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <CSVLink data={EmissionSummaryCsvData} filename="Emission-Summary.csv">
        <Button startIcon={<FileDownloadIcon />} variant="outlined">
          Download Emission by supplier
        </Button>
      </CSVLink>
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={onDeleteClick}
        selectedCount={selectedCount}
        target="document"
        title="Delete documents?"
      />
    </Stack>
  );
};
