import React from "react";

import { AuditLogIcon } from "assets";

export const AUDIT_LOGS_PAGE = {
  ABSOLUTE_PATH: "/audit-page",
  TITLE: "Audit Logs",
  ICON: <AuditLogIcon />,
  IS_TBD: false,
  renderMenu: false,
};
