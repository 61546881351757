import React, { useRef } from "react";
import { Box } from "@mui/material";
import { RecUploadIcon } from "assets"; // Assuming you have this icon
import { useNotify } from "hooks";
import { useMutation } from "@tanstack/react-query";
import { RecDocumentUploaded, createRecDocument } from "api/rec";
import { useParams } from "react-router-dom";
import { uploadDocumentToS3 } from "api";

export const RecPdfEmptyData: React.FunctionComponent<{
  isFileUploaded: any;
}> = ({ isFileUploaded }) => {
  const fileInputRef: any = useRef(null);
  const notify = useNotify();

  const { mutateAsync: uploaded } = useMutation(RecDocumentUploaded);
  const { mutate: create } = useMutation(createRecDocument);
  const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);

  const { recId } = useParams();

  const handleFileChange = (e: any) => {
    const files = e.target.files[0].name;

    create(
      {
        fileName: files,
        recId: parseInt(recId!, 10),
      },
      {
        onSuccess: (details) => {
          uploadToS3({
            url: details.s3uploadUrl,
            file: e.target.files[0] as File,
          })
            .then(() => uploaded({
              recId: parseInt(recId!, 10),
            }))
            .then((res) => {
              if (res.data[0].status === "ERROR") {
                // notify.error(
                //   `Failed to upload file: ${e.target.files[0].name}!`,
                // );
              }
            })
            .catch(() => {
              // TODO cleanup document if failed to upload
              // notify.error(
              //   `Failed to upload file: ${e.target.files[0].name}!`,
              // );
            }).then(() => {
              notify.success(`Successfully uploaded REC file!`);
              isFileUploaded(true)
              setTimeout(() => {
                isFileUploaded(false)
              }, 2000)
            })
        },
      },
    );
  };


  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        position: "absolute",
        backgroundColor: 'white'

      }}
    >
      <RecUploadIcon
        width={84}
        height={84}
        onClick={handleIconClick}
        style={{ cursor: "pointer" }}
      />
      <input
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>
  );
};

