import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Button, Stack } from "@mui/material";
import React, { FunctionComponent } from "react";

import { downloadUtilityBillPdf } from "api";

import { COLORS } from "components/consts";

import { MaybeNull } from "types";

import { downloadFile, openPdfInNewTab } from "utils";

interface UtilityBillActionsProps {
  actionsDisabled: boolean;
  downloadUrl: string;
  fileName: MaybeNull<string>;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setScaleChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const ZOOM = 0.25;

const iconWrapperStyle = {
  padding: 0,
  minWidth: "fit-content",
  color: COLORS.white,
};

export const UtilityBillActions: FunctionComponent<UtilityBillActionsProps> = ({
  actionsDisabled,
  downloadUrl,
  fileName,
  setScale,
  setScaleChange,
}) => (
  <Stack direction="row">
    <Button
      disabled={actionsDisabled}
      onClick={() =>
        downloadFile(
          () => downloadUtilityBillPdf(downloadUrl),
          fileName || "utility-bill.pdf",
        )
      }
      sx={{ marginRight: "45px", ...iconWrapperStyle }}
      variant="text"
    >
      <CloudDownloadIcon />
    </Button>
    <Stack direction="row" spacing={4}>
      <Button
        disabled={actionsDisabled}
        onClick={() => {
          setScale((state) => state + ZOOM);
          setScaleChange(true);
        }}
        sx={iconWrapperStyle}
        variant="text"
      >
        <ZoomInIcon />
      </Button>
      <Button
        disabled={actionsDisabled}
        onClick={() => {
          setScale((state) => state - ZOOM);
          setScaleChange(true);
        }}
        sx={iconWrapperStyle}
        variant="text"
      >
        <ZoomOutIcon />
      </Button>
      <Button
        disabled={actionsDisabled}
        onClick={() => setScale(0.75)}
        sx={iconWrapperStyle}
        variant="text"
      >
        <RestoreIcon />
      </Button>
    </Stack>
    <Button
      disabled={actionsDisabled}
      onClick={() => openPdfInNewTab(() => downloadUtilityBillPdf(downloadUrl))}
      sx={{ marginLeft: "45px", ...iconWrapperStyle }}
      variant="text"
    >
      <OpenInNewIcon />
    </Button>
  </Stack>
);
