import { CssBaseline } from "@mui/material";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import UpdateFileCountContext from "providers/uploadFileCount-provider";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';

import { Root } from "./pages";
import "./main.css"

import {
  AppQueryProvider,
  AuthProvider,
  SnackbarProvider,
  ThemeProvider,
} from "./providers";

export const App = () => (
  <BrowserRouter>
    <RecoilRoot>
      <AppQueryProvider>
        <UpdateFileCountContext>
          <AuthProvider>
            <ThemeProvider>
              <SnackbarProvider>
                <CssBaseline />
                <Root />
                <ToastContainer closeOnClick={false} closeButton={false} autoClose={3000} style={{width:"auto"}}/>
              </SnackbarProvider>
            </ThemeProvider>
          </AuthProvider>
        </UpdateFileCountContext>
      </AppQueryProvider>
    </RecoilRoot>
  </BrowserRouter>
);
