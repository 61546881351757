import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import {
  Tooltip,
  UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR,
  UTILITY_BILL_STATUS_TO_ICON,
  UtilityBillStatusKey,
} from "components";

import { toUSNumber } from "utils";

export const DocumentStatusCount: FunctionComponent<{
  keyName: UtilityBillStatusKey;
  value: number;
}> = ({ keyName, value }) => {
  const Icon = UTILITY_BILL_STATUS_TO_ICON[keyName];
  const toolTipIcon = () => {
    if (keyName === "DECLARED") {
      return "User review required to select an emission factor";
    }
    if (keyName === "INCOMPLETE_DATA") {
      return "Missing Data";
    }
    if (keyName === "VERIFICATION_FAILED") {
      return "Anomaly Flag";
    }
    if (keyName === "APPROVED") {
      return "Approved emission factors, included in calculation";
    }
    if (keyName === "CLEANED") {
      return "Cleaned";
    }
    if (keyName === "ERROR") {
      return "Error";
    }
    if (keyName === "DECLINED") {
      return "Declined";
    }
    return "";
  };

  return (
    <Tooltip title={toolTipIcon() as string}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          bgcolor: UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR[keyName],
          rect: { display: "none" },
          padding: "2px 6px",
          borderRadius: "4px",
        }}
      >
        <Icon />
        {toUSNumber(value)}
      </Box>
    </Tooltip>
  );
};
