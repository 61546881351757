import React, { HTMLAttributes, useEffect, useRef } from "react";

interface CustomDivProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  "data-id": string;
  "data-type": string;
}

const DivElement: React.FC<CustomDivProps> = (props) => <div {...props} />;

export const PrivacysPolicy = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      addScript();
    }
  }, []);

  const addScript = () => {
    const myobj = document.getElementsByClassName("addScript");
    for (let i = 0; i < myobj.length; i += 1) {
      myobj[i].remove();
    }
    if (document.createElement("script")) {
      const script = document.createElement("script");
      script.setAttribute("class", "addScript");
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  };

  return (
    <DivElement
      name="termly-embed"
      data-id="12bf0215-4806-429d-aab4-53e513f5175a"
      data-type="iframe"
    />
  );
};
