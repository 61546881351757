import { Box, Button } from "@mui/material";
import React, { FunctionComponent } from "react";

import { CheckIcon } from "assets";

import { Dialog } from "components";

export const UtilityBillSaveChangesDialog: FunctionComponent<{
  handleSaveClick: () => void;
  isOpen: boolean;
  onClose: () => void;
  varient: string;
}> = ({ handleSaveClick, isOpen, onClose, varient }) => (
  <Dialog
    isOpen={isOpen}
    title={
      varient === "duplicateBill"
        ? "Warning: Duplicate Bill Uploaded, Are you sure want to save the changes?"
        : "Are you sure you want to save changes?"
    }
    onClose={onClose}
    actions={
      <Box>
        <Button variant="outlined" color="warning" onClick={onClose}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={handleSaveClick}
          type="submit"
          name="save"
        >
          {varient === "duplicateBill" ? "Confirm" : "Save"}
        </Button>
      </Box>
    }
  />
);
