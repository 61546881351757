import { Box } from "@mui/system";
import { type MRT_ColumnDef } from "material-react-table";
import React from "react";

import { PendingEmissionfactorPopupDTO } from "api";

import { Tooltip } from "components";

const popUpColumn = (
  arrProductName: any,
  arrGeography: any,
  arrUnit: any,
  arrSector: any,
) => {
  const EMISSION_FACTORS_POPUP_COLUMNS: MRT_ColumnDef<PendingEmissionfactorPopupDTO>[] =
    [
      {
        header: "Product Name",
        accessorKey: "productName",
        size: 280,
        filterVariant: "multi-select",
        filterSelectOptions: arrProductName,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box sx={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        // minSize: 120,
      },
      {
        header: "Activity Name",
        accessorKey: "activityName",
        size: 280,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                className=""
              >
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        // minSize: 120,
      },
      {
        header: "Product Information",
        accessorKey: "productInformation",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box sx={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        size: 280,
      },
      {
        header: "Sector",
        accessorKey: "sector",
        size: 100,
        filterVariant: "multi-select",
        filterSelectOptions: arrSector,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box
                sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                className=""
              >
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        // minSize: 50,
      },
      {
        header: "Geography",
        accessorKey: "geography",
        size: 100,
        filterVariant: "multi-select",
        filterSelectOptions: arrGeography,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box>{value as string}</Box>
            </Tooltip>
          );
        },
        // minSize: 50,
      },
      {
        header: "Unit",
        accessorKey: "unit",
        size: 100,
        filterVariant: "multi-select",
        filterSelectOptions: arrUnit,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box>{value as string}</Box>
            </Tooltip>
          );
        },
        // minSize: 50,
      },
      {
        header: "Time Period",
        accessorKey: "timePeriod",
        size: 100,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          return (
            <Tooltip title={value as string}>
              <Box>{value as string}</Box>
            </Tooltip>
          );
        },
        // minSize: 80,
      },
    ];
  return { EMISSION_FACTORS_POPUP_COLUMNS };
};

export default popUpColumn;

//   const getIsIntermediateSelection =
//     useGetIsIntermediateSelectionEmissionFactorsRadio();
//   const setSelectedFromIndeterminate =
//     useSetFromIntermediateSelectionRadioEmissionFactors();
//   const selected = useRecoilValue(selectedEmissionFactorsRadioState);
//   const selectedCount = useRecoilValue(selectedEmissionFactorsRadioCount);
//   const setSelected = useSetSelectedEmissionFactorsRadio();
//   const getGwp = useRecoilValue(useSetSelectedRadioGWPRow);

//   return useColumnsWithSelectionColumnRadio<any>({
//     columns: EMISSION_FACTORS_POPUP_COLUMNS,
//     getIsIntermediateSelection,
//     selected,
//     setSelected,
//     getGwp,
//     selectedCount,
//     setSelectedFromIndeterminate,
//   });
// };
