import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";

import {
  UTILITY_BILL_TYPE_TO_ICON,
  UTILITY_BILL_TYPE_TO_MESSAGE,
  UtilityBillTypeKey,
} from "./consts";

import { Tooltip } from "../tooltip";

export const UtilityBillType: FunctionComponent<{
  type?: UtilityBillTypeKey | null;
  height?: string;
  width?: string;
}> = ({ type, height, width }) => {
  if (!type) {
    return null;
  }

  if (!UTILITY_BILL_TYPE_TO_ICON[type]) {
    return (
      <Tooltip title={type}>
        <Box
          sx={{
            alignItems: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {type}
        </Box>
      </Tooltip>
    );
  }

  const Icon = UTILITY_BILL_TYPE_TO_ICON[type];

  return (
    <Tooltip title={UTILITY_BILL_TYPE_TO_MESSAGE[type]}>
      <Icon {...(height && width ? { height, width } : {})} />
    </Tooltip>
  );
};
