import { Box, Grid, Stack } from "@mui/material";
import React, { FunctionComponent, useState } from "react";

import { SiteDTO } from "api";

import { Label14SemiBold } from "components/typography";

import { useSites } from "hooks";

import { getInitials } from "utils";

import { Avatar } from "../avatar";
import { Dialog } from "../dialog";
import { NoResultFound } from "../no-result-found";
import { SearchBar, useSearch } from "../search-bar";

interface SiteSelectionDialogProps {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  onSiteSelect: (id: number) => void;
}

export const SiteSelectionDialog: FunctionComponent<
  SiteSelectionDialogProps
> = ({ isDialogOpen, onDialogClose, onSiteSelect }) => {
  const { data: sites } = useSites();
  const {
    search,
    onSearchChange: oonSearchChangeBase,
    onSearchClear: onSearchClearBase,
  } = useSearch();
  const [filteredSites, setFilteredSites] = useState<SiteDTO[]>([]);
  const availableSites = search ? filteredSites : sites || [];

  const onSearchChange = (value: string) => {
    const filteredSitesBySiteName = availableSites.filter((site) =>
      `${site.name?.toLowerCase()}`.startsWith(value.toLowerCase()),
    );

    setFilteredSites(filteredSitesBySiteName || []);
    oonSearchChangeBase(value);
  };

  const onSearchClear = () => {
    onSearchClearBase();
    setFilteredSites([]);
  };

  return (
    <Dialog
      title="Select the site"
      dividers
      isOpen={isDialogOpen}
      onClose={() => {
        onSearchClear();
        onDialogClose();
      }}
    >
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <SearchBar
            sx={{ width: "100%" }}
            onChange={onSearchChange}
            onClear={onSearchClear}
            placeholder="Search"
            search={search}
          />
          {search && !availableSites.length && (
            <Box mt={3}>
              <NoResultFound />
            </Box>
          )}
        </Grid>

        {availableSites?.map((site) => {
          const initials = getInitials(site?.name);

          return (
            <Grid
              key={site.name}
              sx={{ "&:hover": { cursor: "pointer" } }}
              xs={6}
              md={3}
              item
              onClick={() => {
                onSearchClear();
                onSiteSelect(site.id);
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar>{initials}</Avatar>
                <Label14SemiBold>{site.name}</Label14SemiBold>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
};
