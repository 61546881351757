import React, {useContext, useEffect} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Scope3Context } from "pages";



import { checkIsScope3 } from "utils";

import {
   EMISSION_FACTORS_FILES,
    EMISSION_FACTORS_FILES_FOLDERS,
     EMISSION_FACTORS_PAGE, EMISSION_FACTORS_REFERENCES, } from "./consts";

// import { DOCUMENT_UPLOAD_PAGE} from "./document-upload";

// import { useDefaultSelectionWatcher } from "./hooks";

import { EmissionFactorsPage } from "./page";
import { FilesPage } from "./filespage";
import FoldersPage from "./folderpages";


export const ResourcesRouter = () => {
  const { activeScope } = useContext(Scope3Context);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isScope3 = checkIsScope3(activeScope) && activeScope.length === 1;

  useEffect(() => {
    if (pathname === EMISSION_FACTORS_PAGE.ABSOLUTE_PATH && isScope3)
      navigate(EMISSION_FACTORS_REFERENCES.ABSOLUTE_PATH);
  }, [isScope3, pathname, navigate]);

  //   useNewBreadcrumb(EMISSION_FACTORS_PAGE.TITLE);
  //   useDefaultSelectionWatcher();
  return (
      <Routes>
        <Route path="/" element={<EmissionFactorsPage />} />

        <Route
          path={EMISSION_FACTORS_FILES.PATH}
          element={<FilesPage />}
        />
        <Route
          path={EMISSION_FACTORS_FILES_FOLDERS.PATH}
          element={<FoldersPage />}
        />
      </Routes>
  );
};
