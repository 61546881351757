import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import React, { FunctionComponent, useCallback } from "react";
import { Route, useNavigate } from "react-router-dom";

import { ReactChildren } from "types";

export const OKTA_CONFIG = {
  clientId: "0oalol3agcVKLuQjU357",
  issuer: "https://bainco.okta.com/oauth2/auslja9gymuOd66dV357",
  redirectUri: "/login/callback",
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

const oktaAuth = new OktaAuth(OKTA_CONFIG);

export const OktaProvider: FunctionComponent<ReactChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const restoreOriginalUri: RestoreOriginalUriFunction = useCallback(
    (_oktaAuth, originalUri) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    },
    [navigate],
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export const OKTA_AUTH_ROUTES = [
  <Route
    key="okta-redirect-route"
    path={OKTA_CONFIG.redirectUri}
    element={<LoginCallback />}
  />,
];

export const OKTA_SIGN_IN_SUBTITLE =
  "Application allows to sign in with Okta account";

export const OKTA_SIGN_IN_BUTTON_TITLE = "Sign in with Okta SSO";
