import { Box } from "@mui/material";
import React from "react";

import { ImageSlashIcon } from "assets";

import { H3Bold, Label14SemiBold } from "components";

// [AW] TODO: Create reusable component
// [AW] Props; sx, Icon, Title, Body

const HEADING = `No image to show.`;
const BODY = `This file has no image. Upload pdf version to preview.`;

export const PdfEmptyData = () => (
  <Box
    sx={{
      textAlign: "center",
      position: "absolute",
      top: "500%",
      left: "50%",
      bottom: "500%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <ImageSlashIcon width={84} height={84} />
    <H3Bold mb={1}>{HEADING}</H3Bold>
    <Label14SemiBold>{BODY}</Label14SemiBold>
  </Box>
);
