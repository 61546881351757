import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import React, { FunctionComponent } from "react";

interface UnitCostDataProps {
  historicData: any;
  unitCostData: any;
}

HighchartsMore(Highcharts);

const UnitCostData: FunctionComponent<UnitCostDataProps> = ({
  historicData,
  unitCostData,
}) => {
  const chartData =
    historicData &&
    historicData.map((dataPoint: any) => ({
      name: dataPoint.providerName,
      low: Math.min(...dataPoint.boxPlotValue),
      q1: percentile(dataPoint.boxPlotValue, 25),
      median: percentile(dataPoint.boxPlotValue, 50),
      q3: percentile(dataPoint.boxPlotValue, 75),
      high: Math.max(...dataPoint.boxPlotValue),
    }));

  function percentile(arr: any, p: any) {
    const sorted = arr.sort((a: any, b: any) => a - b);
    const index = (p / 100) * (sorted.length - 1);
    if (Number.isInteger(index)) {
      return sorted[index];
    }
    const lower = Math.floor(index);
    const upper = Math.ceil(index);
    return (sorted[lower] + sorted[upper]) / 2;
  }

  const providerNameExists = chartData.some(
    (data: any) => data.name === unitCostData.providerName,
  );

  const options = {
    chart: {
      type: "boxplot",
      inverted: true,
      height: historicData && historicData?.length > 5 ? 400 : 300,
      width: 300,
      spacingBottom: 10,
      spacingTop: 10,
    },
    title: {
      text: "Unit Cost Data by Country",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: chartData && chartData.map((data: any) => data.name),
    },
    yAxis: {
      title: {
        text: unitCostData
          ? `Unit Cost (${unitCostData.currency}/${unitCostData.consumptionUnit})`
          : "Unit Cost",
      },
    },
    series: [
      {
        name: "Observations",
        data: chartData,
        pointWidth: 20,
        color: providerNameExists ? "#348186" : "#D9D9D9",
        fillColor: providerNameExists ? "#348186" : "#D9D9D9",
        tooltip: {
          headerFormat: "",
          pointFormat: "Median: {point.median}",
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default UnitCostData;
