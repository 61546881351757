import { Box } from "@mui/material";
import MaterialReactTable, {
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import React, { useEffect, useRef, useState } from "react";

import { getDocumentSupplieById } from "api";

import {
  FILTERS_AND_ACTIONS_WRAPPER,
  Loading,
  MarginWrapper,
  SCOP_POPUP_FLEX_ALIGN_CENTER_GAP_2,
} from "components";

import { useDocumentSuppliesById } from "hooks";

import { DOCUMENT_UTILITY_BILLS_COLUMNS } from "./columns";

import { PopupActions } from "../popupActions";

export const DocumentExpandedRow: React.FunctionComponent<{
  id: string;
}> = ({ id }) => {
  const { data, isLoading } = useDocumentSuppliesById(id!);
  const [tableData, setTableData] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  useEffect(() => {
    let tableRows = [];

    if (data && data.length > 0) {
      tableRows = (data[0].supplies as any[]) || [];
    }
    setTableData(tableRows);
  }, [data]);
  const refershPage = async () => {
    const details = await getDocumentSupplieById(id!);
    let tableRows = [];
    if (details && details.length > 0) {
      tableRows = (details[0].supplies as any[]) || [];
    }
    setTableData(tableRows);
  };

  const columnsScopePopup = DOCUMENT_UTILITY_BILLS_COLUMNS;

  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "75%",
    overflow: "auto",
    mt: 8,
    px: 4,
  };

  const handleRowSelection = () => {
    setRowSelection({});
  };

  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box sx={SCOP_POPUP_FLEX_ALIGN_CENTER_GAP_2} />

        <Box sx={SCOP_POPUP_FLEX_ALIGN_CENTER_GAP_2}>
          <PopupActions
            refresh={refershPage}
            rowSelection={rowSelection}
            setrowSelection={handleRowSelection}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <Box sx={style}>
            <MaterialReactTable
              muiTableContainerProps={{
                sx: {
                  "& thead tr:first-child th:first-child": {
                    paddingLeft: "20px",
                  },
                  "& tbody tr td:first-child": {
                    paddingLeft: "20px",
                  },
                  border: "1px solid #D9D9D9",
                  borderRadius: "10px",
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  "@media (min-width: 600px)": {
                    width: "100%",
                  },
                  "@media (min-width: 960px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1280px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1440px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1680px)": {
                    width: "1300px",
                  },
                  "@media (min-width: 2400px)": {
                    width: "100%",
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "10px",
                  boxShadow: "none",
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  justifyContent: "center",
                  lineHeight: "30px",
                  "& .MuiBadge-root": {
                    display: "none",
                  },
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  "& > th": {
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                  height: "auto",
                  backgroundColor: "#F8FAFC",
                },
              }}
              muiTableBodyRowProps={{
                sx: {
                  height: "45px",
                  backgroundColor: "white",
                },
              }}
              columns={columnsScopePopup}
              data={tableData}
              enableRowSelection
              enablePagination={false}
              getRowId={(row) => row.id}
              enableColumnResizing
              enableDensityToggle={false}
              enableStickyHeader
              initialState={{ density: "compact" }}
              onSortingChange={setSorting}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection, isLoading, sorting }}
              enableRowVirtualization
            />
          </Box>
        )}
      </Box>
    </MarginWrapper>
  );
};
