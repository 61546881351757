import dayjs, { Dayjs } from "dayjs";
import React, { FunctionComponent } from "react";

import { DatePickerYearField } from "components/inputs/date-picker-year-filed";

import { MaybeNull } from "types";

export const YearlyDateRangePicker: FunctionComponent<{
  label?: string;
  availableYears?: number[];
  year: MaybeNull<Dayjs>;
  onYearChange: (y: Dayjs | null) => void;
}> = ({ availableYears, year, onYearChange, label }) => (
  <DatePickerYearField
    label={label}
    shouldDisableYear={(d: any) =>
      availableYears ? !availableYears.includes(d.year()) : true
    }
    views={["year"]}
    openTo="year"
    minDate={dayjs(
      `${Math.min(...(availableYears || [dayjs().utc().year()]))}-01-01`,
    ).utc()}
    maxDate={dayjs().utc()}
    format="YYYY"
    disableHighlightToday
    disableFuture
    onChange={onYearChange}
    value={year}
  />
);
