import React from "react";

import { NewReleaseIcon, RotateCwIcon } from "assets";

export const SETTINGS_ROUTER_V2 = {
  TITLE: "Settings",
  PATH: "/settingsv2/*",
};

export const SETTINGS_PAGE_V2 = {
  ABSOLUTE_PATH: "/settingsv2",
  PATH: "",
  TITLE: "Settings",
  ICON: <NewReleaseIcon />,
};

export const REST_LEARNING_PAGE = {
  ABSOLUTE_PATH: "/rest-learning",
  PATH: "",
  TITLE: "Rest Learning",
  ICON: <RotateCwIcon />,
};
