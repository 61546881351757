import {
  deleteAuthorizationHeader,
  getResData,
  httpClient,
} from "utils";

import { DOCUMENTS_API_PATHS } from "./consts";
import {
  CreateBillInDocumentDTO,
  DocumentByIdDTO,
  DocumentsDTO,
  DocumentsQuery,
  ScopePopup,
} from "./types";

export const createDocuments = ({
  fileNames,
  siteId,
}: {
  fileNames: string[];
  siteId: number;
}) =>
  httpClient
    .post(
      DOCUMENTS_API_PATHS.DOCUMENTS(),
      fileNames.map((filename) => ({ filename, siteId })),
    )
    .then(getResData);

    export const createComanDocuments = ({
      filename,
      billGuid,
      
    }: {
      filename: string;
      billGuid: string;
    }) =>
      httpClient
        .post(
          DOCUMENTS_API_PATHS.COMANDOCUMENTS(),
          { filename, billGuid }
          // filename.map((filename) => ({ filename, billGuid })),
        )
        .then(getResData);

export const documentUploaded = (documentUuid: string) =>
  httpClient.post(DOCUMENTS_API_PATHS.DOCUMENTS_UPLOADED, [{ documentUuid }]);

  export const comandocumentUploaded = (documentUuid: string) =>
  httpClient.post(DOCUMENTS_API_PATHS.COMANDOCUMENTS_UPLOADED, { documentUuid });

export const uploadDocumentToS3 = ({
  url,
  file,
}: {
  url: string;
  file: File;
}) =>
  httpClient.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
    transformRequest: deleteAuthorizationHeader,
  });

export const getDocuments = (query: DocumentsQuery): Promise<DocumentsDTO> =>
  httpClient
    .get<DocumentsDTO>(DOCUMENTS_API_PATHS.DOCUMENTS(query))
    .then(getResData);

export const getDocumentScope = (query: any): Promise<any> =>
  httpClient.get<any>(DOCUMENTS_API_PATHS.DOCUMENTS(query)).then(getResData);

export const deleteDocument = (documentId: string) =>
  httpClient.delete(DOCUMENTS_API_PATHS.DOCUMENT(documentId));

export const deleteScopeDocument = (documentId: string) =>
  httpClient.delete(DOCUMENTS_API_PATHS.SUPPLIES(documentId));

export const approveScopeDocument = (documentId: string) =>
  httpClient.put(DOCUMENTS_API_PATHS.APPROVED(documentId));

export const downloadDocuments = (documentIds: string[]) =>
  httpClient.post(DOCUMENTS_API_PATHS.EXPORT_DOCUMENTS, documentIds, { responseType: "blob" },);

export const getDocumentById = (documentId: string) =>
  httpClient
    .get<DocumentByIdDTO>(DOCUMENTS_API_PATHS.DOCUMENT(documentId))
    .then(getResData);

export const getUseUserSettings = () =>
  httpClient.get<any>(DOCUMENTS_API_PATHS.USER_SETTING).then(getResData);

export const getDocumentSupplieById = (documentId: string) =>
  httpClient
    .get<ScopePopup[]>(DOCUMENTS_API_PATHS.DOCUMENTS_SUPPLIES(documentId))
    .then(getResData);

export const createBillInDocument = ({
  documentId,
  body,
}: {
  documentId: string;
  body: CreateBillInDocumentDTO;
}) =>
  httpClient
    .post(DOCUMENTS_API_PATHS.CREATE_BILL(documentId), body)
    .then(getResData);

export const getDocumentBills = (documentId: string) =>
  httpClient
    .get<any>(DOCUMENTS_API_PATHS.GET_BILLS(documentId))
    .then(getResData);
