import React, { FunctionComponent } from "react";

import { MaybeNull } from "types";

export const SiteNameDetail: FunctionComponent<{
  name?: MaybeNull<string>;
}> = ({ name }) =>
  name ? (
    <>
      Site <b>{name}</b>
    </>
  ) : null;
