import { Box, Button } from "@mui/material";
import React, { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useSaveCurrentUrl } from "recoils";

import { setToLocalStorage } from "utils";

// eslint-disable-next-line
import { UTILITY_BILL_PAGE } from "../../../document-summary-v2";

export const PreviewUtilityBill: FunctionComponent<{
  id: string;
  rowData: string;
}> = ({ id, rowData }) => {
  const saveCurrentUrl = useSaveCurrentUrl();
  const navigate = useNavigate();
  const onPreviewClick = useCallback(() => {
    saveCurrentUrl();
    setToLocalStorage("selectedRow", rowData);
    navigate(`${UTILITY_BILL_PAGE.ABSOLUTE_PATH(id)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, saveCurrentUrl]);

  return (
    <div>
      <Box onClick={onPreviewClick} sx={{ cursor: "pointer" }}>
        <Button>View</Button>
      </Box>
    </div>
  );
};
