import { SnackbarMessage } from "notistack";
import { FieldErrors } from "react-hook-form";

import { CollectorDTO, UpdateUtilityBillDTO } from "api";

import { TextFieldColorState } from "components";

import { REQUIRED_VALIDATION_MESSAGE } from "consts";

import { MaybeNull } from "types";

import { WARNING_MESSAGES } from "./consts";
import { UtilityBillFormData } from "./types";

export const compareCollector = (
  collectorString: string,
  collector: CollectorDTO,
): boolean => collectorString === getCollectorString(collector);

export const getWarningColorStateProps = (
  key: keyof UtilityBillFormData,
  isWarningFields: Record<keyof UtilityBillFormData, boolean>,
): TextFieldColorState => ({
  color: isWarningFields[key] ? "warning" : undefined,
  message: isWarningFields[key]
    ? WARNING_MESSAGES[key as keyof typeof WARNING_MESSAGES]
    : undefined,
});

export const getErrorColorStateProps: (
  key: keyof UtilityBillFormData,
  formStateErrors: FieldErrors<UtilityBillFormData>,
) => TextFieldColorState = (key, formStateErrors) => ({
  color: "error",
  message:
    formStateErrors[key]?.type === "required"
      ? REQUIRED_VALIDATION_MESSAGE
      : formStateErrors[key]?.message,
});

export const getTextFieldColorStateProps = (
  key: keyof UtilityBillFormData,
  formStateErrors: FieldErrors<UtilityBillFormData>,
  isWarningFields?: Record<keyof UtilityBillFormData, boolean>,
) =>
  formStateErrors[key]
    ? getErrorColorStateProps(key, formStateErrors)
    : isWarningFields
    ? getWarningColorStateProps(key, isWarningFields)
    : {};

export const getTextFieldNoErrorProps = (
  key: keyof UtilityBillFormData,
  isWarningFields?: Record<keyof UtilityBillFormData, boolean>,
) => (isWarningFields ? getWarningColorStateProps(key, isWarningFields) : {});

export const getIsWarningFields = (
  defaultValues: UtilityBillFormData,
  watchValues: UtilityBillFormData,
  nullValues: Record<string, string | number | null | undefined>,
) =>
  Object.keys(watchValues).reduce((acc, key) => {
    acc[key as keyof UtilityBillFormData] =
      key in nullValues
        ? defaultValues[key as keyof UtilityBillFormData] === nullValues[key] &&
          watchValues[key as keyof UtilityBillFormData] === nullValues[key]
        : !defaultValues[key as keyof UtilityBillFormData] &&
          !watchValues[key as keyof UtilityBillFormData];
    return acc;
  }, {} as Record<keyof UtilityBillFormData, boolean>);

function extractValue(inputString: string) {
  // const trimmedString = inputString.trim();
  const trimmedString = inputString && inputString.toString().trim();
  const firstPart = trimmedString && trimmedString.toString().split(" ")[0];
  return firstPart;
}

export const getUtilityBillUpdateDTO = (
  utilityBillId: string,
  formData: UtilityBillFormData,
  collectors?: CollectorDTO[],
): UpdateUtilityBillDTO => {
  const formattedStartDate = formData.startDate?.isValid()
    ? formData.startDate.format("YYYY-MM-DDT00:00:00[Z]")
    : null;

  const formattedEndDate = formData.endDate?.isValid()
    ? formData.endDate.format("YYYY-MM-DDT00:00:00[Z]")
    : null;
    
  const measuredUnit = extractValue(formData?.measuredUnit);

  const measuredValue =
    formData.measuredValue === "" || formData.measuredValue === undefined
      ? null
      : Number(formData.measuredValue.replace(/,/g, "."));

  const amount =
    formData.amount === "" || formData.amount === undefined
      ? null
      : Number(formData.amount.replace(/,/g, "."));

  const traveledMiles =
    formData.traveledMiles === ""
      ? null
      : Number(formData.traveledMiles.replaceAll(",", ""));

  return {
    id: utilityBillId,
    collectorId:
      collectors?.find((c) => compareCollector(formData?.collector, c))?.id ||
      null,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    measuredUnit,
    measuredValue,
    meterNumber: formData?.meterNumber,
    accountNumber: formData?.accountNumber,
    utilityTypeName: formData?.utilityTypeName,
    amount,
    currency: formData?.currency,
    subtype: formData?.subtype === "" ? null : formData?.subtype,
    traveledMiles,
  };
};


export const sendUtilityBillNotificationMessage = (
  notify: (message: SnackbarMessage) => void,
  message: string,
  name: MaybeNull<string>,
) => notify(name ? `${message}: ${name}!` : `${message}!`);

export const getCollectorString = ({
  accountNumber,
  meterNumber,
  utilityTypeName,
  enabled,
}: CollectorDTO) =>{
if (enabled !== undefined && enabled === false) {
  return `[De-activated] ${utilityTypeName || "-"} / ${accountNumber || "-"} / ${meterNumber || "-"}`
} 
  return `${utilityTypeName || "-"} / ${accountNumber || "-"} / ${meterNumber || "-"}`

}
  
