import { Box, Grid } from "@mui/material";
import React, { FunctionComponent, useState } from "react";

import { COLORS, Loading, NoResultFound } from "components";

import { useGetUsers } from "hooks";

import { USER_ROLES, UserRole } from "recoils";

import { User } from "./user";
import { UsersFilters } from "./users-filters";

interface UsersControllerProps {
  shouldFetchUsers: boolean;
}

export const InvitedUsersList: FunctionComponent<UsersControllerProps> = ({
  shouldFetchUsers,
}) => {
  const { data: users, isLoading: areUsersLoading } =
    useGetUsers(shouldFetchUsers);
  const [filterByRole, setFilterByRole] = useState<UserRole | null>(null);
  const disabled = !users?.length;

  if (areUsersLoading) {
    return <Loading />;
  }

  const filteredUsers = (users || []).filter((user) => {
    if (!user.active) {
      return false;
    }
    if (!user.enabled && !(filterByRole === USER_ROLES.ENABLED)) {
      return false;
    }

    if (!filterByRole) {
      return true;
    }

    if (filterByRole === USER_ROLES.SITE_MANAGER) {
      return !user.admin && !user.auditor;
    }

    if (filterByRole === USER_ROLES.ENABLED) {
      return !user.enabled;
    }

    return user[filterByRole];
  });

  return (
    <>
      <UsersFilters usersData={users} onFilterChange={setFilterByRole} isDisabled={disabled} />

      <Box
        sx={{
          borderBottom: `1px solid ${COLORS.extraLightGray}`,
          flex: 2,
          overflowY: "auto",
          paddingBottom: 3,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          {!filteredUsers.length ? (
            <Box width="100%">
              <NoResultFound />
            </Box>
          ) : (
            filteredUsers.map((user) => (
              <User key={user.id} userData={user} getFilter={filterByRole} />
            ))
          )}
        </Grid>
      </Box>
    </>
  );
};
