import { DocumentsDTO } from "api";

export const DOCUMENTS_RESPONSE: DocumentsDTO = {
  documents: [
    {
      id: "960fc990-919f-407c-b2a0-2bbb24f84cb6",
      fileName: "NavexGlobal_CityUtilities_03_29_22 (4).pdf",
      site: "Springfield 1",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:27:00.272715Z",
      pages: 4,
      bills: [
        {
          id: "40989cbc-1afc-4bc6-8a24-d10dac89516a",
          type: {
            id: 5,
            name: "WATER",
            allowedUnits: ["m3"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: true,
            otherFuels: false,
            sewer: false,
          },
          period: "Mar-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "167889",
          status: "CLEANED",
          errorMessage: null,
        },
        {
          id: "797f00cf-104c-40de-a2ba-af86e426fa33",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "c7f7864e-89a0-4126-ad49-1235a2e78c97",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "e4465060-d831-4126-8d27-fbeb89def56e",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "e7e171f6-87ec-4a3a-98ee-1920b14e090c",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Mar-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "364398",
          status: "CLEANED",
          errorMessage: null,
        },
        {
          id: "4f0a046c-3ebd-4d8c-a41d-4a1e41052613",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Mar-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "124904",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 3,
        INCOMPLETE_DATA: 3,
      },
      moved: false,
    },
    {
      id: "1217f107-6871-40d1-86b4-546909c7e43e",
      fileName: "Springfield1_Natural Gas _9667100040_1of1.pdf",
      site: "Springfield 1",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:27:00.235275Z",
      pages: 6,
      bills: [
        {
          id: "51c47ebb-9642-4585-bcfb-d153a9255942",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "808e82ae-7c03-4862-aa75-23c343ff0dc6",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "92bc75e5-f9b3-48ab-ab42-012e78b115fa",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "9ca7051e-bdc7-4f1d-ae77-54ac89d83ab0",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "92102f29-58a4-4ccc-95ff-b750c4244498",
          type: {
            id: 5,
            name: "WATER",
            allowedUnits: ["m3"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: true,
            otherFuels: false,
            sewer: false,
          },
          period: "Jun-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "167889",
          status: "CLEANED",
          errorMessage: null,
        },
        {
          id: "a79326c9-fbd3-439f-a820-43a19e04f835",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Jun-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "364398",
          status: "CLEANED",
          errorMessage: null,
        },
        {
          id: "cfc03c90-398c-472e-9eb6-cd5ff9c4a9c0",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Jun-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "124904",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 3,
        INCOMPLETE_DATA: 4,
      },
      moved: false,
    },
    {
      id: "b1a2d365-afc4-4289-a4a2-7308205b1480",
      fileName: "NavexGlobal_CityUtilities_07_26_22 (2).pdf",
      site: "Springfield 1",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:27:00.219268Z",
      pages: 4,
      bills: [
        {
          id: "550b474c-8b67-4a45-83a7-f7e85d08c550",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "5fc18751-59dd-4499-a3f8-2452c5f06c7f",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "8cd37b62-1108-49d4-b766-af8cc0574b85",
          type: {
            id: 5,
            name: "WATER",
            allowedUnits: ["m3"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: true,
            otherFuels: false,
            sewer: false,
          },
          period: "Jul-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "167889",
          status: "CLEANED",
          errorMessage: null,
        },
        {
          id: "59150d70-c74c-48cf-9e92-6bdf35e75b1d",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "c02d5d18-d79b-4fcd-aa18-8cc9df0cda10",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Jul-22",
          provider: "CityUtilities",
          accountNumber: "9667100040",
          meterNumber: "124904",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 2,
        INCOMPLETE_DATA: 3,
      },
      moved: false,
    },
    {
      id: "3fb7bde8-5517-4386-841b-922969425719",
      fileName: "Tijuana_Electricity_19800131056_1of1.pdf",
      site: "Mexico - Tijuana",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:25:26.481253Z",
      pages: 2,
      bills: [
        {
          id: "f9e4914c-df9f-40ae-a162-c4127a2d74b1",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "7648e025-b98b-4258-881b-63579b043ac1",
      fileName: "001980131056 (9).pdf",
      site: "Mexico - Tijuana",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:25:26.466352Z",
      pages: 2,
      bills: [
        {
          id: "b2de5ff8-d0a3-4596-88c3-cfc6b645e45e",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "858061a1-0b1d-4fc9-b516-9c0e8c7ec26f",
      fileName: "001980131056 (8).pdf",
      site: "Mexico - Tijuana",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:25:26.458573Z",
      pages: 2,
      bills: [
        {
          id: "4fd7e301-387f-4464-a641-5f62822d11d3",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "fb57a4f1-522e-4b03-b2fd-4bff347ff157",
      fileName: "001980131056 (5).pdf",
      site: "Mexico - Tijuana",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:25:26.436885Z",
      pages: 2,
      bills: [
        {
          id: "48ff1456-b056-40bc-843b-0e2e032cef21",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "3ec7e3e5-525b-4c41-8162-a91cd63ab293",
      fileName: "5 Tomah_Scope 2_0652130000_2022-10-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T14:09:19.975348Z",
      pages: 7,
      bills: [
        {
          id: "c65e8527-db5a-47e9-bac9-d251336a0842",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "e4ed4d3b-c4c6-4e8b-956d-e429f6b9d504",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "850689c9-9786-4361-beb7-ba080785b621",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "c0dd4874-c8fd-4564-9837-c3c87fdf2e2c",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "Alliant Energy",
          accountNumber: "0652130000",
          meterNumber: "470008376",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
        INCOMPLETE_DATA: 3,
      },
      moved: false,
    },
    {
      id: "95c2e816-9e8c-4b37-88ad-4f6b003d6e26",
      fileName: "5 Tomah_Scope 2_0652130000_2022-10-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.834775Z",
      pages: 7,
      bills: [
        {
          id: "b1eb5617-9177-4bd3-898e-d284c6aee866",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "d380091b-4b9b-4aca-8812-52382adedc47",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "f9c65901-9188-44c1-ab61-0f3b9f1f3533",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "401e7fca-9ba3-466e-9fd3-7b38e63b3c71",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "Alliant Energy",
          accountNumber: "0652130000",
          meterNumber: "470008376",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
        INCOMPLETE_DATA: 3,
      },
      moved: false,
    },
    {
      id: "36545f0f-018d-4474-b7c4-3cf2bba26118",
      fileName: "6 Tomah_Scope 2_3613100000_2022-10-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.771442Z",
      pages: 2,
      bills: [
        {
          id: "9fed124d-51b5-46aa-9e1e-9938820a500a",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "cce56e38-2749-47d9-bcf0-fa4c400819ff",
      fileName: "7 Tomah_Scope 2_0652130000_2022-08-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.644194Z",
      pages: 6,
      bills: [
        {
          id: "b76d4b06-746d-4383-b987-f7dc63c9edad",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "f3a3d193-07a8-4f61-b452-c891948cf086",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "70daa87a-aa57-497e-ab61-11284a7a82c6",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
        {
          id: "a0814a40-fbed-41db-a2f5-a59743f2eb09",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "Alliant Energy",
          accountNumber: "0652130000",
          meterNumber: "470008376",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
        INCOMPLETE_DATA: 3,
      },
      moved: false,
    },
    {
      id: "3b6e7552-37a4-4dd2-a614-3c79ac4289cf",
      fileName: "4 Tomah_Scope 1_0701665865-00006_2022-09-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.606883Z",
      pages: 1,
      bills: [
        {
          id: "222c1272-1ff9-41a9-8857-d8713735f2f1",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "We Energies",
          accountNumber: "0701665865-00006",
          meterNumber: "000917746",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "3d583465-c2d5-4457-844c-de7927a951fd",
      fileName: "1 Tomah_Scope 1_0701665865-00008_2022-09-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.516048Z",
      pages: 3,
      bills: [
        {
          id: "47f57f60-2f9d-44c3-8bdc-46a437a99619",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "Symmetry",
          accountNumber: "0701665865-00008",
          meterNumber: null,
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "57173d9d-0729-47b4-be14-dba2f4003e5f",
      fileName: "3 Tomah_Scope 1_0701665865-00008_2022-08-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.45387Z",
      pages: 2,
      bills: [
        {
          id: "1b9690da-849c-4980-9925-7dae90a94bce",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "Symmetry",
          accountNumber: "0701665865-00008",
          meterNumber: null,
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "076a3656-86b2-4bd0-aff9-e387e9c67d8d",
      fileName: "2 Tomah_Scope 1_0701665865-00008_2022-09-01.pdf",
      site: "Tomah",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:59:41.416987Z",
      pages: 3,
      bills: [
        {
          id: "bb8ef236-834c-4203-b3c5-57b2f5f4f584",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "Symmetry",
          accountNumber: "0701665865-00008",
          meterNumber: null,
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "6e4172b2-c121-4202-aaaf-62b5ec472098",
      fileName: "1 Windom_Scope 2_00016661-2_2022-08-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:55:07.265536Z",
      pages: null,
      bills: [],
      statuses: {},
      moved: false,
    },
    {
      id: "8b45e9e8-939b-46e0-8b83-e0e334b3694c",
      fileName: "2 Windom_Scope 1_0506448315-00001__2022-08-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:54:39.106091Z",
      pages: 1,
      bills: [
        {
          id: "657633c2-c68e-4f1d-93b1-f018c2d6efa7",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "Minnesota Energy Resources",
          accountNumber: "0506448315-00001",
          meterNumber: "35000384",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "3b88dd28-10ac-440f-aaf5-ffe0a8f868a2",
      fileName: "1 Windom_Scope 2_00016661-2_2022-08-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:53:54.840465Z",
      pages: null,
      bills: [],
      statuses: {},
      moved: false,
    },
    {
      id: "f2fc439a-c4dd-4d9e-a3cf-0eed52d53196",
      fileName: "2 Windom_Scope 1_0506448315-00001__2022-08-01.pdf",
      site: "Warrensburg",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:53:24.931523Z",
      pages: 1,
      bills: [
        {
          id: "49bfc299-1f27-40e6-966a-392899a9089c",
          type: null,
          period: null,
          provider: null,
          accountNumber: null,
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Collector missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "45e5cffb-1a5e-4c6c-af3f-c3043066daa7",
      fileName: "3 Windom_Fuel_2022-09-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:04:43.049777Z",
      pages: 1,
      bills: [
        {
          id: "d87e3c07-6747-4f3c-a8f5-0c5808d99948",
          type: {
            id: 4,
            name: "GASOLINE",
            allowedUnits: ["gallons"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: true,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: null,
          provider: null,
          accountNumber: "Windom_GASOLINE",
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Miles traveled missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "7825497d-3c86-45cd-b413-79403c9610be",
      fileName: "8 Windom_Fuel_2022-08-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:04:43.030045Z",
      pages: 1,
      bills: [
        {
          id: "10805268-cdab-42c8-945b-d6419fe78f63",
          type: {
            id: 4,
            name: "GASOLINE",
            allowedUnits: ["gallons"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: true,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: null,
          provider: null,
          accountNumber: "Windom_GASOLINE",
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Miles traveled missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "be1da2ce-c0b0-49e8-855b-231b26248c21",
      fileName: "6 Windom_Fuel_2022-10-01.pdf",
      site: "Windom",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:04:42.879952Z",
      pages: 1,
      bills: [
        {
          id: "e921d6bf-98ee-400f-850d-dfa4bc3f2cf8",
          type: {
            id: 4,
            name: "GASOLINE",
            allowedUnits: ["gallons"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: true,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: null,
          provider: null,
          accountNumber: "Windom_GASOLINE",
          meterNumber: null,
          status: "INCOMPLETE_DATA",
          errorMessage: "Miles traveled missing",
        },
      ],
      statuses: {
        INCOMPLETE_DATA: 1,
      },
      moved: false,
    },
    {
      id: "1d284318-1c4c-4f98-96b8-f4307ba45666",
      fileName: "5 El Paso_Scope 1_910206645 1125233 18_2022-10.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:26.146352Z",
      pages: 2,
      bills: [
        {
          id: "e5be9e20-d29b-4dd5-a67e-187b9e67bbab",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1125233 18",
          meterNumber: "0206125423",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "e967fc21-4466-4082-9de9-6acaff8f83aa",
      fileName: "10 El Paso_Scope 2_3168330000_2022-09.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:26.078705Z",
      pages: 2,
      bills: [
        {
          id: "42e91c34-60e7-42c0-8113-19ffca466c10",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "El Paso Electric",
          accountNumber: "3168330000",
          meterNumber: "1105312041",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "e74157a7-c4f8-44d2-a29f-0ad3b0084ac6",
      fileName: "8 El Paso_Scope 2_3168330000_2022-08.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:26.037262Z",
      pages: 2,
      bills: [
        {
          id: "0175dc3c-60f6-4cb0-a161-19c07c872456",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "El Paso Electric",
          accountNumber: "3168330000",
          meterNumber: "1105312041",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "0586ba66-19a3-4ff9-af06-17867844ed7a",
      fileName: "4 El Paso_Scope 1_910206645 1420168 18_2022-10.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:26.023503Z",
      pages: 1,
      bills: [
        {
          id: "3117d2d2-10a3-48c9-b3f3-664774993f3f",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1420168 18",
          meterNumber: "1200216796",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "b5586df4-0060-41cb-ac9f-858815012f9d",
      fileName: "9 El Paso_Scope 2_3168330000_2022-10.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.974633Z",
      pages: 2,
      bills: [
        {
          id: "a9f49bf6-c7af-4608-8eb4-45caccc5191b",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "El Paso Electric",
          accountNumber: "3168330000",
          meterNumber: "1105312041",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "135f8761-cd8f-49dc-be12-41423617ae6f",
      fileName: "1 El Paso_Scope 1_910206645 1125233 18_2022-08.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.955538Z",
      pages: 1,
      bills: [
        {
          id: "b4e36f3d-a576-4670-82f2-a23cb59008b2",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1125233 18",
          meterNumber: "0206125423",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "ec18f2a3-dc41-4745-b997-6d210ab66de2",
      fileName: "2 El Paso_Scope 1_910206645 1420168 18_2022-08.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.945696Z",
      pages: 1,
      bills: [
        {
          id: "180b82d0-2ce2-46a7-a2ec-f72487f2caa8",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Aug-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1420168 18",
          meterNumber: "1200216796",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "13a97b63-e6c2-4ce6-909b-a5d46e9dad8e",
      fileName: "6 El Paso_Scope 1_910206645 1125233 18_2022-09.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.932757Z",
      pages: 2,
      bills: [
        {
          id: "89d9b76c-ebe4-46be-a634-f16bc4836e48",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1125233 18",
          meterNumber: "0206125423",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "e3e05b8a-cbf9-4bf9-80b0-f9223795a449",
      fileName: "7 El Paso_Scope 2_3642000000_2022-10.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.915516Z",
      pages: 4,
      bills: [
        {
          id: "1999bd90-333b-4bef-a115-b931916f4f8b",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Oct-22",
          provider: "El Paso Electric",
          accountNumber: "3642000000",
          meterNumber: null,
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "8726057f-a86e-4ae8-986c-fc8804003f42",
      fileName: "3 El Paso_Scope 1_910206645 1420168 18_2022-09.pdf",
      site: "El Paso",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T13:02:25.910352Z",
      pages: 1,
      bills: [
        {
          id: "fc25fef0-245e-4172-a328-c07ce2c9425f",
          type: {
            id: 2,
            name: "NATURAL GAS",
            allowedUnits: ["therms"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: false,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Sep-22",
          provider: "Texas Gas Service",
          accountNumber: "910206645 1420168 18",
          meterNumber: "1200216796",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "9d61c8c8-a12f-4020-ab9b-454cad62886f",
      fileName: "Hays_Electric_5000000681_1of1.pdf",
      site: "Hays",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T08:17:48.864927Z",
      pages: 2,
      bills: [
        {
          id: "809e5266-10eb-4b0e-a5a7-488dd7ad91b2",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Jun-22",
          provider: "MidWestEnergy",
          accountNumber: "17829623",
          meterNumber: "5000000681",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
    {
      id: "7e7b9928-b1d3-4934-909a-cdb47ef4ab3c",
      fileName: "NavexGlobal_MidWestEnergy_07_27_22 (3).pdf",
      site: "Hays",
      uploader: {
        firstName: "Grzegorz",
        lastName: "Galuszka",
        email: "grzegorz.galuszka@codilime.com",
      },
      uploadDate: "2023-01-13T08:17:48.85984Z",
      pages: 2,
      bills: [
        {
          id: "cd499149-eaca-4254-a625-32c73d1a470c",
          type: {
            id: 1,
            name: "ELECTRICITY",
            allowedUnits: ["kWh"],
            createDate: 1670416601.922996,
            updateDate: null,
            creator: {
              id: 1,
              firstName: "test",
              lastName: "user",
              oktaIdentifier: "test@okta.com",
              email: "test@okta.com",
              active: true,
              admin: true,
              auditor: false,
              sites: [],
              createDate: 1670416601.922996,
              updateDate: null,
            },
            gasoline: false,
            electricity: true,
            refrigerants: false,
            water: false,
            otherFuels: false,
            sewer: false,
          },
          period: "Jul-22",
          provider: "MidWestEnergy",
          accountNumber: "17829623",
          meterNumber: "5000000681",
          status: "CLEANED",
          errorMessage: null,
        },
      ],
      statuses: {
        CLEANED: 1,
      },
      moved: true,
    },
  ],
  count: 34,
  documentsScope3: [],
};
