import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import React, { Fragment } from "react";

import { COLORS } from "components";

import { CellValueWrapper } from "./cell";
import { SELECT_COLUMN_ID } from "./consts";
import { Data, TableProps } from "./types";
import { getTableCellBorders } from "./utils";

export const TableBody = <TData extends Data>({
  table,
  selectedRows,
  renderSubComponent,
  nonePaddingColumnIds,
}: TableProps<TData>) => (
  <MuiTableBody>
    {table.getRowModel().rows.map((row, rowIndex, rows) => {
      const isLastRow = rows.length - 1 === rowIndex;
      const isExpanded = renderSubComponent && row.getIsExpanded();
      return (
        <Fragment key={row.id}>
          <TableRow
            hover
            selected={selectedRows ? selectedRows[row.original.id] : undefined}
            sx={{
              backgroundColor: row.original.isDuplicate
                ? COLORS.lightYellow
                : "",
            }}
          >
            {row.getVisibleCells().map((cell, cellIndex, cells) => {
              const isLastCell = cells.length - 1 === cellIndex;
              const defaultPadding =
                nonePaddingColumnIds && nonePaddingColumnIds.has(cell.column.id)
                  ? "none"
                  : undefined;

              return (
                <TableCell
                  key={cell.id}
                  padding={
                    cell.column.id === SELECT_COLUMN_ID
                      ? "checkbox"
                      : defaultPadding
                  }
                  sx={(theme) => ({
                    ...getTableCellBorders({
                      isLastRow,
                      isLastColumn: isLastCell,
                      color: theme.palette.background.default,
                    }),
                  })}
                >
                  <CellValueWrapper>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </CellValueWrapper>
                </TableCell>
              );
            })}
          </TableRow>
          {isExpanded && (
            <TableRow>
              <TableCell
                sx={{ padding: 0, border: "none" }}
                colSpan={row.getVisibleCells().length}
              >
                {renderSubComponent(row)}
              </TableCell>
            </TableRow>
          )}
        </Fragment>
      );
    })}
  </MuiTableBody>
);
