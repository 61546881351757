import React from "react";

export const DASRForms = () => (
  <div className="dsar_content" style={{ height: "100%" }}>
    <iframe
      src="https://app.termly.io/notify/12bf0215-4806-429d-aab4-53e513f5175a"
      title="DASR"
      height="100%"
      width="100%"
    />
  </div>
);
