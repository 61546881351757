import { Box, Button, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { DocumentByIdDTO, UnitDescription, createBillInDocument } from "api";

import { CheckIcon } from "assets";

import {
  AutocompleteField,
  DatePickerFormField,
  H5Bold,
  IMaskFormTextField,
  TextField,
  USCurrencyMask,
  USNumberMask,
  USNumberMask2,
  UTILITY_BILL_TYPES,
} from "components";

import {
  useAllCollectors,
  useCollectorsBySiteId,
  useGetAllowedValues,
  useNotify,
} from "hooks";

import { QUERY_KEYS, REQUIRED_VALIDATION_MESSAGE } from "consts";

import { useUserRole } from "recoils";

import { isNullOrUndefined } from "utils";

// eslint-disable-next-line
import { getCreateBillInDocumentDTO } from "./utils";

// eslint-disable-next-line
import {
  COLLECTOR_LABEL,
  UtilityBillFormData,
  compareCollector,
  getCollectorString,
  getTextFieldColorStateProps,
  sendUtilityBillNotificationMessage,
} from "../../../document-summary-v2";

export const AddDocumentBillFormView: FunctionComponent<{
  document: DocumentByIdDTO;
  onClose: () => void;
}> = ({ document, onClose }) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const userRole = useUserRole();

  const { data: allowedValues } = useGetAllowedValues();
  const { mutateAsync: createBill, isLoading: isCreating } =
    useMutation(createBillInDocument);
  const { data: collectorsFromApi } =
    document.siteId === 0 || document.siteId === -1
      ? // eslint-disable-next-line
        useAllCollectors()
      : // eslint-disable-next-line
        useCollectorsBySiteId(document.siteId);

  const collector: any = null;

  const { register, control, watch, handleSubmit, setValue, formState } =
    useForm<UtilityBillFormData>({
      defaultValues: {
        siteName: document.siteName,
        startDate: null,
        endDate: null,
        measuredUnit: "",
        currency: "",
      },
    });

  const watchValues = watch();

  const [availableUnits, setAvailableUnits] = useState<UnitDescription[]>([]);

  useEffect(() => {
    if (watchValues.collector && collectorsFromApi && allowedValues) {
      const selectedCollector = collectorsFromApi.find((c) =>
        compareCollector(watchValues.collector, c),
      );

      if (selectedCollector) {
        const selectedUtilityTypeInfo = allowedValues.utilityTypes.find(
          (ut: any) =>
            ut.name.toLowerCase() ===
            selectedCollector.utilityTypeName.toLowerCase(),
        );

        if (selectedUtilityTypeInfo) {
          setAvailableUnits(selectedUtilityTypeInfo.unitDescription);
        }
      }
    }
  }, [watchValues.collector, allowedValues, collectorsFromApi]);

  useEffect(() => {
    const savedFormData = localStorage.getItem("utilityBillFormData");
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      Object.keys(formData).forEach((key) => {
        setValue(key as keyof UtilityBillFormData, formData[key]);
      });
    }
  }, [setValue]);

  useEffect(() => {
    if (formState.isDirty) {
      const formValues = {
        siteName: watchValues.siteName,
        provider: watchValues.provider,
        measuredUnit: watchValues.measuredUnit,
        measuredValue: watchValues.measuredValue,
        startDate:
          watchValues.startDate && dayjs(watchValues.startDate).isValid()
            ? dayjs(watchValues.startDate).format("MM/DD/YYYY")
            : null,
        endDate:
          watchValues.endDate && dayjs(watchValues.endDate).isValid()
            ? dayjs(watchValues.endDate).format("MM/DD/YYYY")
            : null,
        amount: watchValues.amount,
        currency: watchValues.currency,
        collector: watchValues.collector,
        traveledMiles: watchValues.traveledMiles,
        subtype: watchValues.subtype,
        meterNumber: watchValues.meterNumber,
        utilityTypeName: watchValues.utilityTypeName,
        accountNumber: watchValues.accountNumber,
      };

      localStorage.setItem("utilityBillFormData", JSON.stringify(formValues));
    }
  }, [watchValues, formState.isDirty]);

  useEffect(() => {
    if (watchValues.collector && collectorsFromApi) {
      const selectedCollector = collectorsFromApi.find((c) =>
        compareCollector(watchValues.collector, c),
      );

      if (selectedCollector) {
        setValue("siteName", selectedCollector.siteName || "");
        setValue("provider", selectedCollector.providerName || "");
        setValue("currency", selectedCollector.currency || "");
        setValue("measuredUnit", selectedCollector.consumptionUnit || "");
      }
    }
  }, [watchValues.collector, collectorsFromApi, setValue]);

  useEffect(() => {
    // eslint-disable-next-line
    const all_adornment: any = window.document.querySelectorAll(
      ".MuiInputAdornment-root button",
    );
    if (all_adornment) {
      all_adornment.forEach((node: any) => {
        // eslint-disable-next-line
        node.tabIndex = -1;
      });
    }
  }, [document]);

  const collectorsOptions = (collectorsFromApi || [])
    .filter((item) => !isNullOrUndefined(item.accountNumber))
    .map(getCollectorString);

  const handleSaveClick = handleSubmit((formData) => {
    const modifiedFormData = {
      ...formData,
      startDate: dayjs(formData.startDate),
      endDate: dayjs(formData.endDate),
    };

    createBill(
      {
        documentId: document.id,
        body: getCreateBillInDocumentDTO(modifiedFormData, collectorsFromApi),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
          sendUtilityBillNotificationMessage(
            notify.success,
            "Successfully saved bill into",
            document.fileName,
          );
          onClose();
        },
        onError: () =>
          sendUtilityBillNotificationMessage(
            notify.error,
            "Failed to save bill",
            document.fileName,
          ),
      },
    );
  });

  return (
    <Box component="form" sx={{ flex: 1, mt: 2 }}>
      <Box component="section" mb={3}>
        <H5Bold mb={2}>Client and provider</H5Bold>
        <Box mb={2}>
          <TextField
            label="Site"
            fullWidth
            {...register("siteName")}
            disabled
          />
        </Box>
        <TextField
          label="Provider"
          fullWidth
          disabled
          {...register("provider")}
        />
      </Box>

      <Box component="section" mb={3}>
        <H5Bold mb={2}>Basic data</H5Bold>
        <Stack direction="row" mb={2} spacing={3}>
          <DatePickerFormField
            name="startDate"
            label="Start date"
            control={control}
            disabled={userRole.isAuditor}
            rules={{
              required: true,
            }}
            {...getTextFieldColorStateProps("startDate", formState.errors)}
          />
          <DatePickerFormField
            name="endDate"
            label="End date"
            control={control}
            disabled={userRole.isAuditor}
            rules={{
              required: true,
            }}
            {...getTextFieldColorStateProps("endDate", formState.errors)}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Controller
            name="collector"
            control={control}
            rules={{ required: true }}
            defaultValue={collector}
            render={({ field }) => (
              <AutocompleteField
                textFieldProps={{
                  label: COLLECTOR_LABEL,
                  ...getTextFieldColorStateProps("collector", formState.errors),
                  disabled: userRole.isAuditor,
                }}
                autocompleteProps={{
                  ...field,
                  onChange: (e, data) => field.onChange(data),
                  options: collectorsOptions,
                  disabled: userRole.isAuditor,
                }}
              />
            )}
          />

          {watchValues.collector?.startsWith(
            UTILITY_BILL_TYPES["VEHICLE FUEL"],
          ) && (
            <IMaskFormTextField
              textFieldProps={{
                label: "Traveled miles",
                fullWidth: true,
                // @ts-ignore
                mask: USNumberMask2,
                disabled: userRole.isAuditor,
                ...getTextFieldColorStateProps(
                  "traveledMiles",
                  formState.errors,
                ),
              }}
              control={control}
              name="traveledMiles"
              rules={{ required: true }}
            />
          )}
          {watchValues.collector?.startsWith(UTILITY_BILL_TYPES.REFRIGERANTS) &&
            allowedValues && (
              <Controller
                name="subtype"
                control={control}
                rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
                render={({ field }) => (
                  <AutocompleteField
                    textFieldProps={{
                      label: "Subtype",
                      ...getTextFieldColorStateProps(
                        "subtype",
                        formState.errors,
                      ),
                    }}
                    autocompleteProps={{
                      ...field,
                      onChange: (e, data) => {
                        field.onChange(data);
                      },
                      options: allowedValues.utilitySubtypesMap.Refrigerants || [],
                    }}
                  />
                )}
              />
            )}
        </Stack>
      </Box>

      <Box component="section" mb={3}>
        <H5Bold mb={2}>Invoice items</H5Bold>
        <Stack direction="row" mb={2} spacing={3}>
          <IMaskFormTextField
            textFieldProps={{
              label: "Usage",
              fullWidth: true,
              // @ts-ignore
              mask: USNumberMask,
              disabled: userRole.isAuditor,
              ...getTextFieldColorStateProps("measuredValue", formState.errors),
            }}
            control={control}
            name="measuredValue"
            rules={{ required: true }}
          />

          <Controller
            name="measuredUnit"
            control={control}
            rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
            render={({ field }) => (
              <AutocompleteField
                textFieldProps={{
                  label: "Unit",
                  ...getTextFieldColorStateProps(
                    "measuredUnit",
                    formState.errors,
                  ),
                }}
                autocompleteProps={{
                  ...field,
                  onChange: (e, data) => {
                    field.onChange(data);
                  },
                  options: availableUnits
                    ? availableUnits.map(
                        (item) => `${item.unit} (${item.description})`,
                      )
                    : [],
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <IMaskFormTextField
            textFieldProps={{
              label: "Cost",
              fullWidth: true,
              // @ts-ignore
              mask: USCurrencyMask,
              disabled: userRole.isAuditor,
              ...getTextFieldColorStateProps("amount", formState.errors),
            }}
            control={control}
            name="amount"
            rules={{ required: true }}
          />

          <Controller
            name="currency"
            control={control}
            rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
            render={({ field }) => (
              <AutocompleteField
                textFieldProps={{
                  label: "Currency",
                  ...getTextFieldColorStateProps("currency", formState.errors),
                }}
                autocompleteProps={{
                  ...field,
                  onChange: (e, data) => {
                    field.onChange(data);
                  },
                  options: allowedValues ? allowedValues.currencies : [],
                }}
              />
            )}
          />
        </Stack>
      </Box>

      <Button
        variant="contained"
        type="submit"
        name="save"
        startIcon={<CheckIcon />}
        onClick={handleSaveClick}
        disabled={isCreating && !userRole.isAuditor}
      >
        Add bill
      </Button>
    </Box>
  );
};
