import { StompSubscription } from "@stomp/stompjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import {
  UtilityBillByIdDTO,
  UtilityBillsDTO,
  UtilityBillsQuery,
  getSavedSearchFilters,
  getUtilityBillById,
  getUtilityBills,
  getUtilityBillsYears,
} from "api";

import { QUERY_KEYS } from "consts";

import { DOCUMENTS_SUMMARY_REFETCH_INTERVAL } from "./consts";
import { OptionalRefetchIntervalAndRetry } from "./types";

import { useWebSocketClient } from "../websocket";

export const useUtilityBills = ({
  refetchInterval = DOCUMENTS_SUMMARY_REFETCH_INTERVAL,
  retry = false,
  ...query
}: UtilityBillsQuery & OptionalRefetchIntervalAndRetry) =>
  useQuery<UtilityBillsDTO>(
    [QUERY_KEYS.UTILITY_BILLS, query],
    () => getUtilityBills(query),
    {
      refetchInterval,
      retry,
    },
  );

export const useUtilityBillById = (id: string) =>
  useQuery<UtilityBillByIdDTO>([QUERY_KEYS.UTILITY_BILLS, id], () =>
    getUtilityBillById(id),
  );

export const useGetSavedSearchFilters = () =>
  useQuery<any>([QUERY_KEYS.UTILITY_BILLS], () => getSavedSearchFilters());

export const useUtilityBillsYears = () =>
  useQuery<number[]>([QUERY_KEYS.UTILITY_BILLS, "years"], getUtilityBillsYears);

export const useUtilityBillWSConnection = (utilityBillId: string) => {
  const wsClient = useWebSocketClient();
  const subscription = useRef<StompSubscription | null>();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (wsClient.connected) {
      subscription.current = wsClient.subscribe(
        `/topic/bill/${utilityBillId}`,
        (payload) => {
          try {
            const utilityBill = JSON.parse(payload.body);
            queryClient.setQueryData(
              [QUERY_KEYS.UTILITY_BILLS, utilityBillId],
              utilityBill,
            );
          } catch (e) {
            console.error(
              "useUtilityBillWSConnection: Could not parse payload from ws!",
            );
          }
        },
      );
    }

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [utilityBillId, wsClient, queryClient, wsClient.connected]);
};
