import { Box } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
} from "react-pdf-highlighter";
import { useParams } from "react-router-dom";

import { Loading } from "components";

import { useUtilityBillById } from "hooks";

import {
  REC_PDF_DOCUMENT_DEFAULT_HEIGHT,
  REC_PDF_DOCUMENT_DEFAULT_WIDTH,
} from "../consts";

import { UTILITY_BILL_PAGE } from "../../../pages/authenticated/documents/document-summary-v2/utility-bill/consts";

const LoadingPDF = () => (
  <Box
    sx={{
      width: REC_PDF_DOCUMENT_DEFAULT_WIDTH,
      height: REC_PDF_DOCUMENT_DEFAULT_HEIGHT,
      position: "relative",
    }}
  >
    <Loading />
  </Box>
);

interface PdfDocumentProps {
  scale: number;
  file: string;
  onLoadSuccess?: ({ numPages }: { numPages: number }) => void;
  pageNumber?: number;
  width: number;
  isScaleChange: boolean;
}

export const RecPdfDocument: FunctionComponent<PdfDocumentProps> = ({
  scale,
  file,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLoadSuccess,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageNumber,
  width,
  isScaleChange,
}) => {
  const { billId } = useParams<{ [UTILITY_BILL_PAGE.KEY]: string }>();

  const { data: utilityBill } = useUtilityBillById(billId || "");

  const BBoxes =
    utilityBill?.bboxes != null ? JSON.parse(utilityBill?.bboxes) : [];
  const dimensionsvalue =
    utilityBill?.dimensions != null ? JSON.parse(utilityBill?.dimensions) : [];
  const searchParams = new URLSearchParams(document.location.search);
  const initialUrl = searchParams.get("url") || file;

  const [rerender, setRerender] = useState(true);

  const setBoxes = (bBoxes: any) => {
    const boxesValues: any = [];
    Object.values(bBoxes)?.map((item: any) => {
      const obj: any = {
        content: {
          text: "",
        },
        position: {
          boundingRect: {
            x1: item?.tlh,
            y1: item?.tlv,
            x2: item?.brh,
            y2: item?.brv,
            width: dimensionsvalue[item.page]?.Width,
            height: dimensionsvalue[item.page]?.Height,
          },
          rects: [
            {
              x1: item?.tlh,
              y1: item?.tlv,
              x2: item?.brh,
              y2: item?.brv,
              width: dimensionsvalue[item.page]?.Width,
              height: dimensionsvalue[item.page]?.Height,
            },
          ],
          pageNumber: item.page + 1,
        },
      };
      return boxesValues?.push(obj);
    });
    return boxesValues;
  };
  const testHighlights: any = {
    [file]: setBoxes(BBoxes),
  };
  const stateHighlights = {
    highlights: testHighlights[initialUrl]
      ? [...testHighlights[initialUrl]]
      : [],
  };
  const renderTip = () => <div />;
  useEffect(() => {
    if (isScaleChange) {
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 100);
    }
  }, [scale, isScaleChange]);
  return (
    <Box
      sx={{
        width,
        height: REC_PDF_DOCUMENT_DEFAULT_HEIGHT,
        // overflowX: scale === 1 ? "hidden" : "auto",
        overflowY: "auto",
        display: "flex",
        // justifyContent: scale === 1 ? "center" : "left",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {rerender ? (
          <PdfLoader url={file} beforeLoad={<LoadingPDF />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                scrollRef={() => {}}
                onSelectionFinished={() => renderTip()}
                pdfScaleValue={String(scale)}
                // eslint-disable-next-line react/no-unstable-nested-components
                highlightTransform={(
                  highlight,
                  index,
                  _setTip,
                  hideTip,
                  _viewportToScaled,
                  _screenshot,
                  isScrolledTo,
                ) => {
                  const isTextHighlight = !(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      isScrolledTo={isScrolledTo}
                      highlight={highlight}
                      onChange={() => {}}
                    />
                  );
                  return (
                    <Popup
                      popupContent={<div />}
                      onMouseOver={() => {}}
                      onMouseOut={hideTip}
                      key={index}
                      // eslint-disable-next-line react/no-children-prop
                      children={component}
                    />
                  );
                }}
                highlights={stateHighlights?.highlights}
                onScrollChange={() => {}}
              />
            )}
          </PdfLoader>
        ) : null}
      </div>
    </Box>
  );
};
