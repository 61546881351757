import { useCallback, useMemo } from "react";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import type { Id, Ids } from "components";

export type SelectedRows = Record<string, boolean>;
export type SelectedRowsGwp = Record<string, boolean>;
export const DEFAULT_SELECTED_ROWS_STATE: SelectedRows = {};

export const createSelectedRadioRowsRecoil = (key: string) => {
  const selectedRadioRowsState = atom<SelectedRows>({
    key: `selected${key}`,
    default: DEFAULT_SELECTED_ROWS_STATE,
  });

  const selectedRadioRowsGWPState = atom<SelectedRowsGwp>({
    key: `selectedGWPState${key}`,
    default: DEFAULT_SELECTED_ROWS_STATE,
  });

  const selectedRadioRowsCount = selector<number>({
    key: `selected${key}Count`,
    get: ({ get }) =>
      Object.values(get(selectedRadioRowsState)).filter((v) => v).length,
  });

  const useSetSelectedRadioRow = () => {
    const [selected, setSelected] = useRecoilState(selectedRadioRowsState);
    return useCallback(
      (guid: Id) => setSelected({ [guid]: !selected[guid] }),
      [selected, setSelected],
    );
  };

  const useSetSelectedRadioGWPRow = () => {
    const [selectedGwp, setSelectedGwp] = useRecoilState(
      selectedRadioRowsGWPState,
    );
    return useCallback(
      (gwp_100a: any) => setSelectedGwp({ [gwp_100a]: !selectedGwp[gwp_100a] }),
      [selectedGwp, setSelectedGwp],
    );
  };

  const useSelectedRadioRows = () => {
    const selected = useRecoilValue(selectedRadioRowsState);
    return useMemo(
      () =>
        Object.entries(selected)
          .filter(([, isSelected]) => isSelected)
          .map(([guid]) => guid),
      [selected],
    );
  };

  const useSetFromIntermediateSelection = () => {
    const [selected, setSelected] = useRecoilState(selectedRadioRowsState);
    return useCallback(
      (guids: Ids, isIntermediate: boolean) =>
        isIntermediate
          ? setSelected({
              ...selected,
              ...guids.reduce((acc, guid) => {
                acc[guid] = isIntermediate;

                return acc;
              }, {} as Record<string, boolean>),
            })
          : setSelected(DEFAULT_SELECTED_ROWS_STATE),
      [selected, setSelected],
    );
  };

  const useSetDefaultSelection = () => {
    const setUserState = useSetRecoilState(selectedRadioRowsState);

    return useCallback(
      () => setUserState(DEFAULT_SELECTED_ROWS_STATE),
      [setUserState],
    );
  };

  const useGetIsIntermediateSelection = () => {
    const selected = useRecoilValue(selectedRadioRowsState);
    const selectedCount = useRecoilValue(selectedRadioRowsCount);

    return useCallback(
      (guids: Ids) =>
        !guids.every((guid) => selected[guid]) && selectedCount !== 0,
      [selected, selectedCount],
    );
  };

  return {
    selectedRadioRowsState,
    selectedRadioRowsCount,
    selectedRadioRowsGWPState,
    useSelectedRadioRows,
    useSetSelectedRadioRow,
    useSetFromIntermediateSelection,
    useSetDefaultSelection,
    useGetIsIntermediateSelection,
    useSetSelectedRadioGWPRow,
  };
};
