import axios, { AxiosRequestTransformer, AxiosResponse } from "axios";

import { initializeHttpClientMock } from "__mocks__";

import { AUTH_PROVIDER, AUTH_PROVIDERS } from "../providers";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8080",
  headers: {
    "Content-Type": "application/json",
    "Content-Security-Policy": `content="default-src 'self'; script-src 'self' ${process.env.REACT_APP_BASE_URL} * 'unsafe-inline'; style-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com 'unsafe-inline'; img-src 'self' ${process.env.REACT_APP_BASE_URL}; font-src 'self'; connect-src 'self' ${process.env.REACT_APP_BASE_URL} *; worker-src 'self' blob:;`
  },
});

export const setHttpClientAuthToken = (accessToken?: string) => {
  if (!accessToken) {
    delete httpClient.defaults.headers.common.Authorization;

    return;
  }

  httpClient.defaults.headers.common.Authorization = ` Bearer ${accessToken}`;
};

if (AUTH_PROVIDERS.NULL === AUTH_PROVIDER) {
  initializeHttpClientMock(httpClient);
}

export const deleteAuthorizationHeader: AxiosRequestTransformer = (
  data,
  headers,
) => {
  if (headers) {
    // @ts-ignore file
    // eslint-disable-next-line no-param-reassign
    delete headers?.common?.Authorization;
  }

  return data;
};

export const getResData = <T>(res: AxiosResponse<T>) => res.data;
