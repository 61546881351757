import { Box, Stack } from "@mui/material";
import React, { FunctionComponent } from "react";

import { MaybeNull } from "types";

import { UtilityBillActions, UtilityBillsPagination } from "./components";

interface PdfFooterProps {
  fileName: MaybeNull<string>;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  downloadUrl: string;
  actionsDisabled: boolean;
  withPagination?: boolean;
  setScaleChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PdfFooter: FunctionComponent<PdfFooterProps> = ({
  fileName,
  setScale,
  downloadUrl,
  actionsDisabled,
  withPagination = true,
  setScaleChange,
}) => (
  <Box
    sx={(theme) => ({
      background: theme.palette.primary.main,
      padding: "12px 24px",
      color: "#ffffff",
    })}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <UtilityBillActions
        actionsDisabled={actionsDisabled}
        downloadUrl={downloadUrl}
        fileName={fileName}
        setScale={setScale}
        setScaleChange={setScaleChange}
      />
      {withPagination ? <UtilityBillsPagination fileName={fileName} /> : null}
    </Stack>
  </Box>
);
